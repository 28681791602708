import { Record, Union } from "../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, union_type, float64_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { singleton } from "../fable_modules/fable-library.3.7.20/List.js";

export class Progress_ProgressTracking extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Indeterminate", "Percentage"];
    }
}

export function Progress_ProgressTracking$reflection() {
    return union_type("Client.JsInteropGeneral.Progress.ProgressTracking", [], Progress_ProgressTracking, () => [[], [["Item", float64_type]]]);
}

export class Progress_ProgressStatus$1 extends Record {
    constructor(stage, tracking) {
        super();
        this.stage = stage;
        this.tracking = tracking;
    }
}

export function Progress_ProgressStatus$1$reflection(gen0) {
    return record_type("Client.JsInteropGeneral.Progress.ProgressStatus`1", [gen0], Progress_ProgressStatus$1, () => [["stage", gen0], ["tracking", Progress_ProgressTracking$reflection()]]);
}

export function Cmd_ofCallbackPromise(task, arg, callback, ofSuccess, ofError) {
    return singleton((dispatch) => {
        task(arg, callback(dispatch)).then((arg_1) => {
            dispatch(ofSuccess(arg_1));
        }).catch((arg_3) => {
            dispatch(ofError(arg_3));
        });
    });
}

