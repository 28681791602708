import { SessionManagementMsg, SessionManagement_update, SessionManagement_SessionManagementModel_token__39A60A33, InactivityConfig_create_ZBA264C0, SessionManagement_init } from "../Session.js";
import { ResetPasswordModel, InactivityModel_get_isActive_, Model_get_inactivity_, InactivityModel_get_loggedOut, InactivityModel_get_warning, Msg, Model, InactivityModel_get_init, ResetPasswordModel_get_empty, InactivityModel_get_timeUntilLogout, InactivityModel_get_timeUntilWarning } from "./AuthTypes.js";
import { Security_ResetPasswordViewModel, Security_ResetPasswordViewModel_get_empty, Security_LoginViewModel, Security_AuthErrorMessage_get_describe, Security_RegisterViewModel_get_empty, Security_LoginViewModel_get_empty } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { Toast_successToast, Urls_Page, Urls_AuthPage, Urls_pageHash, Toast_errorToast } from "../Common/General.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.20/Option.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { securedAuthApi, publicAuthApi } from "../Api.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";

export function init() {
    const patternInput = SessionManagement_init(void 0, InactivityConfig_create_ZBA264C0(InactivityModel_get_timeUntilWarning(), InactivityModel_get_timeUntilLogout()));
    return [new Model(Security_LoginViewModel_get_empty(), Security_RegisterViewModel_get_empty(), ResetPasswordModel_get_empty(), void 0, patternInput[0], InactivityModel_get_init()), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(7)), Cmd_map((arg) => (new Msg(18, arg)), patternInput[1])]))];
}

const errorToast = (() => {
    const f1 = Security_AuthErrorMessage_get_describe();
    return (arg) => Toast_errorToast(f1(arg));
})();

function maybeWithToken(f) {
    let f2_1;
    const value = Cmd_none();
    f2_1 = ((option_1) => defaultArg(option_1, value));
    return (arg_2) => f2_1(map(f, SessionManagement_SessionManagementModel_token__39A60A33(arg_2)));
}

export function update(isInactivityDisabled, msg, model) {
    let l, inputRecord, inputRecord_2, inputRecord_3;
    if (msg.tag === 0) {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 18) {
        const patternInput = SessionManagement_update(isInactivityDisabled, msg.fields[0], model.sessionManagement);
        const patternInput_1 = (msg.fields[0].tag === 7) ? [InactivityModel_get_warning(), Cmd_OfFunc_result(new Msg(19, true))] : ((msg.fields[0].tag === 8) ? [InactivityModel_get_loggedOut(), Cmd_OfFunc_result(new Msg(6))] : [model.inactivity, Cmd_none()]);
        return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, model.userInfo, patternInput[0], patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((arg_1) => (new Msg(18, arg_1)), patternInput[1]), patternInput_1[1]]))];
    }
    else if (msg.tag === 19) {
        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = Model_get_inactivity_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), InactivityModel_get_isActive_())(l)))(msg.fields[0])(model), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new Model(model.loginVm, msg.fields[0](model.registerVm), model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Model(msg.fields[0](model.loginVm), model.registerVm, model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Model(model.loginVm, model.registerVm, (inputRecord = model.resetPasswordModel, new ResetPasswordModel(msg.fields[0](model.resetPasswordModel.resetPasswordVm), inputRecord.resetRequestEmail, inputRecord.hideResetEmail)), model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Model(new Security_LoginViewModel(model.loginVm.email, ""), model.registerVm, model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, publicAuthApi.login, model.loginVm, (arg_4) => (new Msg(5, arg_4)), (arg_5) => (new Msg(0, arg_5)))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            return [new Model(model.loginVm, model.registerVm, new ResetPasswordModel((inputRecord_2 = Security_ResetPasswordViewModel_get_empty(), new Security_ResetPasswordViewModel(model.loginVm.email, msg.fields[0].fields[0].fields[0], inputRecord_2.newPassword, inputRecord_2.confirmPassword)), "", true), void 0, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(18, new SessionManagementMsg(2))), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(1, void 0))))]))];
        }
        else {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(18, new SessionManagementMsg(1, msg.fields[0].fields[0].fields[0]))), Cmd_OfFunc_result(new Msg(7))]))];
        }
    }
    else if (msg.tag === 7) {
        return [model, maybeWithToken((t_2) => Cmd_OfAsyncWith_either((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, securedAuthApi(t_2).getUserInfo, void 0, (arg_8) => (new Msg(8, arg_8)), (arg_9) => (new Msg(0, arg_9))))(model.sessionManagement)];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            const userInfo_1 = msg.fields[0].fields[0];
            return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, userInfo_1, model.sessionManagement, model.inactivity), Cmd_OfFunc_result(new Msg(16, userInfo_1))];
        }
    }
    else if (msg.tag === 6) {
        return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, void 0, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([maybeWithToken((t_3) => Cmd_OfAsyncWith_either((x_2) => {
            Cmd_OfAsync_start(x_2);
        }, securedAuthApi(t_3).logout, void 0, (arg_11) => (new Msg(9, arg_11)), (arg_12) => (new Msg(0, arg_12))))(model.sessionManagement), Cmd_OfFunc_result(new Msg(18, new SessionManagementMsg(2))), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(0))))]))];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_none()];
        }
        else {
            document.location.reload();
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        return [model, Cmd_OfAsyncWith_either((x_3) => {
            Cmd_OfAsync_start(x_3);
        }, publicAuthApi.register, model.registerVm, (arg_14) => (new Msg(11, arg_14)), (arg_15) => (new Msg(0, arg_15)))];
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            return [model, Cmd_batch(ofArray([Toast_successToast("Success"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(0))))]))];
        }
    }
    else if (msg.tag === 12) {
        return [model, Cmd_OfAsyncWith_either((x_4) => {
            Cmd_OfAsync_start(x_4);
        }, publicAuthApi.resetPasswordWithToken, model.resetPasswordModel.resetPasswordVm, (arg_17) => (new Msg(13, arg_17)), (arg_18) => (new Msg(0, arg_18)))];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            return [model, Cmd_batch(ofArray([Toast_successToast("Success, please log in with your new password"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(0))))]))];
        }
    }
    else if (msg.tag === 14) {
        return [new Model(model.loginVm, model.registerVm, (inputRecord_3 = model.resetPasswordModel, new ResetPasswordModel(inputRecord_3.resetPasswordVm, msg.fields[0], inputRecord_3.hideResetEmail)), model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [new Model(model.loginVm, model.registerVm, ResetPasswordModel_get_empty(), model.userInfo, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_5) => {
            Cmd_OfAsync_start(x_5);
        }, publicAuthApi.requestPasswordResetTokenEmail, model.resetPasswordModel.resetRequestEmail, (arg_20) => (new Msg(0, arg_20))), Toast_successToast("An email has been sent to the specified address"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(0))))]))];
    }
    else if (msg.tag === 17) {
        if (msg.fields[0].tag === 2) {
            return [model, Cmd_OfFunc_result(new Msg(7))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else {
        return [model, Cmd_none()];
    }
}

