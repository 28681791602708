import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, array_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { AllMeshData, StudyMeshData, AllMeshData$reflection } from "./MeshesBindings.js";
import { reject, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import * as jszip from "jszip";
import { promise } from "../../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { addToDict } from "../../fable_modules/fable-library.3.7.20/MapUtil.js";
import { exists, length, tryFind, filter, sortBy, map } from "../../fable_modules/fable-library.3.7.20/Seq.js";
import { printf, toText, endsWith, substring } from "../../fable_modules/fable-library.3.7.20/String.js";
import { comparePrimitives } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { map as map_1 } from "../../fable_modules/fable-library.3.7.20/Option.js";

export class ExtractedData extends Record {
    constructor(Dicoms, MeshData) {
        super();
        this.Dicoms = Dicoms;
        this.MeshData = MeshData;
    }
}

export function ExtractedData$reflection() {
    return record_type("RAWMap.Client.Visualization.Meshes.Extraction.ExtractedData", [], ExtractedData, () => [["Dicoms", array_type(class_type("Browser.Types.File", void 0, File))], ["MeshData", AllMeshData$reflection()]]);
}

export function extractData(zipBuffer) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const zip = new jszip();
        return zip.loadAsync(zipBuffer).then((_arg) => {
            const z = _arg;
            const fileData = new Map([]);
            z.forEach((relPath, file) => {
                const zipObj = z.files[file.name];
                const outputByTypePromise = zipObj.async("arraybuffer");
                addToDict(fileData, file.name, outputByTypePromise);
            });
            const dicomFilePromises = map((kvp_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (kvp_2[1].then((_arg_1) => {
                let fileName;
                return Promise.resolve(new File([_arg_1], ((fileName = kvp_2[0], substring(fileName, fileName.lastIndexOf("/") + 1)))));
            })))), sortBy((kvp_1) => kvp_1[0], filter((kvp) => endsWith(kvp[0], ".dcm"), fileData), {
                Compare: comparePrimitives,
            }));
            const getMeshData = (dir) => {
                const asArrayBuffer = (data) => data;
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const getMeshPromise = (fileName_1) => {
                        let fullFileName;
                        const path1 = dir;
                        const path2 = fileName_1;
                        fullFileName = ((path1 === "") ? path2 : toText(printf("%s/%s"))(path1)(path2));
                        const _arg_2 = tryFind((kvp_3) => (kvp_3[0].indexOf(fullFileName) >= 0), fileData);
                        if (_arg_2 != null) {
                            return _arg_2[1];
                        }
                        else {
                            return reject(new Error(toText(printf("Missing %s"))(fullFileName)));
                        }
                    };
                    return getMeshPromise("Anatomy.vtp").then((_arg_3) => {
                        let pr_1, pr;
                        return ((pr_1 = ((pr = getMeshPromise("Diameter_Growth.vtp"), pr.then((arg_4) => arg_4))), pr_1.then(void 0, ((_arg_4) => (void 0))))).then((_arg_5) => (getMeshPromise("Lumen.vtp").then((_arg_6) => (getMeshPromise("Lumen_Centerline.vtp").then((_arg_7) => (getMeshPromise("RAW.vtp").then((_arg_8) => (getMeshPromise("Wall.vtp").then((_arg_9) => (getMeshPromise("Wall_Centerline.vtp").then((_arg_10) => (getMeshPromise("Renal_Artery.vtp").then((_arg_11) => {
                            const meshData = new StudyMeshData(asArrayBuffer(_arg_3), map_1(asArrayBuffer, _arg_5), asArrayBuffer(_arg_6), asArrayBuffer(_arg_7), asArrayBuffer(_arg_8), asArrayBuffer(_arg_9), asArrayBuffer(_arg_10), asArrayBuffer(_arg_11));
                            return Promise.resolve(meshData);
                        })))))))))))));
                    });
                }));
            };
            return ((length(dicomFilePromises) === 0) ? reject(new Error("Missing DICOMs")) : (Promise.all(dicomFilePromises))).then((_arg_12) => (getMeshData("UI_Files").then((_arg_13) => {
                let pr_3;
                return (exists((kvp_5) => (kvp_5[0].indexOf("Previous_Study_Files") === 0), fileData) ? ((pr_3 = getMeshData("Previous_Study_Files"), pr_3.then((arg_6) => arg_6))) : (Promise.resolve(void 0))).then((_arg_14) => {
                    const meshData_1 = new AllMeshData(_arg_13, _arg_14);
                    return Promise.resolve(new ExtractedData(_arg_12, meshData_1));
                });
            })));
        });
    }));
}

