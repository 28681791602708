import { record_type, tuple_type, option_type, float64_type, union_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { PickedMapValueProps, MapValuePickedArgs$reflection } from "./PickedMapValueBindings.js";
import { MeshViewportLocation_parse_1505, MeshViewportLocation$reflection } from "../Common/CommonTypes.js";
import { DisplayMap_get_tryGetUnits, DisplayMap$reflection } from "../Common/CommonBindings.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { FSharpMap__get_Item } from "../../fable_modules/fable-library.3.7.20/Map.js";
import { ofArray, toArray } from "../../fable_modules/fable-library.3.7.20/List.js";
import { defaultArg, map, toArray as toArray_1 } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { printf, interpolate, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { NotesProps, StaticLabel } from "../Notes/NotesBindings.js";

export class LocalMsg {
    constructor() {
    }
}

export function LocalMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.PickedMapValue.Types.LocalMsg", void 0, LocalMsg);
}

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MapValuePicked", "ClearPickedValues"];
    }
}

export function InboundMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.PickedMapValue.Types.InboundMsg", [], InboundMsg, () => [[["Item", MapValuePickedArgs$reflection()]], [["Item", MeshViewportLocation$reflection()]]]);
}

export class OutboundMsg {
    constructor() {
    }
}

export function OutboundMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.PickedMapValue.Types.OutboundMsg", void 0, OutboundMsg);
}

export class ModelPickedMapValue extends Record {
    constructor(PickedMapValues, LabelId) {
        super();
        this.PickedMapValues = PickedMapValues;
        this.LabelId = LabelId;
    }
}

export function ModelPickedMapValue$reflection() {
    return record_type("RAWMap.Client.Visualization.PickedMapValue.Types.ModelPickedMapValue", [], ModelPickedMapValue, () => [["PickedMapValues", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [MeshViewportLocation$reflection(), tuple_type(DisplayMap$reflection(), option_type(float64_type))])], ["LabelId", class_type("System.Guid")]]);
}

export function ModelPickedMapValue_Props(model, viewportLocation, dispatch) {
    let patternInput;
    return new PickedMapValueProps((arg_3) => {
        dispatch(wrapInboundMsg(new InboundMsg(0, arg_3)));
    }, new NotesProps((patternInput = FSharpMap__get_Item(model.PickedMapValues, viewportLocation), toArray(ofArray(toArray_1(map((pickedValue) => {
        let option, clo;
        return new StaticLabel(model.LabelId, toText(interpolate("Point value: %0.2f%P()%P()", [pickedValue, defaultArg((option = DisplayMap_get_tryGetUnits()(patternInput[0]), map((clo = toText(printf(" %s")), clo), option)), "")])), 0, 0.05, 0.25, void 0, void 0, false, false);
    }, patternInput[1]))))), (x_1) => x_1, [], (value_1) => {
    }), (arg_8) => {
        dispatch(wrapInboundMsg(new InboundMsg(1, MeshViewportLocation_parse_1505(arg_8))));
    });
}

