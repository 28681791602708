import { ModelCamera } from "./CameraTypes.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_1 } from "../../Common/InboundOutbound.js";

export function init() {
    return [new ModelCamera(void 0, false), Cmd_none()];
}

export function updateInbound(token, msg, model) {
    if (model.SyncViewports) {
        return [new ModelCamera(msg.fields[0], model.SyncViewports), Cmd_none(), Cmd_none()];
    }
    else {
        return [model, Cmd_none(), Cmd_none()];
    }
}

export function updateLocal(token, msg, model) {
    return [new ModelCamera(void 0, msg.fields[0]), Cmd_none(), Cmd_none()];
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

