import { getSignedUrlPromise, S3Context as S3Context_1 } from "../Common/AwsCommon.js";
import { MeasurementTypes, OutboundMsg, Compare_currentStudyViewport, Compare_previousStudyViewport, DicomData as DicomData_1, Meshes, VisualizationViewMode, LoadingStatus as LoadingStatus_4, Meshes_Current_567A3FC6, LocalMsg, ModelVisualization, DisplayMap_nativeAortaMapsV1_6, DisplayMap_nativeAortaMapsV1_5, DisplayMap_withGrowthMapsV1_6, DisplayMap_withGrowthMapsV1_5, DisplayMap_evarMaps } from "./VisualizationTypes.js";
import { InteractionMode as InteractionMode_1, DisplayMap } from "./Common/CommonBindings.js";
import { map as map_3, ofArray, exists, fold, tryFind, empty, singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import { exists as exists_1, tryPick, ofList, empty as empty_1, tryFind as tryFind_1, map as map_1, find, add, pick, filter, count } from "../fable_modules/fable-library.3.7.20/Map.js";
import { equals } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Shared_IntersectionPlane_get_ofOrder, Shared_IntersectionPlane, Shared_IntersectionPlane_getNormalVector_Z13D484F4 } from "./VisualizationShared.js";
import { UnifiedMeasurementEntry$2, CoreMeasurementValues, UnifiedMeasurementEntry_ofSplitEntry, UnifiedMeasurementEntry_UnifyCallbackArgs$2, SplitMeasurementEntry_mkDoubleMsgCallback, MeasurementCardArgs$3, EditMode } from "./MeasurementsView.js";
import { Cmd_OfPromise_either, Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Toast_errorToast } from "../Common/General.js";
import { value as value_2, bind, some, map as map_2, defaultArg, orElse } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Viewport } from "./VisualizationBindings.js";
import { MeshViewportLocation, MeshViewportLocation_get_all } from "./Common/CommonTypes.js";
import { Visualization_LabelPositionPercentage, Common_StudyStatus } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library.3.7.20/Date.js";
import { update as update_1, init as init_1 } from "./Notes/NotesState.js";
import { update as update_2, init as init_2 } from "./Camera/CameraState.js";
import { update as update_3, init as init_3 } from "./Calipers/CalipersState.js";
import { update as update_4, init as init_4 } from "./Screenshot/ScreenshotState.js";
import { ScreenshotUpdateArgs, LocalMsg as LocalMsg_1, Mode } from "./Screenshot/ScreenshotTypes.js";
import { update as update_5, init as init_5 } from "./SlicePlane/SlicePlaneState.js";
import { update as update_7, init as init_6 } from "./PickedMapValue/PickedMapValueState.js";
import { update as update_6, init as init_7 } from "./AnatomyOrientation/AnatomyOrientationState.js";
import { update, init as init_8 } from "./CenterlineMeasurement/CenterlineMeasurementState.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.20/AsyncBuilder.js";
import { parallel, awaitPromise } from "../fable_modules/fable-library.3.7.20/Async.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.6.0/Fetch.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { extractData } from "./Meshes/MeshExtraction.js";
import { getDataFilesVersion, mkMeshes } from "../../../src/RAWMap.Client/Visualization/Meshes/Meshes.js";
import { InboundMsg } from "./CenterlineMeasurement/CenterlineMeasurementTypes.js";
import { update as update_8, wrapLocalMsg, wrapInboundMsg, InternalMsg$2 } from "../Common/InboundOutbound.js";
import { InboundMsg as InboundMsg_1 } from "./Notes/NotesTypes.js";
import { CalipersMeasurementArgs, CalipersUpdateArgs } from "./Calipers/CalipersTypes.js";

export function setupS3Context(context) {
    return new S3Context_1(context.S3Config, context.S3Client, context.S3Expiry, context.S3Config.PermanentBucket);
}

export function getAvailableMaps(isFollowup, hasDiametricGrowth, analysisPipelineVersion, institutionDesignation, svm) {
    const matchValue = [analysisPipelineVersion, institutionDesignation, isFollowup, hasDiametricGrowth, svm.isPostTreatment, svm.postTreatTreatmentType];
    let pattern_matching_result;
    if (matchValue[1].tag === 1) {
        if (matchValue[4] != null) {
            if (matchValue[4].tag === 1) {
                if (matchValue[0] === 1.5) {
                    if (matchValue[2]) {
                        pattern_matching_result = 7;
                    }
                    else {
                        pattern_matching_result = 5;
                    }
                }
                else if (matchValue[2]) {
                    pattern_matching_result = 9;
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else if (matchValue[5] == null) {
                pattern_matching_result = 11;
            }
            else if (matchValue[5].tag === 0) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else if (matchValue[5] == null) {
            pattern_matching_result = 11;
        }
        else if (matchValue[5].tag === 1) {
            pattern_matching_result = 11;
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[2]) {
        if (matchValue[0] === 1.5) {
            if (matchValue[3]) {
                if (matchValue[4] != null) {
                    if (matchValue[4].tag === 1) {
                        pattern_matching_result = 8;
                    }
                    else if (matchValue[5] == null) {
                        pattern_matching_result = 11;
                    }
                    else if (matchValue[5].tag === 0) {
                        pattern_matching_result = 11;
                    }
                    else {
                        pattern_matching_result = 2;
                    }
                }
                else if (matchValue[5] == null) {
                    pattern_matching_result = 11;
                }
                else if (matchValue[5].tag === 1) {
                    pattern_matching_result = 11;
                }
                else {
                    pattern_matching_result = 11;
                }
            }
            else if (matchValue[4] != null) {
                if (matchValue[4].tag === 1) {
                    pattern_matching_result = 7;
                }
                else if (matchValue[5] == null) {
                    pattern_matching_result = 11;
                }
                else if (matchValue[5].tag === 0) {
                    pattern_matching_result = 11;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else if (matchValue[5] == null) {
                pattern_matching_result = 11;
            }
            else if (matchValue[5].tag === 1) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else if (matchValue[3]) {
            if (matchValue[4] != null) {
                if (matchValue[4].tag === 1) {
                    pattern_matching_result = 10;
                }
                else if (matchValue[5] == null) {
                    pattern_matching_result = 11;
                }
                else if (matchValue[5].tag === 0) {
                    pattern_matching_result = 11;
                }
                else {
                    pattern_matching_result = 3;
                }
            }
            else if (matchValue[5] == null) {
                pattern_matching_result = 11;
            }
            else if (matchValue[5].tag === 1) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else if (matchValue[4] != null) {
            if (matchValue[4].tag === 1) {
                pattern_matching_result = 9;
            }
            else if (matchValue[5] == null) {
                pattern_matching_result = 11;
            }
            else if (matchValue[5].tag === 0) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (matchValue[5] == null) {
            pattern_matching_result = 11;
        }
        else if (matchValue[5].tag === 1) {
            pattern_matching_result = 11;
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[4] != null) {
        if (matchValue[4].tag === 1) {
            if (matchValue[0] === 1.5) {
                pattern_matching_result = 5;
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else if (matchValue[5] == null) {
            pattern_matching_result = 11;
        }
        else if (matchValue[5].tag === 0) {
            pattern_matching_result = 11;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (matchValue[5] == null) {
        pattern_matching_result = 11;
    }
    else if (matchValue[5].tag === 1) {
        pattern_matching_result = 11;
    }
    else {
        pattern_matching_result = 11;
    }
    switch (pattern_matching_result) {
        case 0: {
            return DisplayMap_evarMaps;
        }
        case 1: {
            return singleton(new DisplayMap(0));
        }
        case 2: {
            return DisplayMap_withGrowthMapsV1_5(DisplayMap_evarMaps);
        }
        case 3: {
            return DisplayMap_withGrowthMapsV1_6(DisplayMap_evarMaps);
        }
        case 4: {
            return DisplayMap_evarMaps;
        }
        case 5: {
            return DisplayMap_nativeAortaMapsV1_5;
        }
        case 6: {
            return DisplayMap_nativeAortaMapsV1_6;
        }
        case 7: {
            return DisplayMap_nativeAortaMapsV1_5;
        }
        case 8: {
            return DisplayMap_withGrowthMapsV1_5(DisplayMap_nativeAortaMapsV1_5);
        }
        case 9: {
            return DisplayMap_nativeAortaMapsV1_6;
        }
        case 10: {
            return DisplayMap_withGrowthMapsV1_6(DisplayMap_nativeAortaMapsV1_6);
        }
        case 11: {
            return empty();
        }
    }
}

export function findViewPlane(viewportLocation, viewport, viewportMaps) {
    const numberSelected = count(filter((vpl, vp) => {
        if (!equals(vpl, viewportLocation)) {
            return equals(vp.map, viewport.map);
        }
        else {
            return false;
        }
    }, viewportMaps)) | 0;
    return pick((vpl_1, _vp) => {
        if (equals(vpl_1, viewportLocation)) {
            const planeNormal = Shared_IntersectionPlane_getNormalVector_Z13D484F4((numberSelected === 0) ? (new Shared_IntersectionPlane(0)) : (new Shared_IntersectionPlane(1)));
            return new Float64Array([planeNormal.x, planeNormal.y, planeNormal.z]);
        }
        else {
            return void 0;
        }
    }, viewportMaps);
}

export function updateMeasurements(model, measurementType, measurements, selection) {
    let inputRecord_5;
    const findMatch = (measurementId) => tryFind((item) => (item.measurementIdUnified === measurementId), measurements);
    let patternInput;
    const matchValue = [model, selection];
    let pattern_matching_result, active_2, active_3, newMode_2, selected_2;
    if (matchValue[0].Measurements.activeMeasurement != null) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 0;
            active_2 = matchValue[0].Measurements.activeMeasurement;
        }
        else if (matchValue[1].tag === 3) {
            if (matchValue[1].fields[1].tag === 2) {
                pattern_matching_result = 2;
            }
            else if (matchValue[0].Measurements.activeMeasurement.measurementIdUnified === matchValue[1].fields[0].measurementEntryIdSplit) {
                pattern_matching_result = 3;
                active_3 = matchValue[0].Measurements.activeMeasurement;
                newMode_2 = matchValue[1].fields[1];
                selected_2 = matchValue[1].fields[0];
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[1].tag === 2) {
                pattern_matching_result = 2;
            }
            else if (matchValue[0].Measurements.activeMeasurement.measurementIdUnified === matchValue[1].fields[0].measurementEntryIdSplit) {
                pattern_matching_result = 3;
                active_3 = matchValue[0].Measurements.activeMeasurement;
                newMode_2 = matchValue[1].fields[1];
                selected_2 = matchValue[1].fields[0];
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[1].tag === 1) {
        pattern_matching_result = 1;
    }
    else if (matchValue[1].tag === 3) {
        if (matchValue[1].fields[1].tag === 2) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[1].tag === 2) {
        if (matchValue[1].fields[1].tag === 2) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            patternInput = [new MeasurementCardArgs$3(void 0, new EditMode(0), model.Measurements.entries), active_2.callbacksUnified.onEntryDeselected.childCallback.commandEvent(active_2, model)];
            break;
        }
        case 1: {
            patternInput = [new MeasurementCardArgs$3(void 0, new EditMode(0), model.Measurements.entries), Cmd_none()];
            break;
        }
        case 2: {
            patternInput = [model.Measurements, Toast_errorToast("Unable to set mode to save.")];
            break;
        }
        case 3: {
            patternInput = [new MeasurementCardArgs$3(orElse(findMatch(selected_2.measurementEntryIdSplit), model.Measurements.activeMeasurement), newMode_2, model.Measurements.entries), Cmd_none()];
            break;
        }
        case 4: {
            let pattern_matching_result_1, currentlyActive, newMode_3, selected_3, newMode_4, selected_4, active_4, active_5, newMode_5, selected_5;
            if (matchValue[0].Measurements.activeMeasurement == null) {
                if (matchValue[1].tag === 2) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 3) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 2;
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else if (matchValue[0].Measurements.editMode.tag === 2) {
                if (matchValue[1].tag === 2) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 3) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 3;
                    active_4 = matchValue[0].Measurements.activeMeasurement;
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else if (matchValue[0].Measurements.editMode.tag === 3) {
                if (matchValue[1].tag === 2) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 3) {
                    pattern_matching_result_1 = 1;
                    newMode_4 = matchValue[1].fields[1];
                    selected_4 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 3;
                    active_4 = matchValue[0].Measurements.activeMeasurement;
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else if (matchValue[0].Measurements.editMode.tag === 1) {
                if (matchValue[1].tag === 2) {
                    pattern_matching_result_1 = 3;
                    active_4 = matchValue[0].Measurements.activeMeasurement;
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 3;
                    active_4 = matchValue[0].Measurements.activeMeasurement;
                }
                else if (matchValue[1].tag === 3) {
                    pattern_matching_result_1 = 4;
                    active_5 = matchValue[0].Measurements.activeMeasurement;
                    newMode_5 = matchValue[1].fields[1];
                    selected_5 = matchValue[1].fields[0];
                }
                else {
                    pattern_matching_result_1 = 5;
                }
            }
            else if (matchValue[1].tag === 2) {
                pattern_matching_result_1 = 0;
                currentlyActive = matchValue[0].Measurements.activeMeasurement;
                newMode_3 = matchValue[1].fields[1];
                selected_3 = matchValue[1].fields[0];
            }
            else if (matchValue[1].tag === 3) {
                pattern_matching_result_1 = 0;
                currentlyActive = matchValue[0].Measurements.activeMeasurement;
                newMode_3 = matchValue[1].fields[1];
                selected_3 = matchValue[1].fields[0];
            }
            else if (matchValue[1].tag === 0) {
                pattern_matching_result_1 = 3;
                active_4 = matchValue[0].Measurements.activeMeasurement;
            }
            else {
                pattern_matching_result_1 = 5;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const deselectActive_1 = currentlyActive.callbacksUnified.onEntryDeselected.childCallback.commandEvent(currentlyActive, model);
                    patternInput = [new MeasurementCardArgs$3(orElse(findMatch(selected_3.measurementEntryIdSplit), model.Measurements.activeMeasurement), newMode_3, model.Measurements.entries), deselectActive_1];
                    break;
                }
                case 1: {
                    patternInput = [new MeasurementCardArgs$3(orElse(findMatch(selected_4.measurementEntryIdSplit), model.Measurements.activeMeasurement), newMode_4, model.Measurements.entries), Cmd_none()];
                    break;
                }
                case 2: {
                    patternInput = [model.Measurements, Cmd_none()];
                    break;
                }
                case 3: {
                    patternInput = [(inputRecord_5 = model.Measurements, new MeasurementCardArgs$3(findMatch(active_4.measurementIdUnified), inputRecord_5.editMode, inputRecord_5.entries)), Cmd_none()];
                    break;
                }
                case 4: {
                    const active_6 = defaultArg(findMatch(active_5.measurementIdUnified), active_5);
                    const deactivateActive = active_6.callbacksUnified.onEntryEditCancelled.parentCallback.commandEvent(active_6, model);
                    patternInput = [new MeasurementCardArgs$3(defaultArg(findMatch(selected_5.measurementEntryIdSplit), active_6), newMode_5, model.Measurements.entries), deactivateActive];
                    break;
                }
                case 5: {
                    throw (new Error("Match failure: Microsoft.FSharp.Core.FSharpOption`1"));
                    break;
                }
            }
            break;
        }
    }
    const newMeasurements = patternInput[0];
    return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, new MeasurementCardArgs$3(newMeasurements.activeMeasurement, newMeasurements.editMode, add(measurementType, measurements, model.Measurements.entries)), model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), patternInput[1]];
}

export function updateSinglePlane(viewportLocation, viewportMaps) {
    return add(viewportLocation, find(viewportLocation, map_1((vpl, vp) => (new Viewport(vp.map, findViewPlane(vpl, vp, viewportMaps))), viewportMaps)), viewportMaps);
}

export function updatePlanes(viewportMaps, mapCount, viewportLocation) {
    const workingLocation = viewportLocation;
    const workingViewport = find(workingLocation, viewportMaps);
    const viewportCount = defaultArg(tryFind_1(workingViewport.map, mapCount), 0) | 0;
    const plane = Shared_IntersectionPlane_getNormalVector_Z13D484F4(Shared_IntersectionPlane_get_ofOrder()(viewportCount));
    return [add(workingLocation, new Viewport(workingViewport.map, new Float64Array([plane.x, plane.y, plane.z])), viewportMaps), add(workingViewport.map, viewportCount + 1, mapCount)];
}

export function updateViewPlanes(viewportMaps) {
    let list;
    return ((list = MeshViewportLocation_get_all(), fold((tupledArg, viewportLocation) => updatePlanes(tupledArg[0], tupledArg[1], viewportLocation), [viewportMaps, empty_1()], list)))[0];
}

export function selectEntry(entry, model) {
    return new LocalMsg(1, entry.measurementIdUnified);
}

export function deselectEntry(entry, model) {
    return new LocalMsg(2);
}

export function changeEditMode(editMode, entry, model) {
    return new LocalMsg(3, editMode);
}

export function editEntry(editMode, e, entry, model, dispatch) {
    return dispatch(new LocalMsg(3, editMode));
}

export function saveEntry(entry, model) {
    return new LocalMsg(3, new EditMode(2));
}

export function cancelEditingEntry(entry, model) {
    return new LocalMsg(3, new EditMode(0));
}

export function deleteRequested(entry, model) {
    return new LocalMsg(3, new EditMode(3));
}

export function deleteConfirmed(entry, model) {
    return new LocalMsg(3, new EditMode(0));
}

export function makeUnified(args) {
    let unifyCallbackArgs;
    const selected = SplitMeasurementEntry_mkDoubleMsgCallback(selectEntry);
    const deselected = SplitMeasurementEntry_mkDoubleMsgCallback(deselectEntry);
    const startEditing = SplitMeasurementEntry_mkDoubleMsgCallback((entry_2, model_2) => changeEditMode(new EditMode(1), entry_2, model_2));
    const cancelled = SplitMeasurementEntry_mkDoubleMsgCallback(cancelEditingEntry);
    const saved = SplitMeasurementEntry_mkDoubleMsgCallback(saveEntry);
    unifyCallbackArgs = (new UnifiedMeasurementEntry_UnifyCallbackArgs$2(selected, deselected, startEditing, cancelled, SplitMeasurementEntry_mkDoubleMsgCallback(deleteRequested), SplitMeasurementEntry_mkDoubleMsgCallback(deleteConfirmed), saved));
    return (msgConversion) => ((splitEntry) => UnifiedMeasurementEntry_ofSplitEntry(unifyCallbackArgs, (editMode_1, e, entry_7, model_7, dispatch) => {
        editEntry(editMode_1, e, entry_7, model_7, dispatch);
    }, args, msgConversion, splitEntry));
}

const getCurrentStudyMeshes = (table) => map_1((_arg, v) => map_2(Meshes_Current_567A3FC6, v), table);

export function init(s3Context, studies, selectedPatientId, selectedStudy, visualizationDataFile, institutionDesignation, maybeCurrentUser) {
    const isFollowup = exists((previousStudy) => {
        if (((previousStudy.patientId === selectedStudy.patientId) && (previousStudy.studyId !== selectedStudy.studyId)) && equals(previousStudy.status, new Common_StudyStatus(4))) {
            return compare(previousStudy.studyCreationDate, selectedStudy.studyCreationDate) < 0;
        }
        else {
            return false;
        }
    }, studies);
    const temporaryDefaultSelected = new Viewport(new DisplayMap(0), new Float64Array([]));
    const maps = updateViewPlanes(ofList(ofArray([[new MeshViewportLocation(0), temporaryDefaultSelected], [new MeshViewportLocation(1), temporaryDefaultSelected], [new MeshViewportLocation(2), temporaryDefaultSelected]])));
    const meshes = ofList(map_3((location) => [location, void 0], MeshViewportLocation_get_all()));
    const s3Context_1 = setupS3Context(s3Context);
    const patternInput = init_1(selectedStudy.studyId, maybeCurrentUser);
    const patternInput_1 = init_2();
    const patternInput_2 = init_3(selectedStudy.studyId);
    const patternInput_3 = init_4(new Mode(0), selectedPatientId, selectedStudy.studyId);
    const patternInput_4 = init_5();
    const patternInput_5 = init_6();
    const patternInput_6 = init_7();
    const patternInput_7 = init_8(selectedStudy.studyId, getCurrentStudyMeshes(meshes));
    return [new ModelVisualization(maybeCurrentUser, institutionDesignation, visualizationDataFile, new LoadingStatus_4(0), meshes, false, false, false, false, isFollowup, void 0, patternInput_4[0], patternInput[0], patternInput_1[0], patternInput_2[0], patternInput_7[0], patternInput_3[0], patternInput_6[0], patternInput_5[0], s3Context_1, empty(), maps, empty_1(), selectedStudy, selectedPatientId, new VisualizationViewMode(2), false, new InteractionMode_1(0), new MeasurementCardArgs$3(void 0, new EditMode(0), empty_1()), false, 0, void 0), Cmd_batch(ofArray([Cmd_map((arg) => (new LocalMsg(19, arg)), patternInput[1]), Cmd_map((arg_1) => (new LocalMsg(20, arg_1)), patternInput_1[1]), Cmd_map((arg_2) => (new LocalMsg(21, arg_2)), patternInput_2[1]), Cmd_map((arg_3) => (new LocalMsg(22, arg_3)), patternInput_7[1]), Cmd_map((arg_4) => (new LocalMsg(23, arg_4)), patternInput_3[1]), Cmd_map((arg_5) => (new LocalMsg(24, arg_5)), patternInput_4[1]), Cmd_map((arg_6) => (new LocalMsg(25, arg_6)), patternInput_6[1]), Cmd_map((arg_7) => (new LocalMsg(26, arg_7)), patternInput_5[1])]))];
}

export function fetchDataAsArrayBuffer(url) {
    return singleton_1.Delay(() => singleton_1.Bind(awaitPromise(fetch$(url, empty())), (_arg) => singleton_1.Bind(awaitPromise(_arg.arrayBuffer()), (_arg_1) => singleton_1.Return(_arg_1))));
}

export function fetchManyAsArrayBuffer(urls) {
    return parallel(map_3(fetchDataAsArrayBuffer, urls));
}

export function updateInbound(token, msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfFunc_result(new LocalMsg(4)), Cmd_none()];
    }
    else {
        return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, setupS3Context(msg.fields[1]), model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
    }
}

export function updateLocal(token, msg, model) {
    let arg, inputRecord, matchValue_1, matchValue_2, inputRecord_4, inputRecord_5, matchValue_5;
    let pattern_matching_result, ex, newEditMode, maybeId, url, arrayBuffer, arrayBuffer_1, extractedData, ex_1, extractedData_1, meshData, note, files, bounds, newMode, displayMap, viewportLocation, isEnabled, isEnabled_1, msg_29, mode, status, msg_31, msg_32, measurements_1, selection, msg_34, measurements_2, selection_1, msg_36, msg_38, msg_39, msg_41;
    if (msg.tag === 3) {
        pattern_matching_result = 1;
        newEditMode = msg.fields[0];
    }
    else if (msg.tag === 1) {
        pattern_matching_result = 2;
        maybeId = msg.fields[0];
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 3;
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 4;
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 5;
        url = msg.fields[0];
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 6;
        arrayBuffer = msg.fields[0];
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 7;
        arrayBuffer_1 = msg.fields[0];
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 8;
        extractedData = msg.fields[0];
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 9;
        ex_1 = msg.fields[0];
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 10;
        extractedData_1 = msg.fields[0];
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 11;
        meshData = msg.fields[0];
    }
    else if (msg.tag === 27) {
        pattern_matching_result = 12;
        note = msg.fields[0];
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 13;
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 14;
        files = msg.fields[0];
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 15;
        bounds = msg.fields[0].fields[0];
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 16;
        newMode = msg.fields[0];
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 17;
        displayMap = msg.fields[1];
        viewportLocation = msg.fields[0];
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 18;
        isEnabled = msg.fields[0];
    }
    else if (msg.tag === 18) {
        pattern_matching_result = 19;
        isEnabled_1 = msg.fields[0];
    }
    else if (msg.tag === 19) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                pattern_matching_result = 22;
                status = msg.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 21;
                mode = msg.fields[0].fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 20;
            msg_29 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 25;
            msg_32 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 22;
            status = msg.fields[0].fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            pattern_matching_result = 26;
            measurements_1 = msg.fields[0].fields[0].fields[0].allEntries;
            selection = msg.fields[0].fields[0].fields[0].selectionAction;
        }
        else {
            pattern_matching_result = 21;
            mode = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 22) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 27;
            msg_34 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 22;
            status = msg.fields[0].fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            pattern_matching_result = 28;
            measurements_2 = msg.fields[0].fields[0].fields[0].allEntries;
            selection_1 = msg.fields[0].fields[0].fields[0].selectionAction;
        }
        else {
            pattern_matching_result = 21;
            mode = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 23) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 29;
            msg_36 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 22;
            status = msg.fields[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 21;
            mode = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 20) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 23;
            msg_31 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 24;
        }
    }
    else if (msg.tag === 24) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 31;
            msg_38 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 30;
        }
    }
    else if (msg.tag === 25) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 33;
        }
        else {
            pattern_matching_result = 32;
            msg_39 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 26) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 35;
        }
        else {
            pattern_matching_result = 34;
            msg_41 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 28) {
        pattern_matching_result = 36;
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(ex));
            return [model, Toast_errorToast((arg = ex.message, toText(printf("Operation failed, please contact ViTAA Support. Error: %s"))(arg))), Cmd_none()];
        }
        case 1: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, (inputRecord = model.Measurements, new MeasurementCardArgs$3(inputRecord.activeMeasurement, newEditMode, inputRecord.entries)), model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 2: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, new MeasurementCardArgs$3(tryPick((_arg, measurements) => tryFind((entry) => equals(entry.measurementIdUnified, maybeId), measurements), model.Measurements.entries), new EditMode(0), model.Measurements.entries), model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 3: {
            const matchValue = model.Measurements;
            if (matchValue.editMode.tag === 1) {
                return [model, Toast_errorToast("Can\u0027t deselect while editing"), Cmd_none()];
            }
            else if (matchValue.activeMeasurement == null) {
                return [model, Toast_errorToast("Can\u0027t deselect while no active entry"), Cmd_none()];
            }
            else {
                return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, new MeasurementCardArgs$3(void 0, new EditMode(0), model.Measurements.entries), model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
            }
        }
        case 4: {
            return [model, Cmd_OfPromise_either((fileName) => getSignedUrlPromise(model.S3Context, fileName), model.VisualizationDataFile, (arg_1) => (new LocalMsg(6, arg_1)), (arg_2) => (new LocalMsg(0, arg_2))), Cmd_none()];
        }
        case 5: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, new LoadingStatus_4(1), model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, fetchDataAsArrayBuffer, url, (arg_3) => (new LocalMsg(7, arg_3)), (arg_4) => (new LocalMsg(0, arg_4))), Cmd_none()];
        }
        case 6: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, new LoadingStatus_4(2), model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_OfFunc_result(new LocalMsg(8, arrayBuffer)), Cmd_none()];
        }
        case 7: {
            return [model, Cmd_OfPromise_either(extractData, arrayBuffer_1, (arg_7) => (new LocalMsg(9, arg_7)), (arg_8) => (new LocalMsg(10, arg_8))), Cmd_none()];
        }
        case 8: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, new LoadingStatus_4(3), model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_OfFunc_result(new LocalMsg(11, extractedData)), Cmd_none()];
        }
        case 9: {
            return [model, Cmd_batch(ofArray([Toast_errorToast(ex_1.message), Cmd_OfFunc_result(new LocalMsg(0, ex_1))])), Cmd_none()];
        }
        case 10: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, new LoadingStatus_4(3), model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_batch(map_3(Cmd_OfFunc_result, ofArray([new LocalMsg(12, extractedData_1.Dicoms), new LocalMsg(13, extractedData_1.MeshData)]))), Cmd_none()];
        }
        case 11: {
            const currentStudyMesh = mkMeshes(meshData.currentStudy);
            const maybePreviousStudyMesh = map_2(mkMeshes, meshData.previousStudy);
            const currentStudyVersion = getDataFilesVersion(currentStudyMesh);
            const maybePrevStudyVersion = map_2(getDataFilesVersion, maybePreviousStudyMesh);
            const hasDiametricGrowth = meshData.currentStudy.maybeDiametricGrowth != null;
            const hasPreviousDiametricGrowth = bind((s_1) => s_1.maybeDiametricGrowth, meshData.previousStudy) != null;
            const availableMaps = getAvailableMaps(model.IsFollowupStudy, hasDiametricGrowth, currentStudyVersion, model.PatientInstitutionDesignation, model.SelectedStudy);
            const getMap = (map) => defaultArg(orElse(tryFind((y) => equals(map, y), availableMaps), tryFind((y_1) => (!equals(new DisplayMap(0), y_1)), availableMaps)), new DisplayMap(0));
            const maps = updateViewPlanes(ofList(ofArray([[new MeshViewportLocation(0), new Viewport(new DisplayMap(0), new Float64Array([]))], [new MeshViewportLocation(1), new Viewport((matchValue_1 = [model.PatientInstitutionDesignation, model.IsFollowupStudy, model.SelectedStudy.isPostTreatment, model.SelectedStudy.postTreatTreatmentType], (matchValue_1[2] != null) ? ((matchValue_1[2].tag === 0) ? ((matchValue_1[0].tag === 0) ? ((matchValue_1[3] != null) ? ((matchValue_1[3].tag === 1) ? getMap(new DisplayMap(6)) : (new DisplayMap(0))) : (new DisplayMap(0))) : (new DisplayMap(0))) : getMap(new DisplayMap(4))) : (new DisplayMap(0))), new Float64Array([]))], [new MeshViewportLocation(2), new Viewport((matchValue_2 = [model.PatientInstitutionDesignation, model.IsFollowupStudy, model.SelectedStudy.isPostTreatment, model.SelectedStudy.postTreatTreatmentType], matchValue_2[1] ? ((matchValue_2[0].tag === 0) ? ((matchValue_2[2] != null) ? ((matchValue_2[2].tag === 0) ? ((matchValue_2[3] != null) ? ((matchValue_2[3].tag === 1) ? getMap(new DisplayMap(2)) : (new DisplayMap(0))) : (new DisplayMap(0))) : getMap(new DisplayMap(2))) : (new DisplayMap(0))) : ((matchValue_2[2] != null) ? ((matchValue_2[2].tag === 1) ? getMap(new DisplayMap(6)) : (new DisplayMap(0))) : (new DisplayMap(0)))) : ((matchValue_2[2] != null) ? ((matchValue_2[2].tag === 0) ? ((matchValue_2[0].tag === 0) ? ((matchValue_2[3] != null) ? ((matchValue_2[3].tag === 1) ? getMap(new DisplayMap(6)) : (new DisplayMap(0))) : (new DisplayMap(0))) : (new DisplayMap(0))) : getMap(new DisplayMap(6))) : (new DisplayMap(0)))), new Float64Array([]))]])));
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, ofList(map_3((location) => [location, (location.tag === 0) ? (new Meshes(currentStudyMesh, maybePreviousStudyMesh)) : (new Meshes(mkMeshes(meshData.currentStudy), map_2(mkMeshes, meshData.previousStudy)))], MeshViewportLocation_get_all())), meshData.previousStudy != null, model.DisplayPreviousStudyMaps, hasDiametricGrowth, hasPreviousDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, availableMaps, maps, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, currentStudyVersion, maybePrevStudyVersion), Cmd_OfFunc_result(new LocalMsg(14)), Cmd_none()];
        }
        case 12: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, (inputRecord_4 = model.Measurements, new MeasurementCardArgs$3(map_2((active) => {
                let inputRecord_3;
                return new UnifiedMeasurementEntry$2(active.measurementIdUnified, (inputRecord_3 = active.coreValues, new CoreMeasurementValues(inputRecord_3.name, inputRecord_3.measurementType, inputRecord_3.value, [new Visualization_LabelPositionPercentage(note.xPercentage, note.yPercentage), 0])), active.view, active.callbacksUnified, active.adjustEditMode);
            }, model.Measurements.activeMeasurement), inputRecord_4.editMode, inputRecord_4.entries)), model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 13: {
            const patternInput = update(token, new InternalMsg$2(1, new InboundMsg(2, getCurrentStudyMeshes(model.Meshes))), model.CenterlineMeasurementModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, patternInput[0], model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_12) => (new LocalMsg(22, arg_12)), patternInput[1]), Cmd_none()];
        }
        case 14: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, new DicomData_1(files), model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 15: {
            return [model, Cmd_OfFunc_result(new LocalMsg(19, wrapInboundMsg(new InboundMsg_1(1, bounds)))), Cmd_none()];
        }
        case 16: {
            let newMeasurement;
            const matchValue_3 = model.Measurements.activeMeasurement;
            if (matchValue_3 == null) {
                newMeasurement = model.Measurements;
            }
            else {
                const active_1 = matchValue_3;
                const inputRecord_6 = model.Measurements;
                newMeasurement = (new MeasurementCardArgs$3(new UnifiedMeasurementEntry$2(active_1.measurementIdUnified, (inputRecord_5 = active_1.coreValues, new CoreMeasurementValues(inputRecord_5.name, inputRecord_5.measurementType, inputRecord_5.value, [matchValue_3.coreValues.position[0], matchValue_3.coreValues.position[1] + 1])), active_1.view, active_1.callbacksUnified, active_1.adjustEditMode), inputRecord_6.editMode, inputRecord_6.entries));
            }
            let patternInput_1;
            if (newMode.tag === 0) {
                let deselectCmd;
                if (model.Measurements.activeMeasurement != null) {
                    const active_2 = value_2(model.Measurements.activeMeasurement);
                    deselectCmd = active_2.callbacksUnified.onEntryDeselected.combinedCallback.commandEvent(active_2, model);
                }
                else {
                    deselectCmd = Cmd_none();
                }
                let targetMap;
                const matchValue_4 = [model.SelectedStudy.isPostTreatment, model.SelectedStudy.postTreatTreatmentType];
                let pattern_matching_result_1;
                if (matchValue_4[0] != null) {
                    if (matchValue_4[0].tag === 0) {
                        if (matchValue_4[1] != null) {
                            if (matchValue_4[1].tag === 0) {
                                pattern_matching_result_1 = 1;
                            }
                            else {
                                pattern_matching_result_1 = 0;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 2;
                    }
                }
                else {
                    pattern_matching_result_1 = 2;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        targetMap = (new DisplayMap(6));
                        break;
                    }
                    case 1: {
                        targetMap = (new DisplayMap(0));
                        break;
                    }
                    case 2: {
                        targetMap = (new DisplayMap(4));
                        break;
                    }
                }
                const setScreenshotModeCmd = Cmd_OfFunc_result(wrapLocalMsg(new LocalMsg_1(5, new Mode(1, 2, empty()))));
                patternInput_1 = [model.ViewportSelectedMap, (matchValue_5 = model.MaybePrevStudyAnalysisPipelineVersion, (matchValue_5 == null) ? empty() : getAvailableMaps(model.IsFollowupStudy, model.HasCurrentStudyDiametricGrowth && model.HasPreviousStudyDiametricGrowth, matchValue_5, model.PatientInstitutionDesignation, model.SelectedStudy)), Cmd_batch(ofArray([deselectCmd, Cmd_OfFunc_result(new LocalMsg(5, Compare_previousStudyViewport, targetMap)), Cmd_OfFunc_result(new LocalMsg(5, Compare_currentStudyViewport, targetMap)), Cmd_map((arg_14) => (new LocalMsg(23, arg_14)), setScreenshotModeCmd)]))];
            }
            else {
                let cmd_1;
                if (equals(model.ViewMode, new VisualizationViewMode(0))) {
                    const maybeChangeMapToPreviousCmd = (loc) => {
                        const matchValue_6 = tryFind_1(loc, model.PreviousViewportSelectedMap);
                        if (matchValue_6 == null) {
                            return Cmd_none();
                        }
                        else {
                            return Cmd_OfFunc_result(new LocalMsg(5, loc, matchValue_6.map));
                        }
                    };
                    const setScreenshotModeCmd_1 = Cmd_OfFunc_result(wrapLocalMsg(new LocalMsg_1(5, new Mode(0))));
                    cmd_1 = Cmd_batch(ofArray([maybeChangeMapToPreviousCmd(Compare_previousStudyViewport), maybeChangeMapToPreviousCmd(Compare_currentStudyViewport), Cmd_map((arg_16) => (new LocalMsg(23, arg_16)), setScreenshotModeCmd_1)]));
                }
                else {
                    cmd_1 = Cmd_none();
                }
                const availableMaps_2 = getAvailableMaps(model.IsFollowupStudy, model.HasCurrentStudyDiametricGrowth, model.CurrentStudyAnalysisPipelineVersion, model.PatientInstitutionDesignation, model.SelectedStudy);
                patternInput_1 = [empty_1(), availableMaps_2, cmd_1];
            }
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, patternInput_1[1], model.ViewportSelectedMap, patternInput_1[0], model.SelectedStudy, model.SelectedPatientId, newMode, model.DisplayCenterline, model.InteractionMode, newMeasurement, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), patternInput_1[2], Cmd_none()];
        }
        case 17: {
            if (exists_1((vpl, vp) => {
                if (equals(vpl, viewportLocation)) {
                    return !equals(vp.map, displayMap);
                }
                else {
                    return false;
                }
            }, model.ViewportSelectedMap)) {
                return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, map_1((vpl_1, vp_1) => {
                    if (equals(vpl_1, viewportLocation)) {
                        return new Viewport(displayMap, vp_1.planeAdjustment);
                    }
                    else {
                        return vp_1;
                    }
                }, model.ViewportSelectedMap), model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_OfFunc_result(new LocalMsg(19, wrapInboundMsg(new InboundMsg_1(4)))), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 18: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, isEnabled, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 19: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, isEnabled_1, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 20: {
            const patternInput_2 = update_1(token, msg_29, model.NotesModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, patternInput_2[0], model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_17) => (new LocalMsg(19, arg_17)), patternInput_2[1]), Cmd_none()];
        }
        case 21: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, mode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
        case 22: {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0, status))];
        }
        case 23: {
            const patternInput_3 = update_2(token, msg_31, model.CameraModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, patternInput_3[0], model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_18) => (new LocalMsg(20, arg_18)), patternInput_3[1]), Cmd_none()];
        }
        case 24: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 25: {
            const patternInput_4 = update_3(new CalipersUpdateArgs(token), msg_32, model.CalipersModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, patternInput_4[0], model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_19) => (new LocalMsg(21, arg_19)), patternInput_4[1]), Cmd_none()];
        }
        case 26: {
            const patternInput_5 = updateMeasurements(model, new MeasurementTypes(0), map_3(makeUnified((model_1) => (new CalipersMeasurementArgs(model_1.CalipersModel, model_1.InteractionMode)))((arg_21) => (new LocalMsg(21, wrapLocalMsg(arg_21)))), measurements_1), selection);
            return [patternInput_5[0], patternInput_5[1], Cmd_none()];
        }
        case 27: {
            const patternInput_6 = update(token, msg_34, model.CenterlineMeasurementModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, patternInput_6[0], model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_22) => (new LocalMsg(22, arg_22)), patternInput_6[1]), Cmd_none()];
        }
        case 28: {
            const patternInput_7 = updateMeasurements(model, new MeasurementTypes(1), map_3(makeUnified((model_2) => model_2.CenterlineMeasurementModel)((arg_24) => (new LocalMsg(22, wrapLocalMsg(arg_24)))), measurements_2), selection_1);
            return [patternInput_7[0], patternInput_7[1], Cmd_none()];
        }
        case 29: {
            const patternInput_8 = update_4(new ScreenshotUpdateArgs(token, model.S3Context), msg_36, model.ScreenshotModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, patternInput_8[0], model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_25) => (new LocalMsg(23, arg_25)), patternInput_8[1]), Cmd_none()];
        }
        case 30: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 31: {
            const patternInput_9 = update_5(msg_38, model.SlicePlaneModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, patternInput_9[0], model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_26) => (new LocalMsg(24, arg_26)), patternInput_9[1]), Cmd_none()];
        }
        case 32: {
            const patternInput_10 = update_6(token, msg_39, model.AnatomyOrientationModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, patternInput_10[0], model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_27) => (new LocalMsg(25, arg_27)), patternInput_10[1]), Cmd_none()];
        }
        case 33: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 34: {
            const patternInput_11 = update_7(token, msg_41, model.PickedMapValueModel);
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, patternInput_11[0], model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_map((arg_28) => (new LocalMsg(26, arg_28)), patternInput_11[1]), Cmd_none()];
        }
        case 35: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 36: {
            return [new ModelVisualization(model.MaybeCurrentUser, model.PatientInstitutionDesignation, model.VisualizationDataFile, model.LoadingStatus, model.Meshes, model.HasPreviousStudyMaps, model.DisplayPreviousStudyMaps, model.HasCurrentStudyDiametricGrowth, model.HasPreviousStudyDiametricGrowth, model.IsFollowupStudy, model.DicomData, model.SlicePlaneModel, model.NotesModel, model.CameraModel, model.CalipersModel, model.CenterlineMeasurementModel, model.ScreenshotModel, model.AnatomyOrientationModel, model.PickedMapValueModel, model.S3Context, model.AvailableMaps, model.ViewportSelectedMap, model.PreviousViewportSelectedMap, model.SelectedStudy, model.SelectedPatientId, model.ViewMode, model.DisplayCenterline, model.InteractionMode, model.Measurements, !model.ShowHelpTooltip, model.CurrentStudyAnalysisPipelineVersion, model.MaybePrevStudyAnalysisPipelineVersion), Cmd_none(), Cmd_none()];
        }
    }
}

export function updateVisualization(token, msg, model) {
    return update_8(updateLocal, updateInbound, token, msg, model);
}

