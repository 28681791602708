import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import { Union } from "../fable_modules/fable-library.3.7.20/Types.js";
import { union_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { Vector3 } from "../../../src/RAWMap.Client/Visualization/js/Vector3";

export const Shared_pixelsToMm = (() => {
    const pxPerMm = 96 / 25.4;
    return (px) => (px / pxPerMm);
})();

export const Shared_defaultButtonStyles = singleton(new Option(0, new Color_IColor(2)));

export const Shared_onButtonStyles = singleton(new Option(0, new Color_IColor(16)));

export class Shared_IntersectionPlane extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Coronal", "Sagittal", "Axial"];
    }
}

export function Shared_IntersectionPlane$reflection() {
    return union_type("RAWMap.Client.Visualization.Shared.IntersectionPlane", [], Shared_IntersectionPlane, () => [[], [], []]);
}

export function Shared_IntersectionPlane_getNormalVector_Z13D484F4(plane) {
    switch (plane.tag) {
        case 1: {
            return new Vector3({ x: 0, y: -1, z: 0 });
        }
        case 2: {
            return new Vector3({ x: 0, y: 0, z: 1 });
        }
        default: {
            return new Vector3({ x: 1, y: 0, z: 0 });
        }
    }
}

export function Shared_IntersectionPlane_get_toOrder() {
    return (_arg) => ((_arg.tag === 0) ? 1 : ((_arg.tag === 2) ? 2 : 0));
}

export function Shared_IntersectionPlane_get_ofOrder() {
    return (_arg) => ((_arg === 0) ? (new Shared_IntersectionPlane(1)) : ((_arg === 1) ? (new Shared_IntersectionPlane(0)) : (new Shared_IntersectionPlane(2))));
}

