import { printf, toText, join, isNullOrWhiteSpace } from "../fable-library.3.7.20/String.js";
import { toArray, defaultArg, map, value as value_1 } from "../fable-library.3.7.20/Option.js";
import { sort, contains, exists, map as map_1, tryFind, choose, ofArray, tryHead, ofSeq, cons, singleton, head, tail, isEmpty, reverse } from "../fable-library.3.7.20/List.js";
import { Async_retn } from "../AsyncResult.0.3.0/Result.fs.js";
import { compare, structuralHash, equals, curry } from "../fable-library.3.7.20/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../Fable.Aether.1.0.2/Aether.fs.js";
import { List_distinct } from "../fable-library.3.7.20/Seq2.js";
import { getTicks } from "../fable-library.3.7.20/Date.js";
import { addSeconds, create, fromTicks } from "../fable-library.3.7.20/DateOffset.js";
import { toNumber, fromNumber, fromInteger, op_Modulus, op_Subtraction } from "../fable-library.3.7.20/Long.js";

export function String_toOption(str) {
    if (isNullOrWhiteSpace(str)) {
        return void 0;
    }
    else {
        return str;
    }
}

export function String_fromOption(_arg) {
    if (_arg == null) {
        return null;
    }
    else {
        return value_1(_arg);
    }
}

export function String_pluralize(n) {
    if (n === 1) {
        return "";
    }
    else {
        return "s";
    }
}

export function String_ofListWithOxfordComma(strings) {
    let _arg;
    return join(", ", (_arg = reverse(strings), (!isEmpty(_arg)) ? ((!isEmpty(tail(_arg))) ? (isEmpty(tail(tail(_arg))) ? singleton(toText(printf("%s or %s"))(head(tail(_arg)))(head(_arg))) : reverse(cons(toText(printf("%s, or %s"))(head(tail(_arg)))(head(_arg)), tail(tail(_arg))))) : strings) : strings));
}

export const String_toOption_ = [String_toOption, String_fromOption];

export const String_fromOption_ = [String_fromOption, String_toOption];

export function Option_mapString(mapping) {
    return (arg) => String_fromOption(map(mapping, arg));
}

export function Option_defaultValueAsync(d) {
    const value = Async_retn(d);
    return (option) => defaultArg(option, value);
}

export function List_toSeq_() {
    return [(list) => list, ofSeq];
}

export function List_ofSeq_() {
    return [ofSeq, (list) => list];
}

export function List_toOption_() {
    return [tryHead, (option) => ofArray(toArray(option))];
}

export function List_mapLens_(lens_, lens__1, source) {
    const lens = [lens_, curry(2, lens__1)];
    return [(list_1) => choose((value) => tryFind((container) => equals(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(container), value), source), list_1), (list_2) => map_1(lens[0], list_2)];
}

export function List_overlaps(list1, list2) {
    return exists((elem) => contains(elem, list1, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list2);
}

export function List_containsOneOf(values, list) {
    return exists((x) => contains(x, values, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list);
}

export function List_hasSameItems(list1, list2) {
    const normalize = (arg) => sort(List_distinct(arg, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), {
        Compare: compare,
    });
    return equals(normalize(list1), normalize(list2));
}

export function DateTimeOffset_truncateToMicrosecond(dateTime) {
    const originalTicks = getTicks(dateTime);
    return fromTicks(op_Subtraction(originalTicks, op_Modulus(originalTicks, fromInteger(10, false, 2))), dateTime.offset);
}

export function DateTimeOffset_toUnixSeconds(dto) {
    return fromNumber(dto.getTime() / 1000);
}

export function DateTimeOffset_fromUnixSeconds(secs) {
    let copyOfStruct = create(1970, 1, 1, 0, 0, 0, 0);
    return addSeconds(copyOfStruct, toNumber(secs));
}

