import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Urls_Page, Urls_AuthPage, Urls_pageHash } from "../Common/General.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { Msg } from "./AuthTypes.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import { Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export function view(model, dispatch) {
    let props_4;
    const matchValue = model.userInfo;
    if (matchValue == null) {
        const children_6 = [(props_4 = [new HTMLAttr(64, "bd-tw-button button"), new HTMLAttr(94, Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(0))))], react.createElement("a", keyValueList(props_4, 1), "Login"))];
        return react.createElement("div", {}, ...children_6);
    }
    else {
        const userInfo = matchValue;
        const children_2 = [react.createElement("button", {
            className: "bd-tw-button button",
            onClick: (_arg) => {
                dispatch(new Msg(6));
            },
        }, "Log out")];
        return react.createElement("div", {}, ...children_2);
    }
}

export function sessionExpiryModal(im, dispatch) {
    const closeMsg = (_arg) => {
        dispatch(new Msg(19, false));
    };
    return modal(singleton(new Option(1, im.isActive)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, closeMsg)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Session timeout")), Card_body(empty(), singleton(im.message)), Card_foot(empty(), singleton(button(singleton(new Option_1(17, singleton(new DOMAttr(40, closeMsg)))), singleton(im.button))))]))]));
}

