import { Union } from "../../fable-library.3.7.20/Types.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Color_ofColor, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library.3.7.20/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.20/MapUtil.js";
import { some } from "../../fable-library.3.7.20/Option.js";
import { Reflection_getCaseName } from "../Common.fs.js";
import { cons } from "../../fable-library.3.7.20/List.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "Color", "is-delete", "is-light", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Tag.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [["Item", Color_IColor$reflection()]], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function tag(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let pattern_matching_result, size, color, props, customClass, modifiers;
        if (option.tag === 2) {
            pattern_matching_result = 2;
        }
        else if (option.tag === 3) {
            pattern_matching_result = 2;
        }
        else if (option.tag === 1) {
            pattern_matching_result = 3;
            color = option.fields[0];
        }
        else if (option.tag === 4) {
            pattern_matching_result = 4;
            props = option.fields[0];
        }
        else if (option.tag === 5) {
            pattern_matching_result = 5;
            customClass = option.fields[0];
        }
        else if (option.tag === 6) {
            pattern_matching_result = 6;
            modifiers = option.fields[0];
        }
        else if (option.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
            size = option.fields[0];
        }
        switch (pattern_matching_result) {
            case 0: {
                console.warn(some("`is-small` is not a valid size for the tag element"));
                return result;
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            }
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(color));
            }
            case 4: {
                return Common_GenericOptions__AddProps_416C4D0B(result, props);
            }
            case 5: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            }
            case 6: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, modifiers);
            }
        }
    }, "tag"), (props_1, children_1) => react.createElement("span", keyValueList(props_1, 1), ...children_1), children);
}

export function delete$(options, children) {
    return tag(cons(new Option(2), options), children);
}

export class List_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-addons", "is-centered", "is-right", "are-small", "are-medium", "are-large", "Props", "CustomClass", "Modifiers"];
    }
}

export function List_Option$reflection() {
    return union_type("Fulma.Tag.List.Option", [], List_Option, () => [[], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function list(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "tags"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

