import vtkCenterlinePlaneUpdater from "./CenterlinePlaneUpdater";
import { debugCallback, logDebug } from "../js/Common";

const isDebugging = false;

function log(msg) {
    logDebug("CenterlinePlaneUpdater", isDebugging, msg);
}

function debug(callback) {
    debugCallback(isDebugging, callback);
}

export function createCenterlinePlaneUpdater(meshes, pointDataArrayNames, fieldDataArrayNames, valuesChangedCallback) {
    let lumenSource = meshes.lumenCenterline.dataSource;
    let wallSource = meshes.wallCenterline.dataSource;
    let initialValues = { lumenSource, wallSource, pointDataArrayNames, fieldDataArrayNames, valuesChangedCallback };
    let updater = vtkCenterlinePlaneUpdater.newInstance(initialValues);
    debug(() => global.debugUpdater = updater);
    return updater;
}

export function setCenterlinePlanes(updater, planes) {
    let outPlanes = updater.setCenterlinePlanes(planes);
    debug(() => global.tempOutPlanes = outPlanes);
    return outPlanes;
}

export function setCenterlinePlaneRange(updater, planePair) {
    let ranges = updater.setCenterlinePlaneRange(planePair);
    debug(() => global.tempPlaneRanges = ranges);
    return ranges;
}
