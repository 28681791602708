// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import "vtk.js/Sources/Rendering/Profiles/Geometry";
import "vtk.js/Sources/Rendering/Profiles/Glyph";

import macro from "vtk.js/Sources/macros";
import vtkAbstractWidgetFactory from "vtk.js/Sources/Widgets/Core/AbstractWidgetFactory";
import vtkPlanePointManipulator from "vtk.js/Sources/Widgets/Manipulators/PlaneManipulator";
import vtkPolyLineRepresentation from "vtk.js/Sources/Widgets/Representations/PolyLineRepresentation";
import vtkCalipersHandleRepresentation from "../CalipersHandleRepresentation";
import { distance2BetweenPoints } from "vtk.js/Sources/Common/Core/Math";

import { ViewTypes } from "vtk.js/Sources/Widgets/Core/WidgetManager/Constants";

import widgetBehavior from "./behavior";

// ----------------------------------------------------------------------------
// Factory
// ----------------------------------------------------------------------------

function vtkCalipersWidget(publicAPI, model) {
    model.classHierarchy.push("vtkCalipersWidget");

    model.methodsToLink = [
        "activeScaleFactor",
        "activeColor",
        "useActiveColor",
        "glyphResolution",
        "defaultScale"
    ];

    model.behavior = widgetBehavior;
    model.widgetState = model.state;

    publicAPI.getRepresentationsForViewType = viewType => {
        switch (viewType) {
            case ViewTypes.DEFAULT:
            case ViewTypes.GEOMETRY:
            case ViewTypes.SLICE:
            case ViewTypes.VOLUME:
            default:
                return [
                    {
                        builder: vtkCalipersHandleRepresentation,
                        labels: ["firstPoint"],
                        initialValues: {
                            scaleInPixels: true
                        }
                    },
                    {
                        builder: vtkCalipersHandleRepresentation,
                        labels: ["secondPoint"],
                        initialValues: {
                            scaleInPixels: true
                        }
                    },
                    {
                        builder: vtkPolyLineRepresentation,
                        labels: ["firstPoint", "secondPoint"]
                    },
                ];
        }
    };

    // --- Public methods -------------------------------------------------------

    publicAPI.isComplete = () => model.widgetState.getIsComplete();

    publicAPI.getDistance = () => {
        const firstHandle = model.widgetState.getFirstPoint();
        const secondHandle = model.widgetState.getSecondPoint();

        if (firstHandle && secondHandle) {
            return Math.sqrt(
                distance2BetweenPoints(firstHandle.getOrigin(), secondHandle.getOrigin())
            );
        } else {
            return 0.0;
        }
    };

    // --------------------------------------------------------------------------
    // initialization
    // --------------------------------------------------------------------------

    // Default manipulator
    model.manipulator = vtkPlanePointManipulator.newInstance();
}

// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    state: {
        measurementId: "00000000-0000-0000-0000-000000000000",
        firstPoint: [0, 0, 0],
        secondPoint: [0, 0, 0]
    }
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    vtkAbstractWidgetFactory.extend(publicAPI, model, initialValues);
    macro.setGet(publicAPI, model, ["manipulator"]);

    vtkCalipersWidget(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, "vtkCalipersWidget");

// ----------------------------------------------------------------------------

export default { newInstance, extend };
