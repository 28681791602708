import { cons, ofArray, empty, fold } from "../fable-library.3.7.20/List.js";
import { HTMLAttr } from "../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Record, Union } from "../fable-library.3.7.20/Types.js";
import { record_type, bool_type, option_type, list_type, class_type, string_type, union_type } from "../fable-library.3.7.20/Reflection.js";
import * as react from "react";
import { keyValueList } from "../fable-library.3.7.20/MapUtil.js";

export function Helpers_classes(std, options, booleans) {
    return new HTMLAttr(64, fold((complete_1, tupledArg) => {
        if (tupledArg[1]) {
            return (complete_1 + " ") + tupledArg[0];
        }
        else {
            return complete_1;
        }
    }, fold((complete, opt) => {
        if (opt == null) {
            return complete;
        }
        else {
            return (complete + " ") + opt;
        }
    }, std, options), booleans));
}

export class Fa_ISize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FaExtraSmall", "FaSmall", "FaLarge", "Fa2x", "Fa3x", "Fa4x", "Fa5x", "Fa6x", "Fa7x", "Fa8x", "Fa9x", "Fa10"];
    }
}

export function Fa_ISize$reflection() {
    return union_type("Fable.FontAwesome.Fa.ISize", [], Fa_ISize, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Fa_IconOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "Border", "PullLeft", "PullRight", "Inverse", "Rotate90", "Rotate180", "Rotate270", "FlipHorizontal", "FlipVertical", "IsLi", "Icon", "Spin", "Pulse", "Props", "CustomClass", "FixedWidth", "Stack1x", "Stack2x"];
    }
}

export function Fa_IconOption$reflection() {
    return union_type("Fable.FontAwesome.Fa.IconOption", [], Fa_IconOption, () => [[["Item", Fa_ISize$reflection()]], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [], [], []]);
}

export function Fa_ofSize(size) {
    switch (size.tag) {
        case 1: {
            return "fa-sm";
        }
        case 2: {
            return "fa-lg";
        }
        case 3: {
            return "fa-2x";
        }
        case 4: {
            return "fa-3x";
        }
        case 5: {
            return "fa-4x";
        }
        case 6: {
            return "fa-5x";
        }
        case 7: {
            return "fa-6x";
        }
        case 8: {
            return "fa-7x";
        }
        case 9: {
            return "fa-8x";
        }
        case 10: {
            return "fa-9x";
        }
        case 11: {
            return "fa-10x";
        }
        default: {
            return "fa-xs";
        }
    }
}

export class Fa_IconOptions extends Record {
    constructor(Icon, Size, Border, Pull, HaveSpin, HavePulse, Rotation, Flip, IsInverse, Props, FixedWidth, IsLi, StackSize, CustomClass) {
        super();
        this.Icon = Icon;
        this.Size = Size;
        this.Border = Border;
        this.Pull = Pull;
        this.HaveSpin = HaveSpin;
        this.HavePulse = HavePulse;
        this.Rotation = Rotation;
        this.Flip = Flip;
        this.IsInverse = IsInverse;
        this.Props = Props;
        this.FixedWidth = FixedWidth;
        this.IsLi = IsLi;
        this.StackSize = StackSize;
        this.CustomClass = CustomClass;
    }
}

export function Fa_IconOptions$reflection() {
    return record_type("Fable.FontAwesome.Fa.IconOptions", [], Fa_IconOptions, () => [["Icon", option_type(string_type)], ["Size", option_type(string_type)], ["Border", option_type(string_type)], ["Pull", option_type(string_type)], ["HaveSpin", bool_type], ["HavePulse", bool_type], ["Rotation", option_type(string_type)], ["Flip", option_type(string_type)], ["IsInverse", bool_type], ["Props", list_type(class_type("Fable.React.Props.IHTMLProp"))], ["FixedWidth", bool_type], ["IsLi", bool_type], ["StackSize", option_type(string_type)], ["CustomClass", option_type(string_type)]]);
}

export function Fa_IconOptions_get_Empty() {
    return new Fa_IconOptions(void 0, void 0, void 0, void 0, false, false, void 0, void 0, false, empty(), false, false, void 0, void 0);
}

export function Fa_toIconOptions(faOptions) {
    return fold((result, option) => {
        switch (option.tag) {
            case 1: {
                return new Fa_IconOptions(result.Icon, result.Size, "fa-border", result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 2: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, "fa-pull-left", result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 3: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, "fa-pull-right", result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 4: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, true, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 11: {
                return new Fa_IconOptions(option.fields[0], result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 5: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, "fa-rotate-90", result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 6: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, "fa-rotate-180", result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 7: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, "fa-rotate-270", result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 8: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, "fa-flip-horizontal", result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 9: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, "fa-flip-vertical", result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 12: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, true, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 13: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, true, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 14: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, option.fields[0], result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 16: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, true, result.IsLi, result.StackSize, result.CustomClass);
            }
            case 10: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, true, result.StackSize, result.CustomClass);
            }
            case 15: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, option.fields[0]);
            }
            case 17: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, "fa-stack-1x", result.CustomClass);
            }
            case 18: {
                return new Fa_IconOptions(result.Icon, result.Size, result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, "fa-stack-2x", result.CustomClass);
            }
            default: {
                return new Fa_IconOptions(result.Icon, Fa_ofSize(option.fields[0]), result.Border, result.Pull, result.HaveSpin, result.HavePulse, result.Rotation, result.Flip, result.IsInverse, result.Props, result.FixedWidth, result.IsLi, result.StackSize, result.CustomClass);
            }
        }
    }, Fa_IconOptions_get_Empty(), faOptions);
}

export function Fa_displayIcon(baseElement, baseClass, opts, children) {
    return baseElement(cons(Helpers_classes(baseClass, ofArray([opts.Icon, opts.Size, opts.Border, opts.Pull, opts.Rotation, opts.Flip, opts.CustomClass, opts.StackSize]), ofArray([["fa-fw", opts.FixedWidth], ["fa-li", opts.IsLi], ["fa-pulse", opts.HavePulse], ["fa-spin", opts.HaveSpin], ["fa-inverse", opts.IsInverse]])), opts.Props), children);
}

export function Fa_ul(props, children) {
    return react.createElement("ul", {
        className: "fa-ul",
    }, ...children);
}

export function Fa_ol(props, children) {
    return react.createElement("ol", {
        className: "fa-ul",
    }, ...children);
}

export function Fa_i(faOptions, children) {
    return Fa_displayIcon((props, children_1) => react.createElement("i", keyValueList(props, 1), ...children_1), "", Fa_toIconOptions(faOptions), children);
}

export function Fa_span(faOptions, children) {
    return Fa_displayIcon((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), "", Fa_toIconOptions(faOptions), children);
}

export class Fa_Stack_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "CustomClass", "Props"];
    }
}

export function Fa_Stack_Option$reflection() {
    return union_type("Fable.FontAwesome.Fa.Stack.Option", [], Fa_Stack_Option, () => [[["Item", Fa_ISize$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export class Fa_Stack_Options extends Record {
    constructor(Size, Props, CustomClass) {
        super();
        this.Size = Size;
        this.Props = Props;
        this.CustomClass = CustomClass;
    }
}

export function Fa_Stack_Options$reflection() {
    return record_type("Fable.FontAwesome.Fa.Stack.Options", [], Fa_Stack_Options, () => [["Size", option_type(string_type)], ["Props", list_type(class_type("Fable.React.Props.IHTMLProp"))], ["CustomClass", option_type(string_type)]]);
}

export function Fa_Stack_Options_get_Empty() {
    return new Fa_Stack_Options(void 0, empty(), void 0);
}

export function Fa_stack(options, children) {
    const opts = fold((result, opt) => {
        switch (opt.tag) {
            case 1: {
                return new Fa_Stack_Options(result.Size, result.Props, opt.fields[0]);
            }
            case 2: {
                return new Fa_Stack_Options(result.Size, opt.fields[0], result.CustomClass);
            }
            default: {
                return new Fa_Stack_Options(Fa_ofSize(opt.fields[0]), result.Props, result.CustomClass);
            }
        }
    }, Fa_Stack_Options_get_Empty(), options);
    const classes = Helpers_classes("fa-stack", ofArray([opts.Size, opts.CustomClass]), empty());
    return react.createElement("span", keyValueList(cons(classes, opts.Props), 1), ...children);
}

