import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, class_type, string_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { maxValue, minValue } from "../../fable_modules/fable-library.3.7.20/DateOffset.js";
import { isNullOrEmpty } from "../../fable_modules/fable-library.3.7.20/String.js";
import { compare } from "../../fable_modules/fable-library.3.7.20/Date.js";
import { isMatch } from "../../fable_modules/fable-library.3.7.20/RegExp.js";
import { Common_guidCharacters } from "../Common.js";

export class NameDobViewModel extends Record {
    constructor(firstName, lastName, dateOfBirth) {
        super();
        this.firstName = firstName;
        this.lastName = lastName;
        this.dateOfBirth = dateOfBirth;
    }
}

export function NameDobViewModel$reflection() {
    return record_type("RAWMap.Models.View.NameDobViewModel", [], NameDobViewModel, () => [["firstName", string_type], ["lastName", string_type], ["dateOfBirth", class_type("System.DateTimeOffset")]]);
}

export function NameDobViewModel_get_minDate() {
    return minValue();
}

export function NameDobViewModel_get_maxDate() {
    return maxValue();
}

export function NameDobViewModel_get_empty() {
    return new NameDobViewModel("", "", minValue());
}

export function NameDobViewModel_isValid_7D3B7E8E(nameDobVm) {
    if (!(isNullOrEmpty(nameDobVm.firstName) ? true : isNullOrEmpty(nameDobVm.lastName))) {
        return compare(nameDobVm.dateOfBirth, NameDobViewModel_get_minDate()) > 0;
    }
    else {
        return false;
    }
}

export function NameDobViewModel_get_firstName_() {
    return [(m) => m.firstName, (v) => ((m_1) => (new NameDobViewModel(v, m_1.lastName, m_1.dateOfBirth)))];
}

export function NameDobViewModel_get_lastName_() {
    return [(m) => m.lastName, (v) => ((m_1) => (new NameDobViewModel(m_1.firstName, v, m_1.dateOfBirth)))];
}

export function NameDobViewModel_get_dateOfBirth_() {
    return [(m) => m.dateOfBirth, (v) => ((m_1) => (new NameDobViewModel(m_1.firstName, m_1.lastName, v)))];
}

export class HospitalPatientIdViewModel extends Record {
    constructor(hospitalPatientId, institutionId) {
        super();
        this.hospitalPatientId = hospitalPatientId;
        this.institutionId = institutionId;
    }
}

export function HospitalPatientIdViewModel$reflection() {
    return record_type("RAWMap.Models.View.HospitalPatientIdViewModel", [], HospitalPatientIdViewModel, () => [["hospitalPatientId", string_type], ["institutionId", class_type("System.Guid")]]);
}

export function HospitalPatientIdViewModel_get_empty() {
    return new HospitalPatientIdViewModel("", "00000000-0000-0000-0000-000000000000");
}

export function HospitalPatientIdViewModel_isValid_Z3AB5EA36(hospPatientId) {
    if (!isNullOrEmpty(hospPatientId.hospitalPatientId)) {
        return hospPatientId.institutionId !== "00000000-0000-0000-0000-000000000000";
    }
    else {
        return false;
    }
}

export function HospitalPatientIdViewModel_get_hospitalPatientId_() {
    return [(m) => m.hospitalPatientId, (v) => ((m_1) => (new HospitalPatientIdViewModel(v, m_1.institutionId)))];
}

export function HospitalPatientIdViewModel_get_institutionId_() {
    return [(m) => m.institutionId, (v) => ((m_1) => (new HospitalPatientIdViewModel(m_1.hospitalPatientId, v)))];
}

export class PartialInternalPatientIdViewModel extends Record {
    constructor(internalPatientId) {
        super();
        this.internalPatientId = internalPatientId;
    }
}

export function PartialInternalPatientIdViewModel$reflection() {
    return record_type("RAWMap.Models.View.PartialInternalPatientIdViewModel", [], PartialInternalPatientIdViewModel, () => [["internalPatientId", string_type]]);
}

export function PartialInternalPatientIdViewModel_get_empty() {
    return new PartialInternalPatientIdViewModel("");
}

export function PartialInternalPatientIdViewModel_isValid_293DEEA(intPatientId) {
    if (!isNullOrEmpty(intPatientId.internalPatientId)) {
        return isMatch(Common_guidCharacters, intPatientId.internalPatientId);
    }
    else {
        return false;
    }
}

export function PartialInternalPatientIdViewModel_get_InternalPatientId_() {
    return [(m) => m.internalPatientId, (v) => ((m_1) => (new PartialInternalPatientIdViewModel(v)))];
}

export class PatientViewModel extends Record {
    constructor(id, institutionId, dateCreated) {
        super();
        this.id = id;
        this.institutionId = institutionId;
        this.dateCreated = dateCreated;
    }
}

export function PatientViewModel$reflection() {
    return record_type("RAWMap.Models.View.PatientViewModel", [], PatientViewModel, () => [["id", class_type("System.Guid")], ["institutionId", class_type("System.Guid")], ["dateCreated", class_type("System.DateTimeOffset")]]);
}

export function PatientViewModel_get_empty() {
    return new PatientViewModel("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", minValue());
}

export function PatientViewModel_isValid_46219033(patient) {
    if (patient.id !== "00000000-0000-0000-0000-000000000000") {
        return patient.institutionId !== "00000000-0000-0000-0000-000000000000";
    }
    else {
        return false;
    }
}

export function PatientViewModel_get_id_() {
    return [(m) => m.id, (v) => ((m_1) => (new PatientViewModel(v, m_1.institutionId, m_1.dateCreated)))];
}

export function PatientViewModel_get_institutionId_() {
    return [(m) => m.institutionId, (v) => ((m_1) => (new PatientViewModel(m_1.id, v, m_1.dateCreated)))];
}

export function PatientViewModel_get_dateCreated_() {
    return [(m) => m.dateCreated, (v) => ((m_1) => (new PatientViewModel(m_1.id, m_1.institutionId, v)))];
}

export class PatientCreationViewModel extends Record {
    constructor(id, dateCreated, hospitalPatientIdVm, nameDobVm) {
        super();
        this.id = id;
        this.dateCreated = dateCreated;
        this.hospitalPatientIdVm = hospitalPatientIdVm;
        this.nameDobVm = nameDobVm;
    }
}

export function PatientCreationViewModel$reflection() {
    return record_type("RAWMap.Models.View.PatientCreationViewModel", [], PatientCreationViewModel, () => [["id", class_type("System.Guid")], ["dateCreated", class_type("System.DateTimeOffset")], ["hospitalPatientIdVm", HospitalPatientIdViewModel$reflection()], ["nameDobVm", NameDobViewModel$reflection()]]);
}

export function PatientCreationViewModel_get_empty() {
    return new PatientCreationViewModel("00000000-0000-0000-0000-000000000000", minValue(), HospitalPatientIdViewModel_get_empty(), NameDobViewModel_get_empty());
}

export function PatientCreationViewModel_isValid_7D62DD9A(patient) {
    if (HospitalPatientIdViewModel_isValid_Z3AB5EA36(patient.hospitalPatientIdVm)) {
        return NameDobViewModel_isValid_7D3B7E8E(patient.nameDobVm);
    }
    else {
        return false;
    }
}

export function PatientCreationViewModel_get_id_() {
    return [(m) => m.id, (v) => ((m_1) => (new PatientCreationViewModel(v, m_1.dateCreated, m_1.hospitalPatientIdVm, m_1.nameDobVm)))];
}

export function PatientCreationViewModel_get_dateCreated_() {
    return [(m) => m.dateCreated, (v) => ((m_1) => (new PatientCreationViewModel(m_1.id, v, m_1.hospitalPatientIdVm, m_1.nameDobVm)))];
}

export function PatientCreationViewModel_get_hospitalPatientIdVm_() {
    return [(m) => m.hospitalPatientIdVm, (v) => ((m_1) => (new PatientCreationViewModel(m_1.id, m_1.dateCreated, v, m_1.nameDobVm)))];
}

export function PatientCreationViewModel_get_nameDobVm_() {
    return [(m) => m.nameDobVm, (v) => ((m_1) => (new PatientCreationViewModel(m_1.id, m_1.dateCreated, m_1.hospitalPatientIdVm, v)))];
}

