import vtkUnifiedBranchFilter from "./UnifiedBranchFilter";

export function createBasePipeline(/*vtkPolydata*/ centerlineData, fieldDataArrayNames) {
    let unifiedBranchFilter = vtkUnifiedBranchFilter.newInstance({
        fieldDataArrayNames: fieldDataArrayNames
    });
    unifiedBranchFilter.setInputData(centerlineData);

    return unifiedBranchFilter;
}
