import { Union, Record } from "../../../fable_modules/fable-library.3.7.20/Types.js";
import { FileManagementUpdateArgs$reflection } from "../../../FileManagement/FileManagementTypes.js";
import { CustomReportConclusionViewModel$reflection, CustomReportStatus$reflection, CustomReportViewModel$reflection } from "../../../RAWMap.Models/View/CustomReport.js";
import { lambda_type, union_type, class_type, bool_type, option_type, string_type, record_type } from "../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { ErrorMessage$reflection } from "../../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.20/Choice.js";

export class AdditionalDataUpdateArgs extends Record {
    constructor(fileArgs, customReport) {
        super();
        this.fileArgs = fileArgs;
        this.customReport = customReport;
    }
}

export function AdditionalDataUpdateArgs$reflection() {
    return record_type("RAWMap.Client.Study.AdditionalData.Types.AdditionalDataUpdateArgs", [], AdditionalDataUpdateArgs, () => [["fileArgs", FileManagementUpdateArgs$reflection()], ["customReport", CustomReportViewModel$reflection()]]);
}

export class AdditionalDataModel extends Record {
    constructor(MaybeConclusionBuffer, ShowConclusionModal, IsSaving) {
        super();
        this.MaybeConclusionBuffer = MaybeConclusionBuffer;
        this.ShowConclusionModal = ShowConclusionModal;
        this.IsSaving = IsSaving;
    }
}

export function AdditionalDataModel$reflection() {
    return record_type("RAWMap.Client.Study.AdditionalData.Types.AdditionalDataModel", [], AdditionalDataModel, () => [["MaybeConclusionBuffer", option_type(string_type)], ["ShowConclusionModal", bool_type], ["IsSaving", bool_type]]);
}

export function AdditionalDataModel_get_maybeConclusionBuffer_() {
    return [(m) => m.MaybeConclusionBuffer, (v) => ((m_1) => (new AdditionalDataModel(v, m_1.ShowConclusionModal, m_1.IsSaving)))];
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("RAWMap.Client.Study.AdditionalData.Types.InboundMsg", void 0, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "SaveFinished"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.Study.AdditionalData.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus$reflection()]], [["Item", CustomReportConclusionViewModel$reflection()]]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "SetConclusion", "EditConclusion", "CancelConclusion", "SaveData", "SavedData"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Study.AdditionalData.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [["Item", lambda_type(AdditionalDataModel$reflection(), AdditionalDataModel$reflection())]], [], [], [], [["Item1", CustomReportConclusionViewModel$reflection()], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [CustomReportStatus$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", CustomReportStatus$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

