import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { unit_type, lambda_type, array_type, bool_type, option_type, int32_type, record_type, float64_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class WorldLabel extends Record {
    constructor(id, content, x, y, z) {
        super();
        this.id = id;
        this.content = content;
        this.x = x;
        this.y = y;
        this.z = z;
    }
}

export function WorldLabel$reflection() {
    return record_type("Client.Visualization.Notes.Bindings.WorldLabel", [], WorldLabel, () => [["id", class_type("System.Guid")], ["content", string_type], ["x", float64_type], ["y", float64_type], ["z", float64_type]]);
}

export class StaticLabel extends Record {
    constructor(id, content, moves, xPercentage, yPercentage, x, y, isHidden, isHeader) {
        super();
        this.id = id;
        this.content = content;
        this.moves = (moves | 0);
        this.xPercentage = xPercentage;
        this.yPercentage = yPercentage;
        this.x = x;
        this.y = y;
        this.isHidden = isHidden;
        this.isHeader = isHeader;
    }
}

export function StaticLabel$reflection() {
    return record_type("Client.Visualization.Notes.Bindings.StaticLabel", [], StaticLabel, () => [["id", class_type("System.Guid")], ["content", string_type], ["moves", int32_type], ["xPercentage", float64_type], ["yPercentage", float64_type], ["x", option_type(float64_type)], ["y", option_type(float64_type)], ["isHidden", bool_type], ["isHeader", bool_type]]);
}

export class NotesProps extends Record {
    constructor(staticLabels, wrapLines, worldLabels, onStaticLabelDragEnd) {
        super();
        this.staticLabels = staticLabels;
        this.wrapLines = wrapLines;
        this.worldLabels = worldLabels;
        this.onStaticLabelDragEnd = onStaticLabelDragEnd;
    }
}

export function NotesProps$reflection() {
    return record_type("Client.Visualization.Notes.Bindings.NotesProps", [], NotesProps, () => [["staticLabels", array_type(StaticLabel$reflection())], ["wrapLines", lambda_type(string_type, string_type)], ["worldLabels", array_type(WorldLabel$reflection())], ["onStaticLabelDragEnd", lambda_type(StaticLabel$reflection(), unit_type)]]);
}

