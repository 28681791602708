import { Msg, Model, mode as mode_3 } from "./UserTypes.js";
import { tryFind as tryFind_1, ofArray, sort, empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { Permission, isUserAllowed, UserViewModelModule_empty } from "../RAWMap.Models/Security.js";
import { Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { value as value_1, map, defaultArg, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_attempt, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedUserManagementApi, publicAuthApi } from "../Api.js";
import { Toast_errorToast, Toast_successToast } from "../Common/General.js";
import { tryFind } from "../fable_modules/fable-library.3.7.20/Array.js";
import { Security_UserViewModel$2, Common_EmailAddress_create_Z721C83C5, Security_UserInfo$2_toViewModel_Z3F1C2E28, Security_AuthErrorMessage_get_describe, Security_UserInfo$2_get_id_ } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(new mode_3(0), empty(), empty(), void 0, UserViewModelModule_empty, new Common_SortInfo$1("", new Common_SortDirection(1), (list) => sort(list, {
        Compare: compare,
    }))), Cmd_none()];
}

export function update(userInfo, token, msg, model) {
    let option_1, f1_1, f1, optic;
    const mkListViewModel = () => (new Model(new mode_3(0), model.users, model.institutions, model.selectedUser, UserViewModelModule_empty, model.sortInfo));
    let pattern_matching_result, ex, email, users, e, institutions, e_1, userId, setter, user, toDelete, sortInfo, e_2, e_3;
    if (msg.tag === 2) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 2;
        email = msg.fields[0];
    }
    else if (msg.tag === 1) {
        if (isUserAllowed(new Permission(4), userInfo)) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 6;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
            users = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 8;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
            institutions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 9;
        userId = msg.fields[0];
    }
    else if (msg.tag === 8) {
        if (model.selectedUser == null) {
            pattern_matching_result = 10;
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 12;
        setter = msg.fields[0];
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 13;
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 14;
        user = msg.fields[0];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 22;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 15;
        }
    }
    else if (msg.tag === 14) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 22;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 16;
        }
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 17;
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 18;
        toDelete = msg.fields[0];
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 22;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 19;
        }
    }
    else if (msg.tag === 15) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 23;
            e_3 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 20;
        }
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 21;
        sortInfo = msg.fields[0];
    }
    else if (msg.tag === 18) {
        pattern_matching_result = 24;
    }
    else if (msg.tag === 19) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 25;
        }
        else {
            pattern_matching_result = 26;
        }
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [model, Cmd_OfFunc_result(new Msg(1))];
        }
        case 2: {
            return [model, Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x) => {
                Cmd_OfAsync_start(x);
            }, publicAuthApi.requestPasswordResetTokenEmail, email, (arg_1) => (new Msg(0, arg_1))), Toast_successToast(toText(printf("A password reset email has been sent to %s"))(email))]))];
        }
        case 3: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedUserManagementApi(token).getUsers, void 0, (arg_4) => (new Msg(4, arg_4)), (arg_5) => (new Msg(0, arg_5)))];
        }
        case 4: {
            return [model, Cmd_none()];
        }
        case 5: {
            let cmd;
            const matchValue = [model.mode, model.selectedUser];
            let pattern_matching_result_1;
            if (matchValue[0].tag === 3) {
                if (matchValue[1] != null) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const selectedUser = matchValue[1];
                    cmd = defaultArg((option_1 = tryFind((u) => (u.id === selectedUser.id), users), map((f1_1 = ((f1 = ((optic = Security_UserInfo$2_get_id_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))), (arg_9) => (new Msg(6, f1(arg_9))))), (arg_10) => Cmd_OfFunc_result(f1_1(arg_10))), option_1)), Cmd_none());
                    break;
                }
                case 1: {
                    cmd = Cmd_none();
                    break;
                }
            }
            return [new Model(model.mode, ofArray(users), model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), cmd];
        }
        case 6: {
            return [model, Toast_errorToast(Security_AuthErrorMessage_get_describe()(e))];
        }
        case 7: {
            return [new Model(model.mode, model.users, institutions, model.selectedUser, model.editUserVm, model.sortInfo), Cmd_none()];
        }
        case 8: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        }
        case 9: {
            return [new Model(new mode_3(3), model.users, model.institutions, map(Security_UserInfo$2_toViewModel_Z3F1C2E28, tryFind_1((u_1) => (u_1.id === userId), model.users)), model.editUserVm, model.sortInfo), Cmd_none()];
        }
        case 10: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(new ErrorMessage(0)))];
        }
        case 11: {
            return [new Model(new mode_3(2), model.users, model.institutions, model.selectedUser, value_1(model.selectedUser), model.sortInfo), Cmd_none()];
        }
        case 12: {
            return [new Model(model.mode, model.users, model.institutions, model.selectedUser, setter(model.editUserVm), model.sortInfo), Cmd_none()];
        }
        case 13: {
            return [new Model(new mode_3(1), model.users, model.institutions, void 0, UserViewModelModule_empty, model.sortInfo), Cmd_none()];
        }
        case 14: {
            const matchValue_1 = Common_EmailAddress_create_Z721C83C5(user.email);
            if (matchValue_1.tag === 0) {
                const updatedUser = new Security_UserViewModel$2(user.id, user.email, user.name, user.roles, user.userData);
                return [new Model(new mode_3(0), model.users, model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), (user.id !== "00000000-0000-0000-0000-000000000000") ? Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedUserManagementApi(token).editUser, updatedUser, (arg_13) => (new Msg(14, arg_13)), (arg_14) => (new Msg(0, arg_14))) : Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedUserManagementApi(token).createUser, updatedUser, (arg_16) => (new Msg(13, arg_16)), (arg_17) => (new Msg(0, arg_17)))];
            }
            else {
                return [model, Toast_errorToast(toText(printf("%s is not a valid email address"))(user.email))];
            }
        }
        case 15: {
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("Created user"), Cmd_OfFunc_result(new Msg(1))]))];
        }
        case 16: {
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("User updated"), Cmd_OfFunc_result(new Msg(1))]))];
        }
        case 17: {
            return [new Model(new mode_3(4), model.users, model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), Cmd_none()];
        }
        case 18: {
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedUserManagementApi(token).deleteUser, toDelete, (arg_19) => (new Msg(12, arg_19)), (arg_20) => (new Msg(0, arg_20)))];
        }
        case 19: {
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("Deleted user"), Cmd_OfFunc_result(new Msg(1))]))];
        }
        case 20: {
            return [mkListViewModel(), Cmd_none()];
        }
        case 21: {
            return [new Model(model.mode, model.users, model.institutions, model.selectedUser, model.editUserVm, sortInfo), Cmd_none()];
        }
        case 22: {
            return [model, Toast_errorToast(Security_AuthErrorMessage_get_describe()(e_2))];
        }
        case 23: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_3))];
        }
        case 24: {
            return [mkListViewModel(), Cmd_none()];
        }
        case 25: {
            return [model, Cmd_OfFunc_result(new Msg(1))];
        }
        case 26: {
            return [model, Cmd_none()];
        }
    }
}

