import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, option_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class StudyMeshData extends Record {
    constructor(anatomy, maybeDiametricGrowth, lumen, lumenCenterline, wallMaps, wall, wallCenterline, renalArtery) {
        super();
        this.anatomy = anatomy;
        this.maybeDiametricGrowth = maybeDiametricGrowth;
        this.lumen = lumen;
        this.lumenCenterline = lumenCenterline;
        this.wallMaps = wallMaps;
        this.wall = wall;
        this.wallCenterline = wallCenterline;
        this.renalArtery = renalArtery;
    }
}

export function StudyMeshData$reflection() {
    return record_type("RAWMap.Client.Visualization.Meshes.Bindings.StudyMeshData", [], StudyMeshData, () => [["anatomy", class_type("Fable.Core.JS.ArrayBuffer")], ["maybeDiametricGrowth", option_type(class_type("Fable.Core.JS.ArrayBuffer"))], ["lumen", class_type("Fable.Core.JS.ArrayBuffer")], ["lumenCenterline", class_type("Fable.Core.JS.ArrayBuffer")], ["wallMaps", class_type("Fable.Core.JS.ArrayBuffer")], ["wall", class_type("Fable.Core.JS.ArrayBuffer")], ["wallCenterline", class_type("Fable.Core.JS.ArrayBuffer")], ["renalArtery", class_type("Fable.Core.JS.ArrayBuffer")]]);
}

export class AllMeshData extends Record {
    constructor(currentStudy, previousStudy) {
        super();
        this.currentStudy = currentStudy;
        this.previousStudy = previousStudy;
    }
}

export function AllMeshData$reflection() {
    return record_type("RAWMap.Client.Visualization.Meshes.Bindings.AllMeshData", [], AllMeshData, () => [["currentStudy", StudyMeshData$reflection()], ["previousStudy", option_type(StudyMeshData$reflection())]]);
}

