import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { deleteConfirmation, mkDispatchLocal, mkToolReactButton } from "../Common/CommonView.js";
import { Shared_defaultButtonStyles } from "../VisualizationShared.js";
import { Mode, ScreenshotModal, LocalMsg } from "./ScreenshotTypes.js";
import { Button_visibilityControlled, ComponentVisibility } from "../../Common/Controls.js";
import { VisualizationScreenshotViewModel, VisualizationScreenshotViewModel_isValid_Z6DE73A12 } from "../../RAWMap.Models/View/VisualizationScreenshot.js";
import { Modifier_IModifier, Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { button, Option } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { sortBy, map as map_1, empty, ofArray, singleton } from "../../fable_modules/fable-library.3.7.20/List.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library.3.7.20/MapUtil.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Option as Option_1, modal as modal_1, Card_foot, Card_body, Card_title, Card_head, Card_card, background } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.3.7.20/Seq.js";
import { map, defaultArg } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { Forms_formFieldTextArea } from "../../Common/General.js";
import { TableOption, table } from "../../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { comparePrimitives } from "../../fable_modules/fable-library.3.7.20/Util.js";

export const screenshotIcon = new Fa_IconOption(11, "fas fa-camera");

export function takeScreenshotTool(isDisabled, dispatch) {
    return mkToolReactButton(dispatch, Shared_defaultButtonStyles, screenshotIcon, new LocalMsg(2), false, isDisabled, "Take a screenshot");
}

export function cancelScreenshotTool(isDisabled, dispatch) {
    return mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, "fas fa-times"), new LocalMsg(3), false, isDisabled, "Cancel screenshot");
}

function editScreeshotView(model, vm, dispatch, readOnly) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    let action;
    const matchValue = model.Mode;
    let pattern_matching_result;
    if (matchValue.tag === 3) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            action = "Create";
            break;
        }
        case 1: {
            action = "Edit";
            break;
        }
    }
    let buttonVisibility;
    const matchValue_1 = [model.IsSaving, readOnly];
    buttonVisibility = (matchValue_1[1] ? (new ComponentVisibility(1)) : (matchValue_1[0] ? (new ComponentVisibility(2, "Saving")) : (new ComponentVisibility(0))));
    const createEditFieldsValid = VisualizationScreenshotViewModel_isValid_Z6DE73A12(vm);
    let deleteButton;
    const matchValue_2 = model.Mode;
    let pattern_matching_result_1;
    if (matchValue_2.tag === 3) {
        if (matchValue_2.fields[0].tag === 2) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            const props = [["style", {
                flex: 100,
            }]];
            const children = [Button_visibilityControlled(void 0, buttonVisibility, ofArray([new Option(0, new Color_IColor(8)), new Option(16, model.IsSaving), new Option(20, singleton(new Modifier_IModifier(11))), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
                dispatchLocal(new LocalMsg(5, new Mode(3, new ScreenshotModal(3, vm))));
            }), new HTMLAttr(159, "button")]))]), singleton("Delete"))];
            deleteButton = react.createElement("div", keyValueList(props, 1), ...children);
            break;
        }
        case 1: {
            deleteButton = Helpers_nothing;
            break;
        }
    }
    return ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(toText(printf("%s Screenshot"))(action))))), Card_body(empty(), toList(delay(() => {
        let props_6;
        return append(singleton_1(defaultArg(map((url) => {
            const props_4 = [["style", {
                textAlign: "center",
            }]];
            const children_2 = [react.createElement("img", {
                src: url,
            })];
            return react.createElement("div", keyValueList(props_4, 1), ...children_2);
        }, vm.imageUrl), (props_6 = [["style", {
            minHeight: "10em",
        }], new HTMLAttr(64, "spinner is-loading")], react.createElement("div", keyValueList(props_6, 1))))), delay(() => {
            let children_6;
            return singleton_1((children_6 = [Forms_formFieldTextArea("Description", vm.description, ofArray([new DOMAttr(9, (e) => {
                dispatchLocal(new LocalMsg(1, (vm_1) => (new VisualizationScreenshotViewModel(vm_1.studyId, vm_1.screenshotId, e.target.value, vm_1.imagePath, vm_1.imageUrl))));
            }), new HTMLAttr(79, readOnly)]))], react.createElement("form", {}, ...children_6)));
        }));
    }))), Card_foot(empty(), ofArray([deleteButton, Button_visibilityControlled(void 0, buttonVisibility, ofArray([new Option(0, new Color_IColor(6)), new Option(16, (!createEditFieldsValid) ? true : model.IsSaving), new Option(13, model.IsSaving), new Option(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatchLocal(new LocalMsg(4));
    }), new HTMLAttr(159, "button")]))]), singleton("Save")), button(ofArray([new Option(0, new Color_IColor(2)), new Option(16, model.IsSaving), new Option(17, ofArray([new DOMAttr(40, (_arg_2) => {
        dispatchLocal(new LocalMsg(3));
    }), new HTMLAttr(159, "button")]))]), singleton("Cancel"))]))]))]);
}

export function screenshotModal(model, dispatch, readOnly) {
    const showScreenshotModal = (elements) => modal_1(singleton(new Option_1(1, model.Mode.tag === 3)), elements);
    const showScreenshotEditor = (action, vm) => showScreenshotModal(editScreeshotView(model, vm, dispatch, readOnly));
    const matchValue = model.Mode;
    let pattern_matching_result, s, s_1, s_2;
    if (matchValue.tag === 3) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            s = matchValue.fields[0].fields[1];
        }
        else if (matchValue.fields[0].tag === 2) {
            pattern_matching_result = 1;
            s_1 = matchValue.fields[0].fields[0];
        }
        else if (matchValue.fields[0].tag === 3) {
            pattern_matching_result = 2;
            s_2 = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 0;
            s = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            const children = [showScreenshotEditor("Create", s)];
            return react.createElement("div", {}, ...children);
        }
        case 1: {
            const children_2 = [showScreenshotEditor("Edit", s_1)];
            return react.createElement("div", {}, ...children_2);
        }
        case 2: {
            const children_4 = [showScreenshotEditor("Edit", s_2), showScreenshotModal(deleteConfirmation("Screenshot", s_2.description, new LocalMsg(6), model.IsSaving, new LocalMsg(3), dispatch))];
            return react.createElement("div", {}, ...children_4);
        }
        case 3: {
            return Helpers_nothing;
        }
    }
}

export function screenshotTable(model, dispatch) {
    let children_4;
    const dispatchLocal = mkDispatchLocal(dispatch);
    const children_6 = [table(ofArray([new TableOption(1), new TableOption(2), new TableOption(4), new TableOption(6, singleton(["style", {
        tableLayout: "fixed",
    }]))]), singleton((children_4 = map_1((item) => {
        let children, matchValue;
        const props_2 = [["style", {
            cursor: "pointer",
        }], new DOMAttr(40, (_arg) => {
            dispatchLocal(new LocalMsg(5, new Mode(3, new ScreenshotModal(2, item))));
        })];
        const children_2 = [(children = [(matchValue = item.description, (matchValue === "") ? "Untitled screenshot" : matchValue)], react.createElement("td", {
            className: "side-panel-text",
        }, ...children))];
        return react.createElement("tr", keyValueList(props_2, 1), ...children_2);
    }, sortBy((s_1) => s_1.description, model.Screenshots, {
        Compare: comparePrimitives,
    })), react.createElement("tbody", {}, ...children_4))))];
    return react.createElement("div", {}, ...children_6);
}

