import { useEffect, useRef } from 'react';

import { logDebug, constants, ignore, getDUName } from './Common';
import { setUpMapValuePicker, unsubscribe } from "../PickedMapValue/PickedMapValue";
import { getLookupTableConfigForSelectedMap } from "../Meshes/Meshes";

const isDebugging = false;

function log(msg) {
    logDebug("Visualization.Interaction", isDebugging, msg);
}

function startInteraction(context, interaction) {
    if (interaction) {
        const [start, stop] = interaction;
        start();
        context.current.onExitInteractionMode = stop;
    }
}

export const InteractionModeType = Object.freeze({
    STANDARD: 'Standard',
    CALIPERS: 'Calipers',
    CENTERLINE_DISTANCE_MEASUREMENT: 'CenterlineDistanceMeasurement',
    DIAMETER_RANGE_MEASUREMENT: 'DiameterRangeMeasurement',
    LOCAL_DIAMETER_MEASUREMENT: 'LocalDiameterMeasurement',
    CENTERLINE_VOLUME_MEASUREMENT: 'CenterlineVolumeMeasurement',
    STATIC_MOVE_NOTES: 'StaticMoveNotes',
    MEASUREMENT_MOVE_NOTES: 'MeasurementMoveNotes',
    SCREENSHOT: 'Screenshot'
});

const InteractionViewType = Object.freeze({
    SINGLE_VIEW: "SingleView",
    COMPARE_VIEW: "CompareView",
    STANDARD_VIEW: "StandardView",
    HIDDEN_VIEW: "HiddenView"
});

export function useInteraction(
    parentContext,
    interactionProps,
    notesInteractionStatic,
    notesInteractionMeasurement,
    screenshotInteraction,
    captureScreenshot,
    pickedMapValueProps,
    currentStudyMeshes,
    previousStudyMeshes,
    showPreviousStudyMapsOnly,
    meshViewportLocation,
    selectedMap) {

    const context = useRef(null);

    const { interactionMode, interactionView } = interactionProps;
    const { onMapValuePicked, clearPickedValues } = pickedMapValueProps || { onMapValuePicked: ignore, clearPickedValues: ignore };
    const renderer = parentContext?.renderer;
    const { deletePickedIndicator } = parentContext || { deletePickedIndicator: ignore };

    const resetPickedValues = () => {
        if (meshViewportLocation) {
            clearPickedValues(getDUName(meshViewportLocation));
        }
        unsubscribe(parentContext);
        deletePickedIndicator();
    };

    // Side effect for updating interaction mode
    useEffect(() => {

        if (!context.current) {
            context.current = {};
        }

        log(`Changing interaction mode to ${interactionMode} - ${interactionView}`);

        if (context.current.onExitInteractionMode) {
            context.current.onExitInteractionMode();
            context.current.onExitInteractionMode = null;
        }

        const meshes = (previousStudyMeshes && showPreviousStudyMapsOnly === true) ? previousStudyMeshes : currentStudyMeshes;

        switch (interactionMode.name ?? interactionMode) {
            case InteractionModeType.STANDARD:
                context.current.onExitInteractionMode = null;

                if (renderer && meshes && meshViewportLocation && selectedMap) {
                    const lookupTableConfigValue = getLookupTableConfigForSelectedMap(selectedMap);
                    if (lookupTableConfigValue) {
                    setUpMapValuePicker(
                        lookupTableConfigValue,
                        renderer,
                        meshes,
                        interactionMode,
                        onMapValuePicked,
                        meshViewportLocation,
                        selectedMap,
                        parentContext);
                    }

                    if (parentContext?.updatePickedIndicator) {
                        parentContext.updatePickedIndicator();
                    }
                }

                break;
            case InteractionModeType.CALIPERS:
                resetPickedValues();
                break;
            case InteractionModeType.CENTERLINE_DISTANCE_MEASUREMENT:
                resetPickedValues();
                break;
            case InteractionModeType.DIAMETER_RANGE_MEASUREMENT:
                resetPickedValues();
                break;
            case InteractionModeType.LOCAL_DIAMETER_MEASUREMENT:
                resetPickedValues();
                break;
            case InteractionModeType.CENTERLINE_VOLUME_MEASUREMENT:
                resetPickedValues();
                break;
            case InteractionModeType.STATIC_MOVE_NOTES:
                startInteraction(context, notesInteractionStatic);
                break;
            case InteractionModeType.MEASUREMENT_MOVE_NOTES:
                startInteraction(context, notesInteractionMeasurement);
                break;
            case InteractionModeType.SCREENSHOT:
                switch (getDUName(interactionView)) {
                    case InteractionViewType.SINGLE_VIEW:
                        log("Capturing screenshot in single view mode");
                        captureScreenshot();
                        break;
                    case InteractionViewType.COMPARE_VIEW:
                        log("Capturing screenshot in compare view mode");
                        captureScreenshot(constants.splitScreenshotSize);
                        break;
                    case InteractionViewType.STANDARD_VIEW:
                        log("Starting screenshot interaction in standard view mode");
                        startInteraction(context, screenshotInteraction);
                        break;
                    case InteractionViewType.HIDDEN_VIEW:
                        log("Ignoring screenshot interaction in hidden mode")
                        break;
                    default:
                        console.error(`Unsupported interaction view: '${interactionView}'`);

                }
                break;
            default:
                console.error(`Unsupported interaction mode: '${interactionMode}'`);
        }

    }, [interactionMode, getDUName(interactionView), currentStudyMeshes, previousStudyMeshes, showPreviousStudyMapsOnly]);
}