import { Union } from "fable-compiler/bin/fable-library/Types";
import { debugCallback, isInitialized, logDebug } from '../js/Common';
import macro from "vtk.js/Sources/macros";
import Constants from "vtk.js/Sources/Widgets/Core/WidgetManager/Constants";
import {
    CenterlineBranchSelection,
    CenterlineType
} from "./CenterlinePlaneShared";
import vtkPlaneIndexFilter from "./BranchedPoints/PlaneIndexFilter";
import { createBasePipeline } from "./BranchedPoints/CenterlineMeasurementPipeline";

const { vtkErrorMacro } = macro;
const isDebugging = false;
const moduleName = "CenterlinePlaneUpdater";

function log(msg) {
    logDebug(moduleName, isDebugging, msg);
}

function isValueInitialized(value, valueName) {
    if (!isInitialized(value)) {
        log(`${valueName} is invalid because it is ${value}`);
        if (!isDebugging) {
            throw new ReferenceError("Value is not initialized");
        } else {
            vtkErrorMacro(valueName, " is undefined");
        }
        return false;
    }
    return true;
}

function debug(callback) {
    debugCallback(isDebugging, callback);
}

// ----------------------------------------------------------------------------
// vtkCenterlinePlaneUpdater methods
// ----------------------------------------------------------------------------

function vtkCenterlinePlaneUpdater(publicAPI, model) {
    model.classHierarchy.push('vtkCenterlinePlaneUpdater');

    // --------------------------------------------------------------------------
    // Internal variable
    // --------------------------------------------------------------------------

    model.distanceLeftFilterLumen = undefined;
    model.distanceRightFilterLumen = undefined;
    model.distanceCuspFilterLumen = undefined;
    model.distanceLeftFilterWall = undefined;
    model.distanceRightFilterWall = undefined;
    model.distanceCuspFilterWall = undefined;

    model.transitionLeftFilterLumen = undefined;
    model.transitionRightFilterLumen = undefined;
    model.transitionLeftFilterWall = undefined;
    model.transitionRightFilterWall = undefined;

    // --------------------------------------------------------------------------
    // API internal
    // --------------------------------------------------------------------------

    // From Dmitri Pavlutin https://dmitripavlutin.com/how-to-compare-objects-in-javascript/ under CC by 4.0
    // https://creativecommons.org/licenses/by/4.0/
    function shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }

    /** @typedef {{centerlineType: Union, branchSelection: Union, index: number, callbackIdentifier: string, planeIdentifier: string}} CenterlinePlane  */

    /**
     * @param {CenterlinePlane} plane
     */
    function tryFindPreviousPipeline(plane) {
        for (let i = 0; i < model.planePipelines.length; i++) {
            let basePipe = model.planePipelines[i];
            let checkPlane = basePipe.centerlinePlane;
            if (checkPlane.callbackIdentifier === plane.callbackIdentifier && checkPlane.planeIdentifier === plane.planeIdentifier) {
                return basePipe;
            }
        }
    }

    /**
     * @param {CenterlinePlane} plane
     */
    function selectRootFilter({ centerlineType, branchSelection, index, callbackIdentifier, planeIdentifier }) {
        debug(() => {
            global.branchSelection = branchSelection;
        });

        // If this suddenly breaks, double check that the centerlineType string coming in has the same casing as the
        // CenterlineType enum.
        const centerlineBranchSelection = CenterlineBranchSelection.ofBranchSelection(branchSelection);
        if (centerlineType.name === CenterlineType.LUMEN) {
            if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_LEFT)) {
                if (!model.distanceLeftFilterLumen) {
                    vtkErrorMacro("No filter found for distanceLeftFilterLumen", model.distanceLeftFilterLumen);
                }
                return model.distanceLeftFilterLumen;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_RIGHT)) {
                if (!model.distanceRightFilterLumen) {
                    vtkErrorMacro("No filter found for distanceRightFilterLumen", model.distanceRightFilterLumen);
                }
                return model.distanceRightFilterLumen;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_CUSP)) {
                if (!model.distanceCuspFilterLumen) {
                    vtkErrorMacro("No filter found for distanceCuspFilterLumen", model.distanceCuspFilterLumen);
                }
                return model.distanceCuspFilterLumen;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.TRANSITION_LEFT)) {
                if (!model.transitionLeftFilterLumen) {
                    vtkErrorMacro("No filter found for transitionLeftFilterLumen", model.transitionLeftFilterLumen);
                }
                return model.transitionLeftFilterLumen;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.TRANSITION_RIGHT)) {
                if (!model.transitionRightFilterLumen) {
                    vtkErrorMacro("No filter found for transitionRightFilterLumen", model.transitionRightFilterLumen);
                }
                return model.transitionRightFilterLumen;
            }
        } else if (centerlineType.name === CenterlineType.WALL) {
            if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_LEFT)) {
                if (!model.distanceLeftFilterWall) {
                    vtkErrorMacro("No filter found for distanceLeftFilterWall", model.distanceLeftFilterWall);
                }
                return model.distanceLeftFilterWall;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_RIGHT)) {
                if (!model.distanceRightFilterWall) {
                    vtkErrorMacro("No filter found for distanceRightFilterWall", model.distanceRightFilterWall);
                }
                return model.distanceRightFilterWall;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.DISTANCE_CUSP)) {
                if (!model.distanceCuspFilterWall) {
                    vtkErrorMacro("No filter found for distanceCuspFilterWall", model.distanceCuspFilterWall);
                }
                return model.distanceCuspFilterWall;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.TRANSITION_LEFT)) {
                if (!model.transitionLeftFilterWall) {
                    vtkErrorMacro("No filter found for transitionLeftFilterWall", model.transitionLeftFilterWall);
                }
                return model.transitionLeftFilterWall;
            } else if (CenterlineBranchSelection.areEqual(centerlineBranchSelection, CenterlineBranchSelection.TRANSITION_RIGHT)) {
                if (!model.transitionRightFilterWall) {
                    vtkErrorMacro("No filter found for transitionRightFilterWall", model.transitionRightFilterWall);
                }
                return model.transitionRightFilterWall;
            }
        } else {
            vtkErrorMacro("No match found for centerline", centerlineType, " and branch ", branchSelection);
        }
    }

    /**
     * @param {CenterlinePlane} plane
     * @param {{centerlinePlane: {CenterlinePlane}, pipeline}} previousPipeline
     */
    function previousPipelineNeedsUpdating(plane, previousPipeline) {
        isValueInitialized(plane, Object.keys({ plane })[0]);
        isValueInitialized(previousPipeline, Object.keys({ previousPipeline })[0]);

        // Flattens out the object to prepare it for shallowEqual.
        function flatten(centerlinePlane) {
            const flattenedFields = {
                centerlineType: centerlinePlane.centerlineType.toString(),
                branchSelection: centerlinePlane.branchSelection.toString()
            };
            return Object.assign({}, centerlinePlane, flattenedFields);
        }

        // We want to see if any of the values have changed, but don't care if it's a different object instance
        // reference.
        return !shallowEqual(flatten(plane), flatten(previousPipeline.centerlinePlane));
    }

    /**
     * @param {CenterlinePlane} plane
     * @param {{centerlinePlane: CenterlinePlane, centerlinePlaneSource: {rootFilter: any, planeIndexFilter: any}}} previousPipeline
     */
    function updatePreviousPipeline(plane, previousPipeline) {
        isValueInitialized(plane, Object.keys({ plane })[0]);
        isValueInitialized(previousPipeline, Object.keys({ previousPipeline })[0]);
        let previousFilter = previousPipeline.centerlinePlaneSource;

        // Unset the callback here. We're going to make multiple changes, and don't want the callback to trigger
        // until the end. Otherwise we'll have multiple callbacks going.
        previousFilter.planeIndexFilter.setValuesChangedCallback(() => {
        });

        let newRootFilter = selectRootFilter(plane);

        // Since we use the same set of root filters for everything, we can use a reference comparison here.
        if (newRootFilter !== previousFilter.rootFilter) {
            previousFilter.planeIndexFilter.setInputConnection(newRootFilter.getOutputPort());
            previousFilter.rootFilter = newRootFilter;
        }

        previousFilter.planeIndexFilter.setCurrentIdx(plane.index);

        previousFilter.planeIndexFilter.setValuesChangedCallback(centerlinePointMeasurement => model.valuesChangedCallback(
            centerlinePointMeasurement)
        );

        // Since previousFilter is a reference type, the updates get applied and we can pass the same instance back.
        return { centerlinePlane: plane, centerlinePlaneSource: previousFilter };
    }

    /**
     * @param {CenterlinePlane} plane
     */
    function createNewPipeline(plane) {
        isValueInitialized(plane, Object.keys({ plane })[0]);
        let newRootFilter = selectRootFilter(plane);

        let planeIndexFilter = vtkPlaneIndexFilter.newInstance({
            currentIdx: plane.index,
            valuesChangedCallback: model.valuesChangedCallback,
            pointDataArrayName: model.pointDataArrayNames,
            fieldDataArrayName: model.fieldDataArrayNames,
            centerlinePlane: { callbackIdentifier: plane.callbackIdentifier, planeIdentifier: plane.planeIdentifier }
        });

        if (!newRootFilter) {
            vtkErrorMacro('root filter invalid');
        }

        planeIndexFilter.setInputConnection(newRootFilter.getOutputPort());
        return { centerlinePlane: plane, centerlinePlaneSource: { rootFilter: newRootFilter, planeIndexFilter } };
    }

    /**
     * @param {CenterlinePlane} plane
     * @return {boolean}
     */
    function centerlinePipelineIsValid(
        {
            centerlineType,
            branchSelection,
            index,
            callbackIdentifier,
            planeIdentifier
        }) {
        if (!isValueInitialized(centerlineType, Object.keys({ centerlineType })[0])) {
            return false;
        }
        if (!isValueInitialized(centerlineType.name, Object.keys({ centerlineType })[0])) {
            return false;
        }
        if (!isValueInitialized(branchSelection, Object.keys({ branchSelection })[0])) {
            return false;
        }
        if (!isValueInitialized(branchSelection.fields[0].name, Object.keys({ branchSelection })[0])) {
            return false;
        }
        if (!isValueInitialized(index, Object.keys({ index })[0])) {
            return false;
        }
        if (!isValueInitialized(callbackIdentifier, Object.keys({ callbackIdentifier })[0])) {
            return false;
        }
        if (!isValueInitialized(planeIdentifier, Object.keys({ planeIdentifier })[0])) {
            return false;
        }
        if (centerlineType.name !== CenterlineType.LUMEN && centerlineType.name !== CenterlineType.WALL) {
            vtkErrorMacro("centerlineType not proper union", centerlineType);
            return false;
        }

        const centerlineBranchSelection = CenterlineBranchSelection.ofBranchSelection(branchSelection);
        if (!CenterlineBranchSelection.isValid(centerlineBranchSelection)) {
            vtkErrorMacro("branchSelection not proper union", branchSelection);
            return false;
        }
        if (index < 0) {
            vtkErrorMacro("invalid index", index);
            return false;
        }

        return true;
    }

    /**
     * @param {CenterlinePlane} plane
     */
    function updatePipelineForPlane(plane) {
        if (!centerlinePipelineIsValid(plane)) {
            vtkErrorMacro('plane is invalid: ', plane);
        }

        let maybePreviousFilter = tryFindPreviousPipeline(plane);
        let newFilter;
        if (!maybePreviousFilter) {
            newFilter = createNewPipeline(plane);
        } else if (previousPipelineNeedsUpdating(plane, maybePreviousFilter)) {
            newFilter = updatePreviousPipeline(plane, maybePreviousFilter);
        } else {
            newFilter = maybePreviousFilter;
        }

        return newFilter;

    }

    function updatePipelines(freshPipelines) {
        // TODO VRM-1084: make sure old pipelines are properly deleted/cleaned up
        // TODO VRM-1084: check for memory leaks when this is done
        model.planePipelines = freshPipelines;
        return model.planePipelines;
    }

    // --------------------------------------------------------------------------
    // API public
    // --------------------------------------------------------------------------

    publicAPI.makeBaseFilters = (lumenSource, wallSource) => {
        model.distanceLeftFilterLumen = createBasePipeline(lumenSource, model.fieldDataArrayNames);
        model.distanceLeftFilterLumen.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_LEFT);

        model.distanceRightFilterLumen = createBasePipeline(lumenSource, model.fieldDataArrayNames);
        model.distanceRightFilterLumen.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_RIGHT);

        model.distanceLeftFilterWall = createBasePipeline(wallSource, model.fieldDataArrayNames);
        model.distanceLeftFilterWall.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_LEFT);

        model.distanceRightFilterWall = createBasePipeline(wallSource, model.fieldDataArrayNames);
        model.distanceRightFilterWall.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_RIGHT);

        model.distanceCuspFilterLumen = createBasePipeline(lumenSource, model.fieldDataArrayNames);
        model.distanceCuspFilterLumen.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_CUSP);

        model.distanceCuspFilterWall = createBasePipeline(wallSource, model.fieldDataArrayNames);
        model.distanceCuspFilterWall.setCurrentCenterlineBranch(CenterlineBranchSelection.DISTANCE_CUSP);

        model.transitionLeftFilterLumen = createBasePipeline(lumenSource, model.fieldDataArrayNames);
        model.transitionLeftFilterLumen.setCurrentCenterlineBranch(CenterlineBranchSelection.TRANSITION_LEFT);

        model.transitionRightFilterLumen = createBasePipeline(lumenSource, model.fieldDataArrayNames);
        model.transitionRightFilterLumen.setCurrentCenterlineBranch(CenterlineBranchSelection.TRANSITION_RIGHT);

        model.transitionLeftFilterWall = createBasePipeline(wallSource, model.fieldDataArrayNames);
        model.transitionLeftFilterWall.setCurrentCenterlineBranch(CenterlineBranchSelection.TRANSITION_LEFT);

        model.transitionRightFilterWall = createBasePipeline(wallSource, model.fieldDataArrayNames);
        model.transitionRightFilterWall.setCurrentCenterlineBranch(CenterlineBranchSelection.TRANSITION_RIGHT);
    };

    publicAPI.setCenterlinePlanes = planes => {
        let freshPipelines = [];

        for (let i = 0; i < planes.length; i++) {
            let pipe = updatePipelineForPlane(planes[i]);
            freshPipelines.push(pipe);
        }

        publicAPI.modified();
        return updatePipelines(freshPipelines);
    };

    /**
     * @param {{planeA: CenterlinePlane, planeB: CenterlinePlane}} planes
     */
    publicAPI.setCenterlinePlaneRange = planes => {
        let planeArray = [planes.planeA, planes.planeB];
        let freshPipelines = [];

        for (let i = 0; i < planeArray.length; i++) {

            let pipe = updatePipelineForPlane(planeArray[i]);
            freshPipelines.push(pipe);
        }

        publicAPI.modified();
        let updatedPipelines = updatePipelines(freshPipelines);

        let firstPlane;
        let lastIndex;
        if (updatedPipelines[0].centerlinePlane.index > updatedPipelines[1].centerlinePlane.index) {
            firstPlane = updatedPipelines[1];
            lastIndex = updatedPipelines[0].centerlinePlane.index;
        } else {
            firstPlane = updatedPipelines[0];
            lastIndex = updatedPipelines[1].centerlinePlane.index;
        }

        let rangeValues = firstPlane.centerlinePlaneSource.planeIndexFilter.getValueRange(firstPlane.centerlinePlane.index,
            lastIndex);

        return { pipelines: updatedPipelines, rangeValues };
    };

    /** @typedef {{ firstIndex: number, lastIndex: number }} IndexBounds */

    /** @typedef {{
     *      transitionTrunk: IndexBounds, 
     *      transitionLeftBranch: IndexBounds, 
     *      transitionRightBranch: IndexBounds 
     *  }} BranchIndexBounds
     */

    /**
     * @typedef {{
     *     lumenBounds: BranchIndexBounds,
     *     wallBounds: BranchIndexBounds
     * }} UnifiedBranchIndexBounds
     */

    /**
     *
     * @param {vtkPolyData} source
     * @param {string} arrayName
     * @param {IndexBounds} trunkBounds
     * @returns {IndexBounds}
     */
    function getIliacBounds(source, arrayName, trunkBounds) {
        /** @type {vtkDataArray} */
        const mappingArray = source.getFieldData().getArrayByName(arrayName);
        const firstIndex = trunkBounds.lastIndex + 1;
        return {
            firstIndex,
            lastIndex: firstIndex + mappingArray.getNumberOfTuples() - 1
        };
    }

    /**
     *
     * @param {vtkPolyData} source
     * @param {string} arrayName
     * @return {IndexBounds}
     */
    function getTrunkBounds(source, arrayName) {
        /** @type {vtkDataArray} */
        const mappingArray = source.getFieldData().getArrayByName(arrayName);
        return {
            firstIndex: 0,
            lastIndex: mappingArray.getNumberOfTuples() - 1
        };
    }

    /**
     *
     * @param {vtkPolyData} source
     * @return {BranchIndexBounds}
     */
    function getBranchBounds(source) {
        const transitionTrunkBounds = getTrunkBounds(source, model.fieldDataArrayNames.TransitionBranchTrunk);
        const distanceTrunkBounds = getTrunkBounds(source, model.fieldDataArrayNames.DistanceBranchTrunk);
        
        return {
            transitionTrunk: transitionTrunkBounds,
            transitionLeftBranch: getIliacBounds(source, model.fieldDataArrayNames.TransitionBranchLeft, transitionTrunkBounds),
            transitionRightBranch: getIliacBounds(source, model.fieldDataArrayNames.TransitionBranchRight, transitionTrunkBounds),
            distanceTrunk: distanceTrunkBounds,
            distanceLeftBranch: getIliacBounds(source, model.fieldDataArrayNames.DistanceBranchLeft, distanceTrunkBounds),
            distanceRightBranch: getIliacBounds(source, model.fieldDataArrayNames.DistanceBranchRight, distanceTrunkBounds),
            cuspTrunk: getTrunkBounds(source, model.fieldDataArrayNames.DistanceBranchTrunkCusp)
        };
    }

    publicAPI.setUnifiedBranchIndexBounds = () => {
        if (!model.lumenSource || !model.wallSource) {
            macro.vtkErrorMacro('lumenSource and wallSource must be set before calling setUnifiedBranchIndexBounds');
            return;
        }

        model.unifiedBranchIndexBounds = {
            lumenBounds: getBranchBounds(model.lumenSource),
            wallBounds: getBranchBounds(model.wallSource)
        };
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    centerlinePlanes: [],
    planePipelines: [],
    valuesChangedCallback: () => {
    },
    lumenSource: {},
    wallSource: {},
    pointDataArrayNames: {},
    fieldDataArrayNames: {},
    unifiedBranchIndexBounds: {}
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    let { lumenSource, wallSource } = initialValues;

    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);

    macro.get(publicAPI, model, ['centerlinePlanes', 'planePipelines', 'unifiedBranchIndexBounds']); // getCenterlinePLanes, getPlanePipelines, getUnifiedBranchIndexBounds

    // Object specific methods
    vtkCenterlinePlaneUpdater(publicAPI, model);

    publicAPI.makeBaseFilters(lumenSource, wallSource);
    publicAPI.setUnifiedBranchIndexBounds();
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkCenterlinePlaneUpdater');

// ----------------------------------------------------------------------------

export default { newInstance, extend, Constants };
