import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, lambda_type, unit_type, string_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class ScreenshotProps extends Record {
    constructor(onScreenshotCaptured) {
        super();
        this.onScreenshotCaptured = onScreenshotCaptured;
    }
}

export function ScreenshotProps$reflection() {
    return record_type("RAWMap.Client.Visualization.Screenshot.Bindings.ScreenshotProps", [], ScreenshotProps, () => [["onScreenshotCaptured", lambda_type(string_type, unit_type)]]);
}

