import { length, tryHead } from "../fable_modules/fable-library.3.7.20/List.js";
import { Toast_errorToast } from "../Common/General.js";
import { String_ofListWithOxfordComma, String_pluralize } from "../fable_modules/Webbler.Models.1.2.2/Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { FileComponentSpecs__isFileExtensionAllowed_Z721C83C5, FileType_asExtensions_DC44A4C } from "./Shared.js";

export function validateFiles(fileDataList, onSuccessfulValidation, model, specs, getNumberOfFiles) {
    let fileData, allowedFileTypes, fileData_1, allowedFileTypes_1;
    const matchValue = [tryHead(fileDataList), specs];
    let pattern_matching_result, maxNum_4, allowedFileTypes_2, fileData_2, maxNum_5;
    if (matchValue[1].maxFiles.tag === 1) {
        if (length(fileDataList) > matchValue[1].maxFiles.fields[0]) {
            pattern_matching_result = 0;
            maxNum_4 = matchValue[1].maxFiles.fields[0];
        }
        else if (matchValue[0] != null) {
            if (matchValue[1].maybeFileTypeRestrictions != null) {
                if ((fileData = matchValue[0], (allowedFileTypes = matchValue[1].maybeFileTypeRestrictions, !FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(specs, fileData.file.name)))) {
                    pattern_matching_result = 1;
                    allowedFileTypes_2 = matchValue[1].maybeFileTypeRestrictions;
                    fileData_2 = matchValue[0];
                }
                else if (getNumberOfFiles(model) > matchValue[1].maxFiles.fields[0]) {
                    pattern_matching_result = 2;
                    maxNum_5 = matchValue[1].maxFiles.fields[0];
                }
                else {
                    pattern_matching_result = 3;
                }
            }
            else if (getNumberOfFiles(model) > matchValue[1].maxFiles.fields[0]) {
                pattern_matching_result = 2;
                maxNum_5 = matchValue[1].maxFiles.fields[0];
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else if (getNumberOfFiles(model) > matchValue[1].maxFiles.fields[0]) {
            pattern_matching_result = 2;
            maxNum_5 = matchValue[1].maxFiles.fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[0] != null) {
        if (matchValue[1].maybeFileTypeRestrictions != null) {
            if ((fileData_1 = matchValue[0], (allowedFileTypes_1 = matchValue[1].maybeFileTypeRestrictions, !FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(specs, fileData_1.file.name)))) {
                pattern_matching_result = 1;
                allowedFileTypes_2 = matchValue[1].maybeFileTypeRestrictions;
                fileData_2 = matchValue[0];
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [model, Toast_errorToast(`Only ${maxNum_4} item${String_pluralize(maxNum_4)} can be uploaded at a time.`), Cmd_none()];
        }
        case 1: {
            const allowedExtensions = String_ofListWithOxfordComma(FileType_asExtensions_DC44A4C(allowedFileTypes_2));
            return [model, Toast_errorToast(`Unsupported file type. Please select a ${allowedExtensions} file.`), Cmd_none()];
        }
        case 2: {
            return [model, Toast_errorToast(`Can only have ${maxNum_5} file${String_pluralize(maxNum_5)} uploaded. Please delete unneeded files.`), Cmd_none()];
        }
        case 3: {
            return onSuccessfulValidation(model);
        }
    }
}

