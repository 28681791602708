import { mkToolReactButton } from "../Common/CommonView.js";
import { Shared_defaultButtonStyles, Shared_onButtonStyles } from "../VisualizationShared.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { LocalMsg } from "./CameraTypes.js";

export function lockUnlockTool(isDisabled, model, dispatch) {
    const isOn = model.SyncViewports;
    return mkToolReactButton(dispatch, isOn ? Shared_onButtonStyles : Shared_defaultButtonStyles, new Fa_IconOption(11, "fas fa-lock"), new LocalMsg(0, !isOn), false, isDisabled, ((_arg) => (_arg ? "Unlock viewports" : "Lock viewports"))(isOn));
}

