import { OutboundMsg$1, Model$1_getActiveStep_Z2F6B8DDE, Msg$1 } from "./WizardTypes.js";
import { Option, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty, length, ofArray, mapIndexed, reduce, singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import * as react from "react";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { Option as Option_1, tag as tag_2 } from "../fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Footer_div, footer, content as content_1, Header_Title_Option, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";

export function view(model, dispatch, canCancel, canAdvance, content) {
    const dispatchOutbound = (arg_1) => {
        dispatch(new Msg$1(1, arg_1));
    };
    const activeStep = Model$1_getActiveStep_Z2F6B8DDE(model);
    const stepArrow = icon(singleton(new Option(0, new Size_ISize(2))), singleton(react.createElement("i", {
        className: "fas fa-arrow-right",
    })));
    const cardTitle = reduce((s1, s2) => toList(delay(() => append(s1, delay(() => append(singleton_1(stepArrow), delay(() => s2)))))), mapIndexed((i, step) => singleton(tag_2(ofArray([new Option_1(1, (i < model.activeStepIndex) ? (new Color_IColor(6)) : ((i === model.activeStepIndex) ? (new Color_IColor(4)) : (new Color_IColor(14)))), new Option_1(0, new Size_ISize(2)), new Option_1(5, "wizard-tag")]), singleton(model.describeStep(step)))), model.steps));
    const nextButton = button(ofArray([new Option_2(16, !canAdvance), new Option_2(0, new Color_IColor(6)), new Option_2(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatchOutbound(new OutboundMsg$1(0, activeStep));
    }), new HTMLAttr(159, "button")]))]), toList(delay(() => ((model.activeStepIndex < (length(model.steps) - 1)) ? append(singleton_1("Next"), delay(() => singleton_1(icon(ofArray([new Option(0, new Size_ISize(2)), new Option(4, singleton(["style", {
        marginLeft: "10px",
    }]))]), singleton(react.createElement("i", {
        className: "fas fa-arrow-right",
    })))))) : singleton_1("Finish")))));
    return card(empty(), ofArray([header(empty(), singleton(Header_title(ofArray([new Header_Title_Option(0), new Header_Title_Option(2, "wizard-header")]), cardTitle))), content_1(empty(), content), footer(empty(), ofArray([Footer_div(empty(), singleton(button(ofArray([new Option_2(16, !canCancel), new Option_2(0, new Color_IColor(8)), new Option_2(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatchOutbound(new OutboundMsg$1(1, activeStep));
    }), new HTMLAttr(159, "button")]))]), singleton("Cancel")))), Footer_div(empty(), singleton(nextButton))]))]));
}

