import { ScalarMode } from 'vtk.js/Sources/Rendering/Core/Mapper/Constants';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker'
import vtkSphereSource from 'vtk.js/Sources/Filters/Sources/SphereSource';

import { displayMapTypes, getDUName, ignore, logDebug } from '../js/Common';
import { InteractionModeType } from '../js/Interaction';
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper";
import vtkMath, { radiansFromDegrees } from "vtk.js/Sources/Common/Core/Math";
import vtkWidgetRepresentation from "vtk.js/Sources/Widgets/Representations/WidgetRepresentation";
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';

/** @typedef { import('../Meshes/Meshes.js').LookupTableConfigValueType } LookupTableConfigValue */

const isDebugging = false;

function log(msg) {
    logDebug("Visualization.PickedMapValue", isDebugging, msg);
}

// TODO: When we upgrade vtk.js to >= 26.8.0, import this static function from 'vtk.js/Sources/Widgets/Representations/WidgetRepresentation' instead.
// Source: https://github.com/Kitware/vtk-js/blob/a59102e09454fe10b962975c524a7e09f2bef2eb/Sources/Widgets/Representations/WidgetRepresentation/index.js#LL89C8-L108C2
function getPixelWorldHeightAtCoord(worldCoord, displayScaleParams) {
    const {
        dispHeightFactor,
        cameraPosition,
        cameraDir,
        isParallel,
        rendererPixelDims,
    } = displayScaleParams;
    let scale = 1;
    if (isParallel) {
        scale = dispHeightFactor;
    } else {
        const worldCoordToCamera = [...worldCoord];
        vtkMath.subtract(worldCoordToCamera, cameraPosition, worldCoordToCamera);
        scale = vtkMath.dot(worldCoordToCamera, cameraDir) * dispHeightFactor;
    }

    const rHeight = rendererPixelDims[1];
    return scale / rHeight;
}


/**
 * Unsubscribes the pickMapValueSubscription in the provided context.
 * @param context
 * @returns {void}
 */
export function unsubscribe(context) {
    if (context?.pickMapValueSubscription) {
        log('Unsubscribing pickMapValueSubscription');
        context.pickMapValueSubscription.unsubscribe();
        context.pickMapValueSubscription = null;
    }
}

/**
 * Sets up a handler to pick the map value under the cursor.
 * @param {LookupTableConfigValue} lookupTableConfigValue Value from lookupTableConfig
 * @param {vtkRenderer} renderer
 * @param meshes
 * @param {InteractionModeType} interactionMode
 * @param {{onMapValuePicked}} onMapValuePicked
 * @param meshViewportLocation
 * @param selectedMap
 * @param context
 * @returns {void}
 */
export function setUpMapValuePicker(
    lookupTableConfigValue,
    renderer,
    meshes,
    interactionMode,
    onMapValuePicked,
    meshViewportLocation,
    selectedMap,
    context) {

    unsubscribe(context);

    /** @type {vtkRenderWindow} */
    const renderWindow = renderer.getRenderWindow();
    const interactor = renderWindow.getInteractor();

    const computePixelScale = (location) => {
        const camera = renderer.getActiveCamera();
        const interactor = renderWindow.getInteractor();
        const apiSpecificRenderWindow = interactor.getView();

        // Copied from vtk.js/Sources/Widgets/Core/WidgetManager/index.js.
        const [rwW, rwH] = apiSpecificRenderWindow.getSize();
        const [vxmin, vymin, vxmax, vymax] = renderer.getViewport();
        const rendererPixelDims = [rwW * (vxmax - vxmin), rwH * (vymax - vymin)];
        const isParallel = camera.getParallelProjection();
        const dispHeightFactor = isParallel
            ? 2 * camera.getParallelScale()
            : 2 * Math.tan(radiansFromDegrees(camera.getViewAngle()) / 2);

        const displayScaleParams = {
            dispHeightFactor,
            cameraPosition: camera.getPosition(),
            cameraDir: camera.getDirectionOfProjection(),
            isParallel,
            rendererPixelDims,
        };

        return getPixelWorldHeightAtCoord(location, displayScaleParams);
    };

    const addSphere = (location, color = [0.67, 0.14, 0.96]) => {
        const pixelScale = computePixelScale(location);
        const desiredHeightInPixels = 10;

        const sphereSource = vtkSphereSource.newInstance();
        sphereSource.setCenter(location);
        sphereSource.setRadius(desiredHeightInPixels * pixelScale);
        const sphereMapper = vtkMapper.newInstance();
        sphereMapper.setInputConnection(sphereSource.getOutputPort());
        const sphereActor = vtkActor.newInstance();
        sphereActor.setMapper(sphereMapper);
        sphereActor.getProperty().setColor(color);
        renderer.addActor(sphereActor);

        const updateSphere = () => {
            const pixelScale = computePixelScale(sphereSource.getCenter());

            if (pixelScale === Infinity) {
                // pixelScale can become Infinity when switching from single-viewport made to quad-viewport mode for
                // points that have been selected on the bottom-left or bottom-right maps.
                return;
            }

            sphereSource.setRadius(desiredHeightInPixels * pixelScale);
            log(`updateSphere :: Set sphere radius to ${sphereSource.getRadius()}`);
        }

        return { sphereActor, sphereMapper, sphere: sphereSource, updateSphere };
    }

    /** @typedef {object} PickedPolyPoint
     * @property { number[] } point
     * @property { number } value
     * @property debugPoints
     * @property { function(void): void } updateSphere
     */

    /** @callback getPickedValue
     * @returns { PickedPolyPoint }
     */

    const picker = vtkCellPicker.newInstance();
    picker.setTolerance(0.0);

    /** @type getPickedValue */
    let getPickedValue;
    let primitiveData;
    let mesh;
    if (getDUName(selectedMap) === displayMapTypes.DIAMETRIC_GROWTH) {
        mesh = meshes.maybeDiametricGrowth;
    } else {
        mesh = meshes.wallMaps;
    }

    if (mesh === null) {
        return;
    }

    switch (lookupTableConfigValue.scalarMode) {
        case ScalarMode.USE_CELL_FIELD_DATA:
            primitiveData = mesh.mapper.getInputData().getCellData();

            getPickedValue = () => {
                const cellId = picker.getCellId();
                const pickedPositions = picker.getPickedPositions();
                return {
                    point: pickedPositions.length > 0 ? pickedPositions[0] : null,
                    value: primitiveData.getArrayByName(lookupTableConfigValue.colorByArrayName).getData()[cellId],
                    debugPoints: []
                };
            };
            break;

        case ScalarMode.USE_POINT_FIELD_DATA:
            primitiveData = mesh.mapper.getInputData().getPointData();

            getPickedValue = () => {
                const pickedPoints = picker.getPickedPositions();

                /** @type { number[] } Coordinates of the exact point on the mesh that the user picked. */
                const pickedPoint = pickedPoints?.[0];

                if (!pickedPoint) {
                    return null;
                }

                // Get the points defining the picked cell.

                /** @type { number[] }
                 * @description Array consisting of [numberOfPoints_0, p0_0, p0_1, p0_2, numberOfPoints_1, p1_0, p1_1, p1_2, ...].
                 * For example, [3, 0, 1, 2, 3, 23, 22, 11, ...].
                 */
                const polys = picker.getDataSet().getPolys().getData();

                /** @type { number[] } Size of each cell. */
                const cellSizes = picker.getDataSet().getPolys().getCellSizes();

                const pickedCellId = picker.getCellId();
                const polyIndex = cellSizes
                    .slice(0, pickedCellId)
                    .reduce((sum, currentValue) => sum + currentValue + 1, 0);

                const pickedPolyPointIds = polys.slice(polyIndex + 1, polyIndex + polys[polyIndex] + 1);
                log(`pickedPolyPointIds: ${pickedPolyPointIds}`);

                // For each cell point, compute the distance from the picked point.
                // Note we need to convert to a regular JavaScript array using `Array.from` or map won't work as expected.
                const pickedPolyPoints = Array.from(pickedPolyPointIds).map(pointId => {
                    // Each call to getPoint() seems to re-use the same reference, so we have to copy the array to prevent the previous result from being overwritten.
                    const point = [...picker.getDataSet().getPoints().getPoint(pointId)];
                    const distance = vtkMath.distance2BetweenPoints(pickedPoint, point);
                    return { pointId, point, distance };
                });

                log(`pickedPolyPoints: ${JSON.stringify(pickedPolyPoints)}`);

                // Select the closest cell point.
                const closestPoint = pickedPolyPoints.reduce((minValue, currentValue) => currentValue.distance < minValue.distance ? currentValue : minValue);

                return {
                    point: closestPoint.point,
                    value: primitiveData.getArrayByName(lookupTableConfigValue.colorByArrayName).getData()[closestPoint.pointId],
                    debugPoints: isDebugging ? pickedPolyPoints.filter(p => p.pointId !== closestPoint.pointId) : []
                };
            };
            break;

        default:
            throw "pickMapValue: Invalid scalarMode";
    }

    picker.initializePickList();
    // Tell the picker to only pick actors in the list we provide.
    picker.setPickFromList(true);
    picker.addPickList(mesh.actor);

    context.pickMapValueSubscription = interactor.onRightButtonPress((callData /* IRenderWindowInteractorEvent */) => {
        if (getDUName(interactionMode) !== InteractionModeType.STANDARD) {
            return;
        }

        if (renderer !== callData.pokedRenderer) {
            return;
        }

        if (context?.deletePickedIndicator) {
            context.deletePickedIndicator();
        }

        const position = callData.position;
        const point = [position.x, position.y, 0];
        picker.pick(point, renderer);

        const pickedActors = picker.getActors();
        if (pickedActors && pickedActors.length > 0 && pickedActors[0]) {
            const pickedPolyPoint = getPickedValue();
            log(`pickedPolyPoint: ${JSON.stringify(pickedPolyPoint)}`);

            // Add sphere to show picked point.
            if (pickedPolyPoint !== null) {
                const indicatorInfo = addSphere(pickedPolyPoint.point);
                const debugSphereInfos = pickedPolyPoint.debugPoints.map(p => addSphere(p.point, [0.5, 0.5, 0.5]))

                context.deletePickedIndicator = () => {
                    const deleteIndicator = indicatorInfo => {
                        indicatorInfo.sphereActor.delete();
                        indicatorInfo.sphereMapper.delete();
                        indicatorInfo.sphere.delete();
                    };

                    [indicatorInfo, ...debugSphereInfos].forEach(deleteIndicator);

                    context.updatePickedIndicator = ignore;
                };

                context.updatePickedIndicator = indicatorInfo.updateSphere

            } else {
                context.deletePickedIndicator = ignore;
            }

            onMapValuePicked({
                viewportLocation: getDUName(meshViewportLocation),
                selectedMap: selectedMap,
                maybePickedValue: pickedPolyPoint.value
            });
        } else {
            onMapValuePicked({
                viewportLocation: getDUName(meshViewportLocation),
                selectedMap: selectedMap,
                maybePickedValue: null
            });
        }
    });
}
