import vtkMath from 'vtk.js/Sources/Common/Core/Math';
import { vec3 } from 'gl-matrix';
import { Vector3 } from "../js/Vector3";

export function setCameraViewPlaneNormal(/*vtkCamera*/ camera, /*Vector3*/ directionVector) {

    let baseFocalPoint = camera.getFocalPoint();
    let distanceFromFocal = camera.getDistance();

    let toNormalizeNum = directionVector.toVtk();
    // Normalize in place, so no return value
    vtkMath.normalize(toNormalizeNum);
    let directionNormal = toNormalizeNum;

    let awayFromFocal = vtkMath.multiplyScalar(directionNormal, distanceFromFocal);
    let position = vtkMath.add(baseFocalPoint, awayFromFocal, vec3.create());

    camera.setPosition(...position);
    camera.setFocalPoint(...baseFocalPoint);
    // Make sure we're not twisted at weird angles
    camera.setViewUp(Vector3.unitZ());

    return camera;
}

export function setRendererViewPlaneNormal(/*vtkRenderer*/ renderer, /*Vector3*/ directionVector) {

    let activeCamera = renderer.getActiveCamera();
    let updatedCamera = setCameraViewPlaneNormal(activeCamera, directionVector);
    renderer.setActiveCamera(updatedCamera);
}
