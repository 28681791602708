import vtkStateBuilder from "vtk.js/Sources/Widgets/Core/StateBuilder";
import { distance2BetweenPoints } from "vtk.js/Sources/Common/Core/Math";

export default function generateState({ measurementId, firstPoint, secondPoint, isComplete }) {

    const scale = 20.0;

    const getDistance = state => {
        const firstHandle = state.getFirstPoint();
        const secondHandle = state.getSecondPoint();

        if (firstHandle && secondHandle) {
            return Math.sqrt(
                distance2BetweenPoints(firstHandle.getOrigin(), secondHandle.getOrigin())
            );
        } else {
            return 0.0;
        }
    };

    const state = vtkStateBuilder
        .createBuilder()
        .addStateFromMixin({
            labels: ["firstPoint"],
            mixins: ["origin", "color", "scale1", "visible", "shape", "manipulator"],
            name: "firstPoint",
            initialValues: {
                scale1: scale,
                origin: firstPoint,
                visible: isComplete
            }
        })
        .addStateFromMixin({
            labels: ["secondPoint"],
            mixins: ["origin", "color", "scale1", "visible", "shape", "manipulator"],
            name: "secondPoint",
            initialValues: {
                scale1: scale,
                origin: secondPoint,
                visible: isComplete
            }
        })
        .addField({ name: "measurementId", initialValue: measurementId })
        .addField({ name: "isComplete", initialValue: isComplete })
        .addField({ name: "onDistanceMeasured", initialValue: null })

        // TODO - VRM-1097 - grab this factor from meshes;
        .addField({ name: "unitConversionFactor", initialValue: 1.0 })

        .build();

    state.onModified(() => {
        const onDistanceMeasured = state.getOnDistanceMeasured();
        if (onDistanceMeasured && state.getIsComplete()) {
            const mid = state.getMeasurementId();
            const dist = getDistance(state) * state.getUnitConversionFactor();
            onDistanceMeasured(mid, dist);
        }
    });

    return state;
}
