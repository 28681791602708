import { Vector3 } from "../js/Vector3";
import vtkCalipersWidgetFactory from "./CalipersWidget";

import { logDebug } from '../js/Common';

import { useEffect, useRef } from 'react';

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.Calipers", isDebugging, msg);
}

function mkCalipersWidget(meshes, state) {

    const calipersWidget = vtkCalipersWidgetFactory.newInstance({ state });

    const bounds = meshes.anatomy.dataSource.getBounds();

    // Calculate the midpoint of the bounds for the origin of the widget manipulator
    const { min, max } = Vector3.ofBounds(bounds);
    const origin = Vector3.midBounds(min, max);

    calipersWidget.placeWidget(bounds);

    // Place initial handles in the bounds midpoint
    calipersWidget.getManipulator().setOrigin(origin.toVtk());

    return calipersWidget;
}

function removeWidgets(widgetManager, calipersWidgets) {
    calipersWidgets.forEach(widget => widgetManager.removeWidget(widget));
}

// From Dmitri Pavlutin https://dmitripavlutin.com/how-to-compare-objects-in-javascript/ under CC by 4.0
// https://creativecommons.org/licenses/by/4.0/
function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        let piece1 = object1[key];
        let piece2 = object2[key];
        if (piece1 !== piece2 && piece1.toString() !== piece2.toString()) {
            return false;
        }
    }
    return true;
}

function arrayEquals(a, b) {
    if (a.length === b.length) {
        for (let i = 0; i < a.length; ++i) {
            if (!shallowEqual(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }
    return false;
}

/**
 * @param parentContext
 * @param initialized
 * @param { { measurementId: string, caliperState, pickable: bool }[] } activeMeasurementStates
 * @param {Meshes} meshes
 */
export function useCalipers(parentContext, initialized, { activeMeasurementStates }, meshes) {
    let checkAgainst = useRef([]);
    if (checkAgainst.current && arrayEquals(checkAgainst.current, activeMeasurementStates)) {
        checkAgainst.current.length = 0;
        checkAgainst.current.push(...activeMeasurementStates);
    } else {
        checkAgainst.current = activeMeasurementStates;
    }

    const context = useRef(null);

    useEffect(() => {

        if (parentContext) {

            if (!context.current) {
                log("Initializing calipers effect");

                // Initialize
                const calipersWidgets = new Map();

                context.current = {
                    calipersWidgets
                };
            } else {
                const { calipersWidgets } = context.current;
                const { widgetManager } = parentContext;
                if (activeMeasurementStates && activeMeasurementStates.length > 0) {
                    for (const [id, widget] of calipersWidgets) {
                        let hasMatch = false;
                        for (let i = 0; i < activeMeasurementStates.length && !hasMatch; i++) {
                            let activeState = activeMeasurementStates[i];
                            if (activeState.measurementId === id) {
                                hasMatch = true;
                            }
                        }

                        if (!hasMatch) {
                            log(`Removing measurement ${id}`);
                            calipersWidgets.delete(id);
                            widgetManager.removeWidget(widget);
                        }
                    }

                    let somethingIsPickable = false;

                    for (let i = 0; i < activeMeasurementStates.length; i++) {
                        let activeState = activeMeasurementStates[i];

                        const measurementId = activeState.measurementId;

                        if (!calipersWidgets.has(measurementId)) {

                            log(`Adding measurement ${measurementId}`);

                            const calipersWidget =
                                mkCalipersWidget(meshes, activeState.caliperState);
                            calipersWidgets.set(measurementId, calipersWidget);

                            const behaviourHandle = widgetManager.addWidget(calipersWidget);

                            // Set initial handle orientations
                            behaviourHandle.updateHandleOrientations();

                            if (!calipersWidget.getWidgetState().getIsComplete()) {
                                widgetManager.grabFocus(calipersWidget);
                            }
                        }

                        somethingIsPickable = activeState.pickable || somethingIsPickable;
                    }

                    if (somethingIsPickable) {
                        widgetManager.enablePicking();
                    } else {
                        widgetManager.disablePicking();
                    }

                    context.current.calipersWidgets = calipersWidgets;
                } else {
                    log("Deactivating calipers widgets");

                    removeWidgets(widgetManager, calipersWidgets);
                    calipersWidgets.clear();

                    widgetManager.releaseFocus();
                    widgetManager.disablePicking();
                }
            }
        }

        return () => {

            // Dispose
            if (parentContext && context.current) {
                log("Disposing calipers widgets");
                const { widgetManager } = parentContext;
                const { calipersWidgets } = context.current;
                if (!widgetManager.isDeleted()) {
                    removeWidgets(widgetManager, calipersWidgets);
                }
                calipersWidgets.clear();

                context.current = {
                    calipersWidgets
                };
            }
        };

    }, [initialized, checkAgainst.current]);

}