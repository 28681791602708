import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, float64_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Visualization_LabelPositionPercentage$reflection, Visualization_Point3d$reflection } from "../Common.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotEmptyGuid } from "../../fable_modules/Webbler.Models.1.2.2/Validation.fs.js";
import { ErrorMessage } from "../ErrorMessage.js";
import { StudyValidators_validateNotEqual, StudyValidators_validateFloatIsPositive, StudyValidators_validateStringNotNullOrWhiteSpace } from "./Study.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";

export class CalipersMeasurementViewModel extends Record {
    constructor(measurementId, studyId, name, value, firstPoint, secondPoint, labelPosition) {
        super();
        this.measurementId = measurementId;
        this.studyId = studyId;
        this.name = name;
        this.value = value;
        this.firstPoint = firstPoint;
        this.secondPoint = secondPoint;
        this.labelPosition = labelPosition;
    }
}

export function CalipersMeasurementViewModel$reflection() {
    return record_type("RAWMap.Models.View.CalipersMeasurement.CalipersMeasurementViewModel", [], CalipersMeasurementViewModel, () => [["measurementId", class_type("System.Guid")], ["studyId", class_type("System.Guid")], ["name", string_type], ["value", float64_type], ["firstPoint", Visualization_Point3d$reflection()], ["secondPoint", Visualization_Point3d$reflection()], ["labelPosition", Visualization_LabelPositionPercentage$reflection()]]);
}

export function CalipersMeasurementViewModel_validate_491575AE(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, "measurementId", "not be empty Guid"), vm.measurementId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, "studyId", "not be empty Guid"), vm.studyId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateStringNotNullOrWhiteSpace("name", vm.name), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsPositive("value", vm.value), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateNotEqual("firstPoint", "secondPoint", vm.firstPoint, vm.secondPoint), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))))))));
}

export function CalipersMeasurementViewModel_viewComponents_491575AE(calipers) {
    return ["Calipers", toText(printf("%0.1f %s"))(calipers.value)("mm")];
}

export function CalipersMeasurementViewModel_comparePrevious(current, previous) {
    let measurement_2;
    return [toText(printf("%0.1f %s"))(previous.value)("mm"), (measurement_2 = (current.value - previous.value), toText(printf("%0.1f %s"))(measurement_2)("mm"))];
}

export function CalipersMeasurementViewModel_getStudyId_491575AE(vm) {
    return vm.studyId;
}

export function CalipersMeasurementViewModel_getPrimaryId_491575AE(vm) {
    return vm.measurementId;
}

export function CalipersMeasurementViewModel_get_primaryIdName() {
    return "MeasurementId";
}

export function CalipersMeasurementViewModel_get_description() {
    return "Calipers Measurement";
}

export function CalipersMeasurementViewModel_desc_491575AE(_arg) {
    return CalipersMeasurementViewModel_get_description();
}

