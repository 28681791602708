import { compareTo, printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { option_type, union_type, tuple_type, int32_type, string_type, record_type, list_type, lambda_type, unit_type, class_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { Visualization_LabelPositionPercentage, Visualization_LabelPositionPercentage$reflection } from "../RAWMap.Models/Common.js";
import { ComponentVisibility, Tooltip_tooltip, Button_visibilityControlled, ComponentVisibility$reflection } from "../Common/Controls.js";
import { Header_icon, Footer_div, footer, content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { append as append_1, tryFind, concat, map as map_1, unzip, cons, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { body, Label_Option, label as label_1, Option as Option_1, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { safeHash, equals, partialApply, stringHash, curry } from "../fable_modules/fable-library.3.7.20/Util.js";
import { String_toOption } from "../Common/Extensions.js";
import { filter, toArray, bind, map, defaultArg } from "../fable_modules/fable-library.3.7.20/Option.js";
import { List_except, List_distinct } from "../fable_modules/fable-library.3.7.20/Seq2.js";
import { Fa_i, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { FormField_ReactSelectCallback$1, FormField_reactSelectCreatable } from "../Common/Forms.js";
import { ReactSelectCreatableProps$1 } from "../Common/ReactSelectCreatable.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { HTMLAttr, DOMAttr, CSSProp } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_2, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_none, Cmd_batch, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { TableOption, table as table_1 } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { toList as toList_1 } from "../fable_modules/fable-library.3.7.20/Map.js";
import { CardMode } from "./Common/CommonTypes.js";
import { Controls_buttonDropdownIcon } from "../Common/General.js";
import { Shared_defaultButtonStyles } from "./VisualizationShared.js";
import { Option as Option_3 } from "../fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { deleteConfirmationDispatched } from "./Common/CommonView.js";
import { Option as Option_4, modal as modal_1 } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

export function withInvalidPrefix(message) {
    return toText(printf("Invalid: %s"))(message);
}

export class MeasurementCallback$3 extends Record {
    constructor(viewEvent, commandEvent) {
        super();
        this.viewEvent = viewEvent;
        this.commandEvent = commandEvent;
    }
}

export function MeasurementCallback$3$reflection(gen0, gen1, gen2) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.MeasurementCallback`3", [gen0, gen1, gen2], MeasurementCallback$3, () => [["viewEvent", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), lambda_type(gen0, lambda_type(gen1, lambda_type(lambda_type(gen2, unit_type), unit_type))))], ["commandEvent", lambda_type(gen0, lambda_type(gen1, list_type(lambda_type(lambda_type(gen2, unit_type), unit_type))))]]);
}

export class CallbackGroup$3 extends Record {
    constructor(childCallback, parentCallback, combinedCallback) {
        super();
        this.childCallback = childCallback;
        this.parentCallback = parentCallback;
        this.combinedCallback = combinedCallback;
    }
}

export function CallbackGroup$3$reflection(gen0, gen1, gen2) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.CallbackGroup`3", [gen0, gen1, gen2], CallbackGroup$3, () => [["childCallback", MeasurementCallback$3$reflection(gen0, gen1, gen2)], ["parentCallback", MeasurementCallback$3$reflection(gen0, gen1, gen2)], ["combinedCallback", MeasurementCallback$3$reflection(gen0, gen1, gen2)]]);
}

export class CoreMeasurementValues extends Record {
    constructor(name, measurementType, value, position) {
        super();
        this.name = name;
        this.measurementType = measurementType;
        this.value = value;
        this.position = position;
    }
}

export function CoreMeasurementValues$reflection() {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.CoreMeasurementValues", [], CoreMeasurementValues, () => [["name", string_type], ["measurementType", string_type], ["value", string_type], ["position", tuple_type(Visualization_LabelPositionPercentage$reflection(), int32_type)]]);
}

export function CoreMeasurementValues_get_defaultPosition() {
    return new Visualization_LabelPositionPercentage(0.04, 0.5);
}

export function CoreMeasurementValues_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new CoreMeasurementValues(v, m_1.measurementType, m_1.value, m_1.position)))];
}

export function CoreMeasurementValues_get_measurementType_() {
    return [(m) => m.measurementType, (v) => ((m_1) => (new CoreMeasurementValues(m_1.name, v, m_1.value, m_1.position)))];
}

export function CoreMeasurementValues_get_value_() {
    return [(m) => m.value, (v) => ((m_1) => (new CoreMeasurementValues(m_1.name, m_1.measurementType, v, m_1.position)))];
}

export class SplitCallbacks$2 extends Record {
    constructor(onEntrySelected, onEntryDeselected, onEntryStartEditing, onEntryEditCancelled, onEntryDeleteCancelled, onEntryDeleteRequested, onEntryDeleteConfirmed, onEntrySaved) {
        super();
        this.onEntrySelected = onEntrySelected;
        this.onEntryDeselected = onEntryDeselected;
        this.onEntryStartEditing = onEntryStartEditing;
        this.onEntryEditCancelled = onEntryEditCancelled;
        this.onEntryDeleteCancelled = onEntryDeleteCancelled;
        this.onEntryDeleteRequested = onEntryDeleteRequested;
        this.onEntryDeleteConfirmed = onEntryDeleteConfirmed;
        this.onEntrySaved = onEntrySaved;
    }
}

export function SplitCallbacks$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.SplitCallbacks`2", [gen0, gen1], SplitCallbacks$2, () => [["onEntrySelected", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeselected", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryStartEditing", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryEditCancelled", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteCancelled", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteRequested", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteConfirmed", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntrySaved", MeasurementCallback$3$reflection(SplitMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)]]);
}

export class SplitMeasurementEntry$2 extends Record {
    constructor(measurementEntryIdSplit, coreValues, view, callbacksSplit) {
        super();
        this.measurementEntryIdSplit = measurementEntryIdSplit;
        this.coreValues = coreValues;
        this.view = view;
        this.callbacksSplit = callbacksSplit;
    }
}

export function SplitMeasurementEntry$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.SplitMeasurementEntry`2", [gen0, gen1], SplitMeasurementEntry$2, () => [["measurementEntryIdSplit", class_type("System.Guid")], ["coreValues", CoreMeasurementValues$reflection()], ["view", lambda_type(SplitMeasurementEntry$2$reflection(gen0, gen1), lambda_type(gen0, lambda_type(lambda_type(gen1, unit_type), list_type(class_type("Fable.React.ReactElement")))))], ["callbacksSplit", SplitCallbacks$2$reflection(gen0, gen1)]]);
}

export class CardViewVisibilityControl extends Record {
    constructor(cancelVisibility, saveVisibility) {
        super();
        this.cancelVisibility = cancelVisibility;
        this.saveVisibility = saveVisibility;
    }
}

export function CardViewVisibilityControl$reflection() {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.CardViewVisibilityControl", [], CardViewVisibilityControl, () => [["cancelVisibility", ComponentVisibility$reflection()], ["saveVisibility", ComponentVisibility$reflection()]]);
}

export function Split_View_cardView(visibility, contents, measurementEntry, model, dispatch) {
    return singleton(card(empty(), ofArray([header(empty(), singleton(Header_title(empty(), singleton(toText(printf("%s Measurement"))(measurementEntry.coreValues.measurementType))))), content(empty(), contents(measurementEntry, model, dispatch)), footer(empty(), ofArray([Footer_div(empty(), singleton(Button_visibilityControlled(void 0, visibility.cancelVisibility, ofArray([new Option(0, new Color_IColor(8)), new Option(18, (e) => {
        measurementEntry.callbacksSplit.onEntryEditCancelled.viewEvent(e, measurementEntry, model, dispatch);
    })]), singleton("Cancel")))), Footer_div(empty(), singleton(Button_visibilityControlled(void 0, visibility.saveVisibility, ofArray([new Option(0, new Color_IColor(6)), new Option(18, (e_1) => {
        measurementEntry.callbacksSplit.onEntrySaved.viewEvent(e_1, measurementEntry, model, dispatch);
    })]), singleton("Save"))))]))])));
}

export function Split_View_makeHorizontalField(givenLabel, center, contents) {
    return div(ofArray([new Option_1(10, "horizontal-field"), new Option_1(8)]), ofArray([label_1(toList(delay(() => append(center ? singleton(new Label_Option(1)) : empty(), delay(() => singleton_1(new Label_Option(3, singleton(["style", {
        flexBasis: "85px",
        flexGrow: "0",
    }]))))))), singleton(react.createElement("label", {}, givenLabel))), body(empty(), singleton(contents))]));
}

export function Split_View_highlightedItemWithBoldLabel(label, item) {
    let props;
    return Split_View_makeHorizontalField(label, false, (props = [["style", {
        backgroundColor: "yellow",
    }]], react.createElement("b", keyValueList(props, 1), item)));
}

export function Split_View_nameSelect(setLabelPositionButtonVisibility, currentName, predefinedLabels, measurementEntry, measurementLabelLens_, measurementLabelLens__1, setter, dispatch, isEditingLabels, startEditingLabel, stopEditingLabel) {
    const measurementLabelLens = [measurementLabelLens_, curry(2, measurementLabelLens__1)];
    const maybeCurrentName = String_toOption(currentName);
    const names = defaultArg(map((currentName_1) => List_distinct(cons(currentName_1, predefinedLabels), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), maybeCurrentName), predefinedLabels);
    const patternInput = isEditingLabels ? [new Fa_IconOption(11, "fas fa-compress"), stopEditingLabel(measurementEntry.coreValues.position), new Option(0, new Color_IColor(4)), setLabelPositionButtonVisibility(true)] : [new Fa_IconOption(11, "fas fa-arrows-alt"), startEditingLabel, new Option(0, new Color_IColor(20)), setLabelPositionButtonVisibility(false)];
    const select = FormField_reactSelectCreatable(names, [new ReactSelectCreatableProps$1(7, isEditingLabels)], (x_1) => x_1, maybeCurrentName, new FormField_ReactSelectCallback$1(0, (maybeSelected) => {
        dispatch(setter((vm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), measurementLabelLens)(maybeSelected)(vm)));
    }), "", []);
    return div(ofArray([new Option_1(10, "horizontal-field"), new Option_1(8)]), ofArray([label_1(ofArray([new Label_Option(1), new Label_Option(3, singleton(["style", {
        flexBasis: "85px",
        flexGrow: "0",
    }]))]), singleton(react.createElement("label", {}, "Name"))), body(empty(), singleton(Tooltip_tooltip("Type to create a custom name", singleton(new CSSProp(395, "100%")), !isEditingLabels, select))), div_1(empty(), singleton(Button_visibilityControlled(void 0, patternInput[3], ofArray([new Option(17, singleton(["style", {
        borderColor: "transparent",
    }])), patternInput[2], new Option(18, (_arg) => {
        dispatch(patternInput[1]);
    })]), singleton(icon(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(1)]), singleton(Fa_i(singleton(patternInput[0]), [])))))))]));
}

export function SplitMeasurementEntry_mkDoubleMsgCallback(mkMsg) {
    return new MeasurementCallback$3((_e, measurement, model, dispatch) => {
        dispatch(mkMsg(measurement, model));
    }, (measurement_1, model_1) => Cmd_OfFunc_result(mkMsg(measurement_1, model_1)));
}

export class EditMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Display", "Edit", "Saving", "Delete"];
    }
}

export function EditMode$reflection() {
    return union_type("RAWMap.Client.Visualization.MeasurementsView.EditMode", [], EditMode, () => [[], [], [], []]);
}

export class SelectionAction$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "ForceDeselect", "TrySelect", "ForceSelect"];
    }
}

export function SelectionAction$2$reflection(gen0, gen1) {
    return union_type("RAWMap.Client.Visualization.MeasurementsView.SelectionAction`2", [gen0, gen1], SelectionAction$2, () => [[], [], [["selected", SplitMeasurementEntry$2$reflection(gen0, gen1)], ["desiredMode", EditMode$reflection()]], [["selected", SplitMeasurementEntry$2$reflection(gen0, gen1)], ["desiredMode", EditMode$reflection()]]]);
}

export class MeasurementUpdatedOutbound$2 extends Record {
    constructor(allEntries, selectionAction) {
        super();
        this.allEntries = allEntries;
        this.selectionAction = selectionAction;
    }
}

export function MeasurementUpdatedOutbound$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.MeasurementUpdatedOutbound`2", [gen0, gen1], MeasurementUpdatedOutbound$2, () => [["allEntries", list_type(SplitMeasurementEntry$2$reflection(gen0, gen1))], ["selectionAction", SelectionAction$2$reflection(gen0, gen1)]]);
}

export class UnifiedMeasurementEntry$2 extends Record {
    constructor(measurementIdUnified, coreValues, view, callbacksUnified, adjustEditMode) {
        super();
        this.measurementIdUnified = measurementIdUnified;
        this.coreValues = coreValues;
        this.view = view;
        this.callbacksUnified = callbacksUnified;
        this.adjustEditMode = adjustEditMode;
    }
    Equals(other) {
        const this$ = this;
        return (other instanceof UnifiedMeasurementEntry$2) && UnifiedMeasurementEntry$2__CustomEquals_Z76A17EB0(this$, other);
    }
    GetHashCode() {
        const this$ = this;
        return stringHash(this$.measurementIdUnified) | 0;
    }
    CompareTo(other) {
        const this$ = this;
        return ((other instanceof UnifiedMeasurementEntry$2) ? UnifiedMeasurementEntry$2__CustomCompareTo_43C12863(this$, other.coreValues) : -1) | 0;
    }
}

export function UnifiedMeasurementEntry$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.UnifiedMeasurementEntry`2", [gen0, gen1], UnifiedMeasurementEntry$2, () => [["measurementIdUnified", class_type("System.Guid")], ["coreValues", CoreMeasurementValues$reflection()], ["view", lambda_type(UnifiedMeasurementEntry$2$reflection(gen0, gen1), lambda_type(gen0, lambda_type(lambda_type(gen1, unit_type), list_type(class_type("Fable.React.ReactElement")))))], ["callbacksUnified", UnifiedCallbacks$2$reflection(gen0, gen1)], ["adjustEditMode", lambda_type(EditMode$reflection(), lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), lambda_type(UnifiedMeasurementEntry$2$reflection(gen0, gen1), lambda_type(gen0, lambda_type(lambda_type(gen1, unit_type), unit_type)))))]]);
}

export class UnifiedCallbacks$2 extends Record {
    constructor(onEntrySelected, onEntryDeselected, onEntryStartEditing, onEntryEditCancelled, onEntryDeleteCancelled, onEntryDeleteRequested, onEntryDeleteConfirmed, onEntrySaved) {
        super();
        this.onEntrySelected = onEntrySelected;
        this.onEntryDeselected = onEntryDeselected;
        this.onEntryStartEditing = onEntryStartEditing;
        this.onEntryEditCancelled = onEntryEditCancelled;
        this.onEntryDeleteCancelled = onEntryDeleteCancelled;
        this.onEntryDeleteRequested = onEntryDeleteRequested;
        this.onEntryDeleteConfirmed = onEntryDeleteConfirmed;
        this.onEntrySaved = onEntrySaved;
    }
}

export function UnifiedCallbacks$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.UnifiedCallbacks`2", [gen0, gen1], UnifiedCallbacks$2, () => [["onEntrySelected", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeselected", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryStartEditing", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryEditCancelled", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteCancelled", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteRequested", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntryDeleteConfirmed", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["onEntrySaved", CallbackGroup$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)]]);
}

export function UnifiedMeasurementEntry$2__CustomEquals_Z76A17EB0(this$, other) {
    return other.measurementIdUnified === this$.measurementIdUnified;
}

export function UnifiedMeasurementEntry$2__CustomCompareTo_43C12863(this$, other) {
    return compareTo(other.name, this$.coreValues.name);
}

export function UnifiedMeasurementEntry_coreValues_() {
    return [(m) => m.coreValues, (v) => ((m_1) => (new UnifiedMeasurementEntry$2(m_1.measurementIdUnified, v, m_1.view, m_1.callbacksUnified, m_1.adjustEditMode)))];
}

export class UnifiedMeasurementEntry_UnifyCallbackArgs$2 extends Record {
    constructor(selected, deselected, startEditing, cancelled, deleteRequested, deleteConfirmed, saved) {
        super();
        this.selected = selected;
        this.deselected = deselected;
        this.startEditing = startEditing;
        this.cancelled = cancelled;
        this.deleteRequested = deleteRequested;
        this.deleteConfirmed = deleteConfirmed;
        this.saved = saved;
    }
}

export function UnifiedMeasurementEntry_UnifyCallbackArgs$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.UnifiedMeasurementEntry.UnifyCallbackArgs`2", [gen0, gen1], UnifiedMeasurementEntry_UnifyCallbackArgs$2, () => [["selected", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["deselected", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["startEditing", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["cancelled", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["deleteRequested", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["deleteConfirmed", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)], ["saved", MeasurementCallback$3$reflection(UnifiedMeasurementEntry$2$reflection(gen0, gen1), gen0, gen1)]]);
}

export function UnifiedMeasurementEntry_unifyCallbacks(args, modelGetter, msgConversion, splitEntry) {
    const mkPairedCallback = (splitCallback_2, unifiedCallback_1) => {
        let unifiedCallback, splitCallback;
        let childCallback;
        const splitCallback_1 = splitCallback_2;
        childCallback = (new MeasurementCallback$3((e_1, _unified, model_2, dispatch_1) => {
            splitCallback_1.viewEvent(e_1, splitEntry, modelGetter(model_2), (arg) => {
                dispatch_1(msgConversion(arg));
            });
        }, (_unified_1, model_3) => Cmd_map(msgConversion, splitCallback_1.commandEvent(splitEntry, modelGetter(model_3)))));
        return new CallbackGroup$3(childCallback, unifiedCallback_1, (unifiedCallback = unifiedCallback_1, (splitCallback = childCallback, new MeasurementCallback$3((e, unified, model, dispatch) => {
            unifiedCallback.viewEvent(e, unified, model, dispatch);
            splitCallback.viewEvent(e, unified, model, dispatch);
        }, (unified_1, model_1) => Cmd_batch(ofArray([unifiedCallback.commandEvent(unified_1, model_1), splitCallback.commandEvent(unified_1, model_1)]))))));
    };
    return new UnifiedCallbacks$2(mkPairedCallback(splitEntry.callbacksSplit.onEntrySelected, args.selected), mkPairedCallback(splitEntry.callbacksSplit.onEntryDeselected, args.deselected), mkPairedCallback(splitEntry.callbacksSplit.onEntryStartEditing, args.startEditing), mkPairedCallback(splitEntry.callbacksSplit.onEntryEditCancelled, args.cancelled), mkPairedCallback(splitEntry.callbacksSplit.onEntryDeleteCancelled, args.cancelled), mkPairedCallback(splitEntry.callbacksSplit.onEntryDeleteRequested, args.deleteRequested), mkPairedCallback(splitEntry.callbacksSplit.onEntryDeleteConfirmed, args.deleteConfirmed), mkPairedCallback(splitEntry.callbacksSplit.onEntrySaved, args.saved));
}

export function UnifiedMeasurementEntry_ofSplitEntry(unifyCallbackArgs, editEntry, modelGetter, msgConversion, splitEntry) {
    return new UnifiedMeasurementEntry$2(splitEntry.measurementEntryIdSplit, splitEntry.coreValues, (unified_1, model_1, dispatch_1) => {
        let dispatch, model, unified, bundleCallback;
        const splitModel = modelGetter(model_1);
        return splitEntry.view((dispatch = dispatch_1, (model = model_1, (unified = unified_1, (bundleCallback = ((paired) => (new MeasurementCallback$3((e, _arg, _arg_1, _arg_2) => {
            paired.combinedCallback.viewEvent(e, unified, model, dispatch);
        }, (_arg_3, _arg_4) => Cmd_none()))), new SplitMeasurementEntry$2(unified.measurementIdUnified, unified.coreValues, (_arg_5, _arg_6, _arg_7) => unified.view(unified, model, dispatch), new SplitCallbacks$2(bundleCallback(unified.callbacksUnified.onEntrySelected), bundleCallback(unified.callbacksUnified.onEntryDeselected), bundleCallback(unified.callbacksUnified.onEntryStartEditing), bundleCallback(unified.callbacksUnified.onEntryEditCancelled), bundleCallback(unified.callbacksUnified.onEntryDeleteCancelled), bundleCallback(unified.callbacksUnified.onEntryDeleteRequested), bundleCallback(unified.callbacksUnified.onEntryDeleteConfirmed), bundleCallback(unified.callbacksUnified.onEntrySaved))))))), splitModel, (arg) => {
            dispatch_1(msgConversion(arg));
        });
    }, UnifiedMeasurementEntry_unifyCallbacks(unifyCallbackArgs, modelGetter, msgConversion, splitEntry), editEntry);
}

export class MeasurementCardArgs$3 extends Record {
    constructor(activeMeasurement, editMode, entries) {
        super();
        this.activeMeasurement = activeMeasurement;
        this.editMode = editMode;
        this.entries = entries;
    }
}

export function MeasurementCardArgs$3$reflection(gen0, gen1, gen2) {
    return record_type("RAWMap.Client.Visualization.MeasurementsView.MeasurementCardArgs`3", [gen0, gen1, gen2], MeasurementCardArgs$3, () => [["activeMeasurement", option_type(UnifiedMeasurementEntry$2$reflection(gen0, gen1))], ["editMode", EditMode$reflection()], ["entries", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [gen2, list_type(UnifiedMeasurementEntry$2$reflection(gen0, gen1))])]]);
}

export function itemListButton(columns, viewItem, items, extraItem, isDisabled, _model, _dispatch) {
    let children_8, children_6, children_10;
    const columnLists = unzip(columns);
    const children_12 = [table_1(ofArray([new TableOption(1), new TableOption(2), new TableOption(4), new TableOption(6, singleton(["style", {
        tableLayout: "fixed",
    }]))]), ofArray([(children_8 = [(children_6 = toList(delay(() => map_1((value_1) => react.createElement("th", {}, value_1), columnLists[0]))), react.createElement("tr", {}, ...children_6))], react.createElement("thead", {}, ...children_8)), (children_10 = map_1((item) => {
        const rowProps = isDisabled ? empty() : ofArray([new DOMAttr(40, partialApply(1, viewItem, [item])), ["style", {
            cursor: "pointer",
        }]]);
        const props_2 = toList(delay(() => append(rowProps, delay(() => extraItem(item)))));
        const children_2 = toList(delay(() => map_1((value) => {
            const children = [value(item)];
            return react.createElement("td", {
                className: "side-panel-text",
            }, ...children);
        }, columnLists[1])));
        return react.createElement("tr", keyValueList(props_2, 1), ...children_2);
    }, items), react.createElement("tbody", {}, ...children_10))]))];
    return react.createElement("div", {}, ...children_12);
}

export function measurementsCard(model, _arg, measurementCardMode, buttons, dispatch) {
    let optic, l, optic_2, l_2, props, children;
    const editMode = _arg.editMode;
    const activeMeasurement = _arg.activeMeasurement;
    const columns = ofArray([["Name", (optic = ((l = UnifiedMeasurementEntry_coreValues_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CoreMeasurementValues_get_name_())(l))), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))], ["Type", (optic_2 = ((l_2 = UnifiedMeasurementEntry_coreValues_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CoreMeasurementValues_get_measurementType_())(l_2))), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target_2))]]);
    let entries_1;
    const entryList = concat(map_1((tuple) => tuple[1], toList_1(_arg.entries)));
    entries_1 = defaultArg(map((found) => cons(found, List_except([found], entryList, {
        Equals: equals,
        GetHashCode: safeHash,
    })), bind((active) => {
        if (equals(editMode, new EditMode(0))) {
            return void 0;
        }
        else {
            return tryFind((y) => equals(active, y), entryList);
        }
    }, activeMeasurement)), entryList);
    let actionButtonVisibility;
    if (!equals(measurementCardMode, new CardMode(0))) {
        actionButtonVisibility = (new ComponentVisibility(2, void 0));
    }
    else {
        const matchValue = [editMode, activeMeasurement];
        let pattern_matching_result;
        if (matchValue[0].tag === 1) {
            pattern_matching_result = 1;
        }
        else if (matchValue[0].tag === 3) {
            pattern_matching_result = 1;
        }
        else if (matchValue[0].tag === 2) {
            pattern_matching_result = 2;
        }
        else if (matchValue[1] != null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                actionButtonVisibility = (new ComponentVisibility(0));
                break;
            }
            case 1: {
                actionButtonVisibility = (new ComponentVisibility(2, void 0));
                break;
            }
            case 2: {
                actionButtonVisibility = (new ComponentVisibility(2, void 0));
                break;
            }
        }
    }
    const createButton = Controls_buttonDropdownIcon(new Fa_IconOption(11, "fas fa-plus-circle"))(toList(delay(() => append(Shared_defaultButtonStyles, delay(() => (((!equals(measurementCardMode, new CardMode(0))) ? true : (!equals(editMode, new EditMode(0)))) ? singleton(new Option(16, true)) : empty()))))))(ofArray([new Option_3(2), new Option_3(1)]))(map_1((button) => button(dispatch), buttons));
    const editButton = Button_visibilityControlled(void 0, actionButtonVisibility, toList(delay(() => append(Shared_defaultButtonStyles, delay(() => singleton_1(new Option(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (e_1) => {
        iterate((item_2) => {
            item_2.adjustEditMode(new EditMode(1), e_1, item_2, model, dispatch);
            item_2.callbacksUnified.onEntryStartEditing.combinedCallback.viewEvent(e_1, item_2, model, dispatch);
        }, toArray(activeMeasurement));
    })]))))))), singleton(icon(singleton(new Option_2(4, singleton(["style", {}]))), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-edit")), [])))));
    const deleteButton = Button_visibilityControlled(void 0, actionButtonVisibility, toList(delay(() => append(Shared_defaultButtonStyles, delay(() => singleton_1(new Option(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (e_2) => {
        iterate((item_3) => {
            item_3.adjustEditMode(new EditMode(3), e_2, item_3, model, dispatch);
            item_3.callbacksUnified.onEntryDeleteRequested.combinedCallback.viewEvent(e_2, item_3, model, dispatch);
        }, toArray(activeMeasurement));
    })]))))))), singleton(icon(singleton(new Option_2(4, singleton(["style", {}]))), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-trash")), [])))));
    return singleton(card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Measurements")), Header_icon(empty(), ofArray([editButton, deleteButton, createButton]))])), content(empty(), singleton((props = [new HTMLAttr(64, "table-container"), ["style", {
        maxHeight: "164px",
        overflowY: "visible",
    }]], (children = [itemListButton(columns, (item_1, e) => {
        if (equals(editMode, new EditMode(0))) {
            if (activeMeasurement == null) {
                item_1.callbacksUnified.onEntrySelected.combinedCallback.viewEvent(e, item_1, model, dispatch);
            }
            else if (!equals(activeMeasurement, item_1)) {
                const measurement_1 = activeMeasurement;
                measurement_1.callbacksUnified.onEntryDeselected.combinedCallback.viewEvent(e, measurement_1, model, dispatch);
                item_1.callbacksUnified.onEntrySelected.combinedCallback.viewEvent(e, item_1, model, dispatch);
            }
            else {
                const measurement_2 = activeMeasurement;
                measurement_2.callbacksUnified.onEntryDeselected.combinedCallback.viewEvent(e, measurement_2, model, dispatch);
            }
        }
    }, entries_1, (item) => {
        if (equals(item, activeMeasurement)) {
            return append_1(singleton(new HTMLAttr(64, "is-selected")), equals(editMode, new EditMode(1)) ? singleton(["style", {
                backgroundColor: "#999999",
            }]) : empty());
        }
        else {
            return empty();
        }
    }, equals(measurementCardMode, new CardMode(2)), model, dispatch)], react.createElement("div", keyValueList(props, 1), ...children)))))])));
}

export function makeAllMeasurementCards(model, measurementArgs, measurementCardMode, buttons, dispatch) {
    return toList(delay(() => append(singleton_1(measurementsCard(model, measurementArgs, measurementCardMode, buttons, dispatch)), delay(() => ofArray(toArray(filter((_arg) => {
        if (equals(measurementArgs.editMode, new EditMode(1))) {
            return true;
        }
        else {
            return equals(measurementArgs.editMode, new EditMode(2));
        }
    }, map((entry) => entry.view(entry, model, dispatch), measurementArgs.activeMeasurement))))))));
}

export function deleteMeasurementModal(model, measurement, dispatch) {
    return defaultArg(map((item) => {
        const elements = deleteConfirmationDispatched(item.coreValues.name, item.coreValues.measurementType, (e) => {
            item.callbacksUnified.onEntryDeleteConfirmed.combinedCallback.viewEvent(e, item, model, dispatch);
        }, false, (e_1) => {
            item.callbacksUnified.onEntryDeleteCancelled.combinedCallback.viewEvent(e_1, item, model, dispatch);
        });
        return modal_1(singleton(new Option_4(1, equals(measurement.editMode, new EditMode(3)) && (measurement.activeMeasurement != null))), elements);
    }, measurement.activeMeasurement), Helpers_nothing);
}

