import { Union, Record } from "../../../fable_modules/fable-library.3.7.20/Types.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_1, InboundMsg$reflection as InboundMsg$reflection_1, LocalMsg$reflection as LocalMsg$reflection_1, Model$reflection as Model$reflection_1 } from "../../../FileManagement/FileManagementTypes.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_2, InboundMsg$reflection as InboundMsg$reflection_2, LocalMsg$reflection as LocalMsg$reflection_2, Model$reflection as Model$reflection_2 } from "../../../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { union_type, class_type, record_type } from "../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Msg$3$reflection } from "../../../Common/InboundOutbound.js";

export class Model extends Record {
    constructor(fileUpload, dragDrop) {
        super();
        this.fileUpload = fileUpload;
        this.dragDrop = dragDrop;
    }
}

export function Model$reflection() {
    return record_type("RAWMap.Client.Study.AnalysisForm.AnalysisFile.Types.Model", [], Model, () => [["fileUpload", Model$reflection_1()], ["dragDrop", Model$reflection_2()]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "ClearChildrenLists", "FileManagementMsg", "DragDropMsg"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Study.AnalysisForm.AnalysisFile.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Msg$3$reflection(LocalMsg$reflection_1(), InboundMsg$reflection_1(), OutboundMsg$reflection_1())]], [["Item", Msg$3$reflection(LocalMsg$reflection_2(), InboundMsg$reflection_2(), OutboundMsg$reflection_2())]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DownloadableFilesUpdated", "StartedZipping", "UploadStarted", "UploadCompleted", "UploadFailed", "FileDeleted"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.Study.AnalysisForm.AnalysisFile.Types.OutboundMsg", [], OutboundMsg, () => [[], [], [], [], [], []]);
}

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RefreshFileList"];
    }
}

export function InboundMsg$reflection() {
    return union_type("RAWMap.Client.Study.AnalysisForm.AnalysisFile.Types.InboundMsg", [], InboundMsg, () => [[]]);
}

