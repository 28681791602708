import { Union } from "../../../fable-library.3.7.20/Types.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions__AddCaseName_1505, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection, Size_ISize$reflection } from "../../Common.fs.js";
import { union_type, lambda_type, unit_type, list_type, class_type, string_type, bool_type } from "../../../fable-library.3.7.20/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.7.20/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";
import { DOMAttr, Prop, HTMLAttr } from "../../../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { equals } from "../../../fable-library.3.7.20/Util.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "is-fullwidth", "is-inline", "is-loading", "is-focused", "is-active", "IsReadOnly", "Color", "Id", "Disabled", "Value", "DefaultValue", "ValueOrDefault", "Placeholder", "Props", "OnChange", "Ref", "CustomClass", "has-fixed-size", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Textarea.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Color_IColor$reflection()]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", lambda_type(class_type("Browser.Types.Event", void 0, Event), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Element", void 0, Element), unit_type)]], [["Item", string_type]], [], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function textarea(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let pattern_matching_result, state;
        switch (option.tag) {
            case 7: {
                pattern_matching_result = 1;
                break;
            }
            case 1:
            case 18:
            case 2: {
                pattern_matching_result = 2;
                break;
            }
            case 3: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 4: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 5: {
                pattern_matching_result = 3;
                state = option.fields[0];
                break;
            }
            case 8: {
                pattern_matching_result = 4;
                break;
            }
            case 9: {
                pattern_matching_result = 5;
                break;
            }
            case 6: {
                pattern_matching_result = 6;
                break;
            }
            case 10: {
                pattern_matching_result = 7;
                break;
            }
            case 11: {
                pattern_matching_result = 8;
                break;
            }
            case 12: {
                pattern_matching_result = 9;
                break;
            }
            case 13: {
                pattern_matching_result = 10;
                break;
            }
            case 15: {
                pattern_matching_result = 11;
                break;
            }
            case 16: {
                pattern_matching_result = 12;
                break;
            }
            case 14: {
                pattern_matching_result = 13;
                break;
            }
            case 17: {
                pattern_matching_result = 14;
                break;
            }
            case 19: {
                pattern_matching_result = 15;
                break;
            }
            default: pattern_matching_result = 0}
        switch (pattern_matching_result) {
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
            case 4: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(99, option.fields[0]));
            }
            case 5: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(79, option.fields[0]));
            }
            case 6: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(132, option.fields[0]));
            }
            case 7: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(161, option.fields[0]));
            }
            case 8: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(1, option.fields[0]));
            }
            case 9: {
                const valueOrDefault = option.fields[0];
                return Common_GenericOptions__AddProp_7BFEDA81(result, new Prop(1, (e) => {
                    if ((!(e == null)) && (!equals(e.value, valueOrDefault))) {
                        e.value = valueOrDefault;
                    }
                }));
            }
            case 10: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(128, option.fields[0]));
            }
            case 11: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(9, option.fields[0]));
            }
            case 12: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new Prop(1, option.fields[0]));
            }
            case 13: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 14: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 15: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
        }
    }, "textarea"), (props_1, children_1) => react.createElement("textarea", keyValueList(props_1, 1), ...children_1), children);
}

