import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { view as view_1 } from "../../../FileManagement/FileManagementView.js";
import { uploadControl } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipView.js";
import { LocalMsg } from "./CoreFormFileTypes.js";
import { isEmpty } from "../../../fable_modules/fable-library.3.7.20/List.js";
import { ComponentVisibility } from "../../../Common/Controls.js";
import { FileComponentVisibility } from "../../../FileManagement/Shared.js";
import * as react from "react";

export function view(displayMode, model, dispatch) {
    let matchValue;
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const children = [view_1((maybeFileTypeRestrictions) => uploadControl(model.autoZip, (arg_2) => {
        dispatchLocal(new LocalMsg(3, arg_2));
    }, maybeFileTypeRestrictions), (matchValue = [isEmpty(model.autoZip.OutstandingZips), displayMode.deleteButtonVisibility], matchValue[0] ? displayMode : ((matchValue[1].tag === 0) ? (new FileComponentVisibility(new ComponentVisibility(2, void 0), displayMode.downloadButtonVisibility, displayMode.uploadComponentVisibility, displayMode.uploadButtonVisibility)) : displayMode)), model.fileUpload, (arg_4) => {
        dispatchLocal(new LocalMsg(2, arg_4));
    }, void 0)];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

