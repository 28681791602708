import { Msg, Model } from "./Types.js";
import { Cmd_ofSub, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { connect } from "./Api.js";
import { ofNullable, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";

export function init() {
    return [new Model(void 0, void 0), Cmd_none()];
}

export function update(token, msg, model) {
    switch (msg.tag) {
        case 1: {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getWsConfiguration, void 0, (arg_1) => (new Msg(2, arg_1)), (arg_2) => (new Msg(0, arg_2)))];
        }
        case 2: {
            const settings = msg.fields[0];
            return [new Model(settings, model.Client), Cmd_ofSub((dispatch) => {
                connect(settings, (arg_4) => (new Msg(3, arg_4)), (arg_5) => (new Msg(4, arg_5)), dispatch);
            })];
        }
        case 3: {
            console.log(some("mqtt connected"));
            return [new Model(model.Settings, ofNullable(msg.fields[0])), Cmd_none()];
        }
        case 4: {
            console.log(some(toText(printf("mqtt connection failed: %s"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
        default: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
    }
}

