import { ChildMsg_ofMsg, DefaultActions_create, DefaultActions_terminate, DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../Common/Terminate.js";
import { Msg, Model, Mode as Mode_3 } from "./InstitutionTypes.js";
import { ofArray, tryFind, sort, empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { InstitutionStatsViewModel_makeCsvRows, InstitutionViewModel_get_empty } from "../RAWMap.Models/View/Institution.js";
import { DateTimeOffset_maybeParse, Csv_makeCsvMultiLink, DateTimeOffset_yyyyMMdd, Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Cmd_map, Cmd_batch, Cmd_OfFunc_attempt, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { value as value_4, defaultArg, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { Toast_successToast, Toast_errorToast } from "../Common/General.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";
import { utcNow, minValue } from "../fable_modules/fable-library.3.7.20/DateOffset.js";
import { compare as compare_1 } from "../fable_modules/fable-library.3.7.20/Date.js";
import { FileManagementUpdateArgs } from "../FileManagement/FileManagementTypes.js";
import { init as init_1, tryTerminate, update as update_1 } from "../ImageManager/ImageManagerState.js";
import { S3Context } from "../Common/AwsCommon.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../FileManagement/Shared.js";
import { FileOperationContext } from "../RAWMap.Models/Api.js";
import { wrapInboundMsg } from "../Common/InboundOutbound.js";
import { InboundMsg } from "../ImageManager/ImageManagerTypes.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.ImageManagerComponent.child));

export function init() {
    return [new Model(new Mode_3(0), void 0, empty(), void 0, InstitutionViewModel_get_empty(), new Common_SortInfo$1("", new Common_SortDirection(1), (list) => sort(list, {
        Compare: compare,
    })), void 0, void 0, false, new DefaultActions_ChildModel$2(new Child$1(1), childAbsentStrategy, "institution logo component")), Cmd_none()];
}

export function update(args, msg, model) {
    let matchValue, matchValue_2, dEnd_2, dStart_2, inputRecord_1;
    const mkListViewModel = () => (new Model(new Mode_3(0), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, InstitutionViewModel_get_empty(), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent));
    let pattern_matching_result, ex, userInfo, institutions, e, stats, e_1, isvm, institutionId, setter, date, date_1, institution, sortInfo, e_2, msg_16, institution_1;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
        userInfo = msg.fields[0];
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 2;
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 4;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
            institutions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 5;
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 7;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 6;
            stats = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 8;
        isvm = msg.fields[0];
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 9;
        institutionId = msg.fields[0];
    }
    else if (msg.tag === 9) {
        if (model.SelectedInstitution == null) {
            pattern_matching_result = 10;
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 12;
        setter = msg.fields[0];
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 13;
    }
    else if (msg.tag === 10) {
        if (model.SelectedInstitution == null) {
            pattern_matching_result = 14;
        }
        else {
            pattern_matching_result = 15;
        }
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 16;
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 17;
        date = msg.fields[0];
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 18;
        date_1 = msg.fields[0];
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 19;
    }
    else if (msg.tag === 15) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 23;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 20;
            institution = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 16) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 23;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 21;
        }
    }
    else if (msg.tag === 18) {
        pattern_matching_result = 22;
        sortInfo = msg.fields[0];
    }
    else if (msg.tag === 19) {
        pattern_matching_result = 24;
    }
    else if (msg.tag === 20) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 25;
        }
        else {
            pattern_matching_result = 26;
        }
    }
    else if (msg.tag === 23) {
        pattern_matching_result = 27;
        msg_16 = msg.fields[0];
    }
    else if (msg.tag === 22) {
        pattern_matching_result = 28;
    }
    else if (msg.tag === 21) {
        pattern_matching_result = 29;
        institution_1 = msg.fields[0];
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [new Model(model.Mode, userInfo, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_OfFunc_result(new Msg(2))];
        }
        case 2: {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.token).getInstitutions, void 0, (arg_1) => (new Msg(3, arg_1)), (arg_2) => (new Msg(0, arg_2)))];
        }
        case 3: {
            return [new Model(model.Mode, model.MaybeCurrentUser, institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), (matchValue = [model.Mode, model.SelectedInstitution], (matchValue[0].tag === 3) ? ((matchValue[1] != null) ? Cmd_OfFunc_result(new Msg(7, matchValue[1].id)) : Cmd_none()) : Cmd_none())];
        }
        case 4: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e))];
        }
        case 5: {
            const dStart = defaultArg(model.StatsDateStart, minValue());
            const dEnd = defaultArg(model.StatsDateEnd, utcNow());
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.token).getInstitutionStatistics, [dStart, dEnd], (arg_4) => (new Msg(5, arg_4)), (arg_5) => (new Msg(0, arg_5)))];
        }
        case 6: {
            return [model, Cmd_OfFunc_result(new Msg(6, stats))];
        }
        case 7: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        }
        case 8: {
            const dStart_1 = DateTimeOffset_yyyyMMdd(defaultArg(model.StatsDateStart, minValue()));
            const dEnd_1 = DateTimeOffset_yyyyMMdd(defaultArg(model.StatsDateEnd, utcNow()));
            const fileName = toText(printf("institution_stats_%O-%O.csv"))(dStart_1)(dEnd_1);
            return [model, Cmd_OfFunc_attempt((vm) => {
                const link = Csv_makeCsvMultiLink(fileName, InstitutionStatsViewModel_makeCsvRows(dStart_1, dEnd_1, vm));
                link.click();
            }, isvm, (arg_12) => (new Msg(0, arg_12)))];
        }
        case 9: {
            return [new Model(new Mode_3(3), model.MaybeCurrentUser, model.Institutions, tryFind((a) => (a.id === institutionId), model.Institutions), model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 10: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(new ErrorMessage(0)))];
        }
        case 11: {
            return [new Model(new Mode_3(2), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, value_4(model.SelectedInstitution), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 12: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, setter(model.EditInstitutionVm), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 13: {
            return [new Model(new Mode_3(1), model.MaybeCurrentUser, model.Institutions, void 0, InstitutionViewModel_get_empty(), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 14: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.EditInstitutionVm, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_OfFunc_result(new Msg(10))];
        }
        case 15: {
            const matchValue_1 = model.SelectedInstitution;
            if (matchValue_1 == null) {
                return [model, Cmd_OfFunc_result(new Msg(0, new Error("No institution selected for editing")))];
            }
            else {
                return [new Model(new Mode_3(0), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), (matchValue_1.id === "00000000-0000-0000-0000-000000000000") ? Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(args.token).createInstitution, model.EditInstitutionVm, (arg_13) => (new Msg(15, arg_13)), (arg_14) => (new Msg(0, arg_14))) : Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedApi(args.token).editInstitution, model.EditInstitutionVm, (arg_16) => (new Msg(16, arg_16)), (arg_17) => (new Msg(0, arg_17)))];
            }
        }
        case 16: {
            return [new Model(new Mode_3(4), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 17: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, DateTimeOffset_maybeParse(date + "T00:00"), model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_OfFunc_result(new Msg(12))];
        }
        case 18: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, DateTimeOffset_maybeParse(date_1 + "T00:00"), model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_OfFunc_result(new Msg(12))];
        }
        case 19: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, (matchValue_2 = [model.StatsDateStart, model.StatsDateEnd], (matchValue_2[0] != null) && ((matchValue_2[1] != null) && ((dEnd_2 = matchValue_2[1], (dStart_2 = matchValue_2[0], (compare_1(dEnd_2, utcNow()) <= 0) && (compare_1(dStart_2, dEnd_2) < 0)))))), model.ImageManagerComponent), Cmd_none()];
        }
        case 20: {
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast(toText(printf("Created institution %s"))(institution.name)), Cmd_OfFunc_result(new Msg(2))]))];
        }
        case 21: {
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast(toText(printf("Institution updated"))), Cmd_OfFunc_result(new Msg(2))]))];
        }
        case 22: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, sortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        }
        case 23: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_2))];
        }
        case 24: {
            return [mkListViewModel(), Cmd_none()];
        }
        case 25: {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        case 26: {
            return [model, Cmd_none()];
        }
        case 27: {
            const patternInput = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_17, model_1) => {
                let inputRecord;
                const matchValue_3 = [args.fileArgs.maybeCredentials, args.fileArgs.maybeS3Context];
                let pattern_matching_result_1, context, credentials;
                if (matchValue_3[0] != null) {
                    if (matchValue_3[1] != null) {
                        pattern_matching_result_1 = 0;
                        context = matchValue_3[1];
                        credentials = matchValue_3[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        const args_1 = new FileManagementUpdateArgs(args.fileArgs.token, credentials, context);
                        if (msg_17.tag === 1) {
                            return [model_1, Cmd_none()];
                        }
                        else {
                            return update_1(new FileManagementUpdateArgs(args_1.Token, args_1.Credentials, (inputRecord = args_1.Context, new S3Context(inputRecord.S3Config, inputRecord.S3Client, inputRecord.S3Expiry, args_1.Context.ActiveBucket))), msg_17.fields[0], model_1);
                        }
                    }
                    case 1: {
                        return [model_1, Toast_errorToast("Missing Credentials or S3 Context")];
                    }
                }
            }, model), model.ImageManagerComponent, msg_16);
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, patternInput[0]), Cmd_map((arg_21) => (new Msg(23, arg_21)), patternInput[1])];
        }
        case 28: {
            const patternInput_1 = DefaultActions_terminate(tryTerminate, model.ImageManagerComponent);
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, patternInput_1[0]), Cmd_map((arg_22) => (new Msg(23, arg_22)), patternInput_1[1])];
        }
        case 29: {
            const fileSpecs = new FileComponentSpecs(ofArray([new FileType(2), new FileType(3)]), toText(printf("%O/Logo/"))(institution_1.id), new MaximumFiles(1, 1));
            const createImageManagerComponent = (model_3) => {
                const createdImageManager = init_1(fileSpecs, new FileOperationContext(4));
                const patternInput_2 = DefaultActions_create(createdImageManager[0], createdImageManager[1], model_3.ImageManagerComponent);
                return [new Model(model_3.Mode, model_3.MaybeCurrentUser, model_3.Institutions, model_3.SelectedInstitution, model_3.EditInstitutionVm, model_3.SortInfo, model_3.StatsDateStart, model_3.StatsDateEnd, model_3.isDownloadFieldsValid, patternInput_2[0]), Cmd_batch(ofArray([Cmd_map((arg_24) => (new Msg(23, arg_24)), patternInput_2[1]), Cmd_OfFunc_result(new Msg(23, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg(0)))))]))];
            };
            const matchValue_4 = model.ImageManagerComponent.child;
            switch (matchValue_4.tag) {
                case 1:
                case 2: {
                    return createImageManagerComponent(model);
                }
                default: {
                    const termination = tryTerminate(matchValue_4.fields[0]);
                    if (termination.tag === 1) {
                        return [model, Toast_errorToast(toText(printf("%s was unable to switch institutions."))(model.ImageManagerComponent.childComponentName))];
                    }
                    else {
                        termination.fields[0]();
                        return createImageManagerComponent(new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, (inputRecord_1 = model.ImageManagerComponent, new DefaultActions_ChildModel$2(new Child$1(2), inputRecord_1.childAbsentStrategy, inputRecord_1.childComponentName))));
                    }
                }
            }
        }
    }
}

