import { Record, Union } from "../../../fable_modules/fable-library.3.7.20/Types.js";
import { tuple_type, lambda_type, unit_type, class_type, int32_type, string_type, record_type, option_type, list_type, union_type, float64_type } from "../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { UploadSelection$reflection, FileComponentSpecs$reflection, FileType$reflection } from "../../Shared.js";
import { ZipBatch$reflection, ZipDirectory$reflection } from "../../../Common/JsZipCommon.js";
import { S3Context$reflection } from "../../../Common/AwsCommon.js";
import { S3ObjectViewModel$reflection } from "../../../RAWMap.Models/Api.js";
import { FileData$reflection } from "../../../Common/FileData.js";

export class ZipState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waiting", "Zipping"];
    }
}

export function ZipState$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ZipState", [], ZipState, () => [[], [["percentComplete", float64_type]]]);
}

export class ZipBatchStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Queued", "ZippingBatch", "Complete"];
    }
}

export function ZipBatchStatus$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ZipBatchStatus", [], ZipBatchStatus, () => [[], [["percentComplete", float64_type]], []]);
}

export class FileSelectionMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AutoZipEnabledZipFile", "JustAutoZip", "OtherFile"];
    }
}

export function FileSelectionMode$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.FileSelectionMode", [], FileSelectionMode, () => [[], [], [["maybeFileTypeRestrictions", option_type(list_type(FileType$reflection()))]]]);
}

export class OngoingZip extends Record {
    constructor(directory, state) {
        super();
        this.directory = directory;
        this.state = state;
    }
}

export function OngoingZip$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.OngoingZip", [], OngoingZip, () => [["directory", ZipDirectory$reflection()], ["state", ZipState$reflection()]]);
}

export class ActiveZipBatch extends Record {
    constructor(zipBatch, status) {
        super();
        this.zipBatch = zipBatch;
        this.status = status;
    }
}

export function ActiveZipBatch$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ActiveZipBatch", [], ActiveZipBatch, () => [["zipBatch", ZipBatch$reflection()], ["status", ZipBatchStatus$reflection()]]);
}

export class AutoZipFileSelectorUpdateArgs extends Record {
    constructor(token, numberOfAssociatedFiles, credentials, context) {
        super();
        this.token = token;
        this.numberOfAssociatedFiles = (numberOfAssociatedFiles | 0);
        this.credentials = credentials;
        this.context = context;
    }
}

export function AutoZipFileSelectorUpdateArgs$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.AutoZipFileSelectorUpdateArgs", [], AutoZipFileSelectorUpdateArgs, () => [["token", string_type], ["numberOfAssociatedFiles", int32_type], ["credentials", class_type("Fable.Import.Aws.Credentials.Credentials")], ["context", S3Context$reflection()]]);
}

export class Model extends Record {
    constructor(Specs, PathTestFiles, MaybeActiveZipBatch, OutstandingZips, MaybeCurrentZip) {
        super();
        this.Specs = Specs;
        this.PathTestFiles = PathTestFiles;
        this.MaybeActiveZipBatch = MaybeActiveZipBatch;
        this.OutstandingZips = OutstandingZips;
        this.MaybeCurrentZip = MaybeCurrentZip;
    }
}

export function Model$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.Model", [], Model, () => [["Specs", FileComponentSpecs$reflection()], ["PathTestFiles", list_type(S3ObjectViewModel$reflection())], ["MaybeActiveZipBatch", option_type(ActiveZipBatch$reflection())], ["OutstandingZips", list_type(OngoingZip$reflection())], ["MaybeCurrentZip", option_type(OngoingZip$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowErrorToast", "UploadSelectionChanged", "ValidateFiles", "TriggerUploads", "StartZips", "CompleteZip", "ZipProgress", "UploadFailed", "MakeUploadObjects"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.LocalMsg", [], LocalMsg, () => [[["Item", string_type]], [["Item", list_type(FileData$reflection())]], [["onSuccess", tuple_type(Model$reflection(), list_type(lambda_type(lambda_type(LocalMsg$reflection(), unit_type), unit_type)), list_type(lambda_type(lambda_type(OutboundMsg$reflection(), unit_type), unit_type)))]], [], [["Item", ActiveZipBatch$reflection()]], [["Item", tuple_type(ZipDirectory$reflection(), class_type("Browser.Types.File", void 0, File))]], [["Item", class_type("JSZipBindings.Metadata")]], [["fileName", string_type], ["Item2", class_type("System.Exception")]], [["Item", list_type(FileData$reflection())]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartedZipping", "CompletedZipping", "SelectionMade", "AllUploadsComplete"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.OutboundMsg", [], OutboundMsg, () => [[], [], [["Item", UploadSelection$reflection()]], []]);
}

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClearFileList", "UploadCompleted"];
    }
}

export function InboundMsg$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.InboundMsg", [], InboundMsg, () => [[], []]);
}

export const labelText = {
    mainUpload: "Drag and drop or click here to select a DICOM directory to upload",
    zipCta: "Upload DICOM Directory",
};

