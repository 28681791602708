import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { enum_type, int32_type, bool_type, tuple_type, lambda_type, unit_type, list_type, union_type, option_type, record_type, class_type, string_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { S3Context$reflection } from "../Common/AwsCommon.js";
import { UploadSelection$reflection, FileComponentSpecs$reflection, FileManagementProgress$reflection } from "./Shared.js";
import { FileData$reflection } from "../Common/FileData.js";
import { FileOperationContext$reflection, S3ObjectViewModel$reflection } from "../RAWMap.Models/Api.js";
import { empty, singleton, append, delay, iterate } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { toArray } from "../fable_modules/fable-library.3.7.20/Option.js";
import { iterate as iterate_1, contains, filter, length as length_1, concat, map, ofArray, sum } from "../fable_modules/fable-library.3.7.20/List.js";
import { toList, count } from "../fable_modules/fable-library.3.7.20/Map.js";
import { List_distinct } from "../fable_modules/fable-library.3.7.20/Seq2.js";
import { structuralHash, equals, arrayHash, equalArrays } from "../fable_modules/fable-library.3.7.20/Util.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";

export class FileManagementUpdateArgs extends Record {
    constructor(Token, Credentials, Context) {
        super();
        this.Token = Token;
        this.Credentials = Credentials;
        this.Context = Context;
    }
}

export function FileManagementUpdateArgs$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.FileManagementUpdateArgs", [], FileManagementUpdateArgs, () => [["Token", string_type], ["Credentials", class_type("Fable.Import.Aws.Credentials.Credentials")], ["Context", S3Context$reflection()]]);
}

export class FileManagement extends Record {
    constructor(Name, Progress) {
        super();
        this.Name = Name;
        this.Progress = Progress;
    }
}

export function FileManagement$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.FileManagement", [], FileManagement, () => [["Name", string_type], ["Progress", FileManagementProgress$reflection()]]);
}

export class FileDownload extends Record {
    constructor(Name) {
        super();
        this.Name = Name;
    }
}

export function FileDownload$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.FileDownload", [], FileDownload, () => [["Name", string_type]]);
}

export class StringDisplays extends Record {
    constructor(DeleteWarning) {
        super();
        this.DeleteWarning = DeleteWarning;
    }
}

export function StringDisplays$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.StringDisplays", [], StringDisplays, () => [["DeleteWarning", string_type]]);
}

export class FileManagementViewModel extends Record {
    constructor(MaybeFileData, Name, MaybeManagedUpload, Progress) {
        super();
        this.MaybeFileData = MaybeFileData;
        this.Name = Name;
        this.MaybeManagedUpload = MaybeManagedUpload;
        this.Progress = Progress;
    }
}

export function FileManagementViewModel$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.FileManagementViewModel", [], FileManagementViewModel, () => [["MaybeFileData", option_type(FileData$reflection())], ["Name", string_type], ["MaybeManagedUpload", option_type(class_type("Fable.Import.Aws.S3.ManagedUpload"))], ["Progress", FileManagementProgress$reflection()]]);
}

export class FileHandling extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Normal", "ZipBatch"];
    }
}

export function FileHandling$reflection() {
    return union_type("RAWMap.Client.FileManagement.Types.FileHandling", [], FileHandling, () => [[], []]);
}

export class Model extends Record {
    constructor(Specs, DisplayContent, Message, FileHandling, UploadSelection, FailedUploads, FileManagementsInProgress, PersistedFiles, DownloadableFiles, MaybeS3VmsToDelete, SignedUrls, OperationContext) {
        super();
        this.Specs = Specs;
        this.DisplayContent = DisplayContent;
        this.Message = Message;
        this.FileHandling = FileHandling;
        this.UploadSelection = UploadSelection;
        this.FailedUploads = FailedUploads;
        this.FileManagementsInProgress = FileManagementsInProgress;
        this.PersistedFiles = PersistedFiles;
        this.DownloadableFiles = DownloadableFiles;
        this.MaybeS3VmsToDelete = MaybeS3VmsToDelete;
        this.SignedUrls = SignedUrls;
        this.OperationContext = OperationContext;
    }
}

export function Model$reflection() {
    return record_type("RAWMap.Client.FileManagement.Types.Model", [], Model, () => [["Specs", FileComponentSpecs$reflection()], ["DisplayContent", StringDisplays$reflection()], ["Message", string_type], ["FileHandling", FileHandling$reflection()], ["UploadSelection", list_type(FileData$reflection())], ["FailedUploads", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileManagementViewModel$reflection()])], ["FileManagementsInProgress", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileManagementViewModel$reflection()])], ["PersistedFiles", list_type(S3ObjectViewModel$reflection())], ["DownloadableFiles", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileManagementViewModel$reflection()])], ["MaybeS3VmsToDelete", option_type(list_type(S3ObjectViewModel$reflection()))], ["SignedUrls", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["OperationContext", FileOperationContext$reflection()]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Noop", "ExnErrorMsg", "ShowErrorToast", "ListS3Objects", "GotListS3ObjectsOutput", "ValidateFiles", "UploadSelectionChanged", "TriggerUploads", "MakeUploadObjects", "UploadFile", "AbortUpload", "AbortSuccess", "UploadCompleted", "UploadFailed", "GotProgress", "GetPresignedUrl", "GotPresignedUrl", "PromptS3Delete", "CancelDeleteFromS3", "DeleteFromS3", "GotS3DeleteOutput", "RetryFailedUpload", "CancelFailedUpload"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.FileManagement.Types.LocalMsg", [], LocalMsg, () => [[], [["Item", class_type("System.Exception")]], [["Item", string_type]], [], [["Item", class_type("Fable.Import.Aws.S3.ListObjectsV2Output")]], [["fileDataList", list_type(FileData$reflection())], ["onSuccess", lambda_type(Model$reflection(), tuple_type(Model$reflection(), list_type(lambda_type(lambda_type(LocalMsg$reflection(), unit_type), unit_type)), list_type(lambda_type(lambda_type(OutboundMsg$reflection(), unit_type), unit_type))))]], [["Item1", list_type(FileData$reflection())], ["triggerUpload", bool_type]], [["Item", list_type(FileData$reflection())]], [["Item", list_type(FileData$reflection())]], [["Item1", class_type("Fable.Import.Aws.S3.ManagedUpload")], ["fileName", string_type]], [["fileName", string_type]], [["Item", unit_type]], [["fileName", string_type], ["Item2", class_type("Fable.Import.Aws.S3.ManagedUploadModule.SendData")]], [["fileName", string_type], ["Item2", class_type("System.Exception")]], [["fileName", string_type], ["Item2", FileManagementProgress$reflection()]], [["Item1", enum_type("RAWMap.Client.Common.AwsCommon.DownloadBehavior", int32_type, [["Normal", 0], ["OpenNewWindow", 1]])], ["fileName", string_type]], [["fileName", string_type], ["url", string_type]], [["Item", list_type(S3ObjectViewModel$reflection())]], [], [["Item", list_type(S3ObjectViewModel$reflection())]], [["fileNames", list_type(string_type)], ["Item2", class_type("Fable.Import.Aws.S3.DeleteObjectsOutput")]], [["fileName", string_type]], [["fileName", string_type]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DownloadableFilesUpdated", "ValidSelectionUpdated", "UploadStarted", "UploadCompleted", "UploadFailed", "FileDeleted"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.FileManagement.Types.OutboundMsg", [], OutboundMsg, () => [[], [], [], [], [], []]);
}

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RefreshFileList", "UpdateUploadSelection", "UpdateFileHandling"];
    }
}

export function InboundMsg$reflection() {
    return union_type("RAWMap.Client.FileManagement.Types.InboundMsg", [], InboundMsg, () => [[], [["Item", UploadSelection$reflection()]], [["Item", FileHandling$reflection()]]]);
}

export function StringDisplays_get_defaults() {
    return new StringDisplays("Are you sure you want to delete this file? This operation can not be undone.");
}

export function FileManagementViewModel_abortUpload_27E04A4D(fileManagementVm) {
    iterate((u) => {
        u.abort();
    }, toArray(fileManagementVm.MaybeManagedUpload));
}

export function Model_getNumberOfAssociatedFiles_Z379152CB(model) {
    return sum(ofArray([count(model.FileManagementsInProgress), count(model.DownloadableFiles), count(model.FailedUploads)]), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    });
}

export function Model_getNumberOfNovelSelections_Z379152CB(model) {
    const associatedFileDataList = map((tupledArg) => tupledArg[1].MaybeFileData, List_distinct(concat([toList(model.FileManagementsInProgress), toList(model.DownloadableFiles), toList(model.FailedUploads)]), {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    }));
    return length_1(filter((selected) => (!contains(selected, associatedFileDataList, {
        Equals: equals,
        GetHashCode: structuralHash,
    })), model.UploadSelection)) | 0;
}

export function Model_tryTerminate_Z379152CB(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
        iterate_1((arg_1) => {
            FileManagementViewModel_abortUpload_27E04A4D(arg_1[1]);
        }, toList(model.FileManagementsInProgress));
    })));
}

export function FileList_mkFileSeq(files, i, last) {
    return delay(() => append(singleton(files[i]), delay(() => {
        if (i >= last) {
            return empty();
        }
        else {
            return FileList_mkFileSeq(files, i + 1, last);
        }
    })));
}

export function FileList_toSeq(files) {
    const matchValue = files.length | 0;
    if (matchValue === 0) {
        return empty();
    }
    else {
        return FileList_mkFileSeq(files, 0, matchValue - 1);
    }
}

