import { Union } from "../../fable-library.3.7.20/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.3.7.20/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.20/MapUtil.js";

export class TableOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-bordered", "is-striped", "is-fullwidth", "is-narrow", "is-hoverable", "CustomClass", "Props", "Modifiers"];
    }
}

export function TableOption$reflection() {
    return union_type("Fulma.Table.TableOption", [], TableOption, () => [[], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function table(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 5: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "table"), (props_1, children_1) => react.createElement("table", keyValueList(props_1, 1), ...children_1), children);
}

