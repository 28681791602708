import { FSharpResult$2 } from "../fable-library.3.7.20/Choice.js";
import { isNullOrWhiteSpace, isNullOrEmpty } from "../fable-library.3.7.20/String.js";

export function validateNotEmptyGuid(error, g) {
    if (g === "00000000-0000-0000-0000-000000000000") {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, void 0);
    }
}

export function validateNotNullOrEmpty(error, s) {
    if (isNullOrEmpty(s)) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, void 0);
    }
}

export function validateNotNullOrWhiteSpace(error, s) {
    if (isNullOrWhiteSpace(s)) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, void 0);
    }
}

