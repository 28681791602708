import { Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { UniversalArgs$reflection } from "./SharedSection.js";
import { create, Args$reflection } from "./StudyInfoSection.js";
import { create as create_1, Args$reflection as Args$reflection_1 } from "./MeasurementSection.js";
import { create as create_2, Args$reflection as Args$reflection_2 } from "./ScreenshotSection.js";
import { create as create_3, Args$reflection as Args$reflection_3 } from "./ConclusionSection.js";
import { record_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { TitlePageSection_create } from "./TitlePageSection.js";
import { Pdf_Doc_create } from "../ReportPdfHelpers.js";
import { ErrorMessage } from "../../../../RAWMap.Models/ErrorMessage.js";
import { AsyncResult_ofPromise } from "../../../../Common/Extensions.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../../../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { PDFDocument } from "pdf-lib";
import { toArray } from "../../../../fable_modules/fable-library.3.7.20/Seq.js";
import { rangeDouble } from "../../../../fable_modules/fable-library.3.7.20/Range.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "../../../../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";

export class FullReportArgs extends Record {
    constructor(universalArgs, studyArgs, measurementArgs, screenshotArgs, conclusionArgs) {
        super();
        this.universalArgs = universalArgs;
        this.studyArgs = studyArgs;
        this.measurementArgs = measurementArgs;
        this.screenshotArgs = screenshotArgs;
        this.conclusionArgs = conclusionArgs;
    }
}

export function FullReportArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ReportSections.FullReportArgs", [], FullReportArgs, () => [["universalArgs", UniversalArgs$reflection()], ["studyArgs", Args$reflection()], ["measurementArgs", Args$reflection_1()], ["screenshotArgs", Args$reflection_2()], ["conclusionArgs", Args$reflection_3()]]);
}

export function mkReportTitlePage(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_create(Pdf_Doc_create(), args.universalArgs, args.titlePageArgs), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg)));
}

export function mkReportSections(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create(Pdf_Doc_create(), args.studyArgs), (_arg) => {
        const studySection = _arg;
        studySection.addPage();
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_1(studySection, args.universalArgs, args.measurementArgs), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_2(_arg_1, args.universalArgs, args.screenshotArgs), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_3(_arg_2, args.conclusionArgs), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg_3))));
    }));
}

export function makeAndPrependTitlePage(fileName, pdf, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, mkReportTitlePage(args), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg_3) => (new ErrorMessage(1, arg_3)), AsyncResult_ofPromise()(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PDFDocument.load(_arg_3.output('arraybuffer')).then((_arg) => {
        let arg_1, copyOfStruct;
        const titlePage_1 = _arg;
        return ((arg_1 = toArray(rangeDouble(0, 1, ((copyOfStruct = pdf, copyOfStruct.getPageCount())) - 1)), titlePage_1.copyPages(pdf, arg_1))).then((_arg_1) => {
            let fullDoc;
            _arg_1.forEach((arg_2) => {
                titlePage_1.addPage(arg_2);
            });
            fullDoc = titlePage_1;
            return fullDoc.save().then((_arg_2) => {
                ByteArrayExtensions_SaveFileAs_72B26259(_arg_2, fileName);
                return Promise.resolve();
            });
        });
    })))))))));
}

