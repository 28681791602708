import { EditMode, ListDisplayModification, ModificationSource, Msg, Model, Mode as Mode_8 } from "./PatientTypes.js";
import { singleton as singleton_1, tryFind, contains, filter as filter_5, exists, ofArray, sort, empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { PartialInternalPatientIdViewModel_isValid_293DEEA, HospitalPatientIdViewModel_isValid_Z3AB5EA36, NameDobViewModel_isValid_7D3B7E8E, PatientCreationViewModel_isValid_7D62DD9A, PatientCreationViewModel, HospitalPatientIdViewModel_get_institutionId_, PatientCreationViewModel_get_hospitalPatientIdVm_, NameDobViewModel_get_empty, PartialInternalPatientIdViewModel_get_empty, HospitalPatientIdViewModel_get_empty, PatientCreationViewModel_get_empty } from "../RAWMap.Models/View/Patient.js";
import { PatientFilters_Filter, PatientFilters_FilterComparison, PatientFilters_Field, PatientFilters_FilterType_get_fieldToFilterType, PatientFilters_FilterType, PatientFilters_Filter_isValid_72A93D4F, PatientFilters_Filter_get_empty, Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { stringHash, equals, compare } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { defaultArg, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { Toast_successToast, Toast_errorToast, Urls_Page, Urls_PatientPage, Urls_pageHash } from "../Common/General.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.20/Date.js";
import { minValue, toUniversalTime } from "../fable_modules/fable-library.3.7.20/DateOffset.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(new Mode_8(0), void 0, empty(), empty(), void 0, PatientCreationViewModel_get_empty(), HospitalPatientIdViewModel_get_empty(), PartialInternalPatientIdViewModel_get_empty(), NameDobViewModel_get_empty(), empty(), false, new Common_SortInfo$1("", new Common_SortDirection(1), (list) => sort(list, {
        Compare: compare,
    })), false, PatientFilters_Filter_get_empty()), Cmd_none()];
}

export function setCreateEditVm(model, createEditVm) {
    return new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, createEditVm, createEditVm.hospitalPatientIdVm, model.SearchForInternalPatientIdVm, createEditVm.nameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter);
}

export function update(token, msg, model) {
    let l, inputRecord, SearchForHospitalPatientIdVm, inputRecord_1, SearchByNameDobVm, inputRecord_2, matchValue, inputRecord_4, inputRecord_5, inputRecord_6, arg_41, arg_42, matchValue_4, patient_10;
    let pattern_matching_result, ex, userInfo, institutions, patients, filter, ids, filter_1, institutionDesignation, patient_2, mode, patient_3, setter, setter_1, setter_2, setter_3, filterField, filter_3, filter_4, comp, pId, err_2, ids_1, err_3, ids_2, pvm, err_4, err_5, sortInfo;
    if (msg.tag === 0) {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    else if (msg.tag === 1) {
        pattern_matching_result = 1;
        userInfo = msg.fields[0];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 2;
            institutions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 16) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 4;
        }
        else if (msg.fields[0].tag === 4) {
            pattern_matching_result = 8;
        }
        else {
            pattern_matching_result = 48;
        }
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 4;
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 5;
            patients = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 7;
        filter = msg.fields[0];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 9;
            ids = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 48;
        }
    }
    else if (msg.tag === 19) {
        pattern_matching_result = 10;
        filter_1 = msg.fields[0];
    }
    else if (msg.tag === 18) {
        pattern_matching_result = 11;
        institutionDesignation = msg.fields[0];
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 12;
        patient_2 = msg.fields[0];
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 13;
        mode = msg.fields[1];
        patient_3 = msg.fields[0];
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 14;
        setter = msg.fields[0];
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 15;
        setter_1 = msg.fields[0];
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 16;
        setter_2 = msg.fields[0];
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 17;
        setter_3 = msg.fields[0];
    }
    else if (msg.tag === 34) {
        pattern_matching_result = 18;
    }
    else if (msg.tag === 36) {
        pattern_matching_result = 19;
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 20;
    }
    else if (msg.tag === 23) {
        pattern_matching_result = 21;
    }
    else if (msg.tag === 24) {
        pattern_matching_result = 22;
    }
    else if (msg.tag === 26) {
        pattern_matching_result = 23;
    }
    else if (msg.tag === 37) {
        pattern_matching_result = 24;
        filterField = msg.fields[0];
    }
    else if (msg.tag === 38) {
        pattern_matching_result = 25;
        filter_3 = msg.fields[0];
    }
    else if (msg.tag === 39) {
        pattern_matching_result = 26;
        filter_4 = msg.fields[0];
    }
    else if (msg.tag === 40) {
        pattern_matching_result = 27;
        comp = msg.fields[0];
    }
    else if (msg.tag === 25) {
        pattern_matching_result = 28;
    }
    else if (msg.tag === 27) {
        pattern_matching_result = 29;
    }
    else if (msg.tag === 28) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 10) {
                pattern_matching_result = 31;
            }
            else {
                pattern_matching_result = 32;
                err_2 = msg.fields[0];
            }
        }
        else {
            pattern_matching_result = 30;
            pId = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 29) {
        pattern_matching_result = 33;
    }
    else if (msg.tag === 30) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 10) {
                pattern_matching_result = 35;
            }
            else {
                pattern_matching_result = 36;
                err_3 = msg.fields[0];
            }
        }
        else {
            pattern_matching_result = 34;
            ids_1 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 31) {
        pattern_matching_result = 37;
    }
    else if (msg.tag === 32) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 38;
            ids_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 48;
        }
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 39;
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 42;
            err_4 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 40;
            pvm = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 22) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 43;
            err_5 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 41;
        }
    }
    else if (msg.tag === 33) {
        pattern_matching_result = 44;
    }
    else if (msg.tag === 35) {
        pattern_matching_result = 45;
        sortInfo = msg.fields[0];
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 46;
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 47;
    }
    else {
        pattern_matching_result = 48;
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [new Model(model.Mode, userInfo, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2)), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getInstitutions, void 0, (arg_1) => (new Msg(6, arg_1)), (arg_2) => (new Msg(0, arg_2)))]))];
        }
        case 2: {
            return [new Model(model.Mode, model.MaybeCurrentUser, institutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 3: {
            return [model, Cmd_none()];
        }
        case 4: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).getPatients, void 0, (arg_4) => (new Msg(3, arg_4)), (arg_5) => (new Msg(0, arg_5)))];
        }
        case 5: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 6: {
            return [model, Cmd_none()];
        }
        case 7: {
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getPatientsWithFilter, filter, (arg_7) => (new Msg(5, arg_7)), (arg_8) => (new Msg(0, arg_8)))];
        }
        case 8: {
            return [model, exists((modification) => equals(modification.Source, new ModificationSource(1)), model.ListDisplayModifications) ? Cmd_OfFunc_result(new Msg(4, model.Filter)) : Cmd_none()];
        }
        case 9: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_5((modification_1) => (!equals(modification_1.Source, new ModificationSource(1))), model.ListDisplayModifications), delay(() => singleton(new ListDisplayModification(new ModificationSource(1), 1, (list_2) => filter_5((patient) => contains(patient.id, ids, {
                Equals: (x_3, y) => (x_3 === y),
                GetHashCode: stringHash,
            }), list_2))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 10: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(4, filter_1))];
        }
        case 11: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_5((modification_2) => (!equals(modification_2.Source, new ModificationSource(2))), model.ListDisplayModifications), delay(() => singleton(new ListDisplayModification(new ModificationSource(2), 2, (list_5) => filter_5((patient_1) => exists((institution) => {
                if (institution.id === patient_1.institutionId) {
                    return equals(institution.clinicalDesignation, institutionDesignation);
                }
                else {
                    return false;
                }
            }, model.UserInstitutions), list_5))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 12: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, patient_2, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, patient_2.id))))];
        }
        case 13: {
            const model_1 = setCreateEditVm(model, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = PatientCreationViewModel_get_hospitalPatientIdVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), HospitalPatientIdViewModel_get_institutionId_())(l)))(patient_3.institutionId)((inputRecord = PatientCreationViewModel_get_empty(), new PatientCreationViewModel(patient_3.id, inputRecord.dateCreated, inputRecord.hospitalPatientIdVm, inputRecord.nameDobVm))));
            return [new Model(new Mode_8(6, mode), model_1.MaybeCurrentUser, model_1.UserInstitutions, model_1.Patients, model_1.SelectedPatient, model_1.CreateEditPatientVm, model_1.SearchForHospitalPatientIdVm, model_1.SearchForInternalPatientIdVm, model_1.SearchByNameDobVm, model_1.ListDisplayModifications, model_1.CreateEditFieldsValid, model_1.SortInfo, model_1.FilterFieldsValid, model_1.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 14: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, setter(model.CreateEditPatientVm), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 15: {
            return [(SearchForHospitalPatientIdVm = setter_1(model.SearchForHospitalPatientIdVm), new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, (inputRecord_1 = model.CreateEditPatientVm, new PatientCreationViewModel(inputRecord_1.id, inputRecord_1.dateCreated, setter_1(model.CreateEditPatientVm.hospitalPatientIdVm), inputRecord_1.nameDobVm)), SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter)), Cmd_OfFunc_result(new Msg(34))];
        }
        case 16: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, setter_2(model.SearchForInternalPatientIdVm), model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 17: {
            return [(SearchByNameDobVm = setter_3(model.SearchByNameDobVm), new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, (inputRecord_2 = model.CreateEditPatientVm, new PatientCreationViewModel(inputRecord_2.id, inputRecord_2.dateCreated, inputRecord_2.hospitalPatientIdVm, setter_3(model.CreateEditPatientVm.nameDobVm))), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter)), Cmd_OfFunc_result(new Msg(34))];
        }
        case 18: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, (matchValue = model.Mode, (matchValue.tag === 1) ? PatientCreationViewModel_isValid_7D62DD9A(model.CreateEditPatientVm) : ((matchValue.tag === 6) ? ((matchValue.fields[0].tag === 1) ? NameDobViewModel_isValid_7D3B7E8E(model.SearchByNameDobVm) : HospitalPatientIdViewModel_isValid_Z3AB5EA36(model.SearchForHospitalPatientIdVm)) : ((matchValue.tag === 2) ? HospitalPatientIdViewModel_isValid_Z3AB5EA36(model.SearchForHospitalPatientIdVm) : ((matchValue.tag === 3) ? PartialInternalPatientIdViewModel_isValid_293DEEA(model.SearchForInternalPatientIdVm) : ((matchValue.tag === 4) && NameDobViewModel_isValid_7D3B7E8E(model.SearchByNameDobVm)))))), model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 19: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, PatientFilters_Filter_isValid_72A93D4F(model.Filter), model.Filter), Cmd_none()];
        }
        case 20: {
            return [setCreateEditVm(new Model(new Mode_8(1), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), PatientCreationViewModel_get_empty()), Cmd_OfFunc_result(new Msg(34))];
        }
        case 21: {
            return [new Model(new Mode_8(2), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, HospitalPatientIdViewModel_get_empty(), model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 22: {
            return [new Model(new Mode_8(3), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, PartialInternalPatientIdViewModel_get_empty(), model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 23: {
            return [new Model(new Mode_8(5), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(36))];
        }
        case 24: {
            let filterType;
            if (filterField == null) {
                filterType = (new PatientFilters_FilterType(2));
            }
            else {
                const field = filterField;
                filterType = PatientFilters_FilterType_get_fieldToFilterType()(field);
            }
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, new PatientFilters_Filter("", model.Filter.dateFilter, defaultArg(filterField, new PatientFilters_Field(0)), filterType, new PatientFilters_FilterComparison(0))), Cmd_OfFunc_result(new Msg(36))];
        }
        case 25: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (inputRecord_4 = model.Filter, new PatientFilters_Filter(filter_3, inputRecord_4.dateFilter, inputRecord_4.filterField, inputRecord_4.filterType, inputRecord_4.comparison))), Cmd_OfFunc_result(new Msg(36))];
        }
        case 26: {
            if (filter_4 == null) {
                return [model, Cmd_none()];
            }
            else {
                const dto = filter_4;
                return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (inputRecord_5 = model.Filter, new PatientFilters_Filter(toString(dto, "yyyy-MM-dd") + "T00:00", toUniversalTime(dto), inputRecord_5.filterField, inputRecord_5.filterType, inputRecord_5.comparison))), Cmd_OfFunc_result(new Msg(36))];
            }
        }
        case 27: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (inputRecord_6 = model.Filter, new PatientFilters_Filter(inputRecord_6.filter, inputRecord_6.dateFilter, inputRecord_6.filterField, inputRecord_6.filterType, defaultArg(comp, new PatientFilters_FilterComparison(0))))), Cmd_OfFunc_result(new Msg(36))];
        }
        case 28: {
            return [new Model(new Mode_8(4), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, NameDobViewModel_get_empty(), model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_OfFunc_result(new Msg(34))];
        }
        case 29: {
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).getPatientWithHospitalPatientId, model.SearchForHospitalPatientIdVm, (arg_18) => (new Msg(28, arg_18)), (arg_19) => (new Msg(0, arg_19)))];
        }
        case 30: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, tryFind((patient_4) => (patient_4.id === pId), model.Patients), model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, pId))))];
        }
        case 31: {
            return [model, Toast_errorToast("Could not find a patient matching the search criteria")];
        }
        case 32: {
            return [model, Toast_errorToast(toText(printf("Failed to find patient. (%O)"))(err_2))];
        }
        case 33: {
            return [model, Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).getPatientsWithPartialInternalPatientId, model.SearchForInternalPatientIdVm, (arg_23) => (new Msg(30, arg_23)), (arg_24) => (new Msg(0, arg_24)))];
        }
        case 34: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_5((modification_3) => (!equals(modification_3.Source, new ModificationSource(0))), model.ListDisplayModifications), delay(() => singleton(new ListDisplayModification(new ModificationSource(0), 0, (list_8) => filter_5((patient_5) => contains(patient_5.id, ids_1, {
                Equals: (x_6, y_1) => (x_6 === y_1),
                GetHashCode: stringHash,
            }), list_8))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 35: {
            return [model, Toast_errorToast("Could not find a patient matching the search criteria")];
        }
        case 36: {
            return [model, Toast_errorToast(toText(printf("Failed to find patient. (%O)"))(err_3))];
        }
        case 37: {
            return [model, Cmd_OfAsyncWith_either((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, securedApi(token).getPatientsWithNameDob, model.SearchByNameDobVm, (arg_27) => (new Msg(32, arg_27)), (arg_28) => (new Msg(0, arg_28)))];
        }
        case 38: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_5((modification_4) => (!equals(modification_4.Source, new ModificationSource(0))), model.ListDisplayModifications), delay(() => singleton(new ListDisplayModification(new ModificationSource(0), 0, (list_10) => filter_5((patient_6) => contains(patient_6.id, ids_2, {
                Equals: (x_8, y_2) => (x_8 === y_2),
                GetHashCode: stringHash,
            }), list_10))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 39: {
            let p;
            const inputRecord_7 = model.CreateEditPatientVm;
            p = (new PatientCreationViewModel(inputRecord_7.id, minValue(), inputRecord_7.hospitalPatientIdVm, inputRecord_7.nameDobVm));
            let pattern_matching_result_1;
            if (model.MaybeCurrentUser != null) {
                if (equals(model.Mode, new Mode_8(1))) {
                    pattern_matching_result_1 = 0;
                }
                else if (equals(model.Mode, new Mode_8(6, new EditMode(1)))) {
                    pattern_matching_result_1 = 1;
                }
                else if (equals(model.Mode, new Mode_8(6, new EditMode(0)))) {
                    pattern_matching_result_1 = 2;
                }
                else {
                    pattern_matching_result_1 = 3;
                }
            }
            else {
                pattern_matching_result_1 = 3;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [model, Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).createPatient, p, (arg_30) => (new Msg(21, arg_30)), (arg_31) => (new Msg(0, arg_31)))];
                }
                case 1: {
                    return [model, Cmd_OfAsyncWith_either((x_10) => {
                        Cmd_OfAsync_start(x_10);
                    }, securedApi(token).addPatientNameDobHash, [p.nameDobVm, p.id], (arg_33) => (new Msg(22, arg_33)), (arg_34) => (new Msg(0, arg_34)))];
                }
                case 2: {
                    return [model, Cmd_OfAsyncWith_either((x_11) => {
                        Cmd_OfAsync_start(x_11);
                    }, securedApi(token).changePatientHospitalPatientId, [p.hospitalPatientIdVm, p.id], (arg_36) => (new Msg(22, arg_36)), (arg_37) => (new Msg(0, arg_37)))];
                }
                case 3: {
                    return [model, Cmd_none()];
                }
            }
        }
        case 40: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, pvm, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(toList(delay(() => append(singleton(Toast_successToast("Successfully created patient")), delay(() => append(singleton(Cmd_OfFunc_result(new Msg(2))), delay(() => {
                let matchValue_2;
                return (matchValue_2 = pvm.id, (matchValue_2 === "00000000-0000-0000-0000-000000000000") ? empty() : singleton_1(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, matchValue_2))))));
            })))))))];
        }
        case 41: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(toList(delay(() => append(singleton(Toast_successToast("Successfully modified patient")), delay(() => append(singleton(Cmd_OfFunc_result(new Msg(2))), delay(() => {
                let matchValue_3, patient_8;
                return (matchValue_3 = model.SelectedPatient, (matchValue_3 != null) ? ((!(matchValue_3.id === "00000000-0000-0000-0000-000000000000")) ? ((patient_8 = matchValue_3, singleton_1(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, patient_8.id))))))) : empty()) : empty());
            })))))))];
        }
        case 42: {
            return [model, Toast_errorToast((arg_41 = ErrorMessage_get_describe()(err_4), toText(printf("Failed to create patient. Error: %s"))(arg_41)))];
        }
        case 43: {
            return [model, Toast_errorToast((arg_42 = ErrorMessage_get_describe()(err_5), toText(printf("Failed to edit patient. Error: %s"))(arg_42)))];
        }
        case 44: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, empty(), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 45: {
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, sortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 46: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, PatientCreationViewModel_get_empty(), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        }
        case 47: {
            return [new Model(new Mode_8(0), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), (matchValue_4 = model.SelectedPatient, (matchValue_4 != null) ? ((!(matchValue_4.id === "00000000-0000-0000-0000-000000000000")) ? ((patient_10 = matchValue_4, Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, patient_10.id)))))) : Cmd_none()) : Cmd_none())];
        }
        case 48: {
            return [model, Cmd_none()];
        }
    }
}

