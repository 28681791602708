import { map, head, tail, isEmpty, singleton, ofArray, tryPick, forAll } from "../../fable_modules/fable-library.3.7.20/List.js";
import { StudyValidators_validateStringNotNullOrWhiteSpace } from "../../RAWMap.Models/View/Study.js";
import { SplitMeasurementEntry$2, SplitCallbacks$2, SplitMeasurementEntry_mkDoubleMsgCallback, CoreMeasurementValues, Split_View_highlightedItemWithBoldLabel, Split_View_nameSelect, CardViewVisibilityControl, Split_View_cardView, withInvalidPrefix } from "../MeasurementsView.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { ComponentVisibility } from "../../Common/Controls.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { Mode, ModeModule_$007CActive$007CInactive$007C, LocalMsg, PredefinedLabels_predefinedLabels, CalipersMeasurement_get_label_ } from "./CalipersTypes.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { String_toOption_ } from "../../Common/Extensions.js";
import { uncurry } from "../../fable_modules/fable-library.3.7.20/Util.js";

function view(measurement, model, dispatch) {
    let matchValue_2;
    const isComplete = forAll((cm) => cm.State.getIsComplete(), model.model.ActiveMeasurements);
    let saveVis;
    const matchValue = [isComplete, tryPick((cm_1) => {
        const _arg = StudyValidators_validateStringNotNullOrWhiteSpace("name", cm_1.Label);
        if (_arg.tag === 0) {
            return void 0;
        }
        else {
            return _arg.fields[0];
        }
    }, model.model.ActiveMeasurements)];
    if (matchValue[0]) {
        if (matchValue[1] != null) {
            const errorMessage = matchValue[1];
            saveVis = (new ComponentVisibility(2, withInvalidPrefix(ErrorMessage_get_describe()(errorMessage))));
        }
        else {
            const matchValue_1 = model.model;
            saveVis = (matchValue_1.IsSaving ? (new ComponentVisibility(2, "Already saving")) : (matchValue_1.IsEditingLabels ? (new ComponentVisibility(2, toText(printf("Can\u0027t save while editing labels")))) : (new ComponentVisibility(0))));
        }
    }
    else {
        saveVis = (new ComponentVisibility(2, "Caliper measurement incomplete"));
    }
    return Split_View_cardView(new CardViewVisibilityControl((matchValue_2 = model.model, matchValue_2.IsSaving ? (new ComponentVisibility(2, toText(printf("Already saving")))) : (matchValue_2.IsEditingLabels ? (new ComponentVisibility(2, toText(printf("Can\u0027t cancel while editing labels")))) : (new ComponentVisibility(0)))), saveVis), (measurementEntry_1, model_2, dispatch_2) => {
        const matchValue_3 = model_2.model;
        let pattern_matching_result, caliper;
        if (ModeModule_$007CActive$007CInactive$007C(matchValue_3.Mode).tag === 0) {
            if (!isEmpty(matchValue_3.ActiveMeasurements)) {
                if (isEmpty(tail(matchValue_3.ActiveMeasurements))) {
                    pattern_matching_result = 0;
                    caliper = head(matchValue_3.ActiveMeasurements);
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const measurementEntry = measurementEntry_1;
                let nameEdit;
                let tupledArg;
                const l = CalipersMeasurement_get_label_();
                tupledArg = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_toOption_)(l);
                nameEdit = Split_View_nameSelect((isEditing) => {
                    if ((!isComplete) && (!isEditing)) {
                        return new ComponentVisibility(2, "Caliper measurement incomplete");
                    }
                    else {
                        return new ComponentVisibility(0);
                    }
                }, caliper.Label, PredefinedLabels_predefinedLabels, measurementEntry, tupledArg[0], uncurry(2, tupledArg[1]), (arg_2) => (new LocalMsg(14, arg_2)), dispatch_2, model_2.model.IsEditingLabels, new LocalMsg(15), (arg_4) => (new LocalMsg(16, arg_4[0])));
                return ofArray([Split_View_highlightedItemWithBoldLabel("Value", measurementEntry.coreValues.value), nameEdit]);
            }
            case 1: {
                return singleton("If you see this, please save and refresh your browser window page.");
            }
        }
    }, measurement, model, dispatch);
}

export function makeMeasurementEntry(measurement) {
    let onEntrySelected, onEntryDeselected, onEntryStartEditing, onEntryEditCancelled, onEntryDeleteCancelled, onEntrySaved;
    return new SplitMeasurementEntry$2(measurement.measurementId, new CoreMeasurementValues(measurement.name, "Calipers", toText(printf("%0.1f %s"))(measurement.value)("mm"), [measurement.labelPosition, 0]), view, (onEntrySelected = SplitMeasurementEntry_mkDoubleMsgCallback((_arg, _arg_1) => (new LocalMsg(5, _arg.measurementEntryIdSplit))), (onEntryDeselected = SplitMeasurementEntry_mkDoubleMsgCallback((_arg_2, _arg_3) => (new LocalMsg(6))), (onEntryStartEditing = SplitMeasurementEntry_mkDoubleMsgCallback((_arg_4, _arg_5) => (new LocalMsg(7, new Mode(2), _arg_4.measurementEntryIdSplit))), (onEntryEditCancelled = SplitMeasurementEntry_mkDoubleMsgCallback((_arg_6, _arg_7) => (new LocalMsg(2))), (onEntryDeleteCancelled = SplitMeasurementEntry_mkDoubleMsgCallback((_arg_8, _arg_9) => (new LocalMsg(3))), (onEntrySaved = SplitMeasurementEntry_mkDoubleMsgCallback((_arg_10, _arg_11) => (new LocalMsg(1))), new SplitCallbacks$2(onEntrySelected, onEntryDeselected, onEntryStartEditing, onEntryEditCancelled, onEntryDeleteCancelled, SplitMeasurementEntry_mkDoubleMsgCallback((_arg_12, _arg_13) => (new LocalMsg(12, _arg_12.measurementEntryIdSplit))), SplitMeasurementEntry_mkDoubleMsgCallback((_arg_14, _arg_15) => (new LocalMsg(13, _arg_14.measurementEntryIdSplit))), onEntrySaved))))))));
}

export const makeMeasurementEntries = (list) => map(makeMeasurementEntry, list);

