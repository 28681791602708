import { Forms_formFieldReactSelectMulti, Forms_formFieldReactSelect, Forms_formFieldText, Controls_itemWithBoldLabel, Controls_itemListSortable } from "../Common/General.js";
import { Security_UserViewModel$2, Security_UserInfo$2_get_roles_, Common_EmailAddress_value_Z39D6D3C1, Security_UserInfo$2_get_email_, Common_Name_get_family_, Common_Name_get_given_, Security_UserInfo$2_get_name_ } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { Compose_Lens_op_GreaterMinusGreater_2536FC39, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { append as append_1, map as map_1, exists, filter, contains, tryFind, choose, head, isEmpty, empty as empty_1, singleton as singleton_1, ofArray, tryHead } from "../fable_modules/fable-library.3.7.20/List.js";
import { UserData, UserViewModelModule_email_, UserViewModelModule_name_, UserRole_get_all, UserRole, UserViewModelModule_isValid, Permission, isAllowed, UserRole_get_describe } from "../RAWMap.Models/Security.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Msg } from "./UserTypes.js";
import { toList, append, empty, singleton, delay } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Card_title, Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import * as react from "react";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { join, printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.20/Util.js";
import { List_except } from "../fable_modules/fable-library.3.7.20/Seq2.js";
import { String_trimString_ } from "../RAWMap.Models/Common.js";
import { InstitutionViewModel_get_id_, InstitutionViewModel_get_name_ } from "../RAWMap.Models/View/Institution.js";
import { SelectOptions_value } from "../Common/ReactSelectBind.js";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";

export function userList(model, dispatch) {
    let optic, l, optic_2, l_2, f1, optic_4, f1_3, f1_2, f1_1, optic_6, f2_2, mapping;
    return Controls_itemListSortable(ofArray([["First Name", (optic = ((l = Security_UserInfo$2_get_name_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_given_())(l))), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))], ["Last Name", (optic_2 = ((l_2 = Security_UserInfo$2_get_name_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_family_())(l_2))), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target_2))], ["Email", (f1 = ((optic_4 = Security_UserInfo$2_get_email_(), (target_4) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_4))), (arg_7) => Common_EmailAddress_value_Z39D6D3C1(f1(arg_7)))], ["Role", (f1_3 = ((f1_2 = ((f1_1 = ((optic_6 = Security_UserInfo$2_get_roles_(), (target_6) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_6)(target_6))), (arg_9) => tryHead(f1_1(arg_9)))), (f2_2 = ((mapping = UserRole_get_describe(), (option) => map(mapping, option))), (arg_10) => f2_2(f1_2(arg_10))))), (arg_11) => defaultArg(f1_3(arg_11), ""))]]), (user_1, _arg_1) => {
        dispatch(new Msg(6, user_1.id));
    }, model.users, (user) => {
        const matchValue = model.selectedUser;
        let pattern_matching_result;
        if (matchValue != null) {
            if (matchValue.id === user.id) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return delay(() => singleton(new HTMLAttr(64, "is-selected")));
            }
            case 1: {
                return empty();
            }
        }
    }, model.sortInfo, (updatedInfo, _arg) => {
        dispatch(new Msg(16, updatedInfo));
    });
}

export function listView(model, dispatch) {
    return singleton_1(userList(model, dispatch));
}

export function deleteConfirmation(vm, dispatch) {
    let children_2;
    return modal(singleton_1(new Option(1, true)), ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), singleton_1(react.createElement("p", {}, "Delete User"))), Card_body(empty_1(), singleton_1(content(empty_1(), singleton_1((children_2 = [toText(printf("Are you sure you would like to delete %s %s (%s)? This operation cannot be undone."))(vm.name.given)(vm.name.family)(vm.email)], react.createElement("p", {}, ...children_2)))))), Card_foot(empty_1(), ofArray([button(ofArray([new Option_1(0, new Color_IColor(2)), new Option_1(17, singleton_1(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(6, vm.id));
    })))]), singleton_1("Cancel")), button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, singleton_1(new DOMAttr(40, (_arg_1) => {
        dispatch(new Msg(11, vm));
    })))]), singleton_1("Delete User"))]))]))]));
}

export function detailView(institutions, selectedVm, currentUser, dispatch) {
    const x = append([1], [1]);
    return modal(singleton_1(new Option(1, true)), ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), toList(delay(() => append(singleton(Card_head(empty_1(), singleton_1(Card_title(empty_1(), singleton_1(toText(printf("%O Details"))(selectedVm.email)))))), delay(() => {
        let currentRole;
        const matchValue = selectedVm.roles;
        if (!isEmpty(matchValue)) {
            const arg_1 = UserRole_get_describe()(head(matchValue));
            currentRole = toText(printf("%O"))(arg_1);
        }
        else {
            currentRole = "";
        }
        const formattedInstitutions = join(", ", choose((iid) => map((i_1) => i_1.name, tryFind((i) => (i.id === iid), institutions)), selectedVm.userData.institutions));
        return append(singleton(Card_body(empty_1(), singleton_1(content(empty_1(), toList(delay(() => append(singleton(Controls_itemWithBoldLabel("First Name: ", selectedVm.name.given)), delay(() => append(singleton(Controls_itemWithBoldLabel("Last Name: ", selectedVm.name.family)), delay(() => append(singleton(Controls_itemWithBoldLabel("Email: ", selectedVm.email)), delay(() => append(singleton(Controls_itemWithBoldLabel("Role: ", currentRole)), delay(() => ((!isAllowed(new Permission(5))(selectedVm.roles)) ? singleton(Controls_itemWithBoldLabel("Institutions: ", formattedInstitutions)) : empty()))))))))))))))), delay(() => singleton(Card_foot(empty_1(), ofArray([button(ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(17, singleton_1(new DOMAttr(40, (_arg) => {
            dispatch(new Msg(8));
        })))]), singleton_1("Edit")), button(ofArray([new Option_1(0, new Color_IColor(2)), new Option_1(17, singleton_1(new DOMAttr(40, (_arg_1) => {
            dispatch(new Msg(18));
        })))]), singleton_1("Cancel")), button(ofArray([new Option_1(0, new Color_IColor(4)), new Option_1(17, singleton_1(new DOMAttr(40, (_arg_2) => {
            dispatch(new Msg(3, selectedVm.email));
        })))]), singleton_1("Reset Password")), button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(16, currentUser.id === selectedVm.id), new Option_1(17, singleton_1(new DOMAttr(40, (_arg_3) => {
            dispatch(new Msg(10));
        })))]), singleton_1("Delete User"))])))));
    })))))]));
}

export function userForm(model, currentUser, dispatch) {
    const createEditFieldsValid = UserViewModelModule_isValid(model.editUserVm);
    const vm = model.editUserVm;
    let patternInput;
    const matchValue = [model.mode, model.selectedUser];
    let pattern_matching_result;
    if (matchValue[0].tag === 2) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            patternInput = ["Edit", new Msg(6, matchValue[1].id)];
            break;
        }
        case 1: {
            patternInput = ["Create", new Msg(18)];
            break;
        }
    }
    return modal(singleton_1(new Option(1, true)), ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), singleton_1(Card_title(empty_1(), singleton_1(toText(printf("%s User"))(patternInput[0]))))), Card_body(empty_1(), toList(delay(() => {
        let children;
        const setVmProp = (optic) => (new DOMAttr(9, (e) => {
            let value;
            dispatch(new Msg(17, (value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))));
        }));
        const currentRole = tryHead(vm.roles);
        return singleton((children = toList(delay(() => {
            let l_2;
            const availableRoles = contains(new UserRole(1), currentUser.roles, {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? List_except([new UserRole(3), new UserRole(0)], UserRole_get_all(), {
                Equals: equals,
                GetHashCode: safeHash,
            }) : UserRole_get_all();
            return append(singleton(Forms_formFieldText("First Name", vm.name.given, singleton_1(setVmProp((l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_given_())(UserViewModelModule_name_), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_trimString_)(l_2)))), false)), delay(() => {
                let l_6;
                return append(singleton(Forms_formFieldText("Last Name", vm.name.family, singleton_1(setVmProp((l_6 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_family_())(UserViewModelModule_name_), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_trimString_)(l_6)))), false)), delay(() => append(singleton(Forms_formFieldText("Email Address", vm.email, singleton_1(setVmProp(Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_trimString_)(UserViewModelModule_email_))), vm.id !== "00000000-0000-0000-0000-000000000000")), delay(() => append(singleton(Forms_formFieldReactSelect([], "Role", currentRole, availableRoles, UserRole_get_describe(), (value_2) => {
                    dispatch(new Msg(17, (svm_1) => ((svm_2) => ((value_4) => {
                        const userData_1 = new UserData((value_4 == null) ? empty_1() : ((value_4.tag === 3) ? empty_1() : ((value_4.tag === 0) ? empty_1() : svm_2.userData.institutions)));
                        return new Security_UserViewModel$2(svm_2.id, svm_2.email, svm_2.name, defaultArg(map(singleton_1, value_4), empty_1()), userData_1);
                    }))(svm_1)(value_2)));
                })), delay(() => {
                    let f1_1, optic_4;
                    return (!(isEmpty(vm.roles) ? true : isAllowed(new Permission(5))(vm.roles))) ? singleton(Forms_formFieldReactSelectMulti("Institution", filter((i) => exists((iid) => (iid === i.id), vm.userData.institutions), model.institutions), [], model.institutions, (f1_1 = ((optic_4 = InstitutionViewModel_get_name_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_2))), f1_1), (value_1) => {
                        dispatch(new Msg(17, (svm) => {
                            let list_1, f2, optic_2;
                            return new Security_UserViewModel$2(svm.id, svm.email, svm.name, svm.roles, new UserData((list_1 = ofArray(value_1), map_1((f2 = ((optic_2 = InstitutionViewModel_get_id_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target))), (arg_3) => f2(SelectOptions_value(arg_3))), list_1))));
                        }));
                    })) : empty();
                }))))));
            }));
        })), react.createElement("form", {}, ...children)));
    }))), Card_foot(empty_1(), ofArray([button(ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(16, !createEditFieldsValid), new Option_1(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatch(new Msg(9, vm));
    }), new HTMLAttr(159, "button")]))]), singleton_1("Save")), button(ofArray([new Option_1(0, new Color_IColor(2)), new Option_1(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatch(patternInput[1]);
    }), new HTMLAttr(159, "button")]))]), singleton_1("Cancel"))]))]))]));
}

export function view(model, currentUser, dispatch) {
    let children;
    let children_2;
    let list_1;
    const model_1 = model;
    const matchValue = [model_1.mode, model_1.selectedUser];
    let pattern_matching_result;
    if (matchValue[0].tag === 4) {
        if (matchValue[1] == null) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1] == null) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (matchValue[0].tag === 1) {
        pattern_matching_result = 1;
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1] == null) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            list_1 = singleton_1(deleteConfirmation(matchValue[1], dispatch));
            break;
        }
        case 1: {
            list_1 = singleton_1(userForm(model_1, currentUser, dispatch));
            break;
        }
        case 2: {
            list_1 = singleton_1(detailView(model_1.institutions, matchValue[1], currentUser, dispatch));
            break;
        }
        case 3: {
            throw (new Error("No user selected"));
            break;
        }
        case 4: {
            list_1 = empty_1();
            break;
        }
    }
    children_2 = append_1(singleton_1(card(empty_1(), ofArray([header(empty_1(), ofArray([Header_title(empty_1(), singleton_1("Users")), Header_icon(empty_1(), singleton_1(button(ofArray([new Option_1(4), new Option_1(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatch(new Msg(7));
    }), ["style", {
        backgroundColor: "#F0F0F0",
    }]]))]), singleton_1("Create User"))))])), content_1(empty_1(), singleton_1((children = listView(model, dispatch), react.createElement("div", {
        className: "table-container",
    }, ...children))))]))), list_1);
    return react.createElement("div", {}, ...children_2);
}

