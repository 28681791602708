import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, lambda_type, unit_type, array_type, float64_type, option_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class CameraProps extends Record {
    constructor(activeCamera, initialCameraAdjustment, onCameraChange) {
        super();
        this.activeCamera = activeCamera;
        this.initialCameraAdjustment = initialCameraAdjustment;
        this.onCameraChange = onCameraChange;
    }
}

export function CameraProps$reflection() {
    return record_type("RAWMap.Client.Visualization.Camera.Bindings.CameraProps", [], CameraProps, () => [["activeCamera", option_type(class_type("RAWMap.Client.Visualization.Camera.Bindings.ICamera"))], ["initialCameraAdjustment", array_type(float64_type)], ["onCameraChange", lambda_type(class_type("RAWMap.Client.Visualization.Camera.Bindings.ICamera"), unit_type)]]);
}

