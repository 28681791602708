import { Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { lambda_type, union_type, list_type, string_type, record_type, option_type, class_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { ErrorMessage$reflection } from "../../../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library.3.7.20/Choice.js";
import { AsyncResult_ofSuccess, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { cons, ofArray, append as append_1, concat, empty, head as head_4, tail as tail_2, isEmpty, filter, map } from "../../../../fable_modules/fable-library.3.7.20/List.js";
import { defaultArg, map2, bind, map as map_1 } from "../../../../fable_modules/fable-library.3.7.20/Option.js";
import { singleton, append, delay, toList } from "../../../../fable_modules/fable-library.3.7.20/Seq.js";
import { List_distinct } from "../../../../fable_modules/fable-library.3.7.20/Seq2.js";
import { curry, uncurry, arrayHash, equalArrays } from "../../../../fable_modules/fable-library.3.7.20/Util.js";
import { CenterlineMeasurementValueViewModel_tableDisplay_297A6D85, CenterlineMeasurementValueViewModel_comparePrevious, CenterlineMeasurementViewModel$reflection } from "../../../../RAWMap.Models/View/CenterlineMeasurement.js";
import { CalipersMeasurementViewModel_viewComponents_491575AE, CalipersMeasurementViewModel_comparePrevious, CalipersMeasurementViewModel$reflection } from "../../../../RAWMap.Models/View/CalipersMeasurement.js";
import { securedApi } from "../../../../Api.js";
import { Csv_CsvRowMulti } from "../../../../RAWMap.Models/Common.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../ReportPdfHelpers.js";

export const notAvailable = "N/A";

export class Args extends Record {
    constructor(currentStudyId, maybePreviousStudyId) {
        super();
        this.currentStudyId = currentStudyId;
        this.maybePreviousStudyId = maybePreviousStudyId;
    }
}

export function Args$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.Args", [], Args, () => [["currentStudyId", class_type("System.Guid")], ["maybePreviousStudyId", option_type(class_type("System.Guid"))]]);
}

class ComparisonMeasurementDisplay extends Record {
    constructor(valueDiff) {
        super();
        this.valueDiff = valueDiff;
    }
}

function ComparisonMeasurementDisplay$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ComparisonMeasurementDisplay", [], ComparisonMeasurementDisplay, () => [["valueDiff", string_type]]);
}

class CoreMeasurementDisplay extends Record {
    constructor(name, measurementType, currentValue) {
        super();
        this.name = name;
        this.measurementType = measurementType;
        this.currentValue = currentValue;
    }
}

function CoreMeasurementDisplay$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.CoreMeasurementDisplay", [], CoreMeasurementDisplay, () => [["name", string_type], ["measurementType", string_type], ["currentValue", string_type]]);
}

class BundledMeasurement$1 extends Record {
    constructor(measurement, coreDisplay) {
        super();
        this.measurement = measurement;
        this.coreDisplay = coreDisplay;
    }
}

function BundledMeasurement$1$reflection(gen0) {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.BundledMeasurement`1", [gen0], BundledMeasurement$1, () => [["measurement", gen0], ["coreDisplay", CoreMeasurementDisplay$reflection()]]);
}

function BundledMeasurement_areSame(a, b) {
    return a.coreDisplay.name === b.coreDisplay.name;
}

class ReportMeasurementDisplay extends Record {
    constructor(measurementType, measurementName, maybeCore, maybePrevious, maybeComparisonValue) {
        super();
        this.measurementType = measurementType;
        this.measurementName = measurementName;
        this.maybeCore = maybeCore;
        this.maybePrevious = maybePrevious;
        this.maybeComparisonValue = maybeComparisonValue;
    }
}

function ReportMeasurementDisplay$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ReportMeasurementDisplay", [], ReportMeasurementDisplay, () => [["measurementType", string_type], ["measurementName", string_type], ["maybeCore", option_type(CoreMeasurementDisplay$reflection())], ["maybePrevious", option_type(CoreMeasurementDisplay$reflection())], ["maybeComparisonValue", option_type(ComparisonMeasurementDisplay$reflection())]]);
}

class ReportMeasurement$1 extends Record {
    constructor(apiCall, comparison, makeCore) {
        super();
        this.apiCall = apiCall;
        this.comparison = comparison;
        this.makeCore = makeCore;
    }
}

function ReportMeasurement$1$reflection(gen0) {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ReportMeasurement`1", [gen0], ReportMeasurement$1, () => [["apiCall", lambda_type(string_type, lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(gen0), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(gen0)]], [["ErrorValue", ErrorMessage$reflection()]]])])))], ["comparison", lambda_type(gen0, lambda_type(gen0, option_type(ComparisonMeasurementDisplay$reflection())))], ["makeCore", lambda_type(gen0, CoreMeasurementDisplay$reflection())]]);
}

function ReportMeasurement_getMeasurements(uni, studyId, reportMeasurement) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, reportMeasurement.apiCall(uni.token, studyId), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg)));
}

function ReportMeasurement_bundleMeasurements(measurements, reportMeasurement) {
    return map((measurement) => (new BundledMeasurement$1(measurement, reportMeasurement.makeCore(measurement))), measurements);
}

function ReportMeasurement_makeDisplay(currentMeasurements, previousMeasurements, reportMeasurement, name, measurementType) {
    const mapCore = (option) => map_1((bundled) => bundled.coreDisplay, option);
    let patternInput;
    const _arg = filter((current) => (current.coreDisplay.name === name), currentMeasurements);
    patternInput = ((!isEmpty(_arg)) ? (isEmpty(tail_2(_arg)) ? [head_4(_arg), empty()] : [head_4(_arg), tail_2(_arg)]) : [void 0, empty()]);
    const maybeCurrent = patternInput[0];
    let patternInput_1;
    const _arg_1 = filter((previous) => (previous.coreDisplay.name === name), previousMeasurements);
    patternInput_1 = ((!isEmpty(_arg_1)) ? (isEmpty(tail_2(_arg_1)) ? [head_4(_arg_1), empty()] : [head_4(_arg_1), tail_2(_arg_1)]) : [void 0, empty()]);
    const maybePrevious = patternInput_1[0];
    const maybeComparison = bind((x) => x, map2((current_1, previous_1) => reportMeasurement.comparison(current_1.measurement, previous_1.measurement), maybeCurrent, maybePrevious));
    const displayExtraCurrent = map((measurement) => (new ReportMeasurementDisplay(measurementType, name, mapCore(measurement), void 0, void 0)), patternInput[1]);
    const displayExtraPrevious = map((measurement_1) => (new ReportMeasurementDisplay(measurementType, name, void 0, mapCore(measurement_1), void 0)), patternInput_1[1]);
    return toList(delay(() => append(singleton(new ReportMeasurementDisplay(measurementType, name, mapCore(maybeCurrent), mapCore(maybePrevious), maybeComparison)), delay(() => append(displayExtraCurrent, delay(() => displayExtraPrevious))))));
}

function ReportMeasurement_parseMeasurement(uni, args, reportMeasurement) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_getMeasurements(uni, args.currentStudyId, reportMeasurement), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, defaultArg(map_1((previous) => ReportMeasurement_getMeasurements(uni, previous, reportMeasurement), args.maybePreviousStudyId), AsyncResult_ofSuccess(empty())), (_arg_1) => {
        const bundledCurrent = ReportMeasurement_bundleMeasurements(_arg, reportMeasurement);
        const bundledPrevious = ReportMeasurement_bundleMeasurements(_arg_1, reportMeasurement);
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, concat(map((tupledArg) => ReportMeasurement_makeDisplay(bundledCurrent, bundledPrevious, reportMeasurement, tupledArg[0], tupledArg[1]), List_distinct(map((bundled) => [bundled.coreDisplay.name, bundled.coreDisplay.measurementType], append_1(bundledCurrent, bundledPrevious)), {
            Equals: equalArrays,
            GetHashCode: arrayHash,
        }))));
    })));
}

class AllMeasurements extends Record {
    constructor(centerlineDistances, diameterRanges, localDiameters, centerlineVolumes, calipers) {
        super();
        this.centerlineDistances = centerlineDistances;
        this.diameterRanges = diameterRanges;
        this.localDiameters = localDiameters;
        this.centerlineVolumes = centerlineVolumes;
        this.calipers = calipers;
    }
}

function AllMeasurements$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.AllMeasurements", [], AllMeasurements, () => [["centerlineDistances", ReportMeasurement$1$reflection(CenterlineMeasurementViewModel$reflection())], ["diameterRanges", ReportMeasurement$1$reflection(CenterlineMeasurementViewModel$reflection())], ["localDiameters", ReportMeasurement$1$reflection(CenterlineMeasurementViewModel$reflection())], ["centerlineVolumes", ReportMeasurement$1$reflection(CenterlineMeasurementViewModel$reflection())], ["calipers", ReportMeasurement$1$reflection(CalipersMeasurementViewModel$reflection())]]);
}

function AllMeasurements_makeMeasurements(uni, args, allMeasurements_1) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.centerlineDistances), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.diameterRanges), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.localDiameters), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.centerlineVolumes), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.calipers), (_arg_4) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, append_1(_arg, append_1(_arg_1, append_1(_arg_2, append_1(_arg_3, _arg_4)))))))))));
}

const allMeasurements = (() => {
    const mkReportMeasurement = (getMeasurementsForStudy, tryCompare, getMeasurementTypeAndValue, getMeasurementName) => (new ReportMeasurement$1(uncurry(2, getMeasurementsForStudy), (current, previous) => map_1((tupledArg) => (new ComparisonMeasurementDisplay(tupledArg[1])), tryCompare(current)(previous)), (measurement) => {
        const patternInput = getMeasurementTypeAndValue(measurement);
        return new CoreMeasurementDisplay(getMeasurementName(measurement), patternInput[0], patternInput[1]);
    }));
    const tryCompareCenterlineMeasurements = (current_1, previous_1) => CenterlineMeasurementValueViewModel_comparePrevious(current_1.value, previous_1.value);
    const getCenterlineMeasurementTypeAndValue = (measurement_1) => CenterlineMeasurementValueViewModel_tableDisplay_297A6D85(measurement_1.value);
    const getCenterlineMeasurementName = (measurement_2) => measurement_2.name;
    return new AllMeasurements(mkReportMeasurement((token) => ((studyId) => securedApi(token).getCenterlineDistanceMeasurementsForStudy(studyId)), curry(2, tryCompareCenterlineMeasurements), getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_1) => ((studyId_1) => securedApi(token_1).getDiameterRangeMeasurementsForStudy(studyId_1)), curry(2, tryCompareCenterlineMeasurements), getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_2) => ((studyId_2) => securedApi(token_2).getLocalDiameterMeasurementsForStudy(studyId_2)), curry(2, tryCompareCenterlineMeasurements), getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_3) => ((studyId_3) => securedApi(token_3).getCenterlineVolumeMeasurementsForStudy(studyId_3)), curry(2, tryCompareCenterlineMeasurements), getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_4) => ((studyId_4) => securedApi(token_4).getCalipersMeasurementsForStudy(studyId_4)), (arg_2) => ((arg_3) => CalipersMeasurementViewModel_comparePrevious(arg_2, arg_3)), CalipersMeasurementViewModel_viewComponents_491575AE, (measurement_3) => measurement_3.name));
})();

function convertToReportRows(measurements) {
    return map((measurement) => (new Csv_CsvRowMulti(measurement.measurementName, ofArray([measurement.measurementType, defaultArg(map_1((current) => current.currentValue, measurement.maybeCore), notAvailable), defaultArg(map_1((previous) => previous.currentValue, measurement.maybePrevious), notAvailable), defaultArg(map_1((previous_1) => previous_1.valueDiff, measurement.maybeComparisonValue), notAvailable)]))), measurements);
}

function convertToCsvRows(measurements) {
    return map((measurement) => (new Csv_CsvRowMulti(measurement.measurementName, ofArray([measurement.measurementType, defaultArg(map_1((current) => current.currentValue, measurement.maybeCore), notAvailable)]))), measurements);
}

export function create(doc, uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AllMeasurements_makeMeasurements(uniArgs, args, allMeasurements), (_arg) => {
        const multiRows = convertToReportRows(_arg);
        const patternInput = Pdf_Table_ofCsvRows(toList(delay(() => append(singleton("Measurement Name"), delay(() => append(singleton("Measurement Type"), delay(() => append(singleton("Current Study - Measurement Value"), delay(() => ((args.maybePreviousStudyId != null) ? ofArray(["Previous Study - Measurement Value", "Difference"]) : empty()))))))))), multiRows);
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, Pdf_Table_Basic_make("Measurements", patternInput[0], patternInput[1], Pdf_Spacing_topMargin, doc));
    }));
}

export function getAllMeasurementsCsvRows(uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AllMeasurements_makeMeasurements(uniArgs, args, allMeasurements), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, cons(new Csv_CsvRowMulti("Measurement Name", ofArray(["Measurement Type", "Value"])), convertToCsvRows(_arg)))));
}

