import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { lambda_type, unit_type, record_type, option_type, float64_type, string_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { NotesProps$reflection } from "../Notes/NotesBindings.js";

export class MapValuePickedArgs extends Record {
    constructor(viewportLocation, selectedMap, maybePickedValue) {
        super();
        this.viewportLocation = viewportLocation;
        this.selectedMap = selectedMap;
        this.maybePickedValue = maybePickedValue;
    }
}

export function MapValuePickedArgs$reflection() {
    return record_type("RAWMap.Client.Visualization.PickedMapValue.Bindings.MapValuePickedArgs", [], MapValuePickedArgs, () => [["viewportLocation", string_type], ["selectedMap", string_type], ["maybePickedValue", option_type(float64_type)]]);
}

export class PickedMapValueProps extends Record {
    constructor(onMapValuePicked, notesProps, clearPickedValues) {
        super();
        this.onMapValuePicked = onMapValuePicked;
        this.notesProps = notesProps;
        this.clearPickedValues = clearPickedValues;
    }
}

export function PickedMapValueProps$reflection() {
    return record_type("RAWMap.Client.Visualization.PickedMapValue.Bindings.PickedMapValueProps", [], PickedMapValueProps, () => [["onMapValuePicked", lambda_type(MapValuePickedArgs$reflection(), unit_type)], ["notesProps", NotesProps$reflection()], ["clearPickedValues", lambda_type(string_type, unit_type)]]);
}

