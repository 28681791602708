import { toString } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { MeshViewportLocation } from "../Common/CommonTypes.js";
import { InteractionMode, Bounds } from "../Common/CommonBindings.js";
import { some, map, defaultArg } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { OutboundMsg, VisualizationNoteViewModel_convertFromLabelCoordinates, LabelData_setStaticLabels, LocalMsg, ModelNotes, Mode as Mode_10, LabelData as LabelData_2, StaticLabel_ofVisualizationNoteViewModel } from "./NotesTypes.js";
import { singleton, append, exists, ofArray, choose, toArray, map as map_3, empty, tryFind } from "../../fable_modules/fable-library.3.7.20/List.js";
import { ofArray as ofArray_1, find, toList, add, map as map_2, empty as empty_1 } from "../../fable_modules/fable-library.3.7.20/Map.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { contains as contains_1, choose as choose_1, zip, map as map_1 } from "../../fable_modules/fable-library.3.7.20/Array.js";
import { Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { VisualizationNoteViewModel } from "../../RAWMap.Models/View/VisualizationNote.js";
import { StaticLabel } from "./NotesBindings.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { Toast_errorToast } from "../../Common/General.js";
import { newGuid } from "../../fable_modules/fable-library.3.7.20/Guid.js";
import { Result_map, Result_isError, Result_isOk, Async_map } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { parallel } from "../../fable_modules/fable-library.3.7.20/Async.js";
import { securedApi } from "../../Api.js";
import { CustomReportStatus } from "../../RAWMap.Models/View/CustomReport.js";
import { safeHash, equals } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { List_except } from "../../fable_modules/fable-library.3.7.20/Seq2.js";

function getMeshViewportBounds() {
    const rect = document.getElementById(toString(new MeshViewportLocation(0))).getBoundingClientRect();
    return new Bounds(rect.width, rect.height);
}

function adjustStaticLabelCoordinates(model, bounds, label) {
    return defaultArg(map((noteVm) => StaticLabel_ofVisualizationNoteViewModel(bounds, noteVm), tryFind((note) => (note.noteId === label.id), model.Notes)), label);
}

export function init(selectedStudyId, maybeCurrentUser) {
    return [new ModelNotes(maybeCurrentUser, selectedStudyId, new LabelData_2([], []), empty(), empty_1(), new Mode_10(0), false), Cmd_OfFunc_result(wrapLocalMsg(new LocalMsg(1)))];
}

export function setStaticLabels(model, notes) {
    return LabelData_setStaticLabels(getMeshViewportBounds(), model.LabelData, notes);
}

export function updateInbound(token, msg, model) {
    switch (msg.tag) {
        case 1: {
            const bounds = msg.fields[0];
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, new LabelData_2(model.LabelData.worldLabels, map_1((label_1) => adjustStaticLabelCoordinates(model, bounds, label_1), model.LabelData.staticLabels)), model.Notes, map_2((labelId, label_2) => adjustStaticLabelCoordinates(model, bounds, label_2), model.ModifiedStaticLabels), model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 2: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(1), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 3: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(0), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 4: {
            const hiddenNoteVms = map_3((noteVm) => (new VisualizationNoteViewModel(noteVm.studyId, noteVm.noteId, noteVm.content, noteVm.x, noteVm.y, true)), model.Notes);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, hiddenNoteVms), hiddenNoteVms, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        default: {
            const label = msg.fields[0];
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, new LabelData_2(model.LabelData.worldLabels, map_1((oldLabel) => {
                if (oldLabel.id === label.id) {
                    return new StaticLabel(label.id, label.content, label.moves + 1, label.xPercentage, label.yPercentage, label.x, label.y, label.isHidden, label.isHeader);
                }
                else {
                    return oldLabel;
                }
            }, model.LabelData.staticLabels)), model.Notes, add(label.id, label, model.ModifiedStaticLabels), model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
    }
}

export function updateLocal(token, msg, model) {
    let f1, f1_1;
    let pattern_matching_result, ex, noteIdResults, noteVms, e, noteVm_3, noteVm_4, noteVm_5, deletedNoteVm, status_1, setter, noteVm_10, status_2, e_1, noteId_5;
    if (msg.tag === 15) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 2;
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 3;
        noteIdResults = msg.fields[0];
    }
    else if (msg.tag === 1) {
        pattern_matching_result = 4;
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 6;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
            noteVms = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 7;
        noteVm_3 = msg.fields[0];
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 8;
        noteVm_4 = msg.fields[0];
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 9;
        noteVm_5 = msg.fields[0];
    }
    else if (msg.tag === 10) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 10;
            deletedNoteVm = msg.fields[0].fields[0][1];
            status_1 = msg.fields[0].fields[0][0];
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 11;
        setter = msg.fields[0];
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 12;
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
            noteVm_10 = msg.fields[0].fields[0][1];
            status_2 = msg.fields[0].fields[0][0];
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
            noteVm_10 = msg.fields[0].fields[0][1];
            status_2 = msg.fields[0].fields[0][0];
        }
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 15;
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 16;
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 17;
        noteId_5 = msg.fields[0];
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Notes exception: %O"))(ex)));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, false), Toast_errorToast("An error has occured"), Cmd_none()];
        }
        case 1: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(3, new VisualizationNoteViewModel(model.SelectedStudyId, newGuid(), "", 0.05, 0.25, false)), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 2: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (notes) => {
                const noteIds = toArray(map_3((note_1) => note_1.noteId, notes));
                return Async_map((results) => zip(noteIds, results), parallel(map_3(securedApi(token).editVisualizationNote, notes)));
            }, choose((tupledArg) => {
                let bounds;
                const option = tryFind((note) => (note.noteId === tupledArg[0]), model.Notes);
                return map((bounds = getMeshViewportBounds(), (noteVm) => VisualizationNoteViewModel_convertFromLabelCoordinates(bounds, tupledArg[1], noteVm)), option);
            }, toList(model.ModifiedStaticLabels)), (arg_2) => (new LocalMsg(12, arg_2)), (arg_3) => (new LocalMsg(0, arg_3))), Cmd_none()];
        }
        case 3: {
            const updatedNotes = ofArray(choose_1((noteId_2) => {
                let bounds_1;
                const label_1 = find(noteId_2, model.ModifiedStaticLabels);
                const option_1 = tryFind((note_2) => (note_2.noteId === noteId_2), model.Notes);
                return map((bounds_1 = getMeshViewportBounds(), (noteVm_1) => VisualizationNoteViewModel_convertFromLabelCoordinates(bounds_1, label_1, noteVm_1)), option_1);
            }, map_1((tuple) => tuple[0], noteIdResults.filter((tupledArg_1) => Result_isOk(tupledArg_1[1])))));
            const revisedNotes = map_3((note_3) => defaultArg(tryFind((updatedNote) => (updatedNote.noteId === note_3.noteId), updatedNotes), note_3), model.Notes);
            const failedUpdates = noteIdResults.filter((tupledArg_2) => Result_isError(tupledArg_2[1]));
            const remainingModifiedStaticLabels = ofArray_1(map_1((tupledArg_3) => {
                const noteId_4 = tupledArg_3[0];
                return [noteId_4, find(noteId_4, model.ModifiedStaticLabels)];
            }, failedUpdates));
            const cmd = (failedUpdates.length > 0) ? Toast_errorToast("One or more note positions could not be saved") : Cmd_none();
            const revisedLabelData = setStaticLabels(model, revisedNotes);
            const status = contains_1(new CustomReportStatus(0), choose_1((tupledArg_4) => {
                const result_2 = tupledArg_4[1];
                if (result_2.tag === 1) {
                    return void 0;
                }
                else {
                    return result_2.fields[0];
                }
            }, noteIdResults), {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? (new CustomReportStatus(0)) : (new CustomReportStatus(1));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, revisedLabelData, revisedNotes, remainingModifiedStaticLabels, new Mode_10(0), false), cmd, Cmd_batch(ofArray([Cmd_OfFunc_result(new OutboundMsg(1, new InteractionMode(0))), Cmd_OfFunc_result(new OutboundMsg(0, status))]))];
        }
        case 4: {
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getVisualizationNotesForStudy, model.SelectedStudyId, (arg_5) => (new LocalMsg(2, arg_5)), (arg_6) => (new LocalMsg(0, arg_6))), Cmd_none()];
        }
        case 5: {
            const visibilityUpdatedNoteVms = map_3((noteVm_2) => (new VisualizationNoteViewModel(noteVm_2.studyId, noteVm_2.noteId, noteVm_2.content, noteVm_2.x, noteVm_2.y, true)), noteVms);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, visibilityUpdatedNoteVms), visibilityUpdatedNoteVms, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 6: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_none()];
        }
        case 7: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(4, noteVm_3), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 8: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(5, noteVm_4), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 9: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).deleteVisualizationNote, [noteVm_5.noteId, noteVm_5.studyId], (f1 = Result_map()((r) => [r, noteVm_5]), (arg_9) => (new LocalMsg(10, f1(arg_9)))), (arg_10) => (new LocalMsg(0, arg_10))), Cmd_none()];
        }
        case 10: {
            const newNotes = List_except([deletedNoteVm], model.Notes, {
                Equals: equals,
                GetHashCode: safeHash,
            });
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, newNotes), newNotes, model.ModifiedStaticLabels, new Mode_10(0), false), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0, status_1))];
        }
        case 11: {
            const matchValue = model.Mode;
            switch (matchValue.tag) {
                case 3: {
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(3, setter(matchValue.fields[0])), model.IsSaving), Cmd_none(), Cmd_none()];
                }
                case 4: {
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(4, setter(matchValue.fields[0])), model.IsSaving), Cmd_none(), Cmd_none()];
                }
                default: {
                    console.error(some(toText(printf("Unexpected SetVisualizationNoteVm message while Mode is %A"))(model.Mode)));
                    return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 12: {
            const matchValue_1 = model.Mode;
            switch (matchValue_1.tag) {
                case 3: {
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_5) => {
                        Cmd_OfAsync_start(x_5);
                    }, securedApi(token).createVisualizationNote, matchValue_1.fields[0], (arg_13) => (new LocalMsg(6, arg_13)), (arg_14) => (new LocalMsg(0, arg_14))), Cmd_none()];
                }
                case 4: {
                    const noteVm_9 = matchValue_1.fields[0];
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_6) => {
                        Cmd_OfAsync_start(x_6);
                    }, securedApi(token).editVisualizationNote, noteVm_9, (f1_1 = Result_map()((r_1) => [r_1, noteVm_9]), (arg_17) => (new LocalMsg(7, f1_1(arg_17)))), (arg_18) => (new LocalMsg(0, arg_18))), Cmd_none()];
                }
                default: {
                    return [model, Cmd_OfFunc_result(new LocalMsg(0, new Error("Unable to save note"))), Cmd_none()];
                }
            }
        }
        case 13: {
            const newNotes_1 = exists((note_4) => (note_4.noteId === noteVm_10.noteId), model.Notes) ? map_3((note_5) => {
                if (note_5.noteId === noteVm_10.noteId) {
                    return noteVm_10;
                }
                else {
                    return note_5;
                }
            }, model.Notes) : append(model.Notes, singleton(noteVm_10));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, newNotes_1), newNotes_1, model.ModifiedStaticLabels, new Mode_10(0), false), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0, status_2))];
        }
        case 14: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, false), Toast_errorToast(ErrorMessage_get_describe()(e_1)), Cmd_none()];
        }
        case 15: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(0), model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 16: {
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(2), model.IsSaving), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(1, new InteractionMode(1)))];
        }
        case 17: {
            const updatedNotes_1 = map_3((n) => {
                if (n.noteId === noteId_5) {
                    return new VisualizationNoteViewModel(n.studyId, n.noteId, n.content, n.x, n.y, !n.isHidden);
                }
                else {
                    return n;
                }
            }, model.Notes);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, updatedNotes_1), updatedNotes_1, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

