import { logDebug } from '../js/Common';

import { useRef } from 'react';
import { useCustomCompareEffect } from '../js/UseCustomCompareEffect';
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor";
import { equals } from "fable-compiler/bin/fable-library/Util.js";

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.CenterlineMeasurement", isDebugging, msg);
}

function planePipelinesEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => equals(val.centerlinePlane, b[index].centerlinePlane));
}

function compareDependencies(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        (a.length === 0 ||
            planePipelinesEqual(a[0], b[0]));
}

/**
 * @param container
 * @param parentContext
 * @param initialized
 * @param {{centerlinePlane: {centerlineType: Union, branchSelection: Union, index: num, callbackIdentifier: string, planeIdentifier: string}, centerlinePlaneSource: {rootFilter: any, planeIndexFilter: any}}[]} planePipelines
 */
export function useCenterlineMeasurement(
    container,
    parentContext,
    { planePipelines }
) {
    const context = useRef(null);

    function removePreviousActors(previousActors, renderer) {
        for (let i = 0; i < previousActors.length; i++) {
            let actor = previousActors[i].actor;
            let mapper = previousActors[i].mapper;

            if (renderer) {
                renderer.removeActor(actor);
            }
            actor.delete();
            mapper.delete();
        }
    }

    useCustomCompareEffect(() => {
        if (container && parentContext) {

            if (!context.current) {
                // Initialize
                log("Initializing centerline measurement");

                let previousActors = [];

                context.current =
                    { previousActors };

            }

            const { renderer, renderWindow } = parentContext;
            const { previousActors } = context.current;

            let updatedPreviousActors = [];

            if (planePipelines) {
                removePreviousActors(previousActors, renderer);

                for (let i = 0; i < planePipelines.length; i++) {
                    let source = planePipelines[i].centerlinePlaneSource.planeIndexFilter;

                    let port = source.getOutputPort();
                    let planeMapper = vtkMapper.newInstance();
                    let planeActor = vtkActor.newInstance();

                    planeMapper.setInputConnection(port);
                    planeActor.setMapper(planeMapper);

                    planeActor.getProperty().setEdgeVisibility(true);
                    planeActor.getProperty().setColor(0.2, 0.2, 0.7);
                    planeActor.getProperty().setOpacity(0.6);
                    planeActor.getProperty().setLighting(false);
                    planeActor.getProperty().setEdgeColor(0.5, 0.5, 1);

                    updatedPreviousActors.push({ actor: planeActor, mapper: planeMapper });
                    renderer.addActor(planeActor);
                }

                renderWindow.render();
            }

            context.current.previousActors = updatedPreviousActors;
        }

        return () => {
            // Dispose

            if (context.current) {
                const { previousActors } = context.current;

                let renderer;
                if (container && parentContext) {
                    renderer = parentContext.renderer;
                }

                removePreviousActors(previousActors, renderer);

            }
        };
    }, [planePipelines], compareDependencies);
}