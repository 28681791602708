import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { array_type, tuple_type, float64_type, record_type, bool_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class ActiveMeasurementState extends Record {
    constructor(measurementId, caliperState, pickable) {
        super();
        this.measurementId = measurementId;
        this.caliperState = caliperState;
        this.pickable = pickable;
    }
}

export function ActiveMeasurementState$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Bindings.ActiveMeasurementState", [], ActiveMeasurementState, () => [["measurementId", class_type("System.Guid")], ["caliperState", class_type("RAWMap.Client.Visualization.Calipers.Bindings.ICalipersState")], ["pickable", bool_type]]);
}

export class CalipersStateArgs extends Record {
    constructor(measurementId, firstPoint, secondPoint, isComplete) {
        super();
        this.measurementId = measurementId;
        this.firstPoint = firstPoint;
        this.secondPoint = secondPoint;
        this.isComplete = isComplete;
    }
}

export function CalipersStateArgs$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Bindings.CalipersStateArgs", [], CalipersStateArgs, () => [["measurementId", class_type("System.Guid")], ["firstPoint", tuple_type(float64_type, float64_type, float64_type)], ["secondPoint", tuple_type(float64_type, float64_type, float64_type)], ["isComplete", bool_type]]);
}

export function CalipersStateArgs_init_244AC511(mid) {
    return new CalipersStateArgs(mid, [0, 0, 0], [0, 0, 0], false);
}

export class CalipersProps extends Record {
    constructor(activeMeasurementStates) {
        super();
        this.activeMeasurementStates = activeMeasurementStates;
    }
}

export function CalipersProps$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Bindings.CalipersProps", [], CalipersProps, () => [["activeMeasurementStates", array_type(ActiveMeasurementState$reflection())]]);
}

