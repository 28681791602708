import { toArray, map, ofArray, sort } from "../../fable_modules/fable-library.3.7.20/List.js";
import { comparePrimitives } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { bool_type, lambda_type, tuple_type, list_type, record_type, option_type, float64_type, string_type, class_type, union_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { FSharpResult$2, FSharpChoice$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";
import { Visualization_LabelPositionPercentage$reflection } from "../../RAWMap.Models/Common.js";
import { MeasurementUpdatedOutbound$2$reflection, CoreMeasurementValues_get_defaultPosition } from "../MeasurementsView.js";
import { CalipersMeasurementViewModel$reflection } from "../../RAWMap.Models/View/CalipersMeasurement.js";
import { ErrorMessage$reflection } from "../../RAWMap.Models/ErrorMessage.js";
import { CustomReportStatus$reflection } from "../../RAWMap.Models/View/CustomReport.js";
import { CalipersProps, ActiveMeasurementState } from "./CalipersBindings.js";
import { InteractionMode$reflection } from "../Common/CommonBindings.js";

export const PredefinedLabels_predefinedLabels = sort(ofArray(["Neck Length", "Aneurysm Length", "Left Iliac Length", "Right Iliac Length", "Neck Diameter Proximal", "Neck Diameter Distal", "Maximum Aneurysm Diameter", "Left Iliac Proximal Diameter", "Left Iliac Distal Diameter", "Right Iliac Proximal Diameter", "Right Iliac Distal Diameter", "Aortic Bifurcation Diameter"]), {
    Compare: comparePrimitives,
});

export class Mode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Creating", "Editing"];
    }
}

export function Mode$reflection() {
    return union_type("RAWMap.Client.Visualization.Calipers.Types.Mode", [], Mode, () => [[], [], []]);
}

export function Mode_get_isActive() {
    return (_arg) => ((_arg.tag === 1) ? true : (_arg.tag === 2));
}

export function ModeModule_$007CActive$007CInactive$007C(mode) {
    if (Mode_get_isActive()(mode)) {
        return new FSharpChoice$2(0, void 0);
    }
    else {
        return new FSharpChoice$2(1, void 0);
    }
}

export class CalipersMeasurement extends Record {
    constructor(State, Label, Distance, LabelPosition) {
        super();
        this.State = State;
        this.Label = Label;
        this.Distance = Distance;
        this.LabelPosition = LabelPosition;
    }
}

export function CalipersMeasurement$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Types.CalipersMeasurement", [], CalipersMeasurement, () => [["State", class_type("RAWMap.Client.Visualization.Calipers.Bindings.ICalipersState")], ["Label", string_type], ["Distance", option_type(float64_type)], ["LabelPosition", Visualization_LabelPositionPercentage$reflection()]]);
}

export function CalipersMeasurement_get_label_() {
    return [(m) => m.Label, (v) => ((m_1) => (new CalipersMeasurement(m_1.State, v, m_1.Distance, m_1.LabelPosition)))];
}

export function CalipersMeasurement_init(label, state) {
    return new CalipersMeasurement(state, label, void 0, CoreMeasurementValues_get_defaultPosition());
}

export function CalipersMeasurement_MeasurementId_11348511(cm) {
    return cm.State.getMeasurementId();
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.Calipers.Types.InboundMsg", void 0, InboundMsg);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "SaveActiveCalipersMeasurement", "CancelActiveCalipersMeasurement", "CancelDeletingCalipersMeasurement", "AddCalipersMeasurement", "ShowCalipersMeasurement", "StopShowingCalipersMeasurement", "EditLocalCalipersMeasurement", "GetCalipersMeasurements", "GetCalipersMeasurementsResult", "CreateCalipersMeasurementResult", "EditCalipersMeasurementResult", "RequestDeleteCalipersMeasurement", "ConfirmDeleteCalipersMeasurement", "ActiveVmSetter", "StartEditingLabel", "StopEditingLabel", "DeleteCalipersMeasurementResult", "GotDistanceMeasurement"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.Calipers.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [], [], [], [["measurementId", class_type("System.Guid")]], [], [["Item1", Mode$reflection()], ["measurementId", class_type("System.Guid")]], [["measurementIdToSelect", option_type(class_type("System.Guid"))]], [["measurementIdToSelect", option_type(class_type("System.Guid"))], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(CalipersMeasurementViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(CalipersMeasurementViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(CustomReportStatus$reflection(), CalipersMeasurementViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(CustomReportStatus$reflection(), CalipersMeasurementViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [CustomReportStatus$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", CustomReportStatus$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["measurementId", class_type("System.Guid")]], [["measurementId", class_type("System.Guid")]], [["Item", lambda_type(CalipersMeasurement$reflection(), CalipersMeasurement$reflection())]], [], [["Item", Visualization_LabelPositionPercentage$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(CustomReportStatus$reflection(), class_type("System.Guid")), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(CustomReportStatus$reflection(), class_type("System.Guid"))]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item1", class_type("System.Guid")], ["Item2", float64_type]]]);
}

export class CalipersUpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function CalipersUpdateArgs$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Types.CalipersUpdateArgs", [], CalipersUpdateArgs, () => [["token", string_type]]);
}

export class ModelCalipers extends Record {
    constructor(SelectedStudyId, Mode, ActiveMeasurements, IsSaving, Measurements, IsEditingLabels) {
        super();
        this.SelectedStudyId = SelectedStudyId;
        this.Mode = Mode;
        this.ActiveMeasurements = ActiveMeasurements;
        this.IsSaving = IsSaving;
        this.Measurements = Measurements;
        this.IsEditingLabels = IsEditingLabels;
    }
}

export function ModelCalipers$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Types.ModelCalipers", [], ModelCalipers, () => [["SelectedStudyId", class_type("System.Guid")], ["Mode", Mode$reflection()], ["ActiveMeasurements", list_type(CalipersMeasurement$reflection())], ["IsSaving", bool_type], ["Measurements", list_type(CalipersMeasurementViewModel$reflection())], ["IsEditingLabels", bool_type]]);
}

export function ModelCalipers_ActiveMeasurementStates_69A04C54(mc) {
    return map((m) => m.State, mc.ActiveMeasurements);
}

export function ModelCalipers_Props_69A04C54(mc) {
    return new CalipersProps(toArray(map((activeMeasurement) => (new ActiveMeasurementState(activeMeasurement.getMeasurementId(), activeMeasurement, Mode_get_isActive()(mc.Mode))), ModelCalipers_ActiveMeasurementStates_69A04C54(mc))));
}

export class CalipersMeasurementArgs extends Record {
    constructor(model, interactionMode) {
        super();
        this.model = model;
        this.interactionMode = interactionMode;
    }
}

export function CalipersMeasurementArgs$reflection() {
    return record_type("RAWMap.Client.Visualization.Calipers.Types.CalipersMeasurementArgs", [], CalipersMeasurementArgs, () => [["model", ModelCalipers$reflection()], ["interactionMode", InteractionMode$reflection()]]);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "MeasurementEntriesUpdated", "SetInteractionModeOutbound"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.Calipers.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus$reflection()]], [["Item", MeasurementUpdatedOutbound$2$reflection(CalipersMeasurementArgs$reflection(), LocalMsg$reflection())]], [["Item", InteractionMode$reflection()]]]);
}

