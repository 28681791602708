import * as react from "react";
import { printf, toText, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.20/String.js";
import { Option, container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { Urls_Page, Urls_AuthPage, Urls_pageHash, InputElement_ref, Forms_onEnterKeyPress } from "../Common/General.js";
import { Msg } from "./AuthTypes.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { ofArray, cons, empty, singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { Option as Option_4, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { Option as Option_1, div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_2, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Common_EmailAddress_value_Z39D6D3C1, Security_LoginViewModel } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { Option as Option_3, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Option as Option_5, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { Option as Option_6, content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";

export const supportedDeviceDisclaimer = (() => {
    let children_14, children_12, children_8, children_6;
    const children_16 = ["⚠️ Warning", (children_14 = ["The functionality of the ViTAA RAW Maps web application has been verified and validated in the following environments:", (children_12 = [(children_8 = ["PC - Windows 10 64-bit", (children_6 = [react.createElement("li", {}, "Google Chrome V114.0 64-bit"), react.createElement("li", {}, "Mozilla Firefox 115.0 64-bit"), react.createElement("li", {}, "Microsoft Edge V114.0 64-bit")], react.createElement("ul", {}, ...children_6))], react.createElement("li", {}, ...children_8)), react.createElement("li", {}, "Memory: 8 GB RAM")], react.createElement("ul", {}, ...children_12)), "ViTAA does not recommend the use of other browsers/devices/operating systems when interacting with the RAW Maps web application."], react.createElement("div", {}, ...children_14))];
    return react.createElement("div", {}, ...children_16);
})();

export function view(model, dispatch) {
    const isDisabled = isNullOrWhiteSpace(model.loginVm.email) ? true : isNullOrWhiteSpace(model.loginVm.password);
    return container(singleton(new Option(3, singleton(new DOMAttr(17, (e) => {
        Forms_onEnterKeyPress(isDisabled, () => {
            dispatch(new Msg(4));
        }, e);
    })))), toList(delay(() => {
        let children_4, props_2, children, arg_1;
        const matchValue = model.userInfo;
        if (matchValue == null) {
            return singleton_1((children_4 = [div(empty(), ofArray([label(empty(), singleton("Email")), div_1(singleton(new Option_1(1)), ofArray([input(cons(new Option_2(1, new IInputType(8)), toList(delay(() => append(singleton_1(new Option_2(3, "email")), delay(() => append(empty_1(), delay(() => append(singleton_1(new Option_2(14, (e_1) => {
                InputElement_ref(model.loginVm.email, e_1);
            })), delay(() => singleton_1(new Option_2(13, (e_2) => {
                dispatch(new Msg(2, (lvm) => (new Security_LoginViewModel(e_2.currentTarget.value, lvm.password))));
            })))))))))))), icon(ofArray([new Option_3(0, new Size_ISize(0)), new Option_3(1)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-envelope")), [])))]))])), div(empty(), ofArray([label(empty(), singleton("Password")), div_1(singleton(new Option_1(1)), ofArray([input(ofArray([new Option_2(1, new IInputType(1)), new Option_2(3, "password"), new Option_2(14, (e_3) => {
                InputElement_ref(model.loginVm.password, e_3);
            }), new Option_2(13, (e_4) => {
                dispatch(new Msg(2, (lvm_1) => (new Security_LoginViewModel(lvm_1.email, e_4.currentTarget.value))));
            })])), icon(ofArray([new Option_3(0, new Size_ISize(0)), new Option_3(1)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-lock")), [])))]))])), div(singleton(new Option_4(4)), singleton(div_1(empty(), singleton(button(ofArray([new Option_5(17, singleton(new HTMLAttr(99, "login"))), new Option_5(16, isDisabled), new Option_5(0, new Color_IColor(4)), new Option_5(18, (_arg_2) => {
                dispatch(new Msg(4));
            })]), singleton("Login")))))), (props_2 = [new HTMLAttr(94, Urls_pageHash(new Urls_Page(0, new Urls_AuthPage(2))))], react.createElement("a", keyValueList(props_2, 1), "Forgot password")), content(singleton(new Option_6(2, singleton(["style", {
                marginTop: "3rem",
            }]))), singleton(supportedDeviceDisclaimer))], react.createElement("div", {}, ...children_4)));
        }
        else {
            const userInfo = matchValue;
            return singleton_1((children = [h3(empty())(singleton((arg_1 = Common_EmailAddress_value_Z39D6D3C1(userInfo.email), toText(printf("Logged in as %s"))(arg_1)))), button(ofArray([new Option_5(0, new Color_IColor(4)), new Option_5(18, (_arg_1) => {
                dispatch(new Msg(6));
            })]), singleton("Log out"))], react.createElement("div", {}, ...children)));
        }
    })));
}

