import { Union } from "fable-compiler/bin/fable-library/Types";

export const CenterlineDistanceBranchSelection = {
    LEFT: 'Left',
    RIGHT: 'Right',
    CUSP: 'Cusp'
};

export const CenterlineTransitionBranchSelection = {
    LEFT: 'Left',
    RIGHT: 'Right'
};

export const CenterlineBranchType = {
    DISTANCE: 'UnifiedDistanceBranch',
    TRANSITION: 'UnifiedTransitionBranch'
};

/** @typedef {{ centerlineBranchSelection: string, centerlineBranchType: string }} BranchSelection */

export const CenterlineBranchSelection = {
    /**
     * Converts F# BranchSelection DU instance to BranchSelection.
     * @param branchSelection {Union}
     * @return {BranchSelection}
     */
    ofBranchSelection: (branchSelection) => {
        return {
            centerlineBranchType: branchSelection.name,
            centerlineBranchSelection: branchSelection.fields[0].name
        };
    },
    /**
     * Determines if two instances of BranchSelection are equal.
     * @param a {BranchSelection}
     * @param b {BranchSelection}
     * @return {boolean}
     */
    areEqual: (a, b) => {
        return a.centerlineBranchType === b.centerlineBranchType &&
            a.centerlineBranchSelection === b.centerlineBranchSelection;
    },
    /**
     * Determines if the specified BranchSelection is valid.
     * @param branchSelection {BranchSelection}
     * @return {boolean}
     */
    isValid: (branchSelection) => {
        return [
            CenterlineBranchSelection.DISTANCE_LEFT,
            CenterlineBranchSelection.DISTANCE_RIGHT,
            CenterlineBranchSelection.DISTANCE_CUSP,
            CenterlineBranchSelection.TRANSITION_LEFT,
            CenterlineBranchSelection.TRANSITION_RIGHT
        ].some((value) => {
            return CenterlineBranchSelection.areEqual(value, branchSelection);
        });
    },
    DISTANCE_LEFT: {
        centerlineBranchType: CenterlineBranchType.DISTANCE,
        centerlineBranchSelection: CenterlineDistanceBranchSelection.LEFT
    },
    DISTANCE_RIGHT: {
        centerlineBranchType: CenterlineBranchType.DISTANCE,
        centerlineBranchSelection: CenterlineDistanceBranchSelection.RIGHT
    },
    DISTANCE_CUSP: {
        centerlineBranchType: CenterlineBranchType.DISTANCE,
        centerlineBranchSelection: CenterlineDistanceBranchSelection.CUSP
    },
    TRANSITION_LEFT: {
        centerlineBranchType: CenterlineBranchType.TRANSITION,
        centerlineBranchSelection: CenterlineTransitionBranchSelection.LEFT
    },
    TRANSITION_RIGHT: {
        centerlineBranchType: CenterlineBranchType.TRANSITION,
        centerlineBranchSelection: CenterlineTransitionBranchSelection.RIGHT
    }
};

export const CenterlineType = {
    LUMEN: 'Lumen',
    WALL: 'Wall',
};

export const AdditionalArrayNames = {
    LAST_INDEX_OF_TRANSITION_BRANCH_TRUNK: 'LastIndexOfTransitionBranchTrunk'
};