import { isArrayLike, equals, createAtom } from "../../../fable_modules/fable-library.3.7.20/Util.js";
import { printf, toText } from "../../../fable_modules/fable-library.3.7.20/String.js";
import { SaveReportArgs, OutboundMsg, Operation, LocalMsg, Operation_display_Z518F1114, ModelReportPrompt, Mode as Mode_1 } from "./ReportPromptTypes.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { PatientCreationViewModel_get_empty, HospitalPatientIdViewModel_get_institutionId_, PatientCreationViewModel_get_hospitalPatientIdVm_, PatientCreationViewModel_get_id_ } from "../../../RAWMap.Models/View/Patient.js";
import { Common_ReportType } from "../../../RAWMap.Models/Common.js";
import { GeneratedReportType } from "../../../RAWMap.Models/View/UniqueDeviceIdentifier.js";
import { Cmd_batch, Cmd_OfPromise_either, Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.20/Choice.js";
import { ErrorMessage, ErrorMessage_get_describe, Terminate_TerminateError } from "../../../RAWMap.Models/ErrorMessage.js";
import { value as value_4, some } from "../../../fable_modules/fable-library.3.7.20/Option.js";
import { Toast_successToast, Toast_errorToast } from "../../../Common/General.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../../../Api.js";
import { S3_DeleteObjectRequestParams_get_create, S3_DeleteObjectRequestParams, S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create, S3_GetObjectRequestParams_get_create, S3_GetObjectRequestParams } from "../../../bindings/Fable.Helpers.Aws.js";
import { AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { AsyncResult_ofPromise } from "../../../Common/Extensions.js";
import { PDFDocument } from "pdf-lib";
import { makeAndPrependTitlePage as makeAndPrependTitlePage_1, mkReportSections } from "../ReportPdf/Sections/MakeReportSections.js";
import { makeReportArgs } from "./ReportGeneration.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library.3.7.20/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { utcNow } from "../../../fable_modules/fable-library.3.7.20/DateOffset.js";
import { CustomReportMetadataViewModel } from "../../../RAWMap.Models/View/CustomReport.js";
import { TelemetryAction, FileOperationContext } from "../../../RAWMap.Models/Api.js";
import { TitlePageArgsModule_TitlePageHelperArgs, TitlePageArgsModule_mkTitlePageArgs } from "../ReportPdf/Sections/TitlePageSection.js";
import { UniversalArgs } from "../ReportPdf/Sections/SharedSection.js";
import { update as update_1 } from "../../../Common/InboundOutbound.js";

export let pdfDocument = createAtom(void 0);

export function makeUploadDestinationPath(patientId, studyId) {
    return toText(printf("%O/%O/Reports/LastGeneratedReport/report.pdf"))(patientId)(studyId);
}

export function init(initArgs) {
    let l;
    return [new ModelReportPrompt(initArgs.selectedStudy, initArgs.studyAnalysis, initArgs.maybePreviousStudy, initArgs.promptType, new Mode_1(0), initArgs.initUserRoles, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), PatientCreationViewModel_get_id_())(initArgs.selectedPatient.id)(Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = PatientCreationViewModel_get_hospitalPatientIdVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), HospitalPatientIdViewModel_get_institutionId_())(l)))(initArgs.patientInstitution.id)(PatientCreationViewModel_get_empty())), new Common_ReportType(1), new GeneratedReportType(1)), Cmd_none()];
}

export function updateInbound(_args, _msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

function setMode(model, mode) {
    return new ModelReportPrompt(model.SelectedStudy, model.StudyAnalysis, model.MaybePreviousStudy, model.PromptType, mode, model.UserRoles, model.PatientCreationInfo, model.ReportTypeToGenerate, model.GeneratedReportType);
}

export function tryTerminate(model) {
    const matchValue = model.Mode;
    if (matchValue.tag === 0) {
        return new FSharpResult$2(0, () => {
        });
    }
    else {
        return new FSharpResult$2(1, new Terminate_TerminateError(2, Operation_display_Z518F1114(matchValue.fields[0])));
    }
}

export function showError(model, message) {
    console.error(some(message));
    return [setMode(model, new Mode_1(0)), Toast_errorToast(message), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    let arg_3, arg_20, arg_25, arg_39, arg_44, arg_50, arg_55;
    const model_1 = (!equals(model.UserRoles, args.userRoles)) ? (new ModelReportPrompt(model.SelectedStudy, model.StudyAnalysis, model.MaybePreviousStudy, model.PromptType, model.Mode, args.userRoles, model.PatientCreationInfo, model.ReportTypeToGenerate, model.GeneratedReportType)) : model;
    const matchValue = [msg, model_1];
    let pattern_matching_result, ex, setter, setter_1, e, path, saveArgs, e_1, pdfDoc, e_2, pdfDoc_1, meta, saveReportArgs, e_3, meta_1, saveReportArgs_1, reportStatus, saveReportArgs_2, e_4, saveReportArgs_3, saveReportArgs_4, e_5, path_2, saveReportArgs_6, e_6;
    if (matchValue[0].tag === 1) {
        pattern_matching_result = 1;
        setter = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 2) {
        pattern_matching_result = 2;
        setter_1 = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 4) {
        pattern_matching_result = 3;
    }
    else if (matchValue[0].tag === 5) {
        pattern_matching_result = 4;
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[0].fields[0].tag === 0) {
            if (matchValue[0].fields[0].fields[0]) {
                if (matchValue[1].PromptType.tag === 0) {
                    pattern_matching_result = 12;
                }
                else if (matchValue[1].PromptType.tag === 2) {
                    pattern_matching_result = 32;
                }
                else {
                    pattern_matching_result = 7;
                    path = matchValue[1].PromptType.fields[0];
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 5;
            e = matchValue[0].fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].PromptType.tag === 1) {
            pattern_matching_result = 7;
            path = matchValue[1].PromptType.fields[0];
        }
        else {
            pattern_matching_result = 8;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 10;
            e_1 = matchValue[0].fields[0].fields[0];
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 0) {
                pattern_matching_result = 9;
                saveArgs = matchValue[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].PromptType.tag === 0) {
            pattern_matching_result = 12;
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 9) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 15;
            e_2 = matchValue[0].fields[0].fields[0];
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 1) {
                pattern_matching_result = 14;
                pdfDoc = matchValue[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 16;
            }
        }
        else {
            pattern_matching_result = 16;
        }
    }
    else if (matchValue[0].tag === 10) {
        if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 1) {
                pattern_matching_result = 17;
                pdfDoc_1 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 18;
            }
        }
        else {
            pattern_matching_result = 18;
        }
    }
    else if (matchValue[0].tag === 11) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 21;
            e_3 = matchValue[0].fields[0].fields[0];
        }
        else if (matchValue[0].fields[0].fields[0].metadata != null) {
            if (matchValue[1].Mode.tag === 1) {
                if (matchValue[1].Mode.fields[0].tag === 2) {
                    pattern_matching_result = 19;
                    meta = matchValue[0].fields[0].fields[0].metadata;
                    saveReportArgs = matchValue[0].fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 22;
                }
            }
            else {
                pattern_matching_result = 22;
            }
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 2) {
                pattern_matching_result = 20;
            }
            else {
                pattern_matching_result = 22;
            }
        }
        else {
            pattern_matching_result = 22;
        }
    }
    else if (matchValue[0].tag === 12) {
        if (matchValue[0].fields[0].metadata != null) {
            if (matchValue[1].Mode.tag === 1) {
                if (matchValue[1].Mode.fields[0].tag === 2) {
                    pattern_matching_result = 23;
                    meta_1 = matchValue[0].fields[0].metadata;
                    saveReportArgs_1 = matchValue[0].fields[0];
                }
                else {
                    pattern_matching_result = 24;
                }
            }
            else {
                pattern_matching_result = 24;
            }
        }
        else {
            pattern_matching_result = 24;
        }
    }
    else if (matchValue[0].tag === 13) {
        if (matchValue[0].fields[1].tag === 1) {
            pattern_matching_result = 26;
            e_4 = matchValue[0].fields[1].fields[0];
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 3) {
                pattern_matching_result = 25;
                reportStatus = matchValue[0].fields[1].fields[0];
                saveReportArgs_2 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 27;
            }
        }
        else {
            pattern_matching_result = 27;
        }
    }
    else if (matchValue[0].tag === 14) {
        pattern_matching_result = 28;
        saveReportArgs_3 = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 15) {
        if (matchValue[0].fields[1].tag === 1) {
            pattern_matching_result = 30;
            e_5 = matchValue[0].fields[1].fields[0];
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 5) {
                pattern_matching_result = 29;
                saveReportArgs_4 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 31;
            }
        }
        else {
            pattern_matching_result = 31;
        }
    }
    else if (matchValue[0].tag === 16) {
        if (matchValue[1].PromptType.tag === 2) {
            pattern_matching_result = 33;
            path_2 = matchValue[1].PromptType.fields[0];
        }
        else {
            pattern_matching_result = 34;
        }
    }
    else if (matchValue[0].tag === 17) {
        if (matchValue[0].fields[1].tag === 1) {
            pattern_matching_result = 36;
            e_6 = matchValue[0].fields[1].fields[0];
        }
        else if (matchValue[1].Mode.tag === 1) {
            if (matchValue[1].Mode.fields[0].tag === 6) {
                pattern_matching_result = 35;
                saveReportArgs_6 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 37;
            }
        }
        else {
            pattern_matching_result = 37;
        }
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue[0].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const model_2 = setMode(model_1, new Mode_1(0));
            console.error(some("ReportPrompt exception:"), ex);
            return [model_2, Cmd_none(), Cmd_none()];
        }
        case 1: {
            return [new ModelReportPrompt(model_1.SelectedStudy, model_1.StudyAnalysis, model_1.MaybePreviousStudy, model_1.PromptType, model_1.Mode, model_1.UserRoles, setter(model_1.PatientCreationInfo), model_1.ReportTypeToGenerate, model_1.GeneratedReportType), Cmd_none(), Cmd_none()];
        }
        case 2: {
            return [setter_1(model_1), Cmd_none(), Cmd_none()];
        }
        case 3: {
            const matchValue_1 = [model_1.ReportTypeToGenerate, model_1.PromptType];
            let pattern_matching_result_1;
            if (matchValue_1[0] != null) {
                if (matchValue_1[0].tag === 0) {
                    pattern_matching_result_1 = 2;
                }
                else if (matchValue_1[1].tag === 0) {
                    pattern_matching_result_1 = 1;
                }
                else if (matchValue_1[1].tag === 2) {
                    pattern_matching_result_1 = 3;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (matchValue_1[1].tag === 0) {
                pattern_matching_result_1 = 1;
            }
            else if (matchValue_1[1].tag === 2) {
                pattern_matching_result_1 = 3;
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [model_1, Cmd_OfFunc_result(new LocalMsg(6)), Cmd_none()];
                }
                case 1: {
                    return [model_1, Cmd_OfFunc_result(new LocalMsg(8)), Cmd_none()];
                }
                case 2: {
                    return [new ModelReportPrompt(model_1.SelectedStudy, model_1.StudyAnalysis, model_1.MaybePreviousStudy, model_1.PromptType, new Mode_1(1, new Operation(4)), model_1.UserRoles, model_1.PatientCreationInfo, model_1.ReportTypeToGenerate, model_1.GeneratedReportType), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(args.fileArgs.Token).verifyPatientInfo, model_1.PatientCreationInfo, (arg) => (new LocalMsg(3, arg)), (arg_1) => (new LocalMsg(0, arg_1))), Cmd_none()];
                }
                case 3: {
                    return [model_1, Cmd_OfFunc_result(new LocalMsg(16)), Cmd_none()];
                }
            }
        }
        case 4: {
            return [model_1, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0))];
        }
        case 5: {
            return showError(model_1, (arg_3 = ErrorMessage_get_describe()(e), toText(printf("Error: %s"))(arg_3)));
        }
        case 6: {
            return showError(model_1, "Patient info invalid");
        }
        case 7: {
            return [setMode(model_1, new Mode_1(1, new Operation(0))), Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, () => {
                let downloadPromise;
                const getParams = new S3_GetObjectRequestParams(args.fileArgs.Context.ActiveBucket, path);
                let download;
                const arg_4 = S3_GetObjectRequestParams_get_create()(getParams);
                download = args.fileArgs.Context.S3Client.getObject(arg_4);
                downloadPromise = download.promise();
                return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg_5) => (new ErrorMessage(1, arg_5)), AsyncResult_ofPromise()(downloadPromise)), (_arg) => {
                    let buffer, arg_6;
                    const matchValue_2 = _arg.Body;
                    return (matchValue_2 == null) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult(new FSharpResult$2(1, new ErrorMessage(35, "get request body empty")))) : (isArrayLike(value_4(matchValue_2)) ? ((buffer = value_4(matchValue_2), AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg_7) => (new ErrorMessage(1, arg_7)), AsyncResult_ofPromise()((arg_6 = (buffer.buffer), PDFDocument.load(arg_6)))), (_arg_1) => {
                        pdfDocument(_arg_1, true);
                        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, new SaveReportArgs(void 0));
                    }))) : AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult(new FSharpResult$2(1, new ErrorMessage(35, "unexpected return type")))));
                }));
            }, void 0, (arg_12) => (new LocalMsg(7, arg_12)), (arg_17) => (new LocalMsg(7, new FSharpResult$2(1, new ErrorMessage(1, arg_17))))), Cmd_none()];
        }
        case 8: {
            return showError(model_1, "Error when downloading report: incorrect prompt type");
        }
        case 9: {
            return [model_1, Cmd_OfFunc_result(new LocalMsg(14, saveArgs)), Cmd_none()];
        }
        case 10: {
            return showError(model_1, (arg_20 = ErrorMessage_get_describe()(e_1), toText(printf("Error when downloading report: %s"))(arg_20)));
        }
        case 11: {
            return showError(model_1, "Error when downloading report: incorrect mode");
        }
        case 12: {
            const makeReportCommand = Cmd_OfAsyncWith_either((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, mkReportSections, makeReportArgs(args, model_1), (arg_21) => (new LocalMsg(9, arg_21)), (arg_22) => (new LocalMsg(0, arg_22)));
            return [setMode(model_1, new Mode_1(1, new Operation(1))), makeReportCommand, Cmd_none()];
        }
        case 13: {
            return showError(model_1, "Error when generating report: incorrect prompt type");
        }
        case 14: {
            return [model_1, Cmd_OfFunc_result(new LocalMsg(10, pdfDoc)), Cmd_none()];
        }
        case 15: {
            return showError(model_1, (arg_25 = ErrorMessage_get_describe()(e_2), toText(printf("Error when generating report: %s"))(arg_25)));
        }
        case 16: {
            return showError(model_1, "Error when generating report: incorrect mode");
        }
        case 17: {
            const uploadedFilePath = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            const blob = pdfDoc_1.output('blob');
            let upload;
            const arg_26 = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, args.fileArgs.Context.ActiveBucket), new S3_PutObjectRequestParams(3, uploadedFilePath), new S3_PutObjectRequestParams(1, blob)]));
            upload = args.fileArgs.Context.S3Client.upload(arg_26);
            return [setMode(model_1, new Mode_1(1, new Operation(2))), Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let pr;
                return ((pr = upload.promise(), pr.then((value) => {
                }))).then(() => (PDFDocument.load(pdfDoc_1.output('arraybuffer')).then((_arg_3) => {
                    pdfDocument(_arg_3, true);
                    return Promise.resolve(new SaveReportArgs(new CustomReportMetadataViewModel(model_1.SelectedStudy.studyId, uploadedFilePath, utcNow(), false)));
                })));
            })), void 0, (arg_30) => (new LocalMsg(11, new FSharpResult$2(0, arg_30))), (arg_35) => (new LocalMsg(11, new FSharpResult$2(1, new ErrorMessage(1, arg_35))))), Cmd_none()];
        }
        case 18: {
            return showError(model_1, "Error when uploading report: incorrect mode");
        }
        case 19: {
            const filePath = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            return [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(12, saveReportArgs)), Cmd_OfAsyncWith_attempt((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, securedApi(args.fileArgs.Token).telemetry, new TelemetryAction(0, new FileOperationContext(6, model_1.SelectedStudy.studyId), singleton(filePath)), (arg_37) => (new LocalMsg(0, arg_37)))])), Cmd_none()];
        }
        case 20: {
            return showError(model_1, "Error when uploading report: missing metadata");
        }
        case 21: {
            return showError(model_1, (arg_39 = ErrorMessage_get_describe()(e_3), toText(printf("Error when uploading report: %s"))(arg_39)));
        }
        case 22: {
            return showError(model_1, "Error when uploading report: incorrect mode");
        }
        case 23: {
            return [setMode(model_1, new Mode_1(1, new Operation(3))), Cmd_OfAsyncWith_either((x_8) => {
                Cmd_OfAsync_start(x_8);
            }, securedApi(args.fileArgs.Token).editOrCreateCustomReportMetadata, meta_1, (r) => (new LocalMsg(13, saveReportArgs_1, r)), (arg_40) => (new LocalMsg(0, arg_40))), Cmd_none()];
        }
        case 24: {
            return showError(model_1, "Error when updating report metadata: incorrect mode");
        }
        case 25: {
            return [model_1, Cmd_OfFunc_result(new LocalMsg(14, saveReportArgs_2)), Cmd_OfFunc_result(new OutboundMsg(1, reportStatus))];
        }
        case 26: {
            return showError(model_1, (arg_44 = ErrorMessage_get_describe()(e_4), toText(printf("Error when updating report metadata: %s"))(arg_44)));
        }
        case 27: {
            return showError(model_1, "Error when updating report metadata: incorrect mode");
        }
        case 28: {
            const titlePageArgs = TitlePageArgsModule_mkTitlePageArgs(new TitlePageArgsModule_TitlePageHelperArgs(new UniversalArgs(args.fileArgs.Token, args.fileArgs.Context), model_1.SelectedStudy, args.fileArgs, model_1.ReportTypeToGenerate, model_1.PatientCreationInfo, model_1.GeneratedReportType));
            return [setMode(model_1, new Mode_1(1, new Operation(5))), Cmd_OfAsyncWith_either((x_9) => {
                Cmd_OfAsync_start(x_9);
            }, () => {
                if (pdfDocument() == null) {
                    return AsyncResult_ofError(new ErrorMessage(1, new Error("Error when saving report: Report is None")));
                }
                else {
                    return makeAndPrependTitlePage_1("report.pdf", pdfDocument(), titlePageArgs);
                }
            }, void 0, (r_1) => (new LocalMsg(15, saveReportArgs_3, r_1)), (arg_46) => (new LocalMsg(0, arg_46))), Cmd_none()];
        }
        case 29: {
            let toast;
            const matchValue_3 = model_1.PromptType;
            switch (matchValue_3.tag) {
                case 0: {
                    toast = Toast_successToast("Report created successfully");
                    break;
                }
                case 2: {
                    toast = Cmd_none();
                    break;
                }
                default: {
                    toast = Toast_successToast("Report downloaded successfully");
                }
            }
            const filePath_1 = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            return [setMode(model_1, new Mode_1(0)), Cmd_batch(ofArray([toast, Cmd_OfAsyncWith_attempt((x_10) => {
                Cmd_OfAsync_start(x_10);
            }, securedApi(args.fileArgs.Token).telemetry, new TelemetryAction(1, new FileOperationContext(6, model_1.SelectedStudy.studyId), singleton(filePath_1)), (arg_48) => (new LocalMsg(0, arg_48)))])), Cmd_OfFunc_result(new OutboundMsg(2, saveReportArgs_4.metadata))];
        }
        case 30: {
            return showError(model_1, (arg_50 = ErrorMessage_get_describe()(e_5), toText(printf("Error when saving report: %s"))(arg_50)));
        }
        case 31: {
            return showError(model_1, "Error when saving report: incorrect mode");
        }
        case 32: {
            return showError(model_1, "Error when verifying patient info: incorrect prompt type");
        }
        case 33: {
            const clearedMetadata = new CustomReportMetadataViewModel(model_1.SelectedStudy.studyId, void 0, void 0, void 0);
            const saveReportArgs_5 = new SaveReportArgs(clearedMetadata);
            const deleteParams = new S3_DeleteObjectRequestParams(args.fileArgs.Context.ActiveBucket, path_2);
            let delete$;
            const arg_52 = S3_DeleteObjectRequestParams_get_create()(deleteParams);
            delete$ = args.fileArgs.Context.S3Client.deleteObject(arg_52);
            delete$.promise();
            return [setMode(model_1, new Mode_1(1, new Operation(6))), Cmd_OfAsyncWith_either((x_11) => {
                Cmd_OfAsync_start(x_11);
            }, securedApi(args.fileArgs.Token).editOrCreateCustomReportMetadata, clearedMetadata, (r_2) => (new LocalMsg(17, saveReportArgs_5, r_2)), (arg_53) => (new LocalMsg(0, arg_53))), Cmd_none()];
        }
        case 34: {
            return showError(model_1, "Error when deleting report: incorrect prompt type");
        }
        case 35: {
            return [setMode(model_1, new Mode_1(0)), Toast_successToast("Report deleted successfully"), Cmd_OfFunc_result(new OutboundMsg(2, saveReportArgs_6.metadata))];
        }
        case 36: {
            return showError(model_1, (arg_55 = ErrorMessage_get_describe()(e_6), toText(printf("Error when deleting report: %s"))(arg_55)));
        }
        case 37: {
            return showError(model_1, "Error when deleting report: incorrect mode");
        }
    }
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

