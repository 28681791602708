import { Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { CustomReportViewModel$reflection } from "../../../../RAWMap.Models/View/CustomReport.js";
import { float64_type, record_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Table_Adv_makeTitle, Pdf_Spacing_baseSpacingY, Pdf_Spacing_narrowSpacingY, Pdf_Spacing_topMargin, Pdf_Spacing_leftMargin } from "../ReportPdfHelpers.js";
import { isNullOrWhiteSpace } from "../../../../fable_modules/fable-library.3.7.20/String.js";

export class Args extends Record {
    constructor(additionalData) {
        super();
        this.additionalData = additionalData;
    }
}

export function Args$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ConclusionSection.Args", [], Args, () => [["additionalData", CustomReportViewModel$reflection()]]);
}

export class AddToDocArgs extends Record {
    constructor(maxPageHeight, maxPageWidth, restartY, lineHeight) {
        super();
        this.maxPageHeight = maxPageHeight;
        this.maxPageWidth = maxPageWidth;
        this.restartY = restartY;
        this.lineHeight = lineHeight;
    }
}

export function AddToDocArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ConclusionSection.AddToDocArgs", [], AddToDocArgs, () => [["maxPageHeight", float64_type], ["maxPageWidth", float64_type], ["restartY", float64_type], ["lineHeight", float64_type]]);
}

export function addConclusionText(docArgs, i, splitText, currentY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let currentY_1;
        if ((currentY + docArgs.lineHeight) > docArgs.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs.restartY;
        }
        else {
            currentY_1 = currentY;
        }
        const doc_1 = doc.text(splitText[i], Pdf_Spacing_leftMargin, currentY_1);
        const i_1 = (i + 1) | 0;
        const currentY_2 = currentY_1 + docArgs.lineHeight;
        return (i_1 < splitText.length) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addConclusionText(docArgs, i_1, splitText, currentY_2, doc_1)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [currentY_2, doc_1]);
    });
}

export function create(doc, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const matchValue = args.additionalData.maybeConclusion;
        let pattern_matching_result, conc_1;
        if (matchValue != null) {
            if (!isNullOrWhiteSpace(matchValue)) {
                pattern_matching_result = 0;
                conc_1 = matchValue;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const previousFont = doc.getFontSize();
                const doc_1 = doc.setFontSize(12);
                const pageSize = doc_1.internal.pageSize;
                const pageHeight = pageSize.getHeight();
                const maxWidth = pageSize.getWidth() - (2 * Pdf_Spacing_leftMargin);
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addConclusionText(new AddToDocArgs(pageHeight - Pdf_Spacing_topMargin, maxWidth, Pdf_Spacing_topMargin, Pdf_Spacing_narrowSpacingY), 0, doc_1.splitTextToSize(conc_1, maxWidth), Pdf_Spacing_topMargin + Pdf_Spacing_baseSpacingY, Pdf_Table_Adv_makeTitle("Assessment", Pdf_Spacing_topMargin, doc_1.addPage())), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg[1].setFontSize(previousFont)));
            }
            case 1: {
                return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc);
            }
        }
    });
}

