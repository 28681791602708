import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { ofArray, singleton as singleton_1, empty, ofSeq, map } from "../../../fable_modules/fable-library.3.7.20/List.js";
import { FileData_createFromFile } from "../../../Common/FileData.js";
import { FileList_toSeq } from "../../FileManagementTypes.js";
import { LocalMsg } from "./DragDropTypes.js";
import { icon, cta, input, Label_label } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/File.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../../../fable_modules/fable-library.3.7.20/Seq.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { join } from "../../../fable_modules/fable-library.3.7.20/String.js";
import { FileType_asExtensions_DC44A4C } from "../../Shared.js";
import { value } from "../../../fable_modules/fable-library.3.7.20/Option.js";
import { Common_GenericOption } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Fa_IconOption, Fa_i } from "../../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.20/MapUtil.js";

export function uploadControl(dispatch, maybeFileTypeRestrictions) {
    let props_2;
    const uploadChanged = (changed) => {
        dispatch(wrapLocalMsg(new LocalMsg(3, map((arg_2) => FileData_createFromFile(void 0, arg_2), ofSeq(FileList_toSeq(changed))))));
    };
    let child;
    const maybeFileTypeRestrictions_1 = maybeFileTypeRestrictions;
    child = Label_label(empty(), ofArray([input(singleton_1(new Common_GenericOption(1, toList(delay(() => append(singleton(new HTMLAttr(121, true)), delay(() => append(singleton(new DOMAttr(9, (e_2) => {
        uploadChanged(e_2.target.files);
        e_2.target.value = "";
    })), delay(() => ((maybeFileTypeRestrictions_1 != null) ? singleton(new HTMLAttr(2, join(",", FileType_asExtensions_DC44A4C(value(maybeFileTypeRestrictions_1))))) : empty_1())))))))))), cta(empty(), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-cloud-upload-alt")), []))), (props_2 = [new HTMLAttr(63, "file-label"), ["style", {
        whiteSpace: "normal",
        textAlign: "center",
    }]], react.createElement("span", keyValueList(props_2, 1), "Drag and drop or click here to select a file"))]))]));
    return react.createElement("div", {
        onDragOver: (e) => {
            e.stopPropagation();
            e.preventDefault();
        },
        onDrop: (e_1) => {
            e_1.stopPropagation();
            e_1.preventDefault();
            uploadChanged(e_1.dataTransfer.files);
        },
    }, child);
}

