import { Union } from "../../fable-library.3.7.20/Types.js";
import { Common_GenericOptions__AddProp_7BFEDA81, Color_ofColor, Common_parseOptions, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection } from "../Common.fs.js";
import { lambda_type, unit_type, bool_type, union_type, string_type, list_type, class_type } from "../../fable-library.3.7.20/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.20/MapUtil.js";
import { DOMAttr } from "../../Fable.React.7.4.3/Fable.React.Props.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Color", "has-shadow", "is-transparent", "is-fixed-top", "is-fixed-bottom", "is-spaced", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Navbar.Option", [], Option, () => [[["Item", Color_IColor$reflection()]], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Menu_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "Modifiers"];
    }
}

export function Menu_Option$reflection() {
    return union_type("Fulma.Navbar.Menu.Option", [], Menu_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Burger_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "OnClick", "Props", "CustomClass", "Modifiers"];
    }
}

export function Burger_Option$reflection() {
    return union_type("Fulma.Navbar.Burger.Option", [], Burger_Option, () => [[["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Item_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-tab", "is-active", "is-hoverable", "has-dropdown", "is-expanded", "Props", "CustomClass", "Modifiers"];
    }
}

export function Item_Option$reflection() {
    return union_type("Fulma.Navbar.Item.Option", [], Item_Option, () => [[], [["Item", bool_type]], [], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function Item_item(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 4:
            case 0:
            case 2:
            case 3: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 5: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 6: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "navbar-item"), element, children);
}

export function Item_div(x, y) {
    return Item_item((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function Item_a(x, y) {
    return Item_item((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export class Link_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "is-arrowless", "Props", "CustomClass", "Modifiers"];
    }
}

export function Link_Option$reflection() {
    return union_type("Fulma.Navbar.Link.Option", [], Link_Option, () => [[["Item", bool_type]], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function Link_link(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "navbar-link"), element, children);
}

export function Link_div(x, y) {
    return Link_link((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function Link_a(x, y) {
    return Link_link((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export class Dropdown_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "is-boxed", "is-right", "Props", "CustomClass", "Modifiers"];
    }
}

export function Dropdown_Option$reflection() {
    return union_type("Fulma.Navbar.Dropdown.Option", [], Dropdown_Option, () => [[["Item", bool_type]], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function Dropdown_dropdown(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 5: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "navbar-dropdown"), element, children);
}

export function Dropdown_div(x, y) {
    return Dropdown_dropdown((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function Dropdown_a(x, y) {
    return Dropdown_dropdown((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export function Brand_brand(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "navbar-brand"), element, children);
}

export function Brand_div(x, y) {
    return Brand_brand((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function Brand_a(x, y) {
    return Brand_brand((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export function Start_start(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "navbar-start"), element, children);
}

export function Start_div(x, y) {
    return Start_start((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function Start_a(x, y) {
    return Start_start((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export function End_end(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "navbar-end"), element, children);
}

export function End_div(x, y) {
    return End_end((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function End_a(x, y) {
    return End_end((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

export function navbar(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 3:
            case 4:
            case 2:
            case 5: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
            case 6: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "navbar"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

export function menu(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "navbar-menu"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export function burger(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, option.fields[0]));
            }
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "navbar-burger"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export function content(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "navbar-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function divider(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "navbar-divider"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

