import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, option_type, bool_type, union_type, int32_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";
import { AnatomyOrientationProps } from "./AnatomyOrientationBindings.js";

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "GetAnatomyOrientationData", "GotAnatomyOrientationData", "ToggleAnatomyOrientationMarker"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("Fable.Core.JS.ArrayBuffer"), int32_type], FSharpResult$2, () => [[["ResultValue", class_type("Fable.Core.JS.ArrayBuffer")]], [["ErrorValue", int32_type]]])]], [["Item", bool_type]]]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.InboundMsg", void 0, InboundMsg);
}

export class OutboundMsg {
    constructor() {
    }
}

export function OutboundMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.OutboundMsg", void 0, OutboundMsg);
}

export class ModelAnatomyOrientation extends Record {
    constructor(MaybeAnatomyOrientationData, ShowAnatomyOrientation) {
        super();
        this.MaybeAnatomyOrientationData = MaybeAnatomyOrientationData;
        this.ShowAnatomyOrientation = ShowAnatomyOrientation;
    }
}

export function ModelAnatomyOrientation$reflection() {
    return record_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.ModelAnatomyOrientation", [], ModelAnatomyOrientation, () => [["MaybeAnatomyOrientationData", option_type(class_type("Fable.Core.JS.ArrayBuffer"))], ["ShowAnatomyOrientation", bool_type]]);
}

export function ModelAnatomyOrientation_Props_Z550008EC(model) {
    return new AnatomyOrientationProps(model.MaybeAnatomyOrientationData, model.ShowAnatomyOrientation);
}

