import { wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { uncurry, equals } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { LocalMsg, Mode } from "./NotesTypes.js";
import { CardMode } from "../Common/CommonTypes.js";
import { deleteConfirmation, mkToolReactButton } from "../Common/CommonView.js";
import { Size_ISize, Common_GenericOption, Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { button, Option } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { content, Header_icon, Header_title, header, card } from "../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { map, ofArray, singleton, empty } from "../../fable_modules/fable-library.3.7.20/List.js";
import { TableOption, table } from "../../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { singleton as singleton_1, delay, toList } from "../../fable_modules/fable-library.3.7.20/Seq.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library.3.7.20/MapUtil.js";
import { Option as Option_1, modal as modal_1, Card_foot, Card_body, Card_title, Card_head, Card_card, background } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { FormField_onChangeStringOpticTrimmed, FormField_text } from "../../Common/Forms.js";
import { VisualizationNoteViewModel_isValid_45C878EE, VisualizationNoteViewModel_get_label_ } from "../../RAWMap.Models/View/VisualizationNote.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { String_toOption_ } from "../../Common/Extensions.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

function mkDispatchLocal(dispatch) {
    return (arg) => dispatch(wrapLocalMsg(arg));
}

export function notesCard(model, cardMode, dispatch) {
    let children_10, children_8;
    let moveTool;
    const isDisabled = (equals(model.Mode, new Mode(1)) ? true : model.IsSaving) ? true : (!equals(cardMode, new CardMode(0)));
    moveTool = ((model.Mode.tag === 2) ? mkToolReactButton(dispatch, [new Option(0, new Color_IColor(4))], new Fa_IconOption(11, "fas fa-compress"), new LocalMsg(11), model.IsSaving, model.IsSaving, "Save and exit move mode") : mkToolReactButton(dispatch, [new Option(0, new Color_IColor(2))], new Fa_IconOption(11, "fas fa-arrows-alt"), new LocalMsg(14), model.IsSaving, isDisabled, "Enter move mode"));
    const createButton = mkToolReactButton(dispatch, [new Option(0, new Color_IColor(2))], new Fa_IconOption(11, "fas fa-plus-circle"), new LocalMsg(15), model.IsSaving, (!equals(model.Mode, new Mode(0))) ? true : (!equals(cardMode, new CardMode(0))), "Add custom note");
    return singleton(card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Notes")), Header_icon(singleton(new Common_GenericOption(1, singleton(["style", {
        color: "inherit ",
    }]))), ofArray([moveTool, createButton]))])), content(empty(), singleton((children_10 = [table(ofArray([new TableOption(1), new TableOption(2), new TableOption(4), new TableOption(6, singleton(["style", {
        tableLayout: "fixed",
    }]))]), singleton((children_8 = toList(delay(() => map((note) => {
        let props_4, children_4, children_2;
        const isEditDeleteDisabled = (!equals(model.Mode, new Mode(0))) ? true : (!equals(cardMode, new CardMode(0)));
        const isVisibilityToggleDisabled = (!equals(model.Mode, new Mode(0))) ? true : equals(cardMode, new CardMode(2));
        const buttonStyles = ofArray([new Option(1, new Size_ISize(0)), new Option(17, singleton(["style", {
            border: "none",
            background: "transparent",
        }]))]);
        const patternInput = note.isHidden ? ["Show note", new Fa_IconOption(11, "fas fa-eye")] : ["Hide note", new Fa_IconOption(11, "fas fa-eye-slash")];
        const children_6 = [react.createElement("td", {
            className: "side-panel-text",
        }, note.content), (props_4 = [["style", {
            width: "108px",
            whiteSpace: "nowrap",
        }]], (children_4 = [(children_2 = [mkToolReactButton(dispatch, buttonStyles, patternInput[1], new LocalMsg(16, note.noteId), false, isVisibilityToggleDisabled, patternInput[0]), mkToolReactButton(dispatch, buttonStyles, new Fa_IconOption(11, "fas fa-edit"), new LocalMsg(3, note), false, isEditDeleteDisabled, "Edit note"), mkToolReactButton(dispatch, buttonStyles, new Fa_IconOption(11, "fas fa-trash"), new LocalMsg(8, note), false, isEditDeleteDisabled, "Delete note")], react.createElement("div", {}, ...children_2))], react.createElement("td", keyValueList(props_4, 1), ...children_4)))];
        return react.createElement("tr", {}, ...children_6);
    }, model.Notes))), react.createElement("tbody", {}, ...children_8))))], react.createElement("div", {}, ...children_10))))])));
}

function editNoteView(action, vm, createEditFieldsValid, isSaving, dispatch) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    return ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(toText(printf("%s Note"))(action))))), Card_body(empty(), toList(delay(() => {
        let tupledArg, l;
        return singleton_1(FormField_text(vm.content, empty(), [], (tupledArg = ((l = VisualizationNoteViewModel_get_label_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_toOption_)(l))), FormField_onChangeStringOpticTrimmed((arg_2) => {
            dispatchLocal(new LocalMsg(4, arg_2));
        }, tupledArg[0], uncurry(2, tupledArg[1]))), "Content", []));
    }))), Card_foot(empty(), ofArray([button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, (!createEditFieldsValid) ? true : isSaving), new Option(13, isSaving), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatchLocal(new LocalMsg(5));
    }), new HTMLAttr(159, "button")]))]), singleton("Save")), button(ofArray([new Option(0, new Color_IColor(2)), new Option(16, isSaving), new Option(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatchLocal(new LocalMsg(13));
    }), new HTMLAttr(159, "button")]))]), singleton("Cancel"))]))]))]);
}

export function notesModal(model, dispatch) {
    const showNoteModal = (elements) => {
        let _arg;
        return modal_1(singleton(new Option_1(1, (_arg = model.Mode, (_arg.tag === 3) ? true : ((_arg.tag === 4) ? true : (_arg.tag === 5))))), elements);
    };
    const showNoteEditor = (action, noteVm) => showNoteModal(editNoteView(action, noteVm, VisualizationNoteViewModel_isValid_45C878EE(noteVm), model.IsSaving, dispatch));
    const matchValue = model.Mode;
    switch (matchValue.tag) {
        case 3: {
            return showNoteEditor("Create", matchValue.fields[0]);
        }
        case 4: {
            return showNoteEditor("Edit", matchValue.fields[0]);
        }
        case 5: {
            const noteVm_3 = matchValue.fields[0];
            return showNoteModal(deleteConfirmation("Note", noteVm_3.content, new LocalMsg(9, noteVm_3), model.IsSaving, new LocalMsg(13), dispatch));
        }
        default: {
            return Helpers_nothing;
        }
    }
}

