import { OutboundMsg, LocalMsg, AdditionalDataModel } from "./AdditionalDataTypes.js";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { some, bind } from "../../../fable_modules/fable-library.3.7.20/Option.js";
import { isNullOrWhiteSpace } from "../../../fable_modules/fable-library.3.7.20/String.js";
import { CustomReportConclusionViewModel } from "../../../RAWMap.Models/View/CustomReport.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../../../Api.js";
import { Toast_errorToast } from "../../../Common/General.js";
import { ErrorMessage_get_describe } from "../../../RAWMap.Models/ErrorMessage.js";
import { ofArray } from "../../../fable_modules/fable-library.3.7.20/List.js";
import { update as update_1 } from "../../../Common/InboundOutbound.js";

export function init() {
    return [new AdditionalDataModel(void 0, false, false), Cmd_none()];
}

export function updateInbound(args, msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    let ad;
    if (msg.tag === 1) {
        return [(ad = msg.fields[0](model), new AdditionalDataModel(bind((s) => {
            if (isNullOrWhiteSpace(s)) {
                return void 0;
            }
            else {
                return s.trim();
            }
        }, ad.MaybeConclusionBuffer), ad.ShowConclusionModal, ad.IsSaving)), Cmd_none(), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new AdditionalDataModel(args.customReport.maybeConclusion, true, model.IsSaving), Cmd_none(), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new AdditionalDataModel(args.customReport.maybeConclusion, false, model.IsSaving), Cmd_none(), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const conclusion = new CustomReportConclusionViewModel(args.customReport.studyId, model.MaybeConclusionBuffer);
        return [new AdditionalDataModel(model.MaybeConclusionBuffer, model.ShowConclusionModal, true), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, securedApi(args.fileArgs.Token).editOrCreateCustomReportConclusion, conclusion, (r) => (new LocalMsg(5, conclusion, r)), (arg_1) => (new LocalMsg(0, arg_1))), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[1].tag === 1) {
            return [new AdditionalDataModel(model.MaybeConclusionBuffer, model.ShowConclusionModal, false), Toast_errorToast(ErrorMessage_get_describe()(msg.fields[1].fields[0])), Cmd_none()];
        }
        else {
            return [new AdditionalDataModel(model.MaybeConclusionBuffer, false, false), Cmd_none(), Cmd_batch(ofArray([Cmd_OfFunc_result(new OutboundMsg(1, msg.fields[0])), Cmd_OfFunc_result(new OutboundMsg(0, msg.fields[1].fields[0]))]))];
        }
    }
    else {
        console.error(some("CustomReport exception:"), msg.fields[0]);
        return [new AdditionalDataModel(model.MaybeConclusionBuffer, model.ShowConclusionModal, false), Toast_errorToast("An error has occured"), Cmd_none()];
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

