import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { bool_type, option_type, record_type, array_type, float64_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class SliceValues extends Record {
    constructor(index, maxIndex, bounds) {
        super();
        this.index = index;
        this.maxIndex = maxIndex;
        this.bounds = bounds;
    }
}

export function SliceValues$reflection() {
    return record_type("RAWMap.Client.Visualization.SlicePlane.Bindings.SliceValues", [], SliceValues, () => [["index", float64_type], ["maxIndex", float64_type], ["bounds", array_type(float64_type)]]);
}

export class SlicePlaneProps extends Record {
    constructor(bounds, isVisible) {
        super();
        this.bounds = bounds;
        this.isVisible = isVisible;
    }
}

export function SlicePlaneProps$reflection() {
    return record_type("RAWMap.Client.Visualization.SlicePlane.Bindings.SlicePlaneProps", [], SlicePlaneProps, () => [["bounds", option_type(array_type(float64_type))], ["isVisible", bool_type]]);
}

