import { update as update_2, tryTerminate as tryTerminate_1, init as init_2, numberOfAssociatedFiles as numberOfAssociatedFiles_1 } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipState.js";
import { InboundMsg as InboundMsg_1, FileManagementUpdateArgs, FileManagementViewModel_abortUpload_27E04A4D, Model_tryTerminate_Z379152CB, FileHandling, Model_getNumberOfAssociatedFiles_Z379152CB } from "../../../FileManagement/FileManagementTypes.js";
import { Common_ActiveBucketState_getBucketState_Z58682287 } from "../../../RAWMap.Models/Common.js";
import { update as update_1, init as init_1 } from "../../../FileManagement/FileManagementState.js";
import { OutboundMsg, LocalMsg, Model } from "./CoreFormFileTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { length, iterate, ofArray } from "../../../fable_modules/fable-library.3.7.20/List.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { toList } from "../../../fable_modules/fable-library.3.7.20/Map.js";
import { Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_3, wrapInboundMsg } from "../../../Common/InboundOutbound.js";
import { AutoZipFileSelectorUpdateArgs, InboundMsg } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { S3Context } from "../../../Common/AwsCommon.js";
import { some } from "../../../fable_modules/fable-library.3.7.20/Option.js";

export function numberOfAssociatedFiles(model) {
    return numberOfAssociatedFiles_1(Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload), model.autoZip);
}

export function getFileHandling(studyStatus) {
    const matchValue = Common_ActiveBucketState_getBucketState_Z58682287(studyStatus);
    if (matchValue.tag === 1) {
        return new FileHandling(0);
    }
    else {
        return new FileHandling(1);
    }
}

export function init(fileSpecs, fileOperationContext, studyStatus) {
    const patternInput = init_1(fileSpecs, fileOperationContext, getFileHandling(studyStatus));
    const patternInput_1 = init_2(fileSpecs);
    return [new Model(patternInput[0], patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((arg) => (new LocalMsg(2, arg)), patternInput[1]), Cmd_map((arg_1) => (new LocalMsg(3, arg_1)), patternInput_1[1])]))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Model_tryTerminate_Z379152CB(model.fileUpload), (_arg) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, tryTerminate_1(model.autoZip), (_arg_1) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
        _arg();
        _arg_1();
    })))));
}

export function forceTerminate(model) {
    iterate((arg_1) => {
        FileManagementViewModel_abortUpload_27E04A4D(arg_1[1]);
    }, toList(model.fileUpload.FileManagementsInProgress));
}

export function updateLocal(args, msg, model) {
    let inputRecord;
    const matchValue = [msg, model];
    if (matchValue[0].tag === 1) {
        return [model, Cmd_OfFunc_result(new LocalMsg(3, wrapInboundMsg(new InboundMsg(0)))), Cmd_none()];
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[0].fields[0].tag === 0) {
            const patternInput = update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (inputRecord = args.Context, new S3Context(inputRecord.S3Config, inputRecord.S3Client, inputRecord.S3Expiry, args.Context.ActiveBucket))), matchValue[0].fields[0].fields[0], model.fileUpload);
            return [new Model(patternInput[0], model.autoZip), Cmd_map((arg_2) => (new LocalMsg(2, arg_2)), patternInput[1]), Cmd_none()];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 0) {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0))];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 2) {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(2))];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 3) {
            return [model, Cmd_OfFunc_result(new LocalMsg(3, wrapInboundMsg(new InboundMsg(1)))), Cmd_none()];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 5) {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(5))];
        }
        else {
            return [model, Cmd_none(), Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[0].fields[0].tag === 0) {
            const patternInput_1 = update_2(new AutoZipFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload) + length(model.fileUpload.UploadSelection), args.Credentials, args.Context), matchValue[0].fields[0].fields[0], model.autoZip);
            return [new Model(model.fileUpload, patternInput_1[0]), Cmd_map((arg_5) => (new LocalMsg(3, arg_5)), patternInput_1[1]), Cmd_none()];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 0) {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(1))];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 3) {
            return [model, Cmd_OfFunc_result(new LocalMsg(1)), Cmd_OfFunc_result(new OutboundMsg(3))];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 2) {
            return [model, Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg_1(1, matchValue[0].fields[0].fields[0].fields[0])))), Cmd_none()];
        }
        else {
            return [model, Cmd_none(), Cmd_none()];
        }
    }
    else {
        console.error(some(matchValue[0].fields[0]));
        return [matchValue[1], Cmd_none(), Cmd_none()];
    }
}

export function updateInbound(_args, msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg_1(2, getFileHandling(msg.fields[0]))))), Cmd_none()];
    }
    else {
        return [model, Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg_1(0)))), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return update_3(updateLocal, updateInbound, args, msg, model);
}

