import { logDebug, isInitialized } from '../js/Common';

const isDebugging = false;

function log(msg) {
    logDebug("StaticLabelManager", isDebugging, msg);
}

function drawHeaderLabel(ctx, dims, label, fontInfo) {
    ctx.fillStyle = "#FFFF00";

    const height = 30;
    ctx.fillRect(0, 0, dims.width, height);

    ctx.fillStyle = '#000000';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `bold ${fontInfo.font}`;

    label.x = 0.5 * dims.width;
    label.y = 0.5 * height;
    log(`Drawing fixed label at (${label.x}, ${label.y}) / (${label.xPercentage}, ${label.yPercentage}) with content '${label.content}'`);
    ctx.fillText(label.content, label.x, label.y);
    
}

function mkStaticLabelManager(staticLabels, fontInfo, container, canvas, onStaticLabelDragEnd, wrapLines) {
    const ctx = canvas.getContext("2d");
    canvas.style.pointerEvents = "none";

    function drawStaticLabels(labels, canvas, dims) {
        const ctx = canvas.getContext("2d");
        const boundingRect = dims ?? canvas.getBoundingClientRect();

        labels.forEach(label => {
            if (label.isHidden) {
                return;
            }

            if (label.isHeader) {
                drawHeaderLabel(ctx, boundingRect, label, fontInfo);
                return;
            }
            
            let fontStyle = "";
            let fontWeight = "";
            if (canvas.id === "static_staticLabelCanvas") {
                fontStyle = "italic";
            }
            if (canvas.id === "measurement_staticLabelCanvas") {
                fontWeight = "bold";
            }
            ctx.font = `${fontStyle} ${fontWeight} ${fontInfo.font}`;
            ctx.fillStyle = "white";
            ctx.textAlign = 'left';
            ctx.textBaseline = 'bottom';

            let width = boundingRect.width;
            let height = boundingRect.height;

            // Bring coordinates out into local variables to avoid mutating the label object if we don't have to.
            // This could cause incorrect positioning if we're working with a virtual canvas.
            let lblX = label.x;
            let lblY = label.y;

            const isUninitialized = !isInitialized(label.x) || !isInitialized(label.y);

            // Recalculate label positions if dimensions are being overridden
            if (dims || isUninitialized === true) {
                lblX = label.xPercentage * width;
                lblY = label.yPercentage * height;

                // Update the label if dimensions haven't been calculated
                if (isUninitialized === true) {
                    label.x = lblX;
                    label.y = lblY;
                }
            }

            log(`Drawing label at (${lblX}, ${lblY}) / (${label.xPercentage}, ${label.yPercentage}) with content '${label.content}'`);

            let wrapped = wrapLines(label.content);

            // Handle multiline text.
            const lines = wrapped.split('\n');
            for (let i = 0; i < lines.length; i++) {
                const y = lblY + i * fontInfo.sizeInPixels;
                ctx.fillText(lines[i], lblX, y);
            }
        });
    }

    const redrawLabels = staticLabels => {
        const dims = container.getBoundingClientRect();
        if (ctx && dims) {
            log(`Redraw static labels ${dims.width}, ${dims.height}`);
            canvas.setAttribute('width', dims.width);
            canvas.setAttribute('height', dims.height);
            ctx.clearRect(0, 0, dims.width, dims.height);
            drawStaticLabels(staticLabels, canvas);
        }
    };

    const redrawLabelsForCanvas = (staticLabels, canvas, dims) => {
        const ctx = canvas.getContext("2d");
        if (ctx && dims && canvas) {
            log(`Redraw static labels for canvas ${canvas.id} - ${dims.width}, ${dims.height}`);
            canvas.setAttribute('width', dims.width);
            canvas.setAttribute('height', dims.height);
            ctx.clearRect(0, 0, dims.width, dims.height);

            drawStaticLabels(staticLabels, canvas, dims);
        }
    };

    redrawLabels(staticLabels);

    // Add width and height properties to staticLabels for drag and drop support
    const updateLabelDimensions = label => {
        let wrapped = wrapLines(label.content);
        const lines = wrapped.split('\n');
        let maxWidth = 0;

        for (let i = 0; i < lines.length; i++) {
            const measurement = ctx.measureText(lines[i]);
            if (measurement.width > maxWidth) {
                maxWidth = measurement.width;
            }
        }

        label.width = maxWidth;
        label.height = lines.length * fontInfo.sizeInPixels;
    };

    const updateLabelDimensionsForAll = labels => {
        for (const label of labels) {
            updateLabelDimensions(label);
        }
    };

    updateLabelDimensionsForAll(staticLabels);

    const getCanvasMouseCoordinates = e => {
        const boundingRect = canvas.getBoundingClientRect();
        const x = e.clientX - boundingRect.left;
        const y = e.clientY - boundingRect.top;
        return [x, y];
    };

    const getCanvasTouchCoordinates = e => {
        const boundingRect = canvas.getBoundingClientRect();
        const x = e.touches.item(0).clientX - boundingRect.left;
        const y = e.touches.item(0).clientY - boundingRect.top;
        return [x, y];
    };

    const onInteractionStart = (staticLabelManager, getCoords) => {
        return e => {
            log(`staticLabelManager.isMovingStaticLabels = ${staticLabelManager.isMovingStaticLabels}`);
            if (staticLabelManager.isMovingStaticLabels) {
                e.preventDefault();
                e.stopPropagation();
                const [interactionX, interactionY] = getCoords(e);
                log(`(x, y) = (${interactionX}, ${interactionY})`);
                staticLabelManager.interactionXLast = interactionX;
                staticLabelManager.interactionYLast = interactionY;

                for (const label of staticLabelManager.staticLabels) {
                    const isInteractionWithinLabelBounds =
                        !label.isHidden &&
                        interactionX >= label.x &&
                        interactionX <= label.x + label.width &&
                        interactionY >= label.y - fontInfo.sizeInPixels &&
                        interactionY <= label.y + label.height - fontInfo.sizeInPixels;

                    if (isInteractionWithinLabelBounds) {
                        log(`Selected label ${label.id} (${label.content})`);
                        staticLabelManager.selectedLabel = label;
                    }
                }
            } else {
                log("notMoving");
            }
        };
    };

    const onInteractionEnd = staticLabelManager => {
        return e => {
            if (staticLabelManager.isMovingStaticLabels && staticLabelManager.selectedLabel) {
                e.preventDefault();
                e.stopPropagation();
                const boundingRect = canvas.getBoundingClientRect();
                let width = boundingRect.width;
                let height = boundingRect.height;

                staticLabelManager.selectedLabel.xPercentage = staticLabelManager.selectedLabel.x / width;
                staticLabelManager.selectedLabel.yPercentage = staticLabelManager.selectedLabel.y / height;

                onStaticLabelDragEnd(staticLabelManager.selectedLabel);
                staticLabelManager.selectedLabel = null;
            }
        };
    };

    const onInteractionMove = (staticLabelManager, getCoords) => {
        return e => {
            if (staticLabelManager.isMovingStaticLabels && staticLabelManager.selectedLabel) {
                e.preventDefault();
                e.stopPropagation();
                const [interactionX, interactionY] = getCoords(e);
                const boundingRect = canvas.getBoundingClientRect();

                // Mouse interactions can't escape the bounds of the dom object,
                // for mouse this check is redundant and the "else" behaviour is handled in onmouseout.
                // Touch interactions CAN escape the bounds of the dom object
                // for touch this check as an equivalent to onmouseout
                if (
                    interactionX > 0 &&
                    interactionX < boundingRect.width &&
                    interactionY > 0 &&
                    interactionY < boundingRect.height
                ) {
                    const dx = interactionX - staticLabelManager.interactionXLast;
                    const dy = interactionY - staticLabelManager.interactionYLast;
                    staticLabelManager.interactionXLast = interactionX;
                    staticLabelManager.interactionYLast = interactionY;
                    staticLabelManager.selectedLabel.x += dx;
                    staticLabelManager.selectedLabel.y += dy;
                    staticLabelManager.updateLabels(staticLabelManager, staticLabelManager.staticLabels);
                } else {
                    onInteractionEnd(e);
                }
            }
        };
    };

    const configureInteractionEvents = staticLabelManager => {
        canvas.addEventListener("mousedown", onInteractionStart(staticLabelManager, getCanvasMouseCoordinates), { passive: false });
        canvas.addEventListener("mousemove", onInteractionMove(staticLabelManager, getCanvasMouseCoordinates), { passive: false });
        canvas.addEventListener("mouseup", onInteractionEnd(staticLabelManager), { passive: false });
        canvas.addEventListener("mouseout", onInteractionEnd(staticLabelManager), { passive: false });

        canvas.addEventListener("touchstart", onInteractionStart(staticLabelManager, getCanvasTouchCoordinates), { passive: false });
        canvas.addEventListener("touchmove", onInteractionMove(staticLabelManager, getCanvasTouchCoordinates), { passive: false });
        canvas.addEventListener("touchend", onInteractionEnd(staticLabelManager), { passive: false });
        canvas.addEventListener("touchcancel", onInteractionEnd(staticLabelManager), { passive: false });
    };

    const cleanupInteractionEvents = staticLabelManager => {
        canvas.removeEventListener("mousedown", onInteractionStart(staticLabelManager, getCanvasMouseCoordinates), { passive: false });
        canvas.removeEventListener("mousemove", onInteractionMove(staticLabelManager, getCanvasMouseCoordinates), { passive: false });
        canvas.removeEventListener("mouseup", onInteractionEnd(staticLabelManager), { passive: false });
        canvas.removeEventListener("mouseout", onInteractionEnd(staticLabelManager), { passive: false });

        canvas.removeEventListener("touchstart", onInteractionStart(staticLabelManager, getCanvasTouchCoordinates), { passive: false });
        canvas.removeEventListener("touchmove", onInteractionMove(staticLabelManager, getCanvasTouchCoordinates), { passive: false });
        canvas.removeEventListener("touchend", onInteractionEnd(staticLabelManager), { passive: false });
        canvas.removeEventListener("touchcancel", onInteractionEnd(staticLabelManager), { passive: false });
    };

    const staticLabelManager = {
        staticLabels,
        isMovingStaticLabels: false,
        mouseXLast: 0,
        mouseYLast: 0,
        selectedLabel: null,

        redrawLabels,
        configureInteractionEvents,
        cleanupInteractionEvents,
        redrawLabelsForCanvas,

        updateLabels(staticLabelManager, staticLabels) {
            log("staticLabelManager.updateLabels()");
            updateLabelDimensionsForAll(staticLabels); // OPTIMIZE: Consider optimizing so we don't recompute label dimensions if they haven't changed
            staticLabelManager.staticLabels = staticLabels;
            staticLabelManager.redrawLabels(staticLabels);
        },

        setIsMoving(staticLabelManager, isMoving) {
            if (isMoving) {
                canvas.style.pointerEvents = "auto";
            } else {

                canvas.style.pointerEvents = "none";
            }
            log("staticLabelManager.setIsMoving)");
            staticLabelManager.isMovingStaticLabels = isMoving;
        },
    };

    return staticLabelManager;
}

export { mkStaticLabelManager };

