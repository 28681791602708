import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { empty as empty_1, singleton as singleton_1, map, ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import * as react from "react";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { equals, partialApply } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Subdivisions_getSubdivisions, String_toOption, Common_SortInfo$1_getUpdatedSortInfo } from "../RAWMap.Models/Common.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { ComponentVisibility, Button_visibilityControlled } from "../Common/Controls.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { button, Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Mode, Msg } from "./InstitutionTypes.js";
import { InstitutionViewModel_get_empty, InstitutionViewModel_isValid_Z12B60C4C, InstitutionClinicalDesignation_get_describe, InstitutionClinicalDesignation_get_all, InstitutionViewModel, InstitutionViewModel_get_id_, InstitutionClinicalDesignation_get_describeOrDefault, InstitutionViewModel_get_clinicalDesignation_, InstitutionViewModel_get_country_, InstitutionViewModel_get_subdivision_, InstitutionViewModel_get_city_, InstitutionViewModel_get_name_ } from "../RAWMap.Models/View/Institution.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { Option as Option_2, modal, Card_foot, Card_body, Card_title, Card_head, Card_card, background } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { Controls_dropdownButton, Controls_buttonDropdownIcon, Forms_formFieldDate, Forms_formFieldReactSelect, Forms_formFieldText, Controls_itemWithBoldLabel } from "../Common/General.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.20/Option.js";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { view as view_1 } from "../ImageManager/ImageManagerView.js";
import { FileComponentVisibility_get_fileSystemUpload } from "../FileManagement/Shared.js";
import { ChildMsg_ofMsg } from "../Common/Terminate.js";
import { childAbsentStrategy } from "./InstitutionState.js";
import { Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";

export function itemListSortableButton(logoButtonVisibility, columns, viewItem, items, extraItem, sortInfo, onClick, dispatch) {
    let children_14, children_12, children_16;
    const children_18 = [table(ofArray([new TableOption(1), new TableOption(2), new TableOption(4)]), ofArray([(children_14 = [(children_12 = toList(delay(() => append(singleton(react.createElement("td", {}, "Manage Logo")), delay(() => map((tupledArg_1) => {
        const value_1 = tupledArg_1[0];
        const icon = (sortInfo.lastClicked === value_1) ? Fa_i(ofArray([(sortInfo.sortDirection.tag === 1) ? (new Fa_IconOption(11, "fas fa-chevron-down")) : (new Fa_IconOption(11, "fas fa-chevron-up")), new Fa_IconOption(14, singleton_1(["style", {
            paddingLeft: "10px",
        }]))]), []) : Helpers_nothing;
        const props_10 = [new HTMLAttr(64, "disable-select"), new DOMAttr(40, partialApply(1, onClick, [Common_SortInfo$1_getUpdatedSortInfo(value_1, tupledArg_1[1], sortInfo)])), ["style", {
            cursor: "pointer",
        }]];
        return react.createElement("td", keyValueList(props_10, 1), value_1, icon);
    }, columns))))), react.createElement("tr", {}, ...children_12))], react.createElement("thead", {}, ...children_14)), (children_16 = map((item) => {
        const props_6 = toList(delay(() => extraItem(item)));
        const children_6 = toList(delay(() => {
            let children_2;
            return append(singleton((children_2 = [Button_visibilityControlled(void 0, logoButtonVisibility, ofArray([new Option(1, new Size_ISize(0)), new Option(2), new Option(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg) => {
                dispatch(new Msg(21, item));
            })]))]), singleton_1(react.createElement("span", {}, "Manage Logo")))], react.createElement("td", {}, ...children_2))), delay(() => map((tupledArg) => {
                const props_4 = [new DOMAttr(40, partialApply(1, viewItem, [item])), ["style", {
                    cursor: "pointer",
                }]];
                const children_4 = [tupledArg[1](item)];
                return react.createElement("td", keyValueList(props_4, 1), ...children_4);
            }, columns)));
        }));
        return react.createElement("tr", keyValueList(props_6, 1), ...children_6);
    }, sortInfo.sortBy(items)), react.createElement("tbody", {}, ...children_16))]))];
    return react.createElement("div", {}, ...children_18);
}

export function institutionList(model, dispatch) {
    let optic, optic_2, optic_4, optic_6, f1, optic_8, f2, matchValue, user, user_1;
    const columns = ofArray([["Name", (optic = InstitutionViewModel_get_name_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))], ["City", (optic_2 = InstitutionViewModel_get_city_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target_2))], ["State/Province", (optic_4 = InstitutionViewModel_get_subdivision_(), (target_4) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_4))], ["Country", (optic_6 = InstitutionViewModel_get_country_(), (target_6) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_6)(target_6))], ["Clinical Designation", (f1 = ((optic_8 = InstitutionViewModel_get_clinicalDesignation_(), (target_8) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_8)(target_8))), (f2 = InstitutionClinicalDesignation_get_describeOrDefault(), (arg_6) => f2(f1(arg_6))))]]);
    return itemListSortableButton((matchValue = model.MaybeCurrentUser, (matchValue != null) ? (((user = matchValue, isAllowed(new Permission(9))(user.roles))) ? ((user_1 = matchValue, new ComponentVisibility(0))) : (new ComponentVisibility(2, void 0))) : (new ComponentVisibility(2, void 0))), columns, (item, _arg_1) => {
        dispatch(new Msg(7, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), InstitutionViewModel_get_id_())(item)));
    }, model.Institutions, (_arg_2) => empty(), model.SortInfo, (updatedInfo, _arg) => {
        dispatch(new Msg(18, updatedInfo));
    }, dispatch);
}

export function listView(model, dispatch) {
    return singleton_1(institutionList(model, dispatch));
}

export function detailView(vm, canEdit, dispatch) {
    return ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), singleton_1(Card_title(empty_1(), singleton_1(toText(printf("%s Details"))(vm.name))))), Card_body(empty_1(), singleton_1(content(empty_1(), ofArray([Controls_itemWithBoldLabel("Name: ", vm.name), Controls_itemWithBoldLabel("Address: ", vm.address), Controls_itemWithBoldLabel("City: ", vm.city), Controls_itemWithBoldLabel("Clinical Designation: ", InstitutionClinicalDesignation_get_describeOrDefault()(vm.clinicalDesignation)), Controls_itemWithBoldLabel("State/Province: ", vm.subdivision), Controls_itemWithBoldLabel("Country: ", vm.country)])))), Card_foot(empty_1(), ofArray([button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, !canEdit), new Option(17, singleton_1(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(9));
    })))]), singleton_1("Edit")), button(ofArray([new Option(0, new Color_IColor(2)), new Option(17, singleton_1(new DOMAttr(40, (_arg_1) => {
        dispatch(new Msg(19));
    })))]), singleton_1("Cancel"))]))]))]);
}

export function editView(mode, vm, createEditFieldsValid, dispatch) {
    const patternInput = (mode.tag === 2) ? ["Edit", new Msg(7, vm.id)] : ["Create", new Msg(19)];
    return ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), singleton_1(Card_title(empty_1(), singleton_1(toText(printf("%s Institution"))(patternInput[0]))))), Card_body(empty_1(), toList(delay(() => {
        let children;
        const setVmProp = (vmChange) => (new DOMAttr(9, (e) => {
            dispatch(new Msg(17, (svm) => vmChange(svm)(e.target.value)));
        }));
        const parseRoleValue = (mapping, value) => {
            dispatch(new Msg(17, (svm_1) => mapping(svm_1)(value)));
        };
        return singleton((children = [Forms_formFieldText("Name", vm.name, singleton_1(setVmProp((svm_2) => ((value_1) => (new InstitutionViewModel(svm_2.id, value_1, svm_2.address, svm_2.city, svm_2.country, svm_2.subdivision, svm_2.clinicalDesignation))))), false), Forms_formFieldText("Address", vm.address, singleton_1(setVmProp((svm_3) => ((value_2) => (new InstitutionViewModel(svm_3.id, svm_3.name, value_2, svm_3.city, svm_3.country, svm_3.subdivision, svm_3.clinicalDesignation))))), false), Forms_formFieldText("City", vm.city, singleton_1(setVmProp((svm_4) => ((value_3) => (new InstitutionViewModel(svm_4.id, svm_4.name, svm_4.address, value_3, svm_4.country, svm_4.subdivision, svm_4.clinicalDesignation))))), false), Forms_formFieldReactSelect([], "Clinical Designation", vm.clinicalDesignation, InstitutionClinicalDesignation_get_all(), InstitutionClinicalDesignation_get_describe(), partialApply(1, parseRoleValue, [(svm_5) => ((value_4) => (new InstitutionViewModel(svm_5.id, svm_5.name, svm_5.address, svm_5.city, svm_5.country, svm_5.subdivision, value_4)))])), Forms_formFieldReactSelect([], "Country", String_toOption(vm.country), ["Canada", "USA"], (value_5) => value_5, partialApply(1, parseRoleValue, [(svm_6) => ((value_6) => (new InstitutionViewModel(svm_6.id, svm_6.name, svm_6.address, svm_6.city, defaultArg(value_6, ""), "", svm_6.clinicalDesignation)))])), Forms_formFieldReactSelect([], "State/Province", String_toOption(vm.subdivision), Subdivisions_getSubdivisions(vm.country), (value_7) => value_7, partialApply(1, parseRoleValue, [(svm_7) => ((value_8) => (new InstitutionViewModel(svm_7.id, svm_7.name, svm_7.address, svm_7.city, svm_7.country, defaultArg(value_8, ""), svm_7.clinicalDesignation)))]))], react.createElement("form", {}, ...children)));
    }))), Card_foot(empty_1(), ofArray([button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, !createEditFieldsValid), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatch(new Msg(10));
    }), new HTMLAttr(159, "button")]))]), singleton_1("Save")), button(ofArray([new Option(0, new Color_IColor(2)), new Option(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatch(patternInput[1]);
    }), new HTMLAttr(159, "button")]))]), singleton_1("Cancel"))]))]))]);
}

export function downloadStatsView(downloadFieldsValid, model, dispatch) {
    let children;
    return ofArray([background(empty_1(), empty_1()), Card_card(empty_1(), ofArray([Card_head(empty_1(), singleton_1(Card_title(empty_1(), singleton_1("Download Institution Statistics")))), Card_body(empty_1(), singleton_1((children = [Forms_formFieldDate("Start Date", model.StatsDateStart, singleton_1(new DOMAttr(9, (e) => {
        dispatch(new Msg(13, e.currentTarget.value));
    }))), Forms_formFieldDate("End Date", model.StatsDateEnd, singleton_1(new DOMAttr(9, (e_1) => {
        dispatch(new Msg(14, e_1.currentTarget.value));
    })))], react.createElement("form", {}, ...children)))), Card_foot(empty_1(), ofArray([button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, !downloadFieldsValid), new Option(18, (_arg) => {
        dispatch(new Msg(4));
    })]), singleton_1("Download CSV")), button(ofArray([new Option(0, new Color_IColor(2)), new Option(18, (_arg_1) => {
        dispatch(new Msg(19));
    })]), singleton_1("Cancel"))]))]))]);
}

export function imageManagerView(_model, imageManagerModel, dispatch) {
    return card(empty_1(), ofArray([header(empty_1(), ofArray([Header_title(empty_1(), singleton_1("Logo Management")), Header_icon(empty_1(), singleton_1(button(ofArray([new Option(17, singleton_1(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(22));
    }))), new Option(0, new Color_IColor(8))]), singleton_1("Close"))))])), content_1(empty_1(), singleton_1(view_1(FileComponentVisibility_get_fileSystemUpload(), imageManagerModel, (arg_2) => {
        dispatch(new Msg(23, ChildMsg_ofMsg(childAbsentStrategy, arg_2)));
    })))]));
}

export function view(model, dispatch) {
    let children;
    let patternInput;
    const matchValue = model.MaybeCurrentUser;
    if (matchValue == null) {
        patternInput = [false, false];
    }
    else {
        const user = matchValue;
        patternInput = [isAllowed(new Permission(7))(user.roles), isAllowed(new Permission(8))(user.roles)];
    }
    const listView_1 = card(empty_1(), ofArray([header(empty_1(), ofArray([Header_title(empty_1(), singleton_1("Institutions")), Header_icon(empty_1(), singleton_1(Controls_buttonDropdownIcon(new Fa_IconOption(11, "fas fa-cog"))(empty_1())(singleton_1(new Option_1(1)))(singleton_1(new Controls_dropdownButton(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(11));
    }), "Download Institution Statistics "))))), Header_icon(empty_1(), singleton_1(button(ofArray([new Option(4), new Option(16, !patternInput[0]), new Option(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatch(new Msg(8));
    }), ["style", {
        backgroundColor: "#F0F0F0",
    }]]))]), singleton_1("Create Institution"))))])), content_1(empty_1(), singleton_1((children = listView(model, dispatch), react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
    const children_7 = toList(delay(() => append(singleton(listView_1), delay(() => {
        let children_5;
        return append(singleton((children_5 = toList(delay(() => {
            let children_2, children_3, children_4;
            const matchValue_1 = model.Mode;
            switch (matchValue_1.tag) {
                case 1:
                case 2: {
                    return singleton((children_2 = editView(model.Mode, model.EditInstitutionVm, InstitutionViewModel_isValid_Z12B60C4C(model.EditInstitutionVm), dispatch), modal(singleton_1(new Option_2(1, equals(model.Mode, new Mode(1)) ? true : equals(model.Mode, new Mode(2)))), children_2)));
                }
                case 3: {
                    return singleton((children_3 = detailView(defaultArg(model.SelectedInstitution, InstitutionViewModel_get_empty()), patternInput[1], dispatch), modal(singleton_1(new Option_2(1, equals(model.Mode, new Mode(3)))), children_3)));
                }
                case 4: {
                    return singleton((children_4 = downloadStatsView(model.isDownloadFieldsValid, model, dispatch), modal(singleton_1(new Option_2(1, equals(model.Mode, new Mode(4)))), children_4)));
                }
                default: {
                    return singleton(Helpers_nothing);
                }
            }
        })), react.createElement("div", {
            className: "block",
        }, ...children_5))), delay(() => {
            const matchValue_2 = model.ImageManagerComponent.child;
            return (matchValue_2.tag === 0) ? singleton(imageManagerView(model, matchValue_2.fields[0], dispatch)) : singleton(Helpers_nothing);
        }));
    }))));
    return react.createElement("div", {}, ...children_7);
}

