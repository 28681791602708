import { Model$1 } from "./WizardTypes.js";
import { length } from "../fable_modules/fable-library.3.7.20/List.js";

export function init(steps, describe) {
    return new Model$1(steps, describe, 0);
}

export function updateInbound(msg, model) {
    return new Model$1(model.steps, model.describeStep, (model.activeStepIndex < (length(model.steps) - 1)) ? (model.activeStepIndex + 1) : model.activeStepIndex);
}

