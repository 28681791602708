import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { union_type, list_type, record_type, option_type, class_type, string_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { S3Context$reflection } from "../Common/AwsCommon.js";
import { UserData$reflection, UserRole$reflection } from "../RAWMap.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { UniqueDeviceIdentifierViewModel$reflection } from "../RAWMap.Models/View/UniqueDeviceIdentifier.js";
import { Common_SortInfo$1$reflection } from "../RAWMap.Models/Common.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../ImageManager/ImageManagerTypes.js";
import { ChildAbsentStrategy$1$reflection, DefaultActions_ChildModel$2$reflection } from "../Common/Terminate.js";
import { Notification$reflection } from "../RAWMap.Models/Api.js";
import { Msg$3$reflection } from "../Common/InboundOutbound.js";
import { ChainedChildMsg$2$reflection } from "../Common/ElmishDebugger/ChainedChildMsg.js";

export class UdiFileArgs extends Record {
    constructor(token, maybeCredentials, maybeS3Context) {
        super();
        this.token = token;
        this.maybeCredentials = maybeCredentials;
        this.maybeS3Context = maybeS3Context;
    }
}

export function UdiFileArgs$reflection() {
    return record_type("Client.UniqueDeviceIdentifier.Types.UdiFileArgs", [], UdiFileArgs, () => [["token", string_type], ["maybeCredentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["maybeS3Context", option_type(S3Context$reflection())]]);
}

export class UpdateArgs extends Record {
    constructor(fileArgs, token) {
        super();
        this.fileArgs = fileArgs;
        this.token = token;
    }
}

export function UpdateArgs$reflection() {
    return record_type("Client.UniqueDeviceIdentifier.Types.UpdateArgs", [], UpdateArgs, () => [["fileArgs", UdiFileArgs$reflection()], ["token", string_type]]);
}

export class Model extends Record {
    constructor(MaybeCurrentUser, Udis, SortInfo, ImageManagerComponent) {
        super();
        this.MaybeCurrentUser = MaybeCurrentUser;
        this.Udis = Udis;
        this.SortInfo = SortInfo;
        this.ImageManagerComponent = ImageManagerComponent;
    }
}

export function Model$reflection() {
    return record_type("Client.UniqueDeviceIdentifier.Types.Model", [], Model, () => [["MaybeCurrentUser", option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))], ["Udis", list_type(UniqueDeviceIdentifierViewModel$reflection())], ["SortInfo", Common_SortInfo$1$reflection(UniqueDeviceIdentifierViewModel$reflection())], ["ImageManagerComponent", DefaultActions_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "UpdateSortInfo", "CreateImageManagerComponent", "TerminateImageManagerComponent", "ImageManagerComponentMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.UniqueDeviceIdentifier.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())]], [["Item", Notification$reflection()]], [["Item", Common_SortInfo$1$reflection(UniqueDeviceIdentifierViewModel$reflection())]], [["Item", UniqueDeviceIdentifierViewModel$reflection()]], [], [["Item", ChainedChildMsg$2$reflection(Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]]]);
}

