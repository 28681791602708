import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkPixelSpaceCallbackMapper from 'vtk.js/Sources/Rendering/Core/PixelSpaceCallbackMapper';
import vtkPolyData from 'vtk.js/Sources/Common/DataModel/PolyData';
import vtkPoints from 'vtk.js/Sources/Common/Core/Points';

import { logDebug } from '../js/Common';

const isDebugging = false;

function log(msg) {
    logDebug("WorldLabelManager", isDebugging, msg);
}

function mkWorldLabelManager(worldLabels, fontInfo, container, textCanvas) {
    const mkPoints = worldLabels => {
        const points = vtkPoints.newInstance();
        points.setNumberOfPoints(worldLabels.length, 3);
        const initialCoordsList = [];

        for (let i = 0; i < worldLabels.length; i++) {
            const label = worldLabels[i];
            points.setPoint(i, label.x, label.y, label.z);
            initialCoordsList.push(points.getTuple(i));
        }

        return {
            points,
            initialCoordsList
        };
    };

    const { points, initialCoordsList } = mkPoints(worldLabels);

    const polyData = vtkPolyData.newInstance();
    polyData.setPoints(points);

    const psMapper = vtkPixelSpaceCallbackMapper.newInstance();
    psMapper.setInputData(polyData);

    const mkRedrawLabels = worldLabels => {
        const redrawLabels = (coordsList, camera, aspect, depthValues, size) => {
            const dims = container.getBoundingClientRect();
            const ctx = textCanvas.getContext('2d');
            if (ctx && dims) {
                log(`Redraw world labels ${dims.width}, ${dims.height}, ${coordsList}`);
                textCanvas.setAttribute('width', dims.width);
                textCanvas.setAttribute('height', dims.height);
                ctx.clearRect(0, 0, dims.width, dims.height);
                coordsList.forEach((coords, idx) => {
                    ctx.font = fontInfo.font;
                    ctx.fillStyle = "white";
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'middle';
                    // ASSUMPTION: coordsList has the same ordering as worldLabels
                    ctx.fillText(worldLabels[idx].content, coords[0], dims.height - coords[1]);
                });
            }
        };
        return redrawLabels;
    };

    const redrawLabels = mkRedrawLabels(worldLabels);
    redrawLabels(initialCoordsList);

    psMapper.setCallback(redrawLabels);

    const textActor = vtkActor.newInstance();
    textActor.setMapper(psMapper);

    const worldLabelManager = {
        points,
        polyData,
        psMapper,
        textActor,

        updateLabels(worldLabelManager, worldLabels) {
            log("worldLabelManager.updateLabels()");
            const oldPoints = worldLabelManager.points;
            const { points: newPoints, initialCoordsList } = mkPoints(worldLabels);
            const redrawLabels = mkRedrawLabels(worldLabels);

            worldLabelManager.polyData.setPoints(newPoints);
            oldPoints.delete();

            worldLabelManager.psMapper.setCallback(redrawLabels);
            redrawLabels(initialCoordsList); // TODO: Is this needed?
            worldLabelManager.points = newPoints;
        },

        dispose(labelManager) {
            labelManager.textActor.delete();
            labelManager.psMapper.delete();
            labelManager.polyData.delete();
            labelManager.points.delete();
        }
    };

    return worldLabelManager;
}

export { mkWorldLabelManager };