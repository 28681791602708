import { Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, string_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { S3Context$reflection } from "../../../../Common/AwsCommon.js";

export class UniversalArgs extends Record {
    constructor(token, s3Context) {
        super();
        this.token = token;
        this.s3Context = s3Context;
    }
}

export function UniversalArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.Shared.UniversalArgs", [], UniversalArgs, () => [["token", string_type], ["s3Context", S3Context$reflection()]]);
}

