import { Union, Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../FileManagementTypes.js";
import { ChildAbsentStrategy$1$reflection, DefaultActions_ChildModel$2$reflection } from "../../Common/Terminate.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_1, InboundMsg$reflection as InboundMsg$reflection_1, LocalMsg$reflection as LocalMsg$reflection_1, Model$reflection as Model$reflection_2 } from "../FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_2, InboundMsg$reflection as InboundMsg$reflection_2, LocalMsg$reflection as LocalMsg$reflection_2, Model$reflection as Model$reflection_3 } from "../FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { union_type, class_type, record_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Msg$3$reflection } from "../../Common/InboundOutbound.js";
import { ChainedChildMsg$2$reflection } from "../../Common/ElmishDebugger/ChainedChildMsg.js";

export class Model extends Record {
    constructor(fileUpload, dragDrop, autoZip) {
        super();
        this.fileUpload = fileUpload;
        this.dragDrop = dragDrop;
        this.autoZip = autoZip;
    }
}

export function Model$reflection() {
    return record_type("RAWMap.Client.FileManagementParent.Types.Model", [], Model, () => [["fileUpload", DefaultActions_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())], ["dragDrop", Model$reflection_2()], ["autoZip", DefaultActions_ChildModel$2$reflection(Model$reflection_3(), Model$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "ClearChildrenLists", "FileManagementMsg", "CreateFileManagement", "TerminateFileManagement", "DragDropMsg", "AutoZipMsg"];
    }
}

export function Msg$reflection() {
    return union_type("RAWMap.Client.FileManagementParent.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", ChainedChildMsg$2$reflection(Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]], [], [], [["Item", Msg$3$reflection(LocalMsg$reflection_1(), InboundMsg$reflection_1(), OutboundMsg$reflection_1())]], [["Item", ChainedChildMsg$2$reflection(Msg$3$reflection(LocalMsg$reflection_2(), InboundMsg$reflection_2(), OutboundMsg$reflection_2()), ChildAbsentStrategy$1$reflection(Model$reflection()))]]]);
}

