import macro from 'vtk.js/Sources/macros';
import vtkPlaneSource from "vtk.js/Sources/Filters/Sources/PlaneSource";
import { logDebug, debugCallback } from "../../js/Common";
import { AdditionalArrayNames } from "../CenterlinePlaneShared";

const { vtkErrorMacro } = macro;

const isDebugging = false;

function log(msg) {
    logDebug("PlaneIndexFilter", isDebugging, msg);
}

function debug(callback) {
    debugCallback(isDebugging, callback);
}

// ----------------------------------------------------------------------------
// vtkPlaneIndexFilter methods
// ----------------------------------------------------------------------------

var settingStuff = false;

/**
 * @typedef { {
 *  index: number,
 *  location: number[],
 *  normal: number[],
 *  maxDiameter: number,
 *  secondaryDiameter: number,
 *  circleEquivalentDiameter: number,
 *  volume: number,
 *  distance: number,
 *  transitionVolume: number,
 *  trunkVolume: number,
 *  numberOfPoints: number,
 *  callbackIdentifier: string,
 *  planeIdentifier: string 
 *  } } CenterlinePointMeasurement
 */

/**
 *
 * @param publicAPI
 * @param model
 * @returns CenterlinePointMeasurement
 */
function vtkPlaneIndexFilter(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('vtkPlaneIndexFilter');

    /**
     *
     * @param input {vtkPolyData}
     * @param idx {number}
     * @return {CenterlinePointMeasurement}
     */
    function getCenterlinePointMeasurement(input, idx) {
        let numberOfPoints = input.getPoints().getNumberOfTuples();

        if (idx >= numberOfPoints) {
            idx = numberOfPoints - 1;
            model.currentIdx = idx;
        }

        let location = [];
        input.getPoints().getTuple(idx, location);

        const getPointDataArrayTupleAtIndex = (index, arrayName, outArray) => {
            input.getPointData()
                .getArrayByName(arrayName)
                .getTuple(index, outArray);
        };

        const getPointDataArrayTuple = (arrayName, outArray) => {
            getPointDataArrayTupleAtIndex(idx, arrayName, outArray);
        };

        const getFieldDataArrayTupleAtIndex = (index, arrayName, outArray) => {
            input.getFieldData()
                .getArrayByName(arrayName)
                .getTuple(index, outArray);
        };

        debug(() => global.debugIndexInput = input);

        let normal = [];
        getPointDataArrayTuple(model.pointDataArrayName.Normals, normal);

        let maxDiameter = [];
        getPointDataArrayTuple(model.pointDataArrayName.MaximumDiameters, maxDiameter);

        let secondaryDiameter = [];
        getPointDataArrayTuple(model.pointDataArrayName.SecondaryDiameters, secondaryDiameter);

        let circleEquivalent = [];
        getPointDataArrayTuple(model.pointDataArrayName.CircleEquivalentDiameters, circleEquivalent);

        let volume = [];
        getPointDataArrayTuple(model.pointDataArrayName.Volume, volume);

        let distance = [];
        getPointDataArrayTuple(model.pointDataArrayName.Distances, distance);

        let transitionVolume = [];
        getFieldDataArrayTupleAtIndex(0, model.fieldDataArrayName.TransitionVolume, transitionVolume);

        let lastIndexOfTransitionBranchTrunk = [];
        getFieldDataArrayTupleAtIndex(0, AdditionalArrayNames.LAST_INDEX_OF_TRANSITION_BRANCH_TRUNK, lastIndexOfTransitionBranchTrunk);

        let trunkVolume = [];
        getPointDataArrayTupleAtIndex(lastIndexOfTransitionBranchTrunk[0], model.pointDataArrayName.Volume, trunkVolume);

        let callbackIdentifier = model.centerlinePlane.callbackIdentifier;
        let planeIdentifier = model.centerlinePlane.planeIdentifier;

        /** @type CenterlinePointMeasurement */
        const measurement = {
            index: idx,
            location,
            normal,
            maxDiameter: maxDiameter[0],
            secondaryDiameter: secondaryDiameter[0],
            circleEquivalentDiameter: circleEquivalent[0],
            volume: volume[0],
            distance: distance[0],
            transitionVolume: transitionVolume[0],
            trunkVolume: trunkVolume[0],
            numberOfPoints,
            callbackIdentifier,
            planeIdentifier
        };
        return measurement;
    }

    publicAPI.requestData = (inData, outData) => {
        // inData should be a polydata
        const input = inData[0];
        if (!input) {
            vtkErrorMacro('Invalid or missing input');
        }

        let measurement = getCenterlinePointMeasurement(input, model.currentIdx);
        model.pointArrays = measurement;

        // We want the modified callback to happen when the planeSource is changed externally, but not
        // when we change it ourselves. We need to disable the callback when we set it so that we don't
        // end up in a loop where it calls requestData again since the planeSource was modified.
        settingStuff = true;
        model.planeSource.setCenter(measurement.location);
        model.planeSource.setNormal(measurement.normal);
        settingStuff = false;

        // [OPTIMIZE] Maybe switch this to be a publicAPI.setCenterlinePointMeasurement
        // and when that happens, check if it has changed. If it has, then call the callback
        // but otherwise don't call the callback.
        // Example: Imagine the plane colour was changed externally. There would be no need
        // to call the valuesChangedCallback since none of the measurement values would have changed.
        model.valuesChangedCallback(measurement);

        // Hijacking vtkPlaneSource so we don't have to write plane rendering from scratch.
        model.planeSource.requestData([], outData);
        return outData;
    };

    publicAPI.checkModify = args => {
        if (!settingStuff) {
            publicAPI.modified();
        }
    };

    publicAPI.getValueRange = (startIndex, endIndex) => {
        let data = model.inputConnection[0];
        let calledData = data();

        let returnRanges = [];
        for (let i = startIndex; i <= endIndex; i++) {
            returnRanges.push(getCenterlinePointMeasurement(calledData, i));
        }

        return returnRanges;
    };

    // Intention: If you had a special planeSource that you wanted linked to this one, use this function.
    publicAPI.setPlaneSource = newSource => {
        model.planeSubscription.unsubscribe();
        /** @type {vtkPlaneSource} */
        model.planeSource = newSource;
        model.planeSubscription = model.planeSource.onModified(args => publicAPI.checkModify(args));
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    currentIdx: 0,
    valuesChangedCallback: () => {
    },
    pointDataArrayName: {},
    fieldDataArrayName: {},
    planeSource: vtkPlaneSource.newInstance({
        xResolution: 3,
        yResolution: 3,
        point1: [125, 0, 0],
        point2: [0, 125, 0]
    }),
    pointArrays: {
        index: -1,
        location: [0, 0, 0],
        normal: [0, 0, 0],
        maxDiameter: -1,
        secondaryDiameter: -1,
        volume: -1,
        numberOfPoints: -1
    },
    centerlinePlane: { callbackIdentifier: "no callback identifier", planeIdentifier: "no plane identifier" },
    planeSubscription: undefined
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);

    macro.algo(publicAPI, model, 1, 1);

    // Exposing the planeSource allows it to be used externally (e.g. could have a widget than controls the plane).
    macro.get(publicAPI, model, ['planeSource', 'pointArrays']); // getPlaneSource, getPointArrays
    macro.setGet(publicAPI, model, ['currentIdx', 'valuesChangedCallback']); // getCurrentIdx / setCurrentIdx,  getValuesChangedCallback / setValuesChangedCallback

    model.planeSubscription = model.planeSource.onModified(args => publicAPI.checkModify(args));

    vtkPlaneIndexFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkPlaneIndexFilter');

export default { newInstance, extend };
