import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, option_type, union_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { tryFind, ofArray } from "../../fable_modules/fable-library.3.7.20/List.js";
import { defaultArg, map } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { InstitutionClinicalDesignation$reflection } from "./Institution.js";

export class GeneratedReportType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViTAAReport", "CustomReport"];
    }
}

export function GeneratedReportType$reflection() {
    return union_type("RAWMap.Models.View.GeneratedReportType", [], GeneratedReportType, () => [[], []]);
}

export function GeneratedReportType_get_all() {
    return ofArray([new GeneratedReportType(0), new GeneratedReportType(1)]);
}

export function GeneratedReportType_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Custom Report" : "ViTAA Report");
}

export function GeneratedReportType_tryParse_Z721C83C5(reportType) {
    return tryFind((d) => (GeneratedReportType_get_describe()(d) === reportType), GeneratedReportType_get_all());
}

export function GeneratedReportType_get_describeOrDefault() {
    let f1;
    const mapping = GeneratedReportType_get_describe();
    f1 = ((option) => map(mapping, option));
    return (arg) => defaultArg(f1(arg), "");
}

export class UniqueDeviceIdentifierViewModel extends Record {
    constructor(clinicalDesignation, reportType) {
        super();
        this.clinicalDesignation = clinicalDesignation;
        this.reportType = reportType;
    }
}

export function UniqueDeviceIdentifierViewModel$reflection() {
    return record_type("RAWMap.Models.View.UniqueDeviceIdentifierViewModel", [], UniqueDeviceIdentifierViewModel, () => [["clinicalDesignation", option_type(InstitutionClinicalDesignation$reflection())], ["reportType", option_type(GeneratedReportType$reflection())]]);
}

export function UniqueDeviceIdentifierViewModel_get_empty() {
    return new UniqueDeviceIdentifierViewModel(void 0, void 0);
}

export function UniqueDeviceIdentifierViewModel_isValid_59FAA788(udi) {
    if (udi.clinicalDesignation != null) {
        return udi.reportType != null;
    }
    else {
        return false;
    }
}

export function UniqueDeviceIdentifierViewModel_get_clinicalDesignation_() {
    return [(m) => m.clinicalDesignation, (v) => ((m_1) => (new UniqueDeviceIdentifierViewModel(v, m_1.reportType)))];
}

export function UniqueDeviceIdentifierViewModel_get_reportType_() {
    return [(m) => m.reportType, (v) => ((m_1) => (new UniqueDeviceIdentifierViewModel(m_1.clinicalDesignation, v)))];
}

