import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { union_type, bool_type, tuple_type, unit_type, array_type, lambda_type, record_type, string_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";

export class SelectOption$1 extends Record {
    constructor(label, value) {
        super();
        this.label = label;
        this.value = value;
    }
}

export function SelectOption$1$reflection(gen0) {
    return record_type("RAWMap.Client.Common.ReactSelectCreatable.SelectOption`1", [gen0], SelectOption$1, () => [["label", string_type], ["value", gen0]]);
}

export function SelectOptions_label(option) {
    return option.label;
}

export function SelectOptions_value(option) {
    return option.value;
}

export class Accessors$1 extends Record {
    constructor(getOptionValue, getOptionLabel) {
        super();
        this.getOptionValue = getOptionValue;
        this.getOptionLabel = getOptionLabel;
    }
}

export function Accessors$1$reflection(gen0) {
    return record_type("RAWMap.Client.Common.ReactSelectCreatable.Accessors`1", [gen0], Accessors$1, () => [["getOptionValue", lambda_type(SelectOption$1$reflection(gen0), string_type)], ["getOptionLabel", lambda_type(SelectOption$1$reflection(gen0), string_type)]]);
}

export class ReactSelectCreatableProps$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultValue", "Options", "value", "onChange", "IsOptionSelected", "IsMulti", "IsClearable", "IsDisabled", "CreateOptionPosition", "FormatCreateLabel", "Nothing"];
    }
}

export function ReactSelectCreatableProps$1$reflection(gen0) {
    return union_type("RAWMap.Client.Common.ReactSelectCreatable.ReactSelectCreatableProps`1", [gen0], ReactSelectCreatableProps$1, () => [[["Item", array_type(SelectOption$1$reflection(gen0))]], [["Item", array_type(SelectOption$1$reflection(gen0))]], [["Item", SelectOption$1$reflection(gen0)]], [["Item", lambda_type(SelectOption$1$reflection(gen0), unit_type)]], [["Item", lambda_type(tuple_type(SelectOption$1$reflection(gen0), array_type(SelectOption$1$reflection(gen0))), bool_type)]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", lambda_type(string_type, string_type)]], []]);
}

