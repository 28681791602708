import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { Bounds$reflection, InteractionMode$reflection, DisplayMap$reflection } from "./Common/CommonBindings.js";
import { lambda_type, unit_type, bool_type, option_type, class_type, union_type, record_type, array_type, float64_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { ScreenshotProps$reflection } from "./Screenshot/ScreenshotBindings.js";
import { SliceValues$reflection, SlicePlaneProps$reflection } from "./SlicePlane/SlicePlaneBindings.js";
import { NotesProps$reflection } from "./Notes/NotesBindings.js";
import { CameraProps$reflection } from "./Camera/CameraBindings.js";
import { CenterlineMeasurementProps$reflection } from "./CenterlineMeasurement/CenterlineMeasurementBindings.js";
import { CalipersProps$reflection } from "./Calipers/CalipersBindings.js";
import { AnatomyOrientationProps$reflection } from "./AnatomyOrientation/AnatomyOrientationBindings.js";
import { PickedMapValueProps$reflection } from "./PickedMapValue/PickedMapValueBindings.js";
import { MeshViewportLocation$reflection } from "./Common/CommonTypes.js";

export class Viewport extends Record {
    constructor(map, planeAdjustment) {
        super();
        this.map = map;
        this.planeAdjustment = planeAdjustment;
    }
}

export function Viewport$reflection() {
    return record_type("Client.Visualization.Bindings.Viewport", [], Viewport, () => [["map", DisplayMap$reflection()], ["planeAdjustment", array_type(float64_type)]]);
}

export class InteractionView extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SingleView", "CompareView", "StandardView", "HiddenView"];
    }
}

export function InteractionView$reflection() {
    return union_type("Client.Visualization.Bindings.InteractionView", [], InteractionView, () => [[], [], [], []]);
}

export class InteractionModeProps extends Record {
    constructor(interactionMode, interactionView) {
        super();
        this.interactionMode = interactionMode;
        this.interactionView = interactionView;
    }
}

export function InteractionModeProps$reflection() {
    return record_type("Client.Visualization.Bindings.InteractionModeProps", [], InteractionModeProps, () => [["interactionMode", InteractionMode$reflection()], ["interactionView", InteractionView$reflection()]]);
}

export class VisualizationProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ScreenshotProps", "InteractionModeProps"];
    }
}

export function VisualizationProps$reflection() {
    return union_type("Client.Visualization.Bindings.VisualizationProps", [], VisualizationProps, () => [[["Item", ScreenshotProps$reflection()]], [["Item", InteractionModeProps$reflection()]]]);
}

export class MeshVisualizationProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentStudyMeshes", "PreviousStudyMeshes", "SlicePlaneProps", "SelectedMap", "ShowCenterline", "OverlayPreviousStudyMaps", "OnViewportResized", "StaticNotesProps", "MeasurementNotesProps", "CameraProps", "CenterlineMeasurementProps", "CalipersProps", "AnatomyOrientationProps", "ShowPreviousStudyMapsOnly", "PickedMapValueProps", "MeshViewportLocation"];
    }
}

export function MeshVisualizationProps$reflection() {
    return union_type("Client.Visualization.Bindings.MeshVisualizationProps", [], MeshVisualizationProps, () => [[["Item", option_type(class_type("Client.Visualization.Common.Bindings.Meshes.IMeshes"))]], [["Item", option_type(class_type("Client.Visualization.Common.Bindings.Meshes.IMeshes"))]], [["Item", SlicePlaneProps$reflection()]], [["Item", DisplayMap$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(Bounds$reflection(), unit_type)]], [["Item", NotesProps$reflection()]], [["Item", NotesProps$reflection()]], [["Item", CameraProps$reflection()]], [["Item", CenterlineMeasurementProps$reflection()]], [["Item", CalipersProps$reflection()]], [["Item", AnatomyOrientationProps$reflection()]], [["Item", bool_type]], [["Item", PickedMapValueProps$reflection()]], [["Item", MeshViewportLocation$reflection()]]]);
}

export class DicomVisualizationProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DicomFiles", "OnSliceChange", "OnTouchStart", "OnTouchEnd"];
    }
}

export function DicomVisualizationProps$reflection() {
    return union_type("Client.Visualization.Bindings.DicomVisualizationProps", [], DicomVisualizationProps, () => [[["Item", array_type(class_type("Browser.Types.File", void 0, File))]], [["Item", lambda_type(SliceValues$reflection(), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.TouchEvent", void 0, TouchEvent), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.TouchEvent", void 0, TouchEvent), unit_type)]]]);
}

