import { Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Notification$reflection } from "../RAWMap.Models/Api.js";
import { printf, toText, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.20/String.js";
import * as mqtt from "mqtt";
import { filter, iterate as iterate_1, map, collect } from "../fable_modules/fable-library.3.7.20/List.js";
import { Mqtt_ofTopic } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { iterate } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { toArray, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { List_distinctBy } from "../fable_modules/fable-library.3.7.20/Seq2.js";
import { uncurry, stringHash } from "../fable_modules/fable-library.3.7.20/Util.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

export const decoder = Auto_generateBoxedDecoder_79988AEF(Notification$reflection(), void 0, void 0);

export function connect(settings, onConnect, onFailure, dispatch) {
    const s = settings.IsSecure ? "s" : "";
    const host = isNullOrWhiteSpace(settings.Host) ? window.location.hostname : settings.Host;
    const address = toText(printf("ws%s://%s:%d/%s"))(s)(host)(settings.Port)(settings.Path);
    const opts_1 = {
        username: settings.User,
        password: settings.Pwd,
    };
    const client = mqtt.connect(address, opts_1);
    client.addListener("connect", (arg_4) => {
        (() => dispatch(onConnect(client)))(arg_4);
    });
    client.addListener("error", (arg_5) => {
        ((e) => ((arg_6) => dispatch(onFailure(arg_6))))(arg_5);
    });
}

export function subscribe(client, rawTopics, dispatch) {
    const eventName = "message";
    const topics_1 = collect((tupledArg) => map((t) => [Mqtt_ofTopic(t), tupledArg[1]], tupledArg[0]), rawTopics);
    iterate((l) => {
        client.removeListener(eventName, l);
    }, client.listeners(eventName));
    iterate_1((tupledArg_3) => {
        const topic_2 = tupledArg_3[0];
        client.unsubscribe(topic_2);
        console.log(some(toText(printf("Subscribing to %s"))(topic_2)));
        client.subscribe(topic_2);
    }, List_distinctBy((tuple) => tuple[0], topics_1, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }));
    client.addListener(eventName, (delegateArg, delegateArg_1) => {
        let notification;
        const matchValue = fromString(uncurry(2, decoder), delegateArg_1);
        if (matchValue.tag === 1) {
            console.log(some(toText(printf("Error decoding: %O"))(matchValue.fields[0])));
            notification = (void 0);
        }
        else {
            notification = matchValue.fields[0];
        }
        iterate_1((tupledArg_2) => {
            iterate((arg_1) => {
                dispatch(tupledArg_2[1](arg_1));
            }, toArray(notification));
        }, filter((tupledArg_1) => (tupledArg_1[0] === delegateArg), topics_1));
    });
}

export function disconnect(client) {
    return client.end(true);
}

