import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, option_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Result_isOk, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { now } from "../../fable_modules/fable-library.3.7.20/DateOffset.js";
import { StudyValidators_validateStringNotNullOrWhiteSpace, StudyValidators_validateIfSome } from "./Study.js";

export class AnalysisViewModel extends Record {
    constructor(studyId, analysisInfo, associatedAnalyst, analysisCreationDate) {
        super();
        this.studyId = studyId;
        this.analysisInfo = analysisInfo;
        this.associatedAnalyst = associatedAnalyst;
        this.analysisCreationDate = analysisCreationDate;
    }
}

export function AnalysisViewModel$reflection() {
    return record_type("RAWMap.Models.View.Analysis.AnalysisViewModel", [], AnalysisViewModel, () => [["studyId", class_type("System.Guid")], ["analysisInfo", option_type(string_type)], ["associatedAnalyst", class_type("System.Guid")], ["analysisCreationDate", class_type("System.DateTimeOffset")]]);
}

export function AnalysisViewModel_validate_Z24980752(avm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, AnalysisViewModel_validateAnalysisInfo_Z24980752(avm), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, avm))));
}

export function AnalysisViewModel_get_empty() {
    return new AnalysisViewModel("00000000-0000-0000-0000-000000000000", void 0, "00000000-0000-0000-0000-000000000000", now());
}

export function AnalysisViewModel_get_studyId_() {
    return [(m) => m.studyId, (v) => ((m_1) => (new AnalysisViewModel(v, m_1.analysisInfo, m_1.associatedAnalyst, m_1.analysisCreationDate)))];
}

export function AnalysisViewModel_get_analysisInfo_() {
    return [(m) => m.analysisInfo, (v) => ((m_1) => (new AnalysisViewModel(m_1.studyId, v, m_1.associatedAnalyst, m_1.analysisCreationDate)))];
}

export function AnalysisViewModel_get_associatedAnalyst_() {
    return [(m) => m.associatedAnalyst, (v) => ((m_1) => (new AnalysisViewModel(m_1.studyId, m_1.analysisInfo, v, m_1.analysisCreationDate)))];
}

export function AnalysisViewModel_get_analysisCreationDate_() {
    return [(m) => m.analysisCreationDate, (v) => ((m_1) => (new AnalysisViewModel(m_1.studyId, m_1.analysisInfo, m_1.associatedAnalyst, v)))];
}

export function AnalysisViewModel_validateAnalysisInfo_Z24980752(avm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateIfSome((fieldValue) => StudyValidators_validateStringNotNullOrWhiteSpace("AnalysisInfo", fieldValue), avm.analysisInfo), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result))));
}

export function AnalysisViewModel_isValid_Z24980752(avm) {
    return Result_isOk(AnalysisViewModel_validate_Z24980752(avm));
}

