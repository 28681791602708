import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { view as view_1 } from "../../../FileManagement/FileManagementView.js";
import { uploadControl } from "../../../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropView.js";
import { LocalMsg } from "./AnalysisFormFileTypes.js";
import * as react from "react";

export function view(displayMode, model, dispatch) {
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const children = [view_1((maybeFileTypeRestrictions) => uploadControl((arg_2) => {
        dispatchLocal(new LocalMsg(3, arg_2));
    }, maybeFileTypeRestrictions), displayMode, model.fileUpload, (arg_4) => {
        dispatchLocal(new LocalMsg(2, arg_4));
    }, void 0)];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

