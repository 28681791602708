import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { empty as empty_1, singleton as singleton_1, map, ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import * as react from "react";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { partialApply } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Common_SortInfo$1_getUpdatedSortInfo } from "../RAWMap.Models/Common.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { ComponentVisibility, Button_visibilityControlled } from "../Common/Controls.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { button, Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Msg } from "./UniqueDeviceIdentifierTypes.js";
import { UniqueDeviceIdentifierViewModel_get_clinicalDesignation_, GeneratedReportType_get_describeOrDefault, UniqueDeviceIdentifierViewModel_get_reportType_ } from "../RAWMap.Models/View/UniqueDeviceIdentifier.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { InstitutionClinicalDesignation_get_describeOrDefault } from "../RAWMap.Models/View/Institution.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { content, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { view as view_1 } from "../ImageManager/ImageManagerView.js";
import { FileComponentVisibility_get_fileSystemUpload } from "../FileManagement/Shared.js";
import { ChildMsg_ofMsg } from "../Common/Terminate.js";
import { childAbsentStrategy } from "./UniqueDeviceIdentifierState.js";

export function itemListSortableButton(barcodeButtonVisibility, columns, viewItem, items, extraItem, sortInfo, onClick, dispatch) {
    let children_14, children_12, children_16;
    const children_18 = [table(ofArray([new TableOption(1), new TableOption(2), new TableOption(4)]), ofArray([(children_14 = [(children_12 = toList(delay(() => append(singleton(react.createElement("td", {}, "Manage Barcode")), delay(() => map((tupledArg_1) => {
        const value_1 = tupledArg_1[0];
        const icon = (sortInfo.lastClicked === value_1) ? Fa_i(ofArray([(sortInfo.sortDirection.tag === 1) ? (new Fa_IconOption(11, "fas fa-chevron-down")) : (new Fa_IconOption(11, "fas fa-chevron-up")), new Fa_IconOption(14, singleton_1(["style", {
            paddingLeft: "10px",
        }]))]), []) : Helpers_nothing;
        const props_10 = [new HTMLAttr(64, "disable-select"), new DOMAttr(40, partialApply(1, onClick, [Common_SortInfo$1_getUpdatedSortInfo(value_1, tupledArg_1[1], sortInfo)])), ["style", {
            cursor: "pointer",
        }]];
        return react.createElement("td", keyValueList(props_10, 1), value_1, icon);
    }, columns))))), react.createElement("tr", {}, ...children_12))], react.createElement("thead", {}, ...children_14)), (children_16 = map((item) => {
        const props_6 = toList(delay(() => extraItem(item)));
        const children_6 = toList(delay(() => {
            let children_2;
            return append(singleton((children_2 = [Button_visibilityControlled(void 0, barcodeButtonVisibility, ofArray([new Option(1, new Size_ISize(0)), new Option(2), new Option(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg) => {
                dispatch(new Msg(4, item));
            })]))]), singleton_1(react.createElement("span", {}, "Manage Barcode")))], react.createElement("td", {}, ...children_2))), delay(() => map((tupledArg) => {
                const props_4 = [new DOMAttr(40, partialApply(1, viewItem, [item])), ["style", {
                    cursor: "pointer",
                }]];
                const children_4 = [tupledArg[1](item)];
                return react.createElement("td", keyValueList(props_4, 1), ...children_4);
            }, columns)));
        }));
        return react.createElement("tr", keyValueList(props_6, 1), ...children_6);
    }, sortInfo.sortBy(items)), react.createElement("tbody", {}, ...children_16))]))];
    return react.createElement("div", {}, ...children_18);
}

export function udiList(model, dispatch) {
    let f1, optic, f2, f1_1, optic_2, f2_1, matchValue, user, user_1;
    const columns = ofArray([["Report Type", (f1 = ((optic = UniqueDeviceIdentifierViewModel_get_reportType_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))), (f2 = GeneratedReportType_get_describeOrDefault(), (arg_2) => f2(f1(arg_2))))], ["Clinical Designation", (f1_1 = ((optic_2 = UniqueDeviceIdentifierViewModel_get_clinicalDesignation_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target_2))), (f2_1 = InstitutionClinicalDesignation_get_describeOrDefault(), (arg_4) => f2_1(f1_1(arg_4))))]]);
    return itemListSortableButton((matchValue = model.MaybeCurrentUser, (matchValue != null) ? (((user = matchValue, isAllowed(new Permission(29))(user.roles))) ? ((user_1 = matchValue, new ComponentVisibility(0))) : (new ComponentVisibility(2, void 0))) : (new ComponentVisibility(2, void 0))), columns, (_arg_1, _arg_2) => {
    }, model.Udis, (_arg_3) => empty(), model.SortInfo, (updatedInfo, _arg) => {
        dispatch(new Msg(3, updatedInfo));
    }, dispatch);
}

export function listView(model, dispatch) {
    return singleton_1(udiList(model, dispatch));
}

export function imageManagerView(_model, imageManagerModel, dispatch) {
    return card(empty_1(), ofArray([header(empty_1(), ofArray([Header_title(empty_1(), singleton_1("Barcode Management")), Header_icon(empty_1(), singleton_1(button(ofArray([new Option(17, singleton_1(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(5));
    }))), new Option(0, new Color_IColor(8))]), singleton_1("Close"))))])), content(empty_1(), singleton_1(view_1(FileComponentVisibility_get_fileSystemUpload(), imageManagerModel, (arg_2) => {
        dispatch(new Msg(6, ChildMsg_ofMsg(childAbsentStrategy, arg_2)));
    })))]));
}

export function view(model, dispatch) {
    const children = toList(delay(() => append(listView(model, dispatch), delay(() => {
        const matchValue = model.ImageManagerComponent.child;
        return (matchValue.tag === 0) ? singleton(imageManagerView(model, matchValue.fields[0], dispatch)) : singleton(Helpers_nothing);
    }))));
    return react.createElement("div", {}, ...children);
}

