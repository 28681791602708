import { Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { list_type, record_type, string_type, class_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { iterate, pairwise, getSlice, mapIndexed, cons, empty, singleton, append, length, fold, map } from "../fable_modules/fable-library.3.7.20/List.js";
import { stringHash, uncurry } from "../fable_modules/fable-library.3.7.20/Util.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.20/Seq2.js";
import { trimStart, insert, split, join } from "../fable_modules/fable-library.3.7.20/String.js";
import { tail } from "../fable_modules/fable-library.3.7.20/Array.js";
import * as jszip from "jszip";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";

export class FileWithRawPath extends Record {
    constructor(file, pathWithDirectory) {
        super();
        this.file = file;
        this.pathWithDirectory = pathWithDirectory;
    }
}

export function FileWithRawPath$reflection() {
    return record_type("Client.JsZipCommon.ZipDirectory.FileWithRawPath", [], FileWithRawPath, () => [["file", class_type("Browser.Types.File", void 0, File)], ["pathWithDirectory", string_type]]);
}

export class FileInZipDirectory extends Record {
    constructor(file, directoryPath) {
        super();
        this.file = file;
        this.directoryPath = directoryPath;
    }
}

export function FileInZipDirectory$reflection() {
    return record_type("Client.JsZipCommon.ZipDirectory.FileInZipDirectory", [], FileInZipDirectory, () => [["file", class_type("Browser.Types.File", void 0, File)], ["directoryPath", string_type]]);
}

export class ZipDirectory extends Record {
    constructor(directoryName, files) {
        super();
        this.directoryName = directoryName;
        this.files = files;
    }
}

export function ZipDirectory$reflection() {
    return record_type("Client.JsZipCommon.ZipDirectory.ZipDirectory", [], ZipDirectory, () => [["directoryName", string_type], ["files", list_type(FileInZipDirectory$reflection())]]);
}

export class ZipBatch extends Record {
    constructor(BatchName, ZipDirectories) {
        super();
        this.BatchName = BatchName;
        this.ZipDirectories = ZipDirectories;
    }
}

export function ZipBatch$reflection() {
    return record_type("Client.JsZipCommon.ZipDirectory.ZipBatch", [], ZipBatch, () => [["BatchName", string_type], ["ZipDirectories", list_type(ZipDirectory$reflection())]]);
}

export function splitZips(zipChunkSizeThreshold, zipDirs) {
    return map((zipDir) => {
        const chunkIndices_2 = cons(0, fold(uncurry(2, (tupledArg) => {
            const index = tupledArg[0] | 0;
            const chunkIndices = tupledArg[2];
            return (fileInZipDir) => {
                const isLastFileInList = index === (length(zipDir.files) - 1);
                const chunkSize$0027 = (tupledArg[1] + fileInZipDir.file.size) | 0;
                return (isLastFileInList ? true : (chunkSize$0027 >= zipChunkSizeThreshold)) ? [index + 1, 0, append(chunkIndices, singleton(index))] : [index + 1, chunkSize$0027, chunkIndices];
            };
        }), [0, 0, empty()], zipDir.files)[2]);
        const numChunks = (length(chunkIndices_2) - 1) | 0;
        return new ZipBatch(zipDir.directoryName, mapIndexed((i, tupledArg_2) => {
            const startIdx = tupledArg_2[0] | 0;
            const startIdx_1 = ((i === 0) ? startIdx : (startIdx + 1)) | 0;
            return new ZipDirectory(`${zipDir.directoryName}_${(i + 1)}_of_${numChunks}`, getSlice(startIdx_1, tupledArg_2[1], zipDir.files));
        }, pairwise(chunkIndices_2)));
    }, zipDirs);
}

export function createZipBatches(zipChunkSizeThreshold, files) {
    return splitZips(zipChunkSizeThreshold, map((tupledArg_1) => (new ZipDirectory(tupledArg_1[0], map((file_6) => file_6.fileInZipDirectory, tupledArg_1[1]))), List_groupBy((file_5) => file_5.splitDir[0], map((tupledArg) => {
        const pathSegments = tupledArg[1];
        return {
            fileInZipDirectory: new FileInZipDirectory(tupledArg[0].file, join("/", tail(pathSegments))),
            splitDir: pathSegments,
        };
    }, map((file_3) => [file_3, split(file_3.pathWithDirectory, ["/"], null, 0)], map((file_2) => {
        if (!(file_2.pathWithDirectory.indexOf("/") >= 0)) {
            return new FileWithRawPath(file_2.file, insert(file_2.pathWithDirectory, 0, "/"));
        }
        else {
            return file_2;
        }
    }, map((file) => (new FileWithRawPath(file.file, trimStart(file.pathWithDirectory, "/"))), files)))), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })));
}

const generatorOptions = (() => {
    const options = new jszip();
    options.type = "arraybuffer";
    options.compression = "DEFLATE";
    const compression = new jszip();
    compression.level = 6;
    options.compressionOptions = compression;
    return options;
})();

export function zipDirectory(directory, dispatchProgress) {
    const fileName = `${directory.directoryName}.zip`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const zipper = new jszip();
        iterate((file) => {
            zipper.file(file.directoryPath, file.file);
        }, directory.files);
        return zipper.generateAsync(generatorOptions, dispatchProgress).then((_arg) => {
            const zippedFile = new File([_arg], fileName);
            return Promise.resolve(zippedFile);
        });
    }));
}

