import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { union_type, option_type, array_type, bool_type, string_type, record_type, int32_type, lambda_type, unit_type, class_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { singleton, append, delay } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";

export class IDropzoneRootProps extends Record {
    constructor(onBlur, onClick, onDragEnter, onDragLeave, onDragOver, onDrop, onFocus, onKeyDown, tabIndex) {
        super();
        this.onBlur = onBlur;
        this.onClick = onClick;
        this.onDragEnter = onDragEnter;
        this.onDragLeave = onDragLeave;
        this.onDragOver = onDragOver;
        this.onDrop = onDrop;
        this.onFocus = onFocus;
        this.onKeyDown = onKeyDown;
        this.tabIndex = (tabIndex | 0);
    }
}

export function IDropzoneRootProps$reflection() {
    return record_type("RAWMap.Client.Common.ReactDropzone.IDropzoneRootProps", [], IDropzoneRootProps, () => [["onBlur", lambda_type(class_type("Browser.Types.FocusEvent", void 0, FocusEvent), unit_type)], ["onClick", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)], ["onDragEnter", lambda_type(class_type("Browser.Types.DragEvent", void 0, DragEvent), unit_type)], ["onDragLeave", lambda_type(class_type("Browser.Types.DragEvent", void 0, DragEvent), unit_type)], ["onDragOver", lambda_type(class_type("Browser.Types.DragEvent", void 0, DragEvent), unit_type)], ["onDrop", lambda_type(class_type("Browser.Types.DragEvent", void 0, DragEvent), unit_type)], ["onFocus", lambda_type(class_type("Browser.Types.FocusEvent", void 0, FocusEvent), unit_type)], ["onKeyDown", lambda_type(class_type("Browser.Types.KeyboardEvent", void 0, KeyboardEvent), unit_type)], ["tabIndex", int32_type]]);
}

export function IDropzoneRootProps_makeProps_5BEA2B69(props) {
    return delay(() => append(singleton(new DOMAttr(8, props.onBlur)), delay(() => append(singleton(new DOMAttr(40, props.onClick)), delay(() => append(singleton(new DOMAttr(45, props.onDragEnter)), delay(() => append(singleton(new DOMAttr(47, props.onDragLeave)), delay(() => append(singleton(new DOMAttr(48, props.onDragOver)), delay(() => append(singleton(new DOMAttr(50, props.onDrop)), delay(() => append(singleton(new DOMAttr(7, props.onFocus)), delay(() => append(singleton(new DOMAttr(15, props.onKeyDown)), delay(() => singleton(new HTMLAttr(156, props.tabIndex)))))))))))))))))));
}

export class IDropzoneInputProps extends Record {
    constructor(autoComplete, multiple, onChange, onClick, tabIndex, type) {
        super();
        this.autoComplete = autoComplete;
        this.multiple = multiple;
        this.onChange = onChange;
        this.onClick = onClick;
        this.tabIndex = (tabIndex | 0);
        this.type = type;
    }
}

export function IDropzoneInputProps$reflection() {
    return record_type("RAWMap.Client.Common.ReactDropzone.IDropzoneInputProps", [], IDropzoneInputProps, () => [["autoComplete", string_type], ["multiple", bool_type], ["onChange", lambda_type(class_type("Browser.Types.Event", void 0, Event), unit_type)], ["onClick", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)], ["tabIndex", int32_type], ["type", string_type]]);
}

export function IDropzoneInputProps_makeFileInputProps_193CD1B9(props) {
    return delay(() => append(singleton(new HTMLAttr(54, props.autoComplete)), delay(() => append(singleton(new HTMLAttr(121, props.multiple)), delay(() => append(singleton(new DOMAttr(9, props.onChange)), delay(() => append(singleton(new DOMAttr(40, props.onClick)), delay(() => append(singleton(new HTMLAttr(156, props.tabIndex)), delay(() => singleton(new HTMLAttr(159, props.type)))))))))))));
}

export class IDropzoneState extends Record {
    constructor(getInputProps, getRootProps, isDragActive) {
        super();
        this.getInputProps = getInputProps;
        this.getRootProps = getRootProps;
        this.isDragActive = isDragActive;
    }
}

export function IDropzoneState$reflection() {
    return record_type("RAWMap.Client.Common.ReactDropzone.IDropzoneState", [], IDropzoneState, () => [["getInputProps", lambda_type(unit_type, IDropzoneInputProps$reflection())], ["getRootProps", lambda_type(unit_type, IDropzoneRootProps$reflection())], ["isDragActive", bool_type]]);
}

export class IDropzoneOptions extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["disabled", "multiple", "Accept", "OnDropAccepted", "OnDropRejected", "NoDragEventsBubbling", "children"];
    }
}

export function IDropzoneOptions$reflection() {
    return union_type("RAWMap.Client.Common.ReactDropzone.IDropzoneOptions", [], IDropzoneOptions, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", option_type(array_type(string_type))]], [["Item", lambda_type(array_type(class_type("FileSelectorBindings.FileWithPath")), lambda_type(class_type("Browser.Types.Event", void 0, Event), unit_type))]], [["Item", lambda_type(array_type(class_type("ReactDropzoneBindings.React_dropzone.FileRejection")), lambda_type(class_type("Browser.Types.Event", void 0, Event), unit_type))]], [["Item", bool_type]], [["Item", lambda_type(IDropzoneState$reflection(), class_type("Fable.React.ReactElement"))]]]);
}

