import { InboundMsg as InboundMsg_1, FileManagementUpdateArgs, FileManagementViewModel_abortUpload_27E04A4D, Model_tryTerminate_Z379152CB, FileHandling, Model_getNumberOfAssociatedFiles_Z379152CB } from "../FileManagement/FileManagementTypes.js";
import { update as update_1, init as init_1 } from "../FileManagement/FileManagementState.js";
import { update as update_2, init as init_2 } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { ImageInfo, OutboundMsg, ImageInfo_getFileName_Z54611DEE, LocalMsg, Model } from "./ImageManagerTypes.js";
import { Cmd_none, Cmd_batch, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_3, wrapInboundMsg, wrapLocalMsg } from "../Common/InboundOutbound.js";
import { length, head, tail, isEmpty, singleton, iterate, ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__ReturnFrom_1505, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { toList } from "../fable_modules/fable-library.3.7.20/Map.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { getSignedUrlCmd, S3Context } from "../Common/AwsCommon.js";
import { equals } from "../fable_modules/fable-library.3.7.20/Util.js";
import { some, map } from "../fable_modules/fable-library.3.7.20/Option.js";

export function getNumberOfAssociatedFiles(model) {
    return Model_getNumberOfAssociatedFiles_Z379152CB(model.fileManagementModel);
}

export function init(fileSpecs, fileContext) {
    const patternInput = init_1(fileSpecs, fileContext, new FileHandling(0));
    const patternInput_1 = init_2(fileSpecs);
    return [new Model(patternInput[0], patternInput_1[0], void 0), Cmd_map(wrapLocalMsg, Cmd_batch(ofArray([Cmd_map((arg) => (new LocalMsg(2, arg)), patternInput[1]), Cmd_map((arg_1) => (new LocalMsg(3, arg_1)), patternInput_1[1])])))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__ReturnFrom_1505(ResultComputationExpression_result, Model_tryTerminate_Z379152CB(model.fileManagementModel))));
}

export function forceTerminate(model) {
    iterate((arg_1) => {
        FileManagementViewModel_abortUpload_27E04A4D(arg_1[1]);
    }, toList(model.fileManagementModel.FileManagementsInProgress));
}

export function updateLocal(args, msg, model) {
    let inputRecord;
    const matchValue = [msg, model];
    if (matchValue[0].tag === 1) {
        return [model, Cmd_batch(singleton(Cmd_OfFunc_result(new LocalMsg(3, wrapInboundMsg(new InboundMsg(0)))))), Cmd_none()];
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[0].fields[0].tag === 0) {
            const patternInput_1 = update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (inputRecord = args.Context, new S3Context(inputRecord.S3Config, inputRecord.S3Client, inputRecord.S3Expiry, args.Context.ActiveBucket))), matchValue[0].fields[0].fields[0], model.fileManagementModel);
            return [new Model(patternInput_1[0], model.dragDropModel, model.maybeActiveImage), Cmd_map((arg_3) => (new LocalMsg(2, arg_3)), patternInput_1[1]), Cmd_none()];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 0) {
            let patternInput;
            const matchValue_1 = model.fileManagementModel.PersistedFiles;
            let pattern_matching_result, file;
            if (!isEmpty(matchValue_1)) {
                if (isEmpty(tail(matchValue_1))) {
                    pattern_matching_result = 0;
                    file = head(matchValue_1);
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    patternInput = [equals(file.FileName, map(ImageInfo_getFileName_Z54611DEE, model.maybeActiveImage)) ? model : (new Model(model.fileManagementModel, model.dragDropModel, void 0)), Cmd_OfFunc_result(new LocalMsg(4, file))];
                    break;
                }
                case 1: {
                    patternInput = [new Model(model.fileManagementModel, model.dragDropModel, void 0), Cmd_none()];
                    break;
                }
            }
            return [patternInput[0], patternInput[1], Cmd_OfFunc_result(new OutboundMsg(0))];
        }
        else {
            return [model, Cmd_none(), Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[0].fields[0].tag === 0) {
            const patternInput_2 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, getNumberOfAssociatedFiles(model) + length(model.fileManagementModel.UploadSelection), args.Credentials, args.Context), matchValue[0].fields[0].fields[0], model.dragDropModel);
            return [new Model(model.fileManagementModel, patternInput_2[0], model.maybeActiveImage), Cmd_map((arg_6) => (new LocalMsg(3, arg_6)), patternInput_2[1]), Cmd_none()];
        }
        else {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg_1(1, matchValue[0].fields[0].fields[0].fields[0])))), Cmd_OfFunc_result(new LocalMsg(1))])), Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 4) {
        return [model, getSignedUrlCmd(args.Context, 0, matchValue[0].fields[0].FilePath, (tupledArg) => (new LocalMsg(5, tupledArg[0], tupledArg[1])), (arg_9) => (new LocalMsg(0, arg_9))), Cmd_none()];
    }
    else if (matchValue[0].tag === 5) {
        return [new Model(model.fileManagementModel, model.dragDropModel, new ImageInfo(matchValue[0].fields[0], matchValue[0].fields[1])), Cmd_none(), Cmd_none()];
    }
    else {
        console.error(some(matchValue[0].fields[0]));
        return [matchValue[1], Cmd_none(), Cmd_none()];
    }
}

export function updateInbound(_args, msg, model) {
    return [model, Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg_1(0)))), Cmd_none()];
}

export function update(args, msg, model) {
    return update_3(updateLocal, updateInbound, args, msg, model);
}

