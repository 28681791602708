import { ModelSlicePlane } from "./SlicePlaneTypes.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_1 } from "../../Common/InboundOutbound.js";

export function init() {
    return [new ModelSlicePlane(void 0, void 0, false, false, void 0), Cmd_none()];
}

export function updateInbound(unitVar, msg, model) {
    if (msg.tag === 1) {
        return [new ModelSlicePlane(model.Index, model.MaxIndex, model.IsVisible, msg.fields[0], model.Bounds), Cmd_none(), Cmd_none()];
    }
    else {
        const value = msg.fields[0];
        return [new ModelSlicePlane(value.index, value.maxIndex, model.IsVisible, model.IsHovering, value.bounds), Cmd_none(), Cmd_none()];
    }
}

export function updateLocal(unitVar, msg, model) {
    return [new ModelSlicePlane(model.Index, model.MaxIndex, msg.fields[0], model.IsHovering, model.Bounds), Cmd_none(), Cmd_none()];
}

export function update(msg, model) {
    return update_1((arg00$0040, msg_1, model_1) => updateLocal(void 0, msg_1, model_1), (arg00$0040_1, msg_2, model_2) => updateInbound(void 0, msg_2, model_2), void 0, msg, model);
}

