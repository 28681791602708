import { allCanvases, constants, logDebug } from '../js/Common';

const isDebugging = false;

function log(msg) {
    logDebug("Visualization.Screenshot", isDebugging, msg);
}

/**
 * Callback to invoke when the split screenshot is captured.
 * 
 * @callback screenshotCapturedCallback
 * @param {string} imageData Base64 representation of the split image.
 */

/**
 * Splits images onto a canvas.
 * Calls onScreenshotCaptured with the final image.
 *
 * @param {screenshotCapturedCallback} onScreenshotCaptured
 * @param {number} width
 * @param {number} height
 * @param {string[]} base64Images
 */
export function splitImages(onScreenshotCaptured, width, height, base64Images) {

    let c = document.createElement("canvas");
    c.width = width;
    c.height = height;
    let ctx = c.getContext("2d");

    log(`Splitting ${base64Images.length} images on ${width}x${height} canvas`);

    const imgWidth = width / base64Images.length;

    log(`Each split image will be ${imgWidth}x${height}`);

    function split(i) {
        const data = base64Images[i];
        let imageObj = new Image();
        let pad = i === 0 ? 0 : constants.splitScreenshotPadding;
        imageObj.onload = e => {
            log(`Image ${i} has loaded`);
            ctx.drawImage(imageObj, i * imgWidth + pad, 0, imgWidth, height);
            if (i === base64Images.length - 1) {
                // Last image has loaded
                log("Calling split onScreenshotCaptured");
                onScreenshotCaptured(c.toDataURL("image/png"));
            } else {
                split(i + 1);
            }
        };
        log(`Setting src for image ${i}`);
        imageObj.src = data;
    }

    split(0);
}

/**
 * Callback to invoke when the merged screenshot is captured.
 *
 * @callback screenshotCapturedCallback
 * @param {string} imageData Base64 representation of the merged image.
 */

/**
 * Merges images onto a canvas.
 * Calls onScreenshotCaptured with the final image.
 *
 * @param {screenshotCapturedCallback} onScreenshotCaptured
 * @param {number} width
 * @param {number} height
 * @param {string[]} base64Images
 */
function mergeImages(onScreenshotCaptured, width, height, base64Images) {

    let c = document.createElement("canvas");
    c.width = width;
    c.height = height;
    let ctx = c.getContext("2d");

    log(`Merging ${base64Images.length} images on ${width}x${height} canvas`);

    function merge(i) {
        const data = base64Images[i];
        let imageObj = new Image();
        imageObj.onload = e => {
            log(`Image ${i} has loaded`);
            ctx.drawImage(imageObj, 0, 0, width, height);
            if (i === base64Images.length - 1) {
                // Last image has loaded
                log("Calling merged onScreenshotCaptured");
                onScreenshotCaptured(c.toDataURL("image/png"));
            } else {
                merge(i + 1);
            }
        };
        log(`Setting src for image ${i}`);
        imageObj.src = data;
    }

    merge(0);
}

/**
 * @param container
 * @param parentContext
 * @param { onScreenshotCaptured: function(string) }
 */
export function useScreenshot(container, parentContext, redrawCanvases, { onScreenshotCaptured }) {

    const captureScreenshot = (dims = constants.screenshotSize) => {

        log("Grabbing renderwindow images");

        if (container && parentContext) {

            const { renderWindow } = parentContext;

            if (onScreenshotCaptured) {
                const opts = {
                    size: [dims.width, dims.height]
                };
                renderWindow.captureImages('image/png', opts)[0].then(
                    image => {

                        // The renderWindow capture should be first so it doesn't cover label canvases.
                        const images = [image];

                        for (let i = 0; i < allCanvases.length; i++) {
                            const captureCanvas = container.querySelector("#" + allCanvases[i]);
                            if (captureCanvas) {
                                // Resize canvas/text
                                let c = document.createElement("canvas");
                                // Canvas id is used to determine styling
                                c.id = captureCanvas.id;
                                log(`Redraw notes for ${c.id} images`);
                                redrawCanvases(c, dims);
                                images.push(c.toDataURL("image/png"));
                            }
                        }

                        log(`Calling mergeImages with ${images.length} images`);
                        mergeImages(onScreenshotCaptured, dims.width, dims.height, images);
                    }
                );
            }
        }
    };

    const screenshotRequested = e => {
        e.stopPropagation();
        captureScreenshot();
    };

    const startInteraction = () => {
        if (container) {
            container.addEventListener("click", screenshotRequested, true);
            container.addEventListener("touchstart", screenshotRequested, true);
        }
    };

    const stopInteraction = () => {
        if (container) {
            container.removeEventListener("click", screenshotRequested, true);
            container.removeEventListener("touchstart", screenshotRequested, true);
        }
    };

    return [captureScreenshot, startInteraction, stopInteraction];
}