import { Record, Union } from "../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, list_type, class_type, union_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { choose, filter, contains, exists, map, isEmpty, forAll, empty, tryFind, ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { Security_UserViewModel$2, Security_UserViewModel$2_empty_1505 } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.20/String.js";
import { List_containsOneOf } from "./Common.js";
import { map as map_1, defaultArg, value as value_1, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { tryFind as tryFind_1, ofList } from "../fable_modules/fable-library.3.7.20/Map.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.20/Util.js";

export class UserRole extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VitaaAdmin", "InstitutionAdmin", "Physician", "Analyst", "SupportStaff"];
    }
    toString() {
        const x = this;
        return UserRole_get_describe()(x);
    }
}

export function UserRole$reflection() {
    return union_type("RAWMap.Models.Api.Security.UserRole", [], UserRole, () => [[], [], [], [], []]);
}

export function UserRole_get_all() {
    return ofArray([new UserRole(0), new UserRole(1), new UserRole(2), new UserRole(3), new UserRole(4)]);
}

export function UserRole_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Institution Admin" : ((_arg.tag === 2) ? "Physician" : ((_arg.tag === 3) ? "Analyst" : ((_arg.tag === 4) ? "Support Staff" : "ViTAA Admin"))));
}

export function UserRole_tryParse_Z721C83C5(role) {
    return tryFind((r) => (UserRole_get_describe()(r) === role), UserRole_get_all());
}

export class UserData extends Record {
    constructor(institutions) {
        super();
        this.institutions = institutions;
    }
}

export function UserData$reflection() {
    return record_type("RAWMap.Models.Api.Security.UserData", [], UserData, () => [["institutions", list_type(class_type("System.Guid"))]]);
}

export function UserData_get_institutions_() {
    return [(m) => m.institutions, (v) => ((m_1) => (new UserData(v)))];
}

export const UserViewModelModule_empty = Security_UserViewModel$2_empty_1505(new UserData(empty()));

export const UserViewModelModule_id_ = [(m) => m.id, (v) => ((m_1) => (new Security_UserViewModel$2(v, m_1.email, m_1.name, m_1.roles, m_1.userData)))];

export const UserViewModelModule_email_ = [(m) => m.email, (v) => ((m_1) => (new Security_UserViewModel$2(m_1.id, v, m_1.name, m_1.roles, m_1.userData)))];

export const UserViewModelModule_name_ = [(m) => m.name, (v) => ((m_1) => (new Security_UserViewModel$2(m_1.id, m_1.email, v, m_1.roles, m_1.userData)))];

export const UserViewModelModule_roles_ = [(m) => m.roles, (v) => ((m_1) => (new Security_UserViewModel$2(m_1.id, m_1.email, m_1.name, v, m_1.userData)))];

export function UserViewModelModule_isValid(user) {
    if (forAll((arg_1) => (!isNullOrWhiteSpace(arg_1)), ofArray([user.email, user.name.given, user.name.family])) && (!isEmpty(user.roles))) {
        if (List_containsOneOf(ofArray([new UserRole(0), new UserRole(3)]), user.roles)) {
            return true;
        }
        else {
            return !isEmpty(user.userData.institutions);
        }
    }
    else {
        return false;
    }
}

export function Option_apply(fOpt, xOpt) {
    const matchValue = [fOpt, xOpt];
    let pattern_matching_result, f, x;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            f = matchValue[0];
            x = value_1(matchValue[1]);
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return some(f(x));
        }
        case 1: {
            return void 0;
        }
    }
}

export class Permission extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ManageAllUsers", "CreateUser", "EditUser", "DeleteUser", "ViewUsers", "AccessAllInstitutions", "ViewInstitutions", "CreateInstitutions", "EditInstitutions", "ManageInstitutionLogos", "GetUserInstitutions", "ViewStudies", "ViewAllStudies", "CreateStudies", "ViewPatients", "ViewAllPatients", "CreatePatients", "VerifyPatientHash", "ViewCustomReport", "EditCustomReportMetadata", "CreateCustomReport", "DeleteCustomReport", "ViewAnalyses", "ViewAllAnalyses", "CreateAnalyses", "GetAllInstitutionStats", "GetInstitutionStats", "DownloadFailedVerificationFile", "CreateIdentifiedReport", "ManageUdi"];
    }
}

export function Permission$reflection() {
    return union_type("RAWMap.Models.Api.Security.Permission", [], Permission, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

const permissionsByRole = ofList(map((r) => [r, (r.tag === 1) ? ofArray([new Permission(1), new Permission(2), new Permission(4), new Permission(6), new Permission(9), new Permission(14), new Permission(11), new Permission(22), new Permission(26), new Permission(17), new Permission(28), new Permission(18), new Permission(21)]) : ((r.tag === 3) ? ofArray([new Permission(5), new Permission(14), new Permission(15), new Permission(11), new Permission(12), new Permission(22), new Permission(23), new Permission(24)]) : ((r.tag === 2) ? ofArray([new Permission(10), new Permission(14), new Permission(16), new Permission(11), new Permission(13), new Permission(22), new Permission(17), new Permission(28), new Permission(18), new Permission(19), new Permission(20)]) : ((r.tag === 4) ? ofArray([new Permission(10), new Permission(14), new Permission(16), new Permission(11), new Permission(13), new Permission(22), new Permission(17), new Permission(28), new Permission(18), new Permission(19), new Permission(20)]) : ofArray([new Permission(0), new Permission(1), new Permission(2), new Permission(3), new Permission(4), new Permission(7), new Permission(8), new Permission(5), new Permission(6), new Permission(14), new Permission(15), new Permission(11), new Permission(12), new Permission(22), new Permission(23), new Permission(26), new Permission(25), new Permission(27), new Permission(29)]))))], UserRole_get_all()));

export function isAllowed(permission) {
    return (list) => exists((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), list);
}

export function isAllowedByOneOf(permissions, userRoles) {
    return exists((permission) => isAllowed(permission)(userRoles), permissions);
}

export function isUserAllowed(permission, maybeUserInfo) {
    return defaultArg(map_1((userInfo) => isAllowed(permission)(userInfo.roles), maybeUserInfo), false);
}

export function rolesWithPermission(permission) {
    return filter((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), UserRole_get_all());
}

export function collectPermissions(permissionChooser, role) {
    return defaultArg(map_1((list) => choose(permissionChooser, list), tryFind_1(role, permissionsByRole)), empty());
}

