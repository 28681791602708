import macro from 'vtk.js/Sources/macros';
import vtkPoints from 'vtk.js/Sources/Common/Core/Points';
import vtkDataArray from "vtk.js/Sources/Common/Core/DataArray";
import vtkPolyData from "vtk.js/Sources/Common/DataModel/PolyData";
import vtkDataSetAttributes from "vtk.js/Sources/Common/DataModel/DataSetAttributes";
import {
    CenterlineBranchSelection,
    CenterlineBranchType,
    CenterlineDistanceBranchSelection,
    CenterlineTransitionBranchSelection,
    AdditionalArrayNames
} from "../CenterlinePlaneShared";
import { debugCallback, logDebug } from "../../js/Common";

const { vtkErrorMacro } = macro;

const isDebugging = false;

function log(msg) {
    logDebug("UnifiedBranchFilter", isDebugging, msg);
}

function debug(callback) {
    debugCallback(isDebugging, callback);
}


// ----------------------------------------------------------------------------
// vtkUnifiedBranchFilter methods
// ----------------------------------------------------------------------------

function vtkUnifiedBranchFilter(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('vtkUnifiedBranchFilter');

    /** @typedef {{ branchMappingArray: vtkDataArray, numberOfPoints: number }} BranchMappingWithLength */

    /**
     * Gets a unified set of vtkPoints from the master point data array that corresponds to the specified branches.
     * @param input {vtkPolyData}
     * @param branchMappingWithLengths {BranchMappingWithLength[]}
     * @param totalNumberOfPoints {number}
     * @return {vtkPoints}
     */
    function getMasterPointsForBranches(input, branchMappingWithLengths, totalNumberOfPoints) {
        const masterPoints = input.getPoints();
        if (!masterPoints || masterPoints.getNumberOfValues() === 0) {
            const error = 'No points from input';
            vtkErrorMacro(error);
            throw error;
        }

        const branchPoints = vtkPoints.newInstance();
        branchPoints.setNumberOfPoints(totalNumberOfPoints);

        for (let idxBranchMappingWithLength = 0, branchPointsIndexOffset = 0; idxBranchMappingWithLength < branchMappingWithLengths.length; idxBranchMappingWithLength++) {
            let { branchMappingArray, numberOfPoints } = branchMappingWithLengths[idxBranchMappingWithLength];
            branchPointsIndexOffset += idxBranchMappingWithLength === 0 ? 0 : branchMappingWithLengths[idxBranchMappingWithLength - 1].numberOfPoints;
            for (let i = 0; i < numberOfPoints; i++) {
                let idx = branchMappingArray.getTuple(i)[0];
                let point = [];
                masterPoints.getTuple(idx, point);
                branchPoints.setTuple(branchPointsIndexOffset + i, point);
            }
        }

        return branchPoints;
    }

    /**
     *
     * @param arrayToCopy {vtkDataArray}
     * @param numberOfPoints {number}
     * @param getTupleIndex {function(number): number}
     * @param outArrayIndexOffset {number}
     * @param outArray {vtkDataArray}
     */
    function copyTuples(arrayToCopy, numberOfPoints, getTupleIndex, outArrayIndexOffset, outArray) {
        for (let j = 0; j < numberOfPoints; j++) {
            let idx = getTupleIndex(j);
            let point = [];
            arrayToCopy.getTuple(idx, point);
            outArray.setTuple(outArrayIndexOffset + j, point);
        }
    }

    /**
     *
     * @param arrayToCopy {vtkDataArray}
     * @param numberOfPoints {number}
     * @return {vtkDataArray}
     */
    function prepareNewDataArray(arrayToCopy, numberOfPoints) {
        let arrayProperties = arrayToCopy.get();
        let newArray = vtkDataArray.newInstance({
            name: arrayProperties.name,
            numberOfComponents: arrayProperties.numberOfComponents,
            size: numberOfPoints * arrayProperties.numberOfComponents,
            dataType: arrayProperties.dataType,
            rangeTuple: arrayProperties.rangeTuple
        });

        return newArray;
    }

    /**
     *
     * @param arrayToCopy {vtkDataArray}
     * @param numberOfPoints {number}
     * @param indexOffset {number}
     * @param getTupleIndex {function(int): int}
     * @return {vtkDataArray}
     */
    function copyDataArray(arrayToCopy, numberOfPoints, indexOffset, getTupleIndex) {
        const newArray = prepareNewDataArray(arrayToCopy, numberOfPoints);
        copyTuples(arrayToCopy, numberOfPoints, getTupleIndex, indexOffset, newArray);

        return newArray;
    }

    /**
     * Gets a unified set of vtkPoints from the master point data array that corresponds to the specified branches.
     * @param input {vtkPolyData}
     * @param branchMappingWithLengths {BranchMappingWithLength[]}
     * @param totalNumberOfPoints {number}
     * @return {vtkDataSetAttributes} Unified branch data.
     */
    function getMasterDataForBranches(input, branchMappingWithLengths, totalNumberOfPoints) {
        let masterPointData = input.getPointData();
        if (!masterPointData) {
            const error = 'No point data from input';
            vtkErrorMacro(error);
            throw error;
        }

        let arrayCount = masterPointData.getNumberOfArrays();
        let branchPointData = vtkDataSetAttributes.newInstance();

        for (let i = 0; i < arrayCount; i++) {
            /** @type vtkDataArray */
            let arrayToCopy = masterPointData.getArrayByIndex(i);
            let newArray = prepareNewDataArray(arrayToCopy, totalNumberOfPoints);

            for (let idxBranchMappingWithLength = 0, branchPointsIndexOffset = 0; idxBranchMappingWithLength < branchMappingWithLengths.length; idxBranchMappingWithLength++) {
                let { branchMappingArray, numberOfPoints } = branchMappingWithLengths[idxBranchMappingWithLength];
                branchPointsIndexOffset += idxBranchMappingWithLength === 0 ? 0 : branchMappingWithLengths[idxBranchMappingWithLength - 1].numberOfPoints;
                let getTupleIndex = (index) => {
                    return branchMappingArray.getTuple(index)[0];
                };
                copyTuples(arrayToCopy, numberOfPoints, getTupleIndex, branchPointsIndexOffset, newArray);
            }

            branchPointData.addArray(newArray);
        }

        return branchPointData;
    }

    /**
     *
     * @param fieldData {vtkDataSetAttributes}
     * @return {vtkDataArray}
     */
    function copyTransitionVolumeArray(fieldData) {
        const transitionVolumeArray = fieldData.getArrayByName(model.fieldDataArrayNames.TransitionVolume);

        // Transition volume is a single value.
        let getTupleIdx = (_) => {
            return 0;
        };
        let newArray = copyDataArray(transitionVolumeArray, 1, 0, getTupleIdx);
        return newArray;
    }

    /**
     *
     * @param fieldData {vtkDataSetAttributes}
     * @return {vtkDataArray}
     */
    function createLastIndexOfTransitionBranchTrunkArray(fieldData) {
        /** @type vtkDataArray */
        const transitionBranchTrunkArray = fieldData.getArrayByName(model.fieldDataArrayNames.TransitionBranchTrunk);
        const newArray = prepareNewDataArray(transitionBranchTrunkArray, 1)
        newArray.setName(AdditionalArrayNames.LAST_INDEX_OF_TRANSITION_BRANCH_TRUNK);
        const getTupleIndex = (_) => {
            return transitionBranchTrunkArray.getNumberOfTuples() - 1;
        };
        copyTuples(transitionBranchTrunkArray, 1, getTupleIndex, 0, newArray);
        newArray.getRange(); // Recompute the range.
        return newArray;
    }

    function getUnifiedBranchFieldData(input, totalNumberOfPoints) {
        let fieldData = input.getFieldData();
        if (!fieldData) {
            const error = 'No field data from input';
            vtkErrorMacro(error);
            throw error;
        }

        const branchFieldData = vtkDataSetAttributes.newInstance();

        const transitionVolumeArrayCopy = copyTransitionVolumeArray(fieldData);
        branchFieldData.addArray(transitionVolumeArrayCopy);

        const lastIndexOfTransitionBranchTrunkArray = createLastIndexOfTransitionBranchTrunkArray(fieldData);
        branchFieldData.addArray(lastIndexOfTransitionBranchTrunkArray);

        return branchFieldData;
    }

    /**
     *
     * @param arrayNames {string[]}
     * @param input {vtkPolyData}
     * @return {{branchMappingWithLengths: BranchMappingWithLength[], totalNumberOfPoints: number}}
     */
    function getBranchMappingArrays(arrayNames, input) {
        let totalNumberOfPoints = 0;
        const branchMappingWithLengths = [];

        for (const arrayName of arrayNames) {
            /** @type vtkDataArray */
            const branchMappingArray = input.getFieldData().getArrayByName(arrayName);
            const numberOfPoints = branchMappingArray.getNumberOfTuples();
            totalNumberOfPoints += numberOfPoints;
            branchMappingWithLengths.push({ branchMappingArray, numberOfPoints });
        }

        return { branchMappingWithLengths, totalNumberOfPoints };
    }

    function getUnifiedBranchPointsAndData(arrayNames, input) {
        const { branchMappingWithLengths, totalNumberOfPoints } = getBranchMappingArrays(arrayNames, input);
        const unifiedBranchPoints = getMasterPointsForBranches(input, branchMappingWithLengths, totalNumberOfPoints);
        const unifiedBranchPointData = getMasterDataForBranches(input, branchMappingWithLengths, totalNumberOfPoints);
        const unifiedBranchFieldData = getUnifiedBranchFieldData(input, totalNumberOfPoints);

        return { unifiedBranchPoints, unifiedBranchPointData, unifiedBranchFieldData };
    }

    /**
     *
     * @param {vtkPolyData} input
     * @return {{unifiedBranchPoints: vtkPoints, unifiedBranchPointData: vtkDataSetAttributes, unifiedBranchFieldData: vtkDataSetAttributes}}
     */
    function makeUnifiedDistanceBranch(input) {
        let arrayNames = [];
        switch (model.currentCenterlineBranch.centerlineBranchSelection) {
            case CenterlineDistanceBranchSelection.LEFT:
                arrayNames = [model.fieldDataArrayNames.DistanceBranchTrunk, model.fieldDataArrayNames.DistanceBranchLeft];
                break;
            case CenterlineDistanceBranchSelection.RIGHT:
                arrayNames = [model.fieldDataArrayNames.DistanceBranchTrunk, model.fieldDataArrayNames.DistanceBranchRight];
                break;
            case CenterlineDistanceBranchSelection.CUSP:
                arrayNames = [model.fieldDataArrayNames.DistanceBranchTrunkCusp];
                break;
            default:
                const error = 'Invalid CenterlineBranchSelection'
                vtkErrorMacro(error);
                throw error;
        }
        return getUnifiedBranchPointsAndData(arrayNames, input);
    }

    function makeUnifiedTransitionBranch(input) {
        let arrayNames = [];
        switch (model.currentCenterlineBranch.centerlineBranchSelection) {
            case CenterlineTransitionBranchSelection.LEFT:
                arrayNames = [model.fieldDataArrayNames.TransitionBranchTrunk, model.fieldDataArrayNames.TransitionBranchLeft];
                break;
            case CenterlineTransitionBranchSelection.RIGHT:
                arrayNames = [model.fieldDataArrayNames.TransitionBranchTrunk, model.fieldDataArrayNames.TransitionBranchRight];
                break;
            default:
                const error = 'Invalid CenterlineBranchSelection'
                vtkErrorMacro(error);
                throw error;
        }
        return getUnifiedBranchPointsAndData(arrayNames, input);
    }

    /**
     *
     * @param {vtkPolyData} input
     */
    function makeUnifiedBranch(input) {
        switch (model.currentCenterlineBranch.centerlineBranchType) {
            case CenterlineBranchType.DISTANCE:
                return makeUnifiedDistanceBranch(input);
            case CenterlineBranchType.TRANSITION:
                return makeUnifiedTransitionBranch(input);
            default:
                const error = 'Invalid CenterlineBranchType';
                vtkErrorMacro(error);
                throw error;
        }
    }

    /**
     *
     * @param inData Contains the lumen or wall centerline data.
     * @param outData Contains unified branch.
     */
    publicAPI.requestData = (inData, outData) => {
        /** @type vtkPolyData */
        const input = inData[0];

        if (!input) {
            vtkErrorMacro('Invalid or missing input');
            return;
        }

        debug(() => {
            global.unifiedInput = input;
        });

        let unifiedPolyData = vtkPolyData.newInstance();
        let { unifiedBranchPoints, unifiedBranchPointData, unifiedBranchFieldData } = makeUnifiedBranch(input);

        unifiedPolyData.setPoints(unifiedBranchPoints);
        unifiedPolyData.setPointData(unifiedBranchPointData);
        unifiedPolyData.setFieldData(unifiedBranchFieldData);

        outData[0] = unifiedPolyData;
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    currentCenterlineBranch: CenterlineBranchSelection.DISTANCE_RIGHT,
    fieldDataArrayNames: {}
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);
    macro.setGet(publicAPI, model, ['currentCenterlineBranch']); // setCurrentCenterlineBranch / getCurrentCenterlineBranch
    macro.algo(publicAPI, model, 1, 1);

    vtkUnifiedBranchFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkUnifiedBranchFilter');

export default { newInstance, extend };
