import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { lambda_type, unit_type, bool_type, list_type, union_type, record_type, option_type, class_type, string_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { S3Context$reflection } from "../Common/AwsCommon.js";
import { UserData$reflection, UserRole$reflection } from "../RAWMap.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { InstitutionStatsViewModel$reflection, InstitutionViewModel$reflection } from "../RAWMap.Models/View/Institution.js";
import { Common_SortInfo$1$reflection } from "../RAWMap.Models/Common.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../ImageManager/ImageManagerTypes.js";
import { ChildAbsentStrategy$1$reflection, DefaultActions_ChildModel$2$reflection } from "../Common/Terminate.js";
import { ErrorMessage$reflection } from "../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.20/Choice.js";
import { Notification$reflection } from "../RAWMap.Models/Api.js";
import { Msg$3$reflection } from "../Common/InboundOutbound.js";
import { ChainedChildMsg$2$reflection } from "../Common/ElmishDebugger/ChainedChildMsg.js";

export class InstitutionFileArgs extends Record {
    constructor(token, maybeCredentials, maybeS3Context) {
        super();
        this.token = token;
        this.maybeCredentials = maybeCredentials;
        this.maybeS3Context = maybeS3Context;
    }
}

export function InstitutionFileArgs$reflection() {
    return record_type("Client.Institution.Types.InstitutionFileArgs", [], InstitutionFileArgs, () => [["token", string_type], ["maybeCredentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["maybeS3Context", option_type(S3Context$reflection())]]);
}

export class UpdateArgs extends Record {
    constructor(fileArgs, token) {
        super();
        this.fileArgs = fileArgs;
        this.token = token;
    }
}

export function UpdateArgs$reflection() {
    return record_type("Client.Institution.Types.UpdateArgs", [], UpdateArgs, () => [["fileArgs", InstitutionFileArgs$reflection()], ["token", string_type]]);
}

export class Mode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["List", "Create", "Edit", "Detail", "DownloadCSV"];
    }
}

export function Mode$reflection() {
    return union_type("Client.Institution.Types.Mode", [], Mode, () => [[], [], [], [], []]);
}

export class Model extends Record {
    constructor(Mode, MaybeCurrentUser, Institutions, SelectedInstitution, EditInstitutionVm, SortInfo, StatsDateStart, StatsDateEnd, isDownloadFieldsValid, ImageManagerComponent) {
        super();
        this.Mode = Mode;
        this.MaybeCurrentUser = MaybeCurrentUser;
        this.Institutions = Institutions;
        this.SelectedInstitution = SelectedInstitution;
        this.EditInstitutionVm = EditInstitutionVm;
        this.SortInfo = SortInfo;
        this.StatsDateStart = StatsDateStart;
        this.StatsDateEnd = StatsDateEnd;
        this.isDownloadFieldsValid = isDownloadFieldsValid;
        this.ImageManagerComponent = ImageManagerComponent;
    }
}

export function Model$reflection() {
    return record_type("Client.Institution.Types.Model", [], Model, () => [["Mode", Mode$reflection()], ["MaybeCurrentUser", option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))], ["Institutions", list_type(InstitutionViewModel$reflection())], ["SelectedInstitution", option_type(InstitutionViewModel$reflection())], ["EditInstitutionVm", InstitutionViewModel$reflection()], ["SortInfo", Common_SortInfo$1$reflection(InstitutionViewModel$reflection())], ["StatsDateStart", option_type(class_type("System.DateTimeOffset"))], ["StatsDateEnd", option_type(class_type("System.DateTimeOffset"))], ["isDownloadFieldsValid", bool_type], ["ImageManagerComponent", DefaultActions_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetInstitutions", "GetInstitutionsResult", "GetInstitutionStats", "GetInstitutionStatsResult", "ToCsv", "ViewInstitution", "AddInstitution", "EditInstitution", "SaveInstitution", "DownloadStats", "ValidateStatsFields", "SetStatsDateStart", "SetStatsDateEnd", "CreateInstitutionResult", "EditInstitutionResult", "SetInstitutionVm", "UpdateSortInfo", "Cancel", "Notification", "CreateImageManagerComponent", "TerminateImageManagerComponent", "ImageManagerComponentMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Institution.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(InstitutionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(InstitutionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(InstitutionStatsViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(InstitutionStatsViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", list_type(InstitutionStatsViewModel$reflection())]], [["institutionId", class_type("System.Guid")]], [], [], [], [], [], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [InstitutionViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", InstitutionViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", lambda_type(InstitutionViewModel$reflection(), InstitutionViewModel$reflection())]], [["Item", Common_SortInfo$1$reflection(InstitutionViewModel$reflection())]], [], [["Item", Notification$reflection()]], [["Item", InstitutionViewModel$reflection()]], [], [["Item", ChainedChildMsg$2$reflection(Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]]]);
}

