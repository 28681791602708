import { logDebug } from '../js/Common';
import { Vector3 } from "../js/Vector3";
import { setRendererViewPlaneNormal } from "./AdjustCamera";

import { useEffect, useRef } from 'react';

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.Camera", isDebugging, msg);
}

function matchCamera(sourceCamera, targetCamera) {
    targetCamera.setClippingRange(sourceCamera.getClippingRange());
    targetCamera.setViewUp(sourceCamera.getViewUp());
    targetCamera.setFocalPoint(...(sourceCamera.getFocalPoint()));
    targetCamera.setPosition(...(sourceCamera.getPosition()));
    targetCamera.computeDistance(); // Must be called when the focal point or camera position changes
}

export function useCamera(container, parentContext, initialized, {
    onCameraChange,
    activeCamera,
    initialCameraAdjustment
}) {
    const context = useRef(null);

    // Allows an initial camera position to be set
    useEffect(() => {
        if (parentContext && initialCameraAdjustment) {
            log("Running camera adjustment");

            const { renderer, renderWindow } = parentContext;
            let adjustment = Vector3.ofVtk(initialCameraAdjustment);
            setRendererViewPlaneNormal(renderer, adjustment);
            renderWindow.render();
        }
    }, [initialized, initialCameraAdjustment]);

    // Side effect for setting the active camera for synchronizing viewports
    useEffect(() => {
        log(`Setting active camera: ${container}, ${parentContext}`);

        if (container && parentContext) {

            const { renderer, renderWindow } = parentContext;

            if (!context.current) {

                // Initialize

                log("Initializing camera");

                const localCamera = renderer.getActiveCamera();
                const interactor = renderWindow.getInteractor();
                interactor.bindEvents(container);

                interactor.onMouseWheel(function () {
                    if (onCameraChange) {
                        onCameraChange(renderer.getActiveCamera());
                    }
                });

                interactor.onMouseMove(function () {
                    if (onCameraChange) {
                        onCameraChange(renderer.getActiveCamera());
                    }
                });

                context.current = {
                    localCamera
                };

            } else {

                // Apply effect
                log("Setting active camera");
                const { genericRenderWindow } = parentContext;
                const renderWindow = genericRenderWindow.getRenderWindow();
                const localCamera = context.current.localCamera;

                if (activeCamera) {
                    // Setting active camera
                    context.current.syncedViewportCamera = activeCamera;

                    matchCamera(context.current.syncedViewportCamera, localCamera);

                    if (!(context.current.syncedViewportCamera === localCamera)) {
                        context.current.modifiedSubscription =
                            context.current.syncedViewportCamera.onModified(function (_) {
                                matchCamera(context.current.syncedViewportCamera, localCamera);
                            });
                    }

                }
                renderWindow.render();
            }
        }

        return () => {

            // Dispose
            if (context.current) {
                if (context.current.modifiedSubscription) {
                    context.current.modifiedSubscription.unsubscribe();
                }
            }
        };

    }, [initialized, activeCamera]);

}