import { substring, printf, toText, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.20/String.js";
import { some, map, defaultArg, value as value_2 } from "../fable_modules/fable-library.3.7.20/Option.js";
import { empty, length, skipWhile, singleton, append, tryFindIndexBack, splitAt, cons, head as head_1, tail as tail_1, isEmpty, reverse, ofArray, contains } from "../fable_modules/fable-library.3.7.20/List.js";
import { equals, stringHash } from "../fable_modules/fable-library.3.7.20/Util.js";
import { isWhiteSpace } from "../fable_modules/fable-library.3.7.20/Char.js";
import { toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.7.20/Date.js";
import { toUniversalTime, minValue } from "../fable_modules/fable-library.3.7.20/DateOffset.js";
import { awaitPromise } from "../fable_modules/fable-library.3.7.20/Async.js";
import { result } from "../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";

export function String_toOption(str) {
    if (isNullOrWhiteSpace(str)) {
        return void 0;
    }
    else {
        return str;
    }
}

export function String_fromOption(_arg) {
    if (_arg == null) {
        return null;
    }
    else {
        return value_2(_arg);
    }
}

export function String_pluralize(n) {
    if (n === 1) {
        return "";
    }
    else {
        return "s";
    }
}

export function String_aAn(value) {
    if (isNullOrWhiteSpace(value)) {
        return "a";
    }
    else if (contains(value.toLocaleLowerCase()[0], ofArray(["a", "e", "i", "o", "u"]), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })) {
        return "an";
    }
    else {
        return "a";
    }
}

export function String_orList(strings) {
    let _arg;
    return join(", ", (_arg = reverse(strings), (!isEmpty(_arg)) ? (isEmpty(tail_1(_arg)) ? strings : reverse(cons(toText(printf("or ")) + head_1(_arg), tail_1(_arg)))) : strings));
}

export const String_toOption_ = [String_toOption, String_fromOption];

export const String_fromOption_ = [String_fromOption, String_toOption];

export function String_$007CPrefix$007C_$007C(p, s) {
    if (s.indexOf(p) === 0) {
        return substring(s, p.length);
    }
    else {
        return void 0;
    }
}

function String_runWordWrap(wrapped_mut, toWrap_mut, maxCharacters_mut) {
    String_runWordWrap:
    while (true) {
        const wrapped = wrapped_mut, toWrap = toWrap_mut, maxCharacters = maxCharacters_mut;
        const maxIndex = maxCharacters | 0;
        const patternInput = splitAt(maxIndex, toWrap);
        const wrapping = patternInput[0];
        const patternInput_1 = splitAt(defaultArg(tryFindIndexBack(isWhiteSpace, wrapping), maxIndex), wrapping);
        const nextWrapped = append(wrapped, append(singleton("\n"), patternInput_1[0]));
        const nextToWrap = append(skipWhile(isWhiteSpace, patternInput_1[1]), patternInput[1]);
        if (length(nextToWrap) < maxCharacters) {
            return append(nextWrapped, append(singleton("\n"), nextToWrap));
        }
        else {
            wrapped_mut = nextWrapped;
            toWrap_mut = nextToWrap;
            maxCharacters_mut = maxCharacters;
            continue String_runWordWrap;
        }
        break;
    }
}

export function String_wordWrap(maxCharacters, toWrap) {
    const toWrap_1 = toWrap.trim();
    if (toWrap_1.length < maxCharacters) {
        return toWrap_1;
    }
    else {
        return join("", String_runWordWrap(empty(), toList(toWrap_1.split("")), maxCharacters));
    }
}

export function Option_mapString(mapping, option) {
    return String_fromOption(map(mapping, option));
}

export function Option_ofDu(duCase) {
    return [(du) => (equals(du, duCase) ? (void 0) : some(du)), (maybeDu) => defaultArg(maybeDu, duCase)];
}

export function Option_ofBoolAndValue(isSuccess, value) {
    if (isSuccess) {
        return some(value);
    }
    else {
        return void 0;
    }
}

export function DateTimeOffset_toUtcOption(dto) {
    if (equals_1(dto, minValue())) {
        return void 0;
    }
    else {
        return toUniversalTime(dto);
    }
}

export function DateTimeOffset_ofOptionToUtc(_arg) {
    if (_arg == null) {
        return minValue();
    }
    else {
        return toUniversalTime(_arg);
    }
}

export const DateTimeOffset_toUtcOption_ = [DateTimeOffset_toUtcOption, DateTimeOffset_ofOptionToUtc];

export const DateTimeOffset_ofOptionToUtc_ = [DateTimeOffset_ofOptionToUtc, DateTimeOffset_toUtcOption];

export function AsyncResult_ofPromise() {
    return (arg_1) => awaitPromise(result(arg_1));
}

