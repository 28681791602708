import { roundNumber } from 'vtk.js/Sources/Common/Core/Math';

export class Vector3 {
    constructor({ x, y, z }) {
        this._x = x;
        this._y = y;
        this._z = z;
    }

    get z() {
        return this._z;
    }

    set z(value) {
        this._z = value;
    }

    get y() {
        return this._y;
    }

    set y(value) {
        this._y = value;
    }

    get x() {
        return this._x;
    }

    set x(value) {
        this._x = value;
    }

    static ofVtk(/*number[]*/ values) {
        return new Vector3({ x: values[0], y: values[1], z: values[2] });
    }

    static ofBounds(/*number[]*/ values) {
        let min = new Vector3({ x: values[0], y: values[2], z: values[4] });
        let max = new Vector3({ x: values[1], y: values[3], z: values[5] });
        return { min, max };
    }

    static midBounds(/*Vector3*/ min, /*Vector3*/ max) {
        function center(p1, p2) {
            let baseCenter = (p1 + p2) / 2;
            return roundNumber(baseCenter);
        }

        let centerX = center(min.x, max.x);
        let centerY = center(min.y, max.y);
        let centerZ = center(min.z, max.z);

        return new Vector3({ x: centerX, y: centerY, z: centerZ });
    }

    static unitX() {
        return [1, 0, 0];
    }

    static unitY() {
        return [0, 1, 0];
    }

    static unitZ() {
        return [0, 0, 1];
    }

    toVtk() {
        return [this._x, this._y, this._z];
    }
}