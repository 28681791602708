import { value as value_5, toArray, bind, map, defaultArg } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { Option, content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { isEmpty, tryFind, empty, ofArray, singleton, append } from "../fable_modules/fable-library.3.7.20/List.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import * as react from "react";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { PatientViewModel_get_id_ } from "../RAWMap.Models/View/Patient.js";
import { InstitutionViewModel_get_name_ } from "../RAWMap.Models/View/Institution.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Controls_multiVerticalTileColumnFlex, Controls_dropdownButton, Controls_buttonDropdownText, Controls_itemList } from "../Common/General.js";
import { equals, int32ToString } from "../fable_modules/fable-library.3.7.20/Util.js";
import { StudyViewModelModule_status_, StudyViewModelModule_studyCreationDate_, StudyViewModelModule_studyNumber_ } from "../RAWMap.Models/View/Study.js";
import { Common_Treatment, Common_YesNo, Common_StudyStatus_get_display, DateTimeOffset_yyyyMMdd } from "../RAWMap.Models/Common.js";
import { Msg } from "./StudyTypes.js";
import { button, Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Option as Option_2 } from "../fable_modules/Fulma.2.16.0/Components/Dropdown.fs.js";
import { EditMode } from "../Patient/PatientTypes.js";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { card as card_1 } from "./CoreForm/CoreFormView.js";
import { card as card_2 } from "./AnalysisForm/AnalysisFormView.js";
import { Common_GenericOption, Modifier_IModifier, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { view as view_1 } from "./CustomReport/CustomReportView.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { helpToolTipsCardOption, staticNotesCard, allMeasurementsCards as allMeasurementsCards_1, view as view_2 } from "../Visualization/VisualizationView.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { wrapLocalMsg } from "../Common/InboundOutbound.js";
import { LocalMsg } from "../Visualization/VisualizationTypes.js";
import { Option as Option_3, container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";

export function coreIsBeingModified(model) {
    return defaultArg(map((core) => {
        const matchValue = core.Mode;
        switch (matchValue.tag) {
            case 1:
            case 2: {
                return true;
            }
            default: {
                return false;
            }
        }
    }, model.CoreFormComponent), false);
}

export function analysisIsBeingModified(model) {
    return defaultArg(map((ma) => {
        const matchValue = ma.Mode;
        switch (matchValue.tag) {
            case 1:
            case 2: {
                return true;
            }
            default: {
                return false;
            }
        }
    }, model.AnalysisFormComponent), false);
}

export function coreStudyIsBeingDownloaded(model) {
    return defaultArg(map((core) => {
        if (core.Mode.tag === 4) {
            return true;
        }
        else {
            return false;
        }
    }, model.CoreFormComponent), false);
}

export function disableButton(model, perm) {
    let user;
    let e_3;
    let e_1;
    const matchValue = model.MaybeCurrentUser;
    let pattern_matching_result;
    if (matchValue != null) {
        if ((user = matchValue, isAllowed(perm)(user.roles))) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            e_1 = false;
            break;
        }
        case 1: {
            e_1 = true;
            break;
        }
    }
    e_3 = (coreIsBeingModified(model) || e_1);
    return analysisIsBeingModified(model) || e_3;
}

export function patientDetails(patient, institution, maybePostEvar, _dispatch) {
    return toList(delay(() => {
        const arrangeContentWithHtmlProps = (htmlProps, tag, value) => {
            let children_2, children;
            return content(singleton(new Option(2, append(singleton(["style", {
                overflowWrap: "anywhere",
            }]), htmlProps))), ofArray([(children_2 = [(children = [toText(printf("%s: "))(tag)], react.createElement("b", {}, ...children))], react.createElement("p", {}, ...children_2)), toText(printf("%s"))(value)]));
        };
        const arrangeContent = (tag_3, value_1) => arrangeContentWithHtmlProps(empty(), tag_3, value_1);
        return append_1(singleton_1(arrangeContent("Internal Patient ID", Optic_Get_op_HatDot_21762A61(new Optic_Get(0), PatientViewModel_get_id_())(patient))), delay(() => append_1(singleton_1(arrangeContent("Institution", Optic_Get_op_HatDot_21762A61(new Optic_Get(0), InstitutionViewModel_get_name_())(institution))), delay(() => {
            const matchValue = maybePostEvar;
            return (matchValue == null) ? singleton_1(arrangeContent("Post EVAR", "--")) : ((matchValue.tag === 1) ? singleton_1(arrangeContent("Post EVAR", "No")) : singleton_1(arrangeContentWithHtmlProps(singleton(new HTMLAttr(65, "post-evar")), "Post EVAR", "Yes")));
        }))));
    }));
}

export function existingStudies(currentStudyId, studies, dispatch) {
    let f2_2;
    const children = [Controls_itemList(ofArray([["Study Number", (arg_2) => int32ToString(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_studyNumber_)(arg_2))], ["Created", (arg_4) => DateTimeOffset_yyyyMMdd(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_studyCreationDate_)(arg_4))], ["Status", (f2_2 = Common_StudyStatus_get_display(), (arg_6) => f2_2(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_status_)(arg_6)))]]), (item_1, _arg) => {
        dispatch(new Msg(20, item_1));
    }, studies, (item) => {
        let pattern_matching_result;
        if (currentStudyId != null) {
            if (currentStudyId === item.studyId) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return delay(() => singleton_1(new HTMLAttr(64, "is-selected")));
            }
            case 1: {
                const matchValue = item.status;
                switch (matchValue.tag) {
                    case 0: {
                        return delay(() => singleton_1(new HTMLAttr(64, "has-background-warning")));
                    }
                    case 1: {
                        return delay(() => singleton_1(new HTMLAttr(64, "has-background-danger")));
                    }
                    default: {
                        return empty_1();
                    }
                }
            }
        }
    })];
    return react.createElement("div", {
        className: "table-container",
    }, ...children);
}

export function existingStudiesSmall(currentStudyId, studies, dispatch) {
    const children = [Controls_itemList(ofArray([["Study Number", (arg_2) => int32ToString(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_studyNumber_)(arg_2))], ["Created", (arg_4) => DateTimeOffset_yyyyMMdd(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_studyCreationDate_)(arg_4))]]), (item_1, _arg) => {
        dispatch(new Msg(20, item_1));
    }, studies, (item) => {
        let pattern_matching_result;
        if (currentStudyId != null) {
            if (currentStudyId === item.studyId) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return delay(() => singleton_1(new HTMLAttr(64, "is-selected")));
            }
            case 1: {
                return empty_1();
            }
        }
    })];
    return react.createElement("div", {
        className: "table-container",
    }, ...children);
}

export function editView(model, patient, institution, dispatch) {
    let props_2, children_2, props, children;
    const noEditPatientPermission = disableButton(model, new Permission(16));
    const creatingCustomReport = model.CustomReportComponent != null;
    let patientInfoCard;
    const cardButton = Controls_buttonDropdownText("Edit Patient")(singleton(new Option_1(16, noEditPatientPermission ? true : creatingCustomReport)))(toList(delay(() => append_1(singleton_1(new Option_2(2)), delay(() => ((!noEditPatientPermission) ? singleton_1(new Option_2(1)) : empty_1()))))))(ofArray([new Controls_dropdownButton(new DOMAttr(40, (_arg) => {
        dispatch(new Msg(23, patient, new EditMode(0)));
    }), "Update Institution Patient ID"), new Controls_dropdownButton(new DOMAttr(40, (_arg_1) => {
        dispatch(new Msg(23, patient, new EditMode(1)));
    }), "Update Patient Name and Date of Birth")]));
    patientInfoCard = singleton(card(empty(), toList(delay(() => append_1(singleton_1(header(empty(), ofArray([Header_title(empty(), singleton("Patient Details")), Header_icon(empty(), singleton(cardButton))]))), delay(() => {
        let _arg_2;
        return singleton_1(content_1(empty(), patientDetails(patient, institution, bind((study_3) => {
            const matchValue = study_3.postTreatTreatmentType;
            if (matchValue != null) {
                if (matchValue.tag === 1) {
                    return new Common_YesNo(0);
                }
                else {
                    return new Common_YesNo(1);
                }
            }
            else {
                return void 0;
            }
        }, (_arg_2 = tryFind((study) => equals(study.postTreatTreatmentType, new Common_Treatment(1)), model.Studies), (_arg_2 == null) ? tryFind((study_2) => (study_2.postTreatTreatmentType != null), model.Studies) : _arg_2)), dispatch)));
    }))))));
    const canEditStudies = disableButton(model, new Permission(13));
    const addAnalysis = card(empty(), singleton(header(empty(), singleton(Header_icon(empty(), singleton(button(ofArray([new Option_1(16, disableButton(model, new Permission(24))), new Option_1(4), new Option_1(17, ofArray([new DOMAttr(40, (_arg_3) => {
        dispatch(new Msg(9));
    }), ["style", {
        backgroundColor: "#F0F0F0",
    }]]))]), singleton("Create Analysis"))))))));
    const isAnalysisBeingModified = (model.AnalysisFormComponent != null) ? ((model.AnalysisFormComponent.Mode.tag === 1) ? false : (!(model.AnalysisFormComponent.Mode.tag === 2))) : true;
    const studyCard = bind((coreModel) => card_1(coreModel, isAnalysisBeingModified, (perm) => disableButton(model, perm), (arg_1) => {
        dispatch(new Msg(1, arg_1));
    }), model.CoreFormComponent);
    let analysisCard;
    const existingAnalysis = bind((analysisModel) => card_2(analysisModel, (perm_1) => disableButton(model, perm_1), (arg_3) => {
        dispatch(new Msg(4, arg_3));
    }), model.AnalysisFormComponent);
    const matchValue_1 = [existingAnalysis, model.CoreFormComponent];
    let pattern_matching_result;
    if (matchValue_1[0] == null) {
        if (matchValue_1[1] != null) {
            if (matchValue_1[1].Mode.tag === 3) {
                if (disableButton(model, new Permission(24))) {
                    pattern_matching_result = 2;
                }
                else if (matchValue_1[1].SelectedStudy.status.tag === 3) {
                    pattern_matching_result = 3;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else if (matchValue_1[1] != null) {
        if (matchValue_1[1].Mode.tag === 3) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 5;
    }
    switch (pattern_matching_result) {
        case 0: {
            analysisCard = existingAnalysis;
            break;
        }
        case 1: {
            analysisCard = (void 0);
            break;
        }
        case 2: {
            analysisCard = (void 0);
            break;
        }
        case 3: {
            analysisCard = addAnalysis;
            break;
        }
        case 4: {
            analysisCard = (void 0);
            break;
        }
        case 5: {
            analysisCard = existingAnalysis;
            break;
        }
    }
    let existingStudiesCard;
    const studyGrid = existingStudies(model.StudyGuid, model.Studies, dispatch);
    const cardButton_1 = singleton(button(ofArray([new Option_1(4), new Option_1(16, canEditStudies ? true : creatingCustomReport), new Option_1(17, ofArray([new DOMAttr(40, (_arg_4) => {
        dispatch(new Msg(25));
    }), ["style", {
        backgroundColor: "#F0F0F0",
    }]]))]), singleton("Create Study")));
    existingStudiesCard = singleton(card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Existing Studies")), Header_icon(empty(), cardButton_1)])), content_1(empty(), singleton(studyGrid))])));
    let maybeStudyAlertCard;
    const matchValue_2 = model.CustomReportModel;
    let pattern_matching_result_1;
    if (matchValue_2 != null) {
        if (defaultArg(matchValue_2.maybeLastReportOutdated, false)) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            maybeStudyAlertCard = singleton(card(empty(), singleton(content_1(ofArray([new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(7)))), new Common_GenericOption(1, singleton(["style", {
                padding: "0.5rem 1rem",
            }]))]), singleton("Data has changed since the last time the custom report was generated")))));
            break;
        }
        case 1: {
            maybeStudyAlertCard = (void 0);
            break;
        }
    }
    let displayCards;
    const matchValue_5 = model.VisualizationComponent;
    if (matchValue_5 == null) {
        displayCards = toList(delay(() => append_1(ofArray(toArray(analysisCard)), delay(() => ofArray(toArray(studyCard))))));
    }
    else {
        const visualizationModel_1 = matchValue_5;
        let patternInput_1;
        const matchValue_6 = [model.CustomReportComponent, model.CustomReportModel];
        let pattern_matching_result_2, customReportComponentModel, customReportModel_2;
        if (matchValue_6[0] == null) {
            pattern_matching_result_2 = 1;
        }
        else if (matchValue_6[1] == null) {
            pattern_matching_result_2 = 1;
        }
        else {
            pattern_matching_result_2 = 0;
            customReportComponentModel = matchValue_6[0];
            customReportModel_2 = matchValue_6[1];
        }
        switch (pattern_matching_result_2) {
            case 0: {
                patternInput_1 = ["none", view_1(customReportModel_2, customReportComponentModel, model.MaybeCurrentUser, (arg_16) => {
                    dispatch(new Msg(13, arg_16));
                })];
                break;
            }
            case 1: {
                patternInput_1 = ["block", Helpers_nothing];
                break;
            }
        }
        displayCards = ofArray([(props_2 = [["style", {
            display: patternInput_1[0],
        }]], (children_2 = [view_2(visualizationModel_1, (arg_18) => {
            dispatch(new Msg(12, arg_18));
        })], react.createElement("div", keyValueList(props_2, 1), ...children_2))), patternInput_1[1]]);
    }
    let patternInput;
    const rightCards = singleton(singleton((props = [["style", {}]], (children = toList(delay(() => {
        let matchValue_3;
        return append_1((matchValue_3 = maybeStudyAlertCard, (matchValue_3 != null) ? matchValue_3 : ((empty_1()))), delay(() => displayCards));
    })), react.createElement("div", keyValueList(props, 1), ...children)))));
    const matchValue_4 = [displayCards, model.VisualizationComponent, model.CustomReportComponent];
    let pattern_matching_result_3, visualizationModel;
    if (isEmpty(matchValue_4[0])) {
        pattern_matching_result_3 = 0;
    }
    else if (matchValue_4[1] != null) {
        if (matchValue_4[2] == null) {
            pattern_matching_result_3 = 2;
            visualizationModel = matchValue_4[1];
        }
        else {
            pattern_matching_result_3 = 1;
        }
    }
    else {
        pattern_matching_result_3 = 1;
    }
    switch (pattern_matching_result_3) {
        case 0: {
            patternInput = [singleton(patientInfoCard), singleton(existingStudiesCard)];
            break;
        }
        case 1: {
            patternInput = [ofArray([patientInfoCard, existingStudiesCard]), rightCards];
            break;
        }
        case 2: {
            const allMeasurementsCards = allMeasurementsCards_1(visualizationModel, (arg_6) => {
                dispatch(new Msg(12, arg_6));
            });
            const notesCard = staticNotesCard(visualizationModel, (arg_11) => {
                dispatch(new Msg(12, wrapLocalMsg(new LocalMsg(19, arg_11))));
            });
            const maybeHelpToolTipsCard = helpToolTipsCardOption(visualizationModel, (arg_14) => {
                dispatch(new Msg(12, wrapLocalMsg(arg_14)));
            });
            patternInput = [toList(delay(() => append_1((maybeHelpToolTipsCard != null) ? singleton_1(value_5(maybeHelpToolTipsCard)) : empty_1(), delay(() => append_1(allMeasurementsCards, delay(() => singleton_1(notesCard))))))), rightCards];
            break;
        }
    }
    return Controls_multiVerticalTileColumnFlex([new HTMLAttr(99, "study-edit-view")], "380px", "360px", patternInput[0], singleton(["style", {
        maxHeight: "calc(100vh - 102px - 5.5rem)",
        overflowY: "auto",
        overflowX: "visible",
    }]), patternInput[1], singleton(["style", {
        overflowY: "hidden",
    }]));
}

export function view(_pid, model, dispatch) {
    let props_10, children_10, props_12, props_6, props_8, props_2, children_2, props_4;
    if ((model.isStudiesLoading ? true : model.isAnalysisLoading) && (model.CoreFormComponent == null)) {
        const props = [["style", {
            minHeight: "10em",
        }], new HTMLAttr(64, "spinner is-loading")];
        return react.createElement("div", keyValueList(props, 1));
    }
    else {
        const matchValue = [model.SelectedPatient, model.PatientInstitution];
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                const institution = matchValue[1];
                const patient = matchValue[0];
                const matchValue_1 = [model.StudyGuid, model.CoreFormComponent];
                let pattern_matching_result, guid;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] == null) {
                        pattern_matching_result = 0;
                        guid = matchValue_1[0];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return container(empty(), singleton(content(empty(), ofArray([(props_10 = [["style", {
                            textAlign: "center",
                        }]], (children_10 = [toText(printf("Study with ID %O not found"))(guid)], react.createElement("p", keyValueList(props_10, 1), ...children_10))), (props_12 = [["style", {
                            textAlign: "center",
                        }]], react.createElement("p", keyValueList(props_12, 1), "Either study does not exist or you lack permission to access it"))]))));
                    }
                    case 1: {
                        const children_14 = [container(singleton(new Option_3(0)), singleton(editView(model, patient, institution, dispatch)))];
                        return react.createElement("div", {}, ...children_14);
                    }
                }
            }
            else {
                return container(empty(), singleton(content(empty(), ofArray([(props_6 = [["style", {
                    textAlign: "center",
                }]], react.createElement("p", keyValueList(props_6, 1), "Institution not found")), (props_8 = [["style", {
                    textAlign: "center",
                }]], react.createElement("p", keyValueList(props_8, 1), "Either institution does not exist or you lack permission to access it"))]))));
            }
        }
        else {
            return container(empty(), singleton(content(empty(), ofArray([(props_2 = [["style", {
                textAlign: "center",
            }]], (children_2 = [toText(printf("Patient with ID %O not found"))(model.PatientGuid)], react.createElement("p", keyValueList(props_2, 1), ...children_2))), (props_4 = [["style", {
                textAlign: "center",
            }]], react.createElement("p", keyValueList(props_4, 1), "Either patient does not exist or you lack permission to access them"))]))));
        }
    }
}

