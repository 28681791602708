import { Vector3 } from "../js/Vector3";
import vtkMath from "vtk.js/Sources/Common/Core/Math";
import { vec3 } from "gl-matrix";
import vtkPlaneSource from 'vtk.js/Sources/Filters/Sources/PlaneSource';
import { Representation } from 'vtk.js/Sources/Rendering/Core/Property/Constants';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';

import { useEffect, useRef } from 'react';

import { logDebug, constants } from '../js/Common';

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.SlicePlane", isDebugging, msg);
}

export function useSlicePlane(parentContext, { bounds, isVisible }) {

    const context = useRef(null);

    // Effect to render the slice plane based on input bounds
    useEffect(() => {
        if (parentContext) {
            log("Updating plane");
            const { genericRenderWindow } = parentContext;
            const renderWindow = genericRenderWindow.getRenderWindow();

            if (bounds) {

                if (!context.current) {

                    log("Creating plane");

                    // Set up the plane

                    let boundingVectors = Vector3.ofBounds(bounds);
                    const planeSize =
                        vtkMath.subtract(boundingVectors.max.toVtk(),
                            boundingVectors.min.toVtk(),
                            vec3.create())[0] * (3 / 8);

                    const planeSource = vtkPlaneSource.newInstance({
                        xResolution: constants.planeResolution,
                        yResolution: constants.planeResolution,
                        point1: [planeSize, 0, 0],
                        point2: [0, planeSize, 0]
                    });

                    const renderer = genericRenderWindow.getRenderer();
                    const planeMapper = vtkMapper.newInstance();
                    planeMapper.setInputConnection(planeSource.getOutputPort());

                    const planeActor = vtkActor.newInstance();
                    planeActor.getProperty().setEdgeVisibility(true);
                    planeActor.getProperty().setLighting(false);
                    planeActor.getProperty().setRepresentation(Representation.WIREFRAME);
                    planeActor.setMapper(planeMapper);

                    if (isVisible) {
                        renderer.addActor(planeActor);
                    }

                    context.current = {
                        planeMapper,
                        planeActor,
                        planeSource
                    }
                }

                // Update plane
                let boundingVectors = Vector3.ofBounds(bounds);
                let centerBounds = Vector3.midBounds(boundingVectors.min, boundingVectors.max);
                context.current.planeSource.setCenter(centerBounds.toVtk());
                context.current.planeSource.setNormal(Vector3.unitZ());
            }

            renderWindow.render();
        }
    }, [isVisible, bounds]);

    // Effect to show/hide the slice plane
    useEffect(() => {
        if (parentContext && context.current) {
            const { genericRenderWindow } = parentContext;
            const renderer = genericRenderWindow.getRenderer();
            const renderWindow = genericRenderWindow.getRenderWindow();

            if (isVisible === true) {
                renderer.addActor(context.current.planeActor);
            } else {
                renderer.removeActor(context.current.planeActor);
            }

            renderWindow.render();
        }
    }, [isVisible]);
}