import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { Mqtt_MqttConfiguration$reflection } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { union_type, string_type, record_type, class_type, option_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";

export class Model extends Record {
    constructor(Settings, Client) {
        super();
        this.Settings = Settings;
        this.Client = Client;
    }
}

export function Model$reflection() {
    return record_type("Client.Notifications.Types.Model", [], Model, () => [["Settings", option_type(Mqtt_MqttConfiguration$reflection())], ["Client", option_type(class_type("Fable.Import.Mqtt.Client"))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Error", "Authenticated", "GotSettings", "Connected", "Failed"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Notifications.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Mqtt_MqttConfiguration$reflection()]], [["Item", class_type("Fable.Import.Mqtt.Client")]], [["Item", string_type]]]);
}

