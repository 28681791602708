import { DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_terminate, ChildMsg_ofMsg, DefaultActions_create, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../Common/Terminate.js";
import { ofArray, singleton, sort, allPairs, map } from "../fable_modules/fable-library.3.7.20/List.js";
import { GeneratedReportType_get_describeOrDefault, GeneratedReportType_get_all, UniqueDeviceIdentifierViewModel } from "../RAWMap.Models/View/UniqueDeviceIdentifier.js";
import { InstitutionClinicalDesignation_get_describeOrDefault, InstitutionClinicalDesignation_get_all } from "../RAWMap.Models/View/Institution.js";
import { Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Msg, Model } from "./UniqueDeviceIdentifierTypes.js";
import { Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../FileManagement/Shared.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { update as update_1, tryTerminate, init as init_1 } from "../ImageManager/ImageManagerState.js";
import { FileOperationContext } from "../RAWMap.Models/Api.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { wrapInboundMsg } from "../Common/InboundOutbound.js";
import { InboundMsg } from "../ImageManager/ImageManagerTypes.js";
import { Toast_errorToast } from "../Common/General.js";
import { FileManagementUpdateArgs } from "../FileManagement/FileManagementTypes.js";
import { S3Context } from "../Common/AwsCommon.js";
import { some } from "../fable_modules/fable-library.3.7.20/Option.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.ImageManagerComponent.child));

export const udis = map((tupledArg) => (new UniqueDeviceIdentifierViewModel(tupledArg[1], tupledArg[0])), allPairs(GeneratedReportType_get_all(), InstitutionClinicalDesignation_get_all()));

export function init() {
    return [new Model(void 0, udis, new Common_SortInfo$1("", new Common_SortDirection(1), (list) => sort(list, {
        Compare: compare,
    })), new DefaultActions_ChildModel$2(new Child$1(1), childAbsentStrategy, "UDI Barcode Component")), Cmd_none()];
}

export function update(args, msg, model) {
    let arg_2, arg_1, inputRecord;
    switch (msg.tag) {
        case 1: {
            return [new Model(msg.fields[0], model.Udis, model.SortInfo, model.ImageManagerComponent), Cmd_none()];
        }
        case 2: {
            return [model, Cmd_none()];
        }
        case 3: {
            return [new Model(model.MaybeCurrentUser, model.Udis, msg.fields[0], model.ImageManagerComponent), Cmd_none()];
        }
        case 4: {
            const udi = msg.fields[0];
            const fileSpecs = new FileComponentSpecs(singleton(new FileType(3)), (arg_2 = InstitutionClinicalDesignation_get_describeOrDefault()(udi.clinicalDesignation), (arg_1 = GeneratedReportType_get_describeOrDefault()(udi.reportType), toText(printf("UDI/%s-%s/"))(arg_1)(arg_2))), new MaximumFiles(1, 1));
            const createImageManagerComponent = (model_1) => {
                const createdImageManager = init_1(fileSpecs, new FileOperationContext(5));
                const patternInput = DefaultActions_create(createdImageManager[0], createdImageManager[1], model_1.ImageManagerComponent);
                return [new Model(model_1.MaybeCurrentUser, model_1.Udis, model_1.SortInfo, patternInput[0]), Cmd_batch(ofArray([Cmd_map((arg_3) => (new Msg(6, arg_3)), patternInput[1]), Cmd_OfFunc_result(new Msg(6, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg(0)))))]))];
            };
            const matchValue = model.ImageManagerComponent.child;
            switch (matchValue.tag) {
                case 1:
                case 2: {
                    return createImageManagerComponent(model);
                }
                default: {
                    const termination = tryTerminate(matchValue.fields[0]);
                    if (termination.tag === 1) {
                        return [model, Toast_errorToast(toText(printf("%s was unable to switch unique device identifiers."))(model.ImageManagerComponent.childComponentName))];
                    }
                    else {
                        termination.fields[0]();
                        return createImageManagerComponent(new Model(model.MaybeCurrentUser, model.Udis, model.SortInfo, (inputRecord = model.ImageManagerComponent, new DefaultActions_ChildModel$2(new Child$1(2), inputRecord.childAbsentStrategy, inputRecord.childComponentName))));
                    }
                }
            }
        }
        case 5: {
            const patternInput_1 = DefaultActions_terminate(tryTerminate, model.ImageManagerComponent);
            return [new Model(model.MaybeCurrentUser, model.Udis, model.SortInfo, patternInput_1[0]), Cmd_map((arg_6) => (new Msg(6, arg_6)), patternInput_1[1])];
        }
        case 6: {
            const patternInput_2 = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_5, model_4) => {
                let inputRecord_1;
                const matchValue_1 = [args.fileArgs.maybeCredentials, args.fileArgs.maybeS3Context];
                let pattern_matching_result, context, credentials;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] != null) {
                        pattern_matching_result = 0;
                        context = matchValue_1[1];
                        credentials = matchValue_1[0];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        const args_1 = new FileManagementUpdateArgs(args.fileArgs.token, credentials, context);
                        if (msg_5.tag === 1) {
                            return [model_4, Cmd_none()];
                        }
                        else {
                            return update_1(new FileManagementUpdateArgs(args_1.Token, args_1.Credentials, (inputRecord_1 = args_1.Context, new S3Context(inputRecord_1.S3Config, inputRecord_1.S3Client, inputRecord_1.S3Expiry, args_1.Context.ActiveBucket))), msg_5.fields[0], model_4);
                        }
                    }
                    case 1: {
                        return [model_4, Toast_errorToast("Missing Credentials or S3 Context")];
                    }
                }
            }, model), model.ImageManagerComponent, msg.fields[0]);
            return [new Model(model.MaybeCurrentUser, model.Udis, model.SortInfo, patternInput_2[0]), Cmd_map((arg_7) => (new Msg(6, arg_7)), patternInput_2[1])];
        }
        default: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
    }
}

