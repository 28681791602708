import { Record, Union } from "../fable_modules/fable-library.3.7.20/Types.js";
import { bool_type, string_type, option_type, list_type, record_type, int32_type, union_type, float64_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { endsWith, interpolate, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { exists, collect, ofArray, singleton } from "../fable_modules/fable-library.3.7.20/List.js";
import { ComponentVisibility, ComponentVisibility$reflection } from "../Common/Controls.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.20/Option.js";
import { FileData$reflection } from "../Common/FileData.js";

export class FileManagementProgress extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InProgress", "Done"];
    }
}

export function FileManagementProgress$reflection() {
    return union_type("RAWMap.Client.FileManagement.Shared.FileManagementProgress", [], FileManagementProgress, () => [[["Item", float64_type]], []]);
}

export function FileManagementProgress_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Uploaded" : toText(interpolate("%.0f%P()%% uploaded", [_arg.fields[0]])));
}

export class FileType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Zip", "Pdf", "Jpeg", "Png"];
    }
}

export function FileType$reflection() {
    return union_type("RAWMap.Client.FileManagement.Shared.FileType", [], FileType, () => [[], [], [], []]);
}

export function FileType_get_getExtensions() {
    return (_arg) => ((_arg.tag === 1) ? singleton(".pdf") : ((_arg.tag === 2) ? ofArray([".jpg", ".jpeg"]) : ((_arg.tag === 3) ? singleton(".png") : singleton(".zip"))));
}

export function FileType_asExtensions_DC44A4C(fileTypes) {
    return collect(FileType_get_getExtensions(), fileTypes);
}

export class MaximumFiles extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Unlimited", "Limited"];
    }
}

export function MaximumFiles$reflection() {
    return union_type("RAWMap.Client.FileManagement.Shared.MaximumFiles", [], MaximumFiles, () => [[], [["Item", int32_type]]]);
}

export class FileComponentVisibility extends Record {
    constructor(deleteButtonVisibility, downloadButtonVisibility, uploadComponentVisibility, uploadButtonVisibility) {
        super();
        this.deleteButtonVisibility = deleteButtonVisibility;
        this.downloadButtonVisibility = downloadButtonVisibility;
        this.uploadComponentVisibility = uploadComponentVisibility;
        this.uploadButtonVisibility = uploadButtonVisibility;
    }
}

export function FileComponentVisibility$reflection() {
    return record_type("RAWMap.Client.FileManagement.Shared.FileComponentVisibility", [], FileComponentVisibility, () => [["deleteButtonVisibility", ComponentVisibility$reflection()], ["downloadButtonVisibility", ComponentVisibility$reflection()], ["uploadComponentVisibility", ComponentVisibility$reflection()], ["uploadButtonVisibility", ComponentVisibility$reflection()]]);
}

export function FileComponentVisibility_downloadOnly_Z510FEC72(downloadButtonVisibility) {
    return new FileComponentVisibility(new ComponentVisibility(1), downloadButtonVisibility, new ComponentVisibility(1), new ComponentVisibility(1));
}

export function FileComponentVisibility_get_fileSystemUpload() {
    return new FileComponentVisibility(new ComponentVisibility(0), new ComponentVisibility(0), new ComponentVisibility(0), new ComponentVisibility(0));
}

export function FileComponentVisibility_get_autoZipUpload() {
    return new FileComponentVisibility(new ComponentVisibility(0), new ComponentVisibility(0), new ComponentVisibility(0), new ComponentVisibility(1));
}

export class FileComponentSpecs extends Record {
    constructor(maybeFileTypeRestrictions, uploadDestinationPath, maxFiles) {
        super();
        this.maybeFileTypeRestrictions = maybeFileTypeRestrictions;
        this.uploadDestinationPath = uploadDestinationPath;
        this.maxFiles = maxFiles;
    }
}

export function FileComponentSpecs$reflection() {
    return record_type("RAWMap.Client.FileManagement.Shared.FileComponentSpecs", [], FileComponentSpecs, () => [["maybeFileTypeRestrictions", option_type(list_type(FileType$reflection()))], ["uploadDestinationPath", string_type], ["maxFiles", MaximumFiles$reflection()]]);
}

export function FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(this$, fileName) {
    return defaultArg(map((arg_1) => exists((extension) => endsWith(fileName, extension), FileType_asExtensions_DC44A4C(arg_1)), this$.maybeFileTypeRestrictions), true);
}

export class UploadSelection extends Record {
    constructor(files, triggerUpload) {
        super();
        this.files = files;
        this.triggerUpload = triggerUpload;
    }
}

export function UploadSelection$reflection() {
    return record_type("RAWMap.Client.FileManagement.Shared.UploadSelection", [], UploadSelection, () => [["files", list_type(FileData$reflection())], ["triggerUpload", bool_type]]);
}

