import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class ChainedChildMsg$2 extends Record {
    constructor(elmishChainedMsg, heldData) {
        super();
        this.elmishChainedMsg = elmishChainedMsg;
        this.heldData = heldData;
    }
}

export function ChainedChildMsg$2$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Common.ElmishDebugger.ChainedChildMsg`2", [gen0, gen1], ChainedChildMsg$2, () => [["elmishChainedMsg", gen0], ["heldData", gen1]]);
}

