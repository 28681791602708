import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { Visualization_Centerline$reflection, Visualization_UnifiedTransitionBranch$reflection, Visualization_UnifiedDistanceBranch$reflection } from "../../RAWMap.Models/Common.js";
import { array_type, record_type, class_type, int32_type, union_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { Bindings_CenterlinePointMeasurement$reflection, Bindings_PlaneIdentifier$reflection } from "../../RAWMap.Models/View/CenterlineMeasurement.js";

export class Branch extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnifiedDistanceBranch", "UnifiedTransitionBranch"];
    }
}

export function Branch$reflection() {
    return union_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.Branch", [], Branch, () => [[["Item", Visualization_UnifiedDistanceBranch$reflection()]], [["Item", Visualization_UnifiedTransitionBranch$reflection()]]]);
}

export class CenterlinePlane extends Record {
    constructor(centerlineType, branchSelection, index, callbackIdentifier, planeIdentifier) {
        super();
        this.centerlineType = centerlineType;
        this.branchSelection = branchSelection;
        this.index = (index | 0);
        this.callbackIdentifier = callbackIdentifier;
        this.planeIdentifier = planeIdentifier;
    }
}

export function CenterlinePlane$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlinePlane", [], CenterlinePlane, () => [["centerlineType", Visualization_Centerline$reflection()], ["branchSelection", Branch$reflection()], ["index", int32_type], ["callbackIdentifier", class_type("System.Guid")], ["planeIdentifier", Bindings_PlaneIdentifier$reflection()]]);
}

export class CenterlinePlaneRange extends Record {
    constructor(planeA, planeB) {
        super();
        this.planeA = planeA;
        this.planeB = planeB;
    }
}

export function CenterlinePlaneRange$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlinePlaneRange", [], CenterlinePlaneRange, () => [["planeA", CenterlinePlane$reflection()], ["planeB", CenterlinePlane$reflection()]]);
}

export class PlanePipeline extends Record {
    constructor(centerlinePlane, centerlinePlaneSource) {
        super();
        this.centerlinePlane = centerlinePlane;
        this.centerlinePlaneSource = centerlinePlaneSource;
    }
}

export function PlanePipeline$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.PlanePipeline", [], PlanePipeline, () => [["centerlinePlane", CenterlinePlane$reflection()], ["centerlinePlaneSource", class_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.ICenterlinePlaneSource")]]);
}

export class RangeResult extends Record {
    constructor(pipelines, rangeValues) {
        super();
        this.pipelines = pipelines;
        this.rangeValues = rangeValues;
    }
}

export function RangeResult$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.RangeResult", [], RangeResult, () => [["pipelines", array_type(PlanePipeline$reflection())], ["rangeValues", array_type(Bindings_CenterlinePointMeasurement$reflection())]]);
}

export class CenterlineMeasurementProps extends Record {
    constructor(planePipelines) {
        super();
        this.planePipelines = planePipelines;
    }
}

export function CenterlineMeasurementProps$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlineMeasurementProps", [], CenterlineMeasurementProps, () => [["planePipelines", array_type(PlanePipeline$reflection())]]);
}

